import { typedMemo } from '@helpers/react/typedMemo';
import { Dispatch, ForwardedRef, ReactElement, SetStateAction, forwardRef } from 'react';

import { appendClasses } from '@helpers/ui';

import { ITableRow, THeadCell } from '@app/types/TableTypes';

import TableCell from '../TableCell';

import './TableRenderCell.scss';

interface TableRenderCellProps<T> {
    headCell: THeadCell<T>;
    row: ITableRow<T>;
    setRow: Dispatch<SetStateAction<ITableRow<T>>>;
}

const TableRenderCell = typedMemo(
    forwardRef(<T extends {}>({ headCell, row, setRow }: TableRenderCellProps<T>, ref: any) => {
        if (headCell?.render) {
            return headCell.render(row, setRow, ref);
        }

        const stickyClasses = appendClasses([
            headCell.sticky === 'left' ? 'sticky-left' : '',
            headCell.sticky === 'right' ? 'sticky-right' : '',
        ]);

        return <TableCell className={stickyClasses} ref={ref} />;
    }),
) as <T>(props: TableRenderCellProps<T> & { ref?: ForwardedRef<any> }) => ReactElement;

export default TableRenderCell;
