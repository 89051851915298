import { useState } from 'react';

import { OpenInNew as OpenInNewIcon, Send } from '@components/icons';
import PageHeader from '@components/layout/PageHeader';
import SendSMSModal from '@components/overlays/SendSMSModal';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { openUrl } from '@helpers/ui';

import CCBrandView from '../brands/CCBrandView';
import CCRetailerView from '../retailers/CCRetailerView';

import './ControlCenterView.scss';

const ControlCenterView = () => {
    const { account } = useSparkplugAccount();
    const [showSendSMSModal, setShowSendSMSModal] = useState(false);

    if (!account) {
        return <></>;
    }

    const onOpenDashboard = () => {
        openUrl(`/${account._id}`);
    };

    return (
        <div className="control-center-view">
            <PageHeader
                title={[
                    {
                        url: `/control-center/accounts`,
                        title: 'Accounts',
                    },
                    account.name,
                ]}
                metaTitle={`${account.name} | Control Center`}
            >
                <Toolbar collapseOnMobile>
                    <Toolbar.Button
                        className="toolbar-group-start"
                        variant="flat"
                        color="blue"
                        endIcon={<OpenInNewIcon />}
                        onClick={onOpenDashboard}
                    >
                        Dashboard
                    </Toolbar.Button>

                    {account.type === 'retailer' && (
                        <Toolbar.Button
                            startIcon={<Send />}
                            variant="filled"
                            color="blue"
                            onClick={() => {
                                setShowSendSMSModal(true);
                            }}
                        >
                            Send Message
                        </Toolbar.Button>
                    )}
                </Toolbar>
            </PageHeader>

            {account.type === 'retailer' ? <CCRetailerView /> : null}

            {account.type === 'brand' ? <CCBrandView /> : null}

            <SendSMSModal
                isVisible={showSendSMSModal}
                onClose={() => {
                    setShowSendSMSModal(false);
                }}
            />
        </div>
    );
};

ControlCenterView.defaultProps = {
    showAdd: false,
    showSwitch: false,
};

export default ControlCenterView;
