import axios from 'axios';

import {
    ListInvoicesPathParams,
    ListInvoicesResponseBody,
    ListPaymentMethodsPathParams,
    ListPaymentMethodsResponseBody,
    ListSubscriptionsPathParams,
    ListSubscriptionsResponseBody,
} from '@sparkplug/lib';

export default class BillingAPI {
    static async listSubscriptions({
        groupId,
    }: ListSubscriptionsPathParams): Promise<ListSubscriptionsResponseBody> {
        return (await axios.get(`/api/v1/billing/subscriptions/${groupId}`)).data;
    }

    static async listInvoices({
        groupId,
    }: ListInvoicesPathParams): Promise<ListInvoicesResponseBody> {
        return (await axios.get(`/api/v1/billing/invoices/${groupId}`)).data;
    }

    static async listPaymentMethods({
        groupId,
    }: ListPaymentMethodsPathParams): Promise<ListPaymentMethodsResponseBody> {
        return (await axios.get(`/api/v1/billing/payment-methods/${groupId}`)).data;
    }
}
