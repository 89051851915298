import { SquareMerchantCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface SquarePosSpecificFieldsProps {
    credentials: SquareMerchantCredentials;
}

const SquarePosSpecificFields = ({ credentials }: SquarePosSpecificFieldsProps) => {
    const { merchantName, merchantId } = credentials as SquareMerchantCredentials & {
        merchantName: string;
    };

    return (
        <>
            <Form.TextField
                label="Merchant Name"
                name="merchantName"
                disabled
                defaultValue={merchantName}
            />
            <Form.TextField
                label="Merchant Id"
                name="merchantId"
                disabled
                defaultValue={merchantId}
            />
        </>
    );
};

export default SquarePosSpecificFields;
