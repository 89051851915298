import { Component, ComponentType } from 'react';
import { QueryClient } from 'react-query';

import { getPosConfigDetails } from '@core/accounts';

import Tabs from '@components/layout/Tabs';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import { useQueryClient } from '@hooks/QueryHooks';

import POSConfigDetails from './POSConfigDetails';
import POSConfigLocations from './POSConfigLocations';
import POSConfigProductInfo from './POSConfigProductInfo';

import './POSConfigDetailsModal.scss';

const log = require('../../../../log/Log')('DetailsModal');

interface DetailsModalProps extends IDefaultModalProps {
    configId: string;
}

interface WrappedDetailsModalProps extends DetailsModalProps {
    queryClient: QueryClient;
}

const withCustomHOC = (WrappedComponent: ComponentType<WrappedDetailsModalProps>) => {
    return (props: DetailsModalProps) => {
        const { configId, isVisible } = props;
        const queryClient = useQueryClient();

        return configId && isVisible ? (
            <WrappedComponent {...props} queryClient={queryClient} />
        ) : (
            <></>
        );
    };
};

interface DetailsModalState {
    busy: boolean;
    config: any | null;
    group: any | null;
    locations: any | null;
    locationInfos: any | null;
    brands: any | null;
    categories: any | null;
    products: any | null;
    configsShowRaw: boolean;
    productInfoShowBrands: boolean;
    productInfoShowCategories: boolean;
    productInfoShowProducts: boolean;
    loaded: boolean;
    currentTab: number;
}

class DetailsModal extends Component<WrappedDetailsModalProps, DetailsModalState> {
    constructor(props: WrappedDetailsModalProps) {
        super(props);
        this.state = {
            busy: false,
            config: null,
            group: null,
            locations: null,
            locationInfos: null,
            brands: null,
            categories: null,
            products: null,
            configsShowRaw: false,
            productInfoShowBrands: false,
            productInfoShowCategories: false,
            productInfoShowProducts: false,
            loaded: false,
            currentTab: 0,
        };
    }

    async componentDidMount() {
        const { configId, queryClient } = this.props;

        try {
            this.setState({ busy: true });

            const configData = (await getPosConfigDetails(
                configId,
                queryClient,
            )) as DetailsModalState;

            this.setState({
                ...configData,
                busy: false,
                loaded: true,
            });
        } catch (err) {
            log.e(`componentDidMount: ${err}`);
            this.setState({ busy: false });
        }
    }

    render() {
        const { isVisible, onClose } = this.props;

        const {
            busy,
            currentTab,

            config,
            group,
            configsShowRaw,

            locations,
            locationInfos,

            brands,
            categories,
            products,
            productInfoShowProducts,
            productInfoShowBrands,
            productInfoShowCategories,
            loaded,
        } = this.state;

        const tabs = [
            {
                title: 'Details',
            },
            {
                title: 'Locations',
            },
            {
                title: 'Data',
            },
        ];

        const modalTitle =
            loaded && group?.name
                ? `${group?.name} POS Config Details`
                : 'Loading POS Config Details...';

        return (
            <Modal
                className="pos-details-modal"
                isVisible={isVisible}
                onClose={onClose}
                size="extra-wide"
            >
                <Modal.Title onClose={onClose}>{modalTitle}</Modal.Title>

                <Modal.Content>
                    {!busy && (
                        <>
                            <Tabs color="neutral" value={currentTab}>
                                {tabs.map(({ title }, i) => (
                                    <Tabs.Tab
                                        key={i}
                                        label={title}
                                        value={i}
                                        onClick={() => this.setState({ currentTab: i })}
                                    />
                                ))}
                            </Tabs>

                            <Tabs.TabPanel value={currentTab} index={0}>
                                <POSConfigDetails
                                    {...{
                                        config,
                                        group,
                                        configsShowRaw,
                                        setState: (value: DetailsModalState) =>
                                            this.setState(value),
                                    }}
                                />
                            </Tabs.TabPanel>

                            <Tabs.TabPanel value={currentTab} index={1}>
                                <POSConfigLocations
                                    {...{
                                        locations,
                                        locationInfos,
                                    }}
                                />
                            </Tabs.TabPanel>

                            <Tabs.TabPanel value={currentTab} index={2}>
                                <POSConfigProductInfo
                                    {...{
                                        brands,
                                        categories,
                                        products,
                                        productInfoShowProducts,
                                        productInfoShowBrands,
                                        productInfoShowCategories,
                                        loaded,
                                        setState: (value: DetailsModalState) =>
                                            this.setState(value),
                                    }}
                                />
                            </Tabs.TabPanel>
                        </>
                    )}
                </Modal.Content>
            </Modal>
        );
    }
}

export default withCustomHOC(DetailsModal);
