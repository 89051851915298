import { useState } from 'react';

import { AccountTypeOptions, IndustryOptions } from '@constants/AccountConstants';

import { Industry } from '@sparkplug/lib';

import { createAccount } from '@core/accounts';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import PageLoading from '@components/layout/PageLoading';
import { useHistory } from '@components/router';

import { IAccount } from '@app/types/AccountsTypes';

import Modal from './Modal';

import './AddAccountModal.scss';

const AddAccountModal = ({
    isVisible,
    onClose,
}: {
    isVisible: boolean;
    onClose: (val?: boolean) => void;
}) => {
    const history = useHistory();

    const [industries, setIndustries] = useState<Industry[]>([]);
    const [savingAccount, setSavingAccount] = useState<boolean>(false);

    const onSave = (accountData: Pick<IAccount, 'name' | 'type' | 'hubspotId'>) => {
        setSavingAccount(true);
        createAccount({
            ...accountData,
            industries,
        })
            .then(({ _id }) => {
                history.push(`/control-center/accounts/${_id}/details`);
                onClose(true);
            })
            .catch(() => {
                onClose(false);
            })
            .finally(() => {
                setSavingAccount(false);
            });
    };

    return (
        <Modal className="cc-add-account-modal" isVisible={isVisible} onClose={onClose}>
            <Form>
                <Modal.Title>Add Account</Modal.Title>
                <PageLoading className={savingAccount ? 'show' : 'hide'} />
                <Modal.Content>
                    <Form.TextField label="Account Name" name="name" required />
                    <Form.Select
                        label="Account Type"
                        name="type"
                        defaultValue="brand"
                        required
                        options={AccountTypeOptions}
                    />
                    <Form.SearchSelect
                        required
                        label="Industries"
                        name="industries"
                        value={industries}
                        onChange={({ value }) => {
                            setIndustries([value] as Industry[]);
                        }}
                        options={IndustryOptions}
                    />
                    <Form.TextField label="Hubspot Account" name="hubspotId" required />
                </Modal.Content>

                <Modal.Actions>
                    <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                        Cancel
                    </Button>
                    <Form.Button color="blue" variant="filled" onClick={onSave}>
                        Add
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default AddAccountModal;
