import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

import { Spark, SparkDisplayStatus } from '@sparkplug/lib';

import { useGetSparksQuery } from '@core/sparks/queries/GetSparksQuery';

import { useSparksFilters } from '@features/spark-dashboard/hooks/useSparkFilters';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { noop } from '@helpers/util';

import { ISparksContext } from '@app/types/SparksTypes';

export const SparksContext = createContext<ISparksContext>({
    refetchSparks: noop,
    sparks: [],
    sparksAreReady: false,
    sparksIsLoading: false,
    sparkFilters: {
        location: [],
        status: 'active',
        schedule: [],
        training: [],
        type: [],
        requestState: undefined,
    },
    updateSparkFilters: (sparks: Spark[]): Spark[] => sparks,
    applySparkFilters: (sparks: Spark[]): Spark[] => sparks,
});

export const useSparks = () => useContext(SparksContext);

export const SparksProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { account, accountIsReady } = useSparkplugAccount();

    const { user, isAdminApp } = useApp();

    const initialSparkStatus = useMemo(() => {
        // this is the last segment of the route
        const statusSegment = window.location.pathname.split('/').pop();
        if (
            statusSegment &&
            ['active', 'upcoming', 'complete', 'sent', 'requests', 'inbox'].includes(statusSegment)
        ) {
            if (statusSegment === 'inbox' || statusSegment === 'sent') return 'inbox/sent';
            if (statusSegment === 'requests') return 'inbox/requests';
            return statusSegment as SparkDisplayStatus;
        }
        return 'active';
    }, [isAdminApp]);

    const { sparkFilters, updateSparkFilters, applySparkFilters } = useSparksFilters<Spark>({
        accountType: account?.type,
        initialFilters: {
            location: [],
            type: [],
            status: initialSparkStatus,
            schedule: [],
            training: [],
        },
    });
    const { refetchSparks, sparksAreReady, sparks, isLoading } = useGetSparksQuery({
        account,
        sparkFilters: {
            status: sparkFilters.status === 'inbox/sent' ? 'inbox' : sparkFilters.status,
        },
        isAdminApp,
        accountIsReady,
    });

    const value: ISparksContext = useMemo(
        () => ({
            refetchSparks,
            sparksAreReady,
            sparks,
            sparkFilters,
            updateSparkFilters,
            applySparkFilters,
            sparksIsLoading: isLoading,
        }),
        [
            sparksAreReady,
            refetchSparks,
            sparks,
            updateSparkFilters,
            sparkFilters,
            applySparkFilters,
            isLoading,
        ],
    );

    return <SparksContext.Provider value={value}>{children}</SparksContext.Provider>;
};
