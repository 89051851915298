import { FC, useMemo } from 'react';

import { ExclamationIcon } from '@components/icons';
import Toolbar from '@components/toolbar/Toolbar';

import { IOption } from '@app/types/UITypes';

import { ProductInventoryFilters } from '../ProductInventoryTable/useProductInventoryTableFilters';

export interface RetailerDashboardInventoryToolbarProps {
    showLocationsDropdown: boolean;
    filters: ProductInventoryFilters;
    updateFilters: (updatedFilters: Partial<ProductInventoryFilters>) => void;
    visibleBrandOptions: IOption[];
    visibleProductOptions: IOption[];
    visibleCategoryOptions: IOption[];
    visibleLocationOptions: IOption[];
    selectedBrandCountProps: {
        tooltipMessage: string | undefined;
        pluralUnitLabel: string;
    };
    selectedProductCountProps: {
        tooltipMessage: string | undefined;
        pluralUnitLabel: string;
    };
    selectedCategoryCountProps: {
        tooltipMessage: string | undefined;
        pluralUnitLabel: string;
    };
    selectedLocationCountProps: {
        tooltipMessage: string | undefined;
        pluralUnitLabel: string;
    };
    searchFilter: string;
    onChangeSearchFilter: (event: any) => void;
}

export const RetailerDashboardInventoryToolbar: FC<RetailerDashboardInventoryToolbarProps> = ({
    filters,
    updateFilters,
    visibleBrandOptions,
    selectedBrandCountProps,
    visibleProductOptions,
    selectedProductCountProps,
    visibleCategoryOptions,
    selectedCategoryCountProps,
    showLocationsDropdown,
    visibleLocationOptions,
    selectedLocationCountProps,
    searchFilter,
    onChangeSearchFilter,
}) => {
    const isAllBrandsSelected = useMemo(
        () =>
            visibleBrandOptions.length > 0 &&
            filters.sparkBrands &&
            filters.sparkBrands.length === visibleBrandOptions.length &&
            visibleBrandOptions.every((option) => filters.sparkBrands?.includes(option)),
        [visibleBrandOptions, filters.sparkBrands],
    );

    const isAllCategoriesSelected = useMemo(
        () =>
            visibleCategoryOptions.length > 0 &&
            filters.categories &&
            filters.categories.length === visibleCategoryOptions.length &&
            visibleCategoryOptions.every((option) => filters.categories?.includes(option)),
        [visibleCategoryOptions, filters.categories],
    );

    const isAllProductsSelected = useMemo(
        () =>
            visibleProductOptions.length > 0 &&
            filters.products &&
            filters.products.length === visibleProductOptions.length &&
            visibleProductOptions.every((option) => filters.products?.includes(option)),
        [visibleProductOptions, filters.products],
    );

    const isAllLocationsSelected = useMemo(
        () =>
            visibleLocationOptions.length > 0 &&
            filters.locations &&
            filters.locations.length === visibleLocationOptions.length &&
            filters.locations.every((option) => filters.locations?.includes(option)),
        [visibleLocationOptions, filters.locations],
    );

    return (
        <Toolbar className="retailer-dashboard-inventory-toolbar [&>.toolbar]:justify-start">
            <Toolbar.Search
                name="sparks-search"
                defaultValue={searchFilter}
                onChange={onChangeSearchFilter}
            />
            {showLocationsDropdown && (
                <Toolbar.DropdownListSelector
                    label={`${isAllLocationsSelected ? '(All) ' : ''}Locations`}
                    options={visibleLocationOptions}
                    selected={filters.locations || []}
                    onApply={(locations) => updateFilters({ ...filters, locations })}
                    showCount={!isAllLocationsSelected && filters.locations?.length > 0}
                    clear={{
                        active: !!filters.locations?.length,
                        onClear: () => {
                            updateFilters({ ...filters, locations: [] });
                        },
                    }}
                    selectedCountProps={selectedLocationCountProps}
                />
            )}
            <Toolbar.DropdownListSelector
                label={`${isAllBrandsSelected ? '(All) ' : ''}Brands`}
                options={visibleBrandOptions}
                selected={filters.sparkBrands || []}
                onApply={(sparkBrands) => updateFilters({ ...filters, sparkBrands })}
                showCount={!isAllBrandsSelected && filters.sparkBrands?.length > 0}
                clear={{
                    active: !!filters.sparkBrands?.length,
                    onClear: () => {
                        updateFilters({ ...filters, sparkBrands: [] });
                    },
                }}
                selectedCountProps={selectedBrandCountProps}
            />
            <Toolbar.DropdownListSelector
                label={`${isAllCategoriesSelected ? '(All) ' : ''}Categories`}
                options={visibleCategoryOptions}
                selected={filters.categories || []}
                onApply={(categories) => updateFilters({ ...filters, categories })}
                showCount={!isAllCategoriesSelected && filters.categories?.length > 0}
                clear={{
                    active: !!filters.categories?.length,
                    onClear: () => {
                        updateFilters({ ...filters, categories: [] });
                    },
                }}
                selectedCountProps={selectedCategoryCountProps}
            />
            <Toolbar.DropdownListSelector
                label={`${isAllProductsSelected ? '(All) ' : ''}Products`}
                options={visibleProductOptions}
                selected={filters.products || []}
                onApply={(products) => updateFilters({ ...filters, products })}
                showCount={!isAllProductsSelected && filters.products?.length > 0}
                clear={{
                    active: !!filters.products?.length,
                    onClear: () => {
                        updateFilters({ ...filters, products: [] });
                    },
                }}
                selectedCountProps={selectedProductCountProps}
            />
            <Toolbar.Checkbox
                label={
                    <div className="out-of-stock">
                        Show only&nbsp;
                        <span className="text-buttercup-700 flex items-center">
                            out-of-stock
                            <ExclamationIcon className="pl-1" />
                        </span>
                    </div>
                }
                value={filters.outOfStock}
                onChange={() => updateFilters({ ...filters, outOfStock: !filters.outOfStock })}
            />
        </Toolbar>
    );
};
