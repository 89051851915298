import { FC, useEffect, useMemo, useState } from 'react';

import { ChartViewOptions } from '@constants/ChartConstants';
import { uniq } from 'lodash';

import { Spark, TChartDataMetric, TComparisonPeriodOption } from '@sparkplug/lib';

import { useChartContext } from '@core/charts/components/AdvancedChart/ChartContext';
import { useComparisonChartContext } from '@core/charts/components/AdvancedComparisonChart/ComparisonChartContext';

import { useVendorRetailerDataPermissions } from '@features/account-links';
import ProductInventoryTable from '@features/inventory/components/ProductInventoryTable';

import BreakdownChart from '@components/charts/BreakdownChart';
import ComparisonChart from '@components/charts/ComparisonChart';
import {
    SparkExpiredOrRejectedGraphic,
    SparkPendingGraphic,
    SparkScheduledGraphic,
} from '@components/graphics';
import { TableChart as TableChartIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Paper from '@components/layout/Paper';
import Tabs from '@components/layout/Tabs';
import SparkLeaderboardsPaper from '@components/sparks/SparkLeaderboardsPaper';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { ICustomSparkChartSettings, useSpark, useSparkPosArchive } from '@hooks/SparksHooks';
import { getDefaultSparkChartDataSettings } from '@hooks/SparksHooks/useSparkCloudChartData';

import {
    formatSparkInfo,
    getSelectedLocationsFromSparkSubGroups,
    isPercentIncreaseSpark,
} from '@helpers/sparks';

import { IChartDataSettings, TChartType } from '@app/types/ChartDataTypes';
import { TSparkStatus } from '@app/types/SparksTypes';
import { IOption } from '@app/types/UITypes';

/**
 * This is an example of what the new `SparkChart` component might look like. The key here is that the
 * query hook will use the settings from the new `SparkChartContext` and the Spark itself in order to make
 * the right query calls. Note that this is an example for a Breakdown Chart because it's more simple, but
 * a Comparison Chart will be more complex. Look at `VendorMarketComparisonChart` for an example of how
 * that might look. This example is pulled from `VendorBreakdownChart`, and written to show how the use
 * of queries between archived and non-archived Sparks might look

const useVendorChartBucketBreakdown = (
    params: GetSparkChartBreakdownPathParams,
    incomingBody: GetSparkChartRequestBody,
    isEnabled: boolean = true,
): {
    isLoading: boolean;
    bucketsKeysByLabel: BucketsKeyedByLabel;
} => {
    const { sparkId, calcType, breakdownType } = params;
    const { spark } = useSparkQuery(sparkId);
    const sparkIsArchived = !!spark.archivedAt;

    // If the spark is archived, we need to use this query hook to get the data
    const { isFetched: sparkPosArchiveIsReady, data: sparkPosArchive } = useSparkPosArchive(sparkId, isEnabled && sparkIsArchived);

    // If the spark is archived return this data
    const { isFetched: bucketsAreReady, data } = useAdvancedQuery(
        ['sparkChartBucketBreakdown', params, incomingBody],
        () => getSparkChartBucketBreakdown(params, incomingBody),
        {
            enabled: isEnabled && !sparkIsArchived,
        },
    );

    const bucketsKeyedByLabel = useMemo(() => {
        if (sparkIsArchived) {
            // If the spark is archived, we can write a function to transform the data into a shape ready for the `AdvancedChart`
            return transformPosArchiveToBucketsKeyedByLabel(sparkPosArchive);
        }

        // Example for using the current chart endpoint if we just want to do the UI refactor
        if (useOldChartEndpoint) {
            // If the spark is not archived and we want to use the current chart endpoint, but do
            // the component refactor, we can write a function to create the `BucketsKeyedByLabel`
            // from the old chart data
            return transformOldChartEndpointDataToBucketsKeyedByLabel(oldCloudChartData, sparkPosData);
        }

        // If the spark is not archived and we want to use the new chart endpoint, then we can just
        // return the data as is because the server should have done all the heavy lifting and keying
        return data?.buckets;
    }, [sparkIsArchived, data]);

    return {
        bucketsAreReady: isArchived ? sparkPosArchiveIsReady : bucketsAreReady,
        bucketsKeysByLabel: data?.buckets,
    };
};

const SparkBreakdownChart = () => {
    const { sparkId, chartSettings, chartBucketFactory, chartValueFormatter } =
        useSparkChartContext();
    const 

    const { bucketsAreReady, bucketsKeysByLabel } =
        useVendorChartBucketBreakdown(
            {
                sparkId,
                calcType: chartSettings.metric,
                breakdownType: chartSettings.breakdown,
            },
            chartSettings,
            true,
        );

    if (!bucketsAreReady) {
        return <Skeleton height={400} />;
    }

    return (
        <AdvancedChart
            settings={chartSettings}
            data={bucketsKeysByLabel}
            chartBucketFactory={chartBucketFactory}
            chartValueFormatter={chartValueFormatter}
        />
    );
};

 */

export interface ToolbarLocationsDropdownProps {
    activeLocationValue: string;
    chartLocationOptions: IOption[];
    onUpdateChartSettings: (updatedSettings: ICustomSparkChartSettings) => void;
}

export const ToolbarLocationsDropdown: FC<ToolbarLocationsDropdownProps> = ({
    activeLocationValue,
    onUpdateChartSettings,
    chartLocationOptions,
}) => (
    <Toolbar.Dropdown
        label={null}
        value={activeLocationValue}
        onSelect={(locationIds: string) =>
            onUpdateChartSettings({
                locationIds: locationIds.split(','),
            })
        }
        options={chartLocationOptions}
    />
);

interface SparkDetailsChartToolbarProps extends ToolbarLocationsDropdownProps {
    showLocationsDropdown: boolean;
    showComparisonWindows: boolean;
    comparisonPeriod: TComparisonPeriodOption;
    setShowComparisonWindows: (updatedValue: boolean) => void;
    setComparisonPeriod: (updatedValue: TComparisonPeriodOption) => void;
    chartViewType: ICustomSparkChartSettings['type'];
    hideComparisonToggle?: boolean;
    showBreakdownAndTableToggle?: boolean;
    sparkStatus: TSparkStatus;
    isArchivedSpark?: boolean;
}

const SparkChartBreakdownOptions = [
    {
        label: 'None',
        value: 'none',
    },
    {
        label: 'Location',
        value: 'location',
    },
    {
        label: 'Category',
        value: 'category',
    },
    {
        label: 'Product',
        value: 'product',
    },
];

export const SparkDetailsChartToolbar: FC<SparkDetailsChartToolbarProps> = ({
    showLocationsDropdown,
    activeLocationValue,
    onUpdateChartSettings,
    chartLocationOptions,
    showComparisonWindows,
    comparisonPeriod,
    setShowComparisonWindows,
    setComparisonPeriod,
    chartViewType,
    hideComparisonToggle = false,
    showBreakdownAndTableToggle = true,
    sparkStatus,
    isArchivedSpark = false,
}) => {
    const { chartSettings, updateChartSettings, onUpdateChartSetting } = useChartContext();

    // comparison + breakdown is only available in table view
    const COMPARETO_BREAKDOWN_REMINDER =
        'The "Compare to" view with "Breakdown" enabled is only available in Table view.';
    const viewOptions = useMemo(
        () => [
            ...ChartViewOptions.map((option) => {
                const disabled =
                    showComparisonWindows &&
                    chartSettings.breakdown !== 'none' &&
                    option.value !== 'table';
                return {
                    ...option,
                    disabled,
                    tooltipProps: disabled ? { title: COMPARETO_BREAKDOWN_REMINDER } : undefined,
                };
            }),
            // we currently only support table view for total units and sales
            ...(showBreakdownAndTableToggle
                ? [
                      {
                          StartIcon: TableChartIcon,
                          label: 'Table',
                          value: 'table',
                      },
                  ]
                : []),
        ],
        [chartSettings.breakdown, showComparisonWindows, showBreakdownAndTableToggle],
    );

    const sparkBreakdownOptions = useMemo(() => {
        // archived spark data does not currently include product/category info
        const availableOptions = isArchivedSpark
            ? SparkChartBreakdownOptions.filter((option) =>
                  ['location', 'none'].includes(option.value),
              )
            : SparkChartBreakdownOptions;

        return availableOptions.map((option) => {
            if (option.value === 'location') {
                const disabled = !showLocationsDropdown; // means there's only 1 location
                return {
                    ...option,
                    disabled,
                    tooltipProps: disabled
                        ? { title: 'This retailer only has 1 location' }
                        : undefined,
                };
            }

            return option;
        });
    }, [isArchivedSpark, showLocationsDropdown]);

    // if comparison is enabled and breakdown is not none, force table view
    useEffect(() => {
        const forceTableView = showComparisonWindows && chartSettings.breakdown !== 'none';

        if (forceTableView && chartSettings.type !== 'table') {
            updateChartSettings({ type: 'table' });
        }
    }, [chartSettings.metric, chartSettings.breakdown, showComparisonWindows]);

    // if breakdown or table is enabled and we switch away from a supported metric, switch back to line chart and clear metric
    useEffect(() => {
        if (
            (chartSettings.breakdown !== 'none' || chartSettings.type === 'table') &&
            !showBreakdownAndTableToggle
        ) {
            updateChartSettings({ type: 'line', breakdown: 'none' });
        }
    }, [
        chartSettings.metric,
        chartSettings.breakdown,
        chartSettings.type,
        showBreakdownAndTableToggle,
    ]);

    return (
        <Toolbar>
            {showLocationsDropdown && (
                <ToolbarLocationsDropdown
                    activeLocationValue={activeLocationValue}
                    onUpdateChartSettings={onUpdateChartSettings}
                    chartLocationOptions={chartLocationOptions}
                />
            )}

            {!hideComparisonToggle && (
                <ComparisonChart.ToolbarDropdownToggle
                    isActive={showComparisonWindows}
                    value={comparisonPeriod}
                    onToggle={(enabled: boolean) => {
                        setShowComparisonWindows(enabled);
                    }}
                    onChange={setComparisonPeriod}
                />
            )}

            {showBreakdownAndTableToggle && ['Active', 'Complete'].includes(sparkStatus) && (
                <Toolbar.Dropdown
                    label="Breakdown"
                    value={chartSettings.breakdown}
                    onSelect={onUpdateChartSetting('breakdown')}
                    options={sparkBreakdownOptions}
                    clear={{
                        active: chartSettings.breakdown !== 'none',
                        onClear: () => {
                            updateChartSettings({ breakdown: 'none' });
                        },
                    }}
                />
            )}

            <Toolbar.Dropdown
                className="toolbar-group-end"
                label={null}
                value={chartViewType}
                onSelect={(type: TChartType) => onUpdateChartSettings({ type })}
                options={viewOptions}
            />
        </Toolbar>
    );
};

interface ChartDetailsPaperProps {
    isCalculatingChartData: boolean;
    calculatorData: any;
    chartSettings: IChartDataSettings;
    spark: Spark;
    transactionsExist: boolean;
    onUpdateChartSettings: (updatedSettings: ICustomSparkChartSettings) => void;
}
const SparkDetailsChartPaper: FC<ChartDetailsPaperProps> = ({
    isCalculatingChartData,
    calculatorData: originalCalculatorData,
    chartSettings: originalChartSettings,
    spark,
    transactionsExist,
    onUpdateChartSettings: originalUpdateChartSettings,
}) => {
    const {
        sparkIsReady,
        sparkPosDataIsReady,
        detailedSparkType,
        sparkPosData: { locations, products },
        sparkSubGroups,
    } = useSpark();

    const chartContextValue = useChartContext();
    const {
        showComparisonWindows,
        setShowComparisonWindows,
        comparisonPeriod,
        setComparisonPeriod,
    } = useComparisonChartContext();

    const chartSettings =
        chartContextValue?.chartSettings ?? getDefaultSparkChartDataSettings(spark);
    const updateChartSettings = chartContextValue?.updateChartSettings ?? (() => {});

    const [contentTab, setContentTab] = useState<'sales' | 'inventory'>('sales');

    const { account } = useSparkplugAccount();

    const sparkLocations = useMemo(() => {
        const subGroupsLocations = getSelectedLocationsFromSparkSubGroups(sparkSubGroups);

        return detailedSparkType === 'leaderboardMulti' ? subGroupsLocations : locations;
    }, [detailedSparkType, sparkPosDataIsReady, locations, sparkSubGroups]);

    const activeLocations = useMemo(() => {
        if (!chartSettings.locationIds?.length) {
            return sparkLocations;
        }

        return sparkLocations.filter(({ value }) => chartSettings.locationIds.includes(value));
    }, [chartSettings.locationIds, sparkLocations]);

    const activeLocationValue = useMemo(() => {
        return activeLocations.map(({ value }) => value).join(',');
    }, [activeLocations]);

    const chartLocationOptions = useMemo<IOption[]>(
        () => [
            {
                value: sparkLocations.map(({ value }) => value).join(','),
                label: `All ${sparkLocations.length} Locations`,
            },
            ...sparkLocations,
        ],
        [sparkLocations],
    );

    const { status } = formatSparkInfo(
        spark.startDate,
        spark.endDate,
        spark.requestState,
        spark.recurringSchedule,
    );

    const isAcceptedSpark = ['accepted', 'none'].includes(spark?.requestState || '');
    const customStatusMessage =
        spark?.requestState === 'rejected' ? 'declined' : spark?.requestState;

    let emptyStateGraphic = (
        <SparkExpiredOrRejectedGraphic className="expired-or-rejected-graphic" />
    );
    if (isAcceptedSpark) {
        emptyStateGraphic = <SparkScheduledGraphic />;
    } else if (spark?.requestState === 'pending') {
        emptyStateGraphic = <SparkPendingGraphic />;
    }

    const showLocationsDropdown =
        sparkIsReady &&
        sparkPosDataIsReady &&
        !['leaderboardMulti', 'goalTeam'].includes(detailedSparkType || '') &&
        sparkLocations.length > 1;

    const { shareInventoryData, shareSalesData } = useVendorRetailerDataPermissions({
        vendorAccountId: account?._id,
        retailerAccountId: spark.groupId,
        isEnabled: account?.type === 'brand',
    });
    // Get Sparkchive to see if we actually have sales data for completed spark
    const { data: sparkPosArchive } = useSparkPosArchive(
        spark._id,
        !!spark.archivedAt && account?.type === 'brand',
    );

    // Vendor data permissions
    const { inventoryEnabled, metricOptions } = useMemo(() => {
        const hasSalesData = spark.archivedAt ? !!sparkPosArchive?.otherCharts?.total_sales : true;

        if (account?.type === 'retailer') {
            let retailerMetricOptions: TChartDataMetric[] = [];
            if (hasSalesData && !spark.originatorGroupId) {
                retailerMetricOptions =
                    spark?.metric === 'percent_increase'
                        ? ['order_average']
                        : uniq<TChartDataMetric>([spark?.metric, 'total_sales']);
            }

            return {
                inventoryEnabled: true,
                // Show total sales on retailer-created sparks
                metricOptions: retailerMetricOptions.length ? retailerMetricOptions : undefined,
            };
        }

        return {
            metricOptions:
                shareSalesData && hasSalesData
                    ? uniq<TChartDataMetric>([spark?.metric, 'total_sales'])
                    : undefined,
            inventoryEnabled: shareInventoryData,
        };
    }, [account, shareInventoryData, shareSalesData, spark.groupId, sparkPosArchive]);

    const showBreakdownAndTableToggle = useMemo(
        () => ['total_sales', 'total_units'].includes(chartSettings.metric),
        [chartSettings.metric],
    );

    const isSales = contentTab === 'sales';
    const showInventory = status === 'Active' || status === 'Upcoming' || status === 'Pending';

    return (
        <Paper className="bottom">
            {showInventory && (
                <Tabs color="blue" value={contentTab}>
                    <Tabs.Tab
                        key="sales"
                        label="Sales"
                        value="sales"
                        onClick={() => setContentTab('sales')}
                    />

                    <Tabs.Tab
                        key="inventory"
                        label="Inventory"
                        value="inventory"
                        onClick={() => setContentTab('inventory')}
                    />
                </Tabs>
            )}
            {isSales && (
                <>
                    {isAcceptedSpark &&
                    (transactionsExist || status === 'Complete' || isCalculatingChartData) ? (
                        <>
                            <SparkDetailsChartToolbar
                                {...{
                                    showLocationsDropdown,
                                    activeLocationValue,
                                    onUpdateChartSettings: updateChartSettings,
                                    chartLocationOptions,
                                    showComparisonWindows,
                                    comparisonPeriod,
                                    setShowComparisonWindows,
                                    setComparisonPeriod,
                                    chartViewType: chartSettings.type,
                                    hideComparisonToggle: isPercentIncreaseSpark(spark),
                                    showBreakdownAndTableToggle,
                                    sparkStatus: status,
                                    isArchivedSpark: !!spark.archivedAt,
                                }}
                            />
                            {/** This should be replaced with something like `SparkChart`. See large comment example above */}
                            {chartSettings.breakdown === 'none' ||
                            chartSettings.type === 'table' ? (
                                <ComparisonChart
                                    isSparkComparison
                                    showLegend={chartSettings.type !== 'table'}
                                    showComparisonPeriod={showComparisonWindows}
                                    chartSettings={chartSettings}
                                    locations={activeLocations}
                                    currentPeriodLabel="Current Spark"
                                    metricOptions={metricOptions}
                                />
                            ) : (
                                <BreakdownChart
                                    metricOptions={
                                        shareSalesData ? ['total_units', 'total_sales'] : undefined
                                    }
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {showLocationsDropdown && (
                                <ToolbarLocationsDropdown
                                    {...{
                                        activeLocationValue,
                                        onUpdateChartSettings: updateChartSettings,
                                        chartLocationOptions,
                                    }}
                                />
                            )}

                            <div className="spark-chart-no-transactions">
                                <EmptyStateDisplay
                                    graphic={emptyStateGraphic}
                                    label={
                                        status === 'Active'
                                            ? 'No Sales Data Yet'
                                            : `spark ${
                                                  isAcceptedSpark
                                                      ? 'scheduled'
                                                      : customStatusMessage
                                              }`
                                    }
                                />
                            </div>
                        </>
                    )}
                </>
            )}
            {!isSales && showInventory && (
                <ProductInventoryTable
                    accountType={account?.type}
                    locations={sparkLocations}
                    products={products}
                    showLocationsDropdown={showLocationsDropdown}
                    inventoryEnabled={inventoryEnabled}
                    retailerAccountId={spark.groupId}
                    isLoading={!sparkPosDataIsReady}
                />
            )}
        </Paper>
    );
};

interface ISparkDetailsChartPaperWrapperProps {
    isCalculatingChartData: boolean;
    spark: Spark;
    calculatorData: any;
    chartSettings: any;
    transactionSummariesExist: boolean;
    onUpdateChartSettings: (updatedSettings: ICustomSparkChartSettings) => void;
}

const SparkDetailsChartPaperWrapper = ({
    isCalculatingChartData,
    calculatorData,
    chartSettings,
    spark,
    transactionSummariesExist,
    onUpdateChartSettings,
}: ISparkDetailsChartPaperWrapperProps) => {
    const { detailedSparkType } = useSpark();

    const isMultiLeaderboard =
        detailedSparkType === 'leaderboardMulti' &&
        ['accepted', 'none'].includes(spark.requestState ?? '');

    const ChartPaper = (
        <SparkDetailsChartPaper
            isCalculatingChartData={isCalculatingChartData}
            calculatorData={calculatorData}
            chartSettings={chartSettings}
            spark={spark}
            transactionsExist={transactionSummariesExist}
            onUpdateChartSettings={onUpdateChartSettings}
        />
    );

    return (
        <div className="spark-details-container">
            <Grid className="spark-details-chart-container">
                {isMultiLeaderboard ? (
                    <>
                        <Grid.Item sm={3}>
                            <SparkLeaderboardsPaper />
                        </Grid.Item>
                        <Grid.Item sm={9}>{ChartPaper}</Grid.Item>
                    </>
                ) : (
                    <Grid.Item sm={12}>{ChartPaper}</Grid.Item>
                )}
            </Grid>
        </div>
    );
};

export default SparkDetailsChartPaperWrapper;
