import { Emoji } from 'emoji-mart';
import moment from 'moment';

import { Credit, Debit, Spark } from '@sparkplug/lib';

import { NoInvoicesIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import { Link } from '@components/router';
import Table from '@components/table/Table';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { appendClasses } from '@helpers/ui';

import { THeadCell } from '@app/types/TableTypes';

import './SparkCreditsTable.scss';

type RowDetails = {
    key: string;
    spark?: Spark;
    username?: string;
    validRetailer: string;
};
export type SparkCreditsTableRow = (Credit & RowDetails) | (Debit & RowDetails);

const creditTypeLabels = {
    plan: 'Plan Credit',
    promotion: 'Promotion Credit',
    referral: 'Referral Credit',
};

export const getAmountLabel = (amount: number) => {
    if (amount === 0) {
        return '--';
    }

    const isNegative = amount < 0;
    return `${isNegative ? '-' : '+'} $${Math.abs(amount).toLocaleString('en', {
        minimumFractionDigits: 2,
    })}`;
};

const sparkLedgerTableHeadCells: THeadCell<SparkCreditsTableRow>[] = [
    {
        id: 'createdAt',
        label: 'Created On',
        sortType: 'date',
        render: ({ createdAt }) => <Table.Cell>{moment(createdAt).format('ll')}</Table.Cell>,
    },
    {
        id: 'username',
        label: 'User',
        sortType: 'string',
        render: ({ username }) => <Table.Cell>{username}</Table.Cell>,
    },
    {
        id: 'creditType',
        label: 'Type',
        sortType: 'string',
        render: (row) => {
            const { creditType } = row as Credit & RowDetails;
            return <Table.Cell>{creditTypeLabels[creditType] ?? '--'}</Table.Cell>;
        },
    },
    {
        id: 'validRetailer',
        label: 'Valid Towards',
        sortType: 'string',
        render: ({ validRetailer }) => (
            <Table.Cell className="valid-towards">
                {validRetailer === 'Any Retailer' ? (
                    <strong>{validRetailer}</strong>
                ) : (
                    validRetailer
                )}
            </Table.Cell>
        ),
    },
    {
        id: 'amount',
        label: 'Amount',
        sortType: 'numeric',
        render: ({ amount = 0 }) => (
            <Table.Cell>
                <div
                    className={appendClasses([
                        'ledger-entry-label',
                        amount > 0 ? 'is-credit' : 'is-debit',
                    ])}
                >
                    {getAmountLabel(amount)}
                </div>
            </Table.Cell>
        ),
    },
    {
        id: 'spark.name',
        label: 'Spark',
        sortType: 'string',
        render: ({ spark }) => {
            const { account } = useSparkplugAccount();

            if (!spark || !account) {
                return (
                    <Table.Cell>
                        <></>
                    </Table.Cell>
                );
            }

            const routeToSpark = `/${account?._id}/sparks/${spark?._id}`;
            return (
                <Table.Cell>
                    <Link
                        className="spark-label"
                        to={routeToSpark}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Emoji emoji={spark.emoji} set="apple" size={18} />
                        <span className="spark-name">{spark.name}</span>
                    </Link>
                </Table.Cell>
            );
        },
    },
];

export const SparkCreditsTable = ({
    isReady,
    rows = [],
    filters = [],
}: {
    isReady: boolean;
    rows: SparkCreditsTableRow[];
    filters: (<T>(items: T[]) => T[])[];
}) => {
    if (isReady && !rows?.length) {
        return (
            <EmptyStateDisplay
                className="spark-credits-table_empty-state"
                graphic={<NoInvoicesIcon />}
                label="no spark credits!"
            />
        );
    }

    return (
        <Table
            className="spark-credits-table"
            isLoading={!isReady}
            variant="raised"
            rows={rows}
            headCells={sparkLedgerTableHeadCells}
            filters={filters}
            defaultOptions={{
                orderBy: 'createdAt',
                order: 'desc',
            }}
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};
