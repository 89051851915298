import { FC, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';

import { AccountLink, AccountType, CreateCreditRequest, CreditType } from '@sparkplug/lib';

import { useAccountLinks, useSuggestedAccountLinks } from '@features/account-links/queries';
import { useCreateCreditMutation } from '@features/spark-credits/mutations/SparkCreditMutations';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';

import { sortByString } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';

import './SparkCreditsModal.scss';

const getCreditTypeOptions = (accountType: AccountType) => {
    const options: { value: CreditType; label: string }[] = [
        { value: 'plan', label: 'Plan Credit' },
        { value: 'promotion', label: 'Promotion Credit' },
    ];

    if (accountType === 'brand') {
        return options.concat({ value: 'referral', label: 'Referral Credit' });
    }

    return options;
};

const mapAccountLinkToInputOptions = (
    accountLink: AccountLink,
): { value: string; label: string } => ({
    value: accountLink.accountId,
    label: accountLink.accountName,
});

export const useReferableRetailerOptionsForAccountLinks = (account: IAccount) => {
    const { accountLinks: currentAccountLinks = [] } = useAccountLinks(
        account._id,
        account.type === 'brand',
    );
    const { suggestedAccountLinks = [] } = useSuggestedAccountLinks(
        account._id,
        account.type === 'brand',
    );

    return useMemo(() => {
        if (account.type === 'retailer') {
            return [];
        }

        const linkedRetailerOptions = currentAccountLinks.map(mapAccountLinkToInputOptions);
        const nonLinkedRetailerOptions = suggestedAccountLinks.map(mapAccountLinkToInputOptions);

        return linkedRetailerOptions
            .concat(nonLinkedRetailerOptions)
            .sort(sortByString('label', 'asc'));
    }, [account, currentAccountLinks, suggestedAccountLinks]);
};

interface SparkCreditsModalProps {
    account: IAccount;
    isVisible: boolean;
    onClose: (didCreateCredit?: boolean) => void;
}
export const SparkCreditsModal: FC<SparkCreditsModalProps> = ({ account, isVisible, onClose }) => {
    const [amount, setAmount] = useState<number>();
    const [creditType, setCreditType] = useState<CreditType>();
    const [forGroupId, setForGroupId] = useState<string | undefined>();

    const { user } = useApp();

    const cannotCreateCredit =
        Number.isNaN(Number(amount)) ||
        (amount ?? 0) <= 0 ||
        !creditType ||
        // Plan/Promo credit types should never have a forGroupId
        !!(['plan', 'promo'].includes(creditType) && forGroupId) ||
        // Referral credits must have a forGroupid
        (creditType === 'referral' && !forGroupId);

    const { createCredit } = useCreateCreditMutation(account._id);
    const handleCreateCredit = async () => {
        if (!user?._id || cannotCreateCredit) {
            return;
        }
        await createCredit({
            ...(creditType && { creditType }),
            ...(amount && { amount }),
            ...(forGroupId && { forGroupId }),
            authorizedBy: user._id,
        } as CreateCreditRequest);
        onClose(true);
    };

    const referableRetailerOptions = useReferableRetailerOptionsForAccountLinks(account);

    return (
        <Modal className="spark-credits-modal" isVisible={isVisible} onClose={onClose}>
            <Form>
                <Modal.Title>{`Create Spark Credit for ${account.name}`}</Modal.Title>

                <Modal.Content>
                    <Form.Select
                        required
                        name="spark-credits_type"
                        label="Type"
                        options={getCreditTypeOptions(account.type)}
                        value={creditType ?? ''}
                        onChange={(e) => setCreditType(e.target.value as CreditType)}
                    />

                    {creditType === 'referral' && (
                        <Form.SearchSelect
                            required
                            name="spark-credits_valid-towards"
                            label="Valid Towards"
                            options={referableRetailerOptions}
                            value={forGroupId ?? ''}
                            onChange={(e) => setForGroupId(e.value)}
                        />
                    )}

                    <Form.Label>Amount</Form.Label>
                    <NumberFormat
                        required
                        thousandSeparator
                        value={amount}
                        className="money-textfield"
                        name="spark-credits_amount"
                        customInput={Form.TextField}
                        onValueChange={({ value }) => setAmount(Number(value))}
                    />
                </Modal.Content>

                <Modal.Actions>
                    <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                        Cancel
                    </Button>

                    <Form.Button
                        color="blue"
                        variant="filled"
                        onClick={handleCreateCredit}
                        disabled={cannotCreateCredit}
                    >
                        Create Credit
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};
