import { useState } from 'react';

import { useErrorToastOnDuplicateApiKey } from '@views/control-center/retailers/components/useToastOnDuplicateApiKey';

import { TreezCredentials, TreezLocation } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Add } from '@components/icons';
import Switch from '@components/inputs/Switch';
import Paper from '@components/layout/Paper';

const TreezLocationFields = ({
    i,
    initialLocation,
    onUpdateLocation,
    onRemoveLocation,
}: {
    i: number;
    initialLocation?: TreezLocation;
    onUpdateLocation: (i: number, key: string, value: any) => void;
    onRemoveLocation: (i: number) => void;
}) => {
    return (
        <Paper className="repeating-field-group" variant="outlined">
            <Form.TextField
                label="Location ID"
                name={`locations.${i}.id`}
                required
                defaultValue={initialLocation?.id}
                onChange={(event) => onUpdateLocation(i, 'id', event.target.value)}
            />
            <Form.TextField
                label="Name"
                name={`locations.${i}.name`}
                disabled={initialLocation?.name?.length! > 0}
                required
                defaultValue={initialLocation?.name}
                onChange={(event) => onUpdateLocation(i, 'name', event.target.value)}
            />
            <Form.TextField
                label="API Key"
                name={`locations.${i}.apiKey`}
                required
                defaultValue={initialLocation?.apiKey}
                onChange={(event) => onUpdateLocation(i, 'apiKey', event.target.value)}
            />
            <Form.Switch
                label="Funnel pre-ordered transactions"
                value={initialLocation?.funnelPreOrders ?? false}
                name={`locations.${i}.funnelPreOrders`}
                onChange={(event: any) =>
                    onUpdateLocation(i, 'funnelPreOrders', event.target.checked)
                }
            />
            <Button
                className="repeating-fields-button-remove"
                color="neutral"
                variant="smooth"
                onClick={() => onRemoveLocation(i)}
            >
                Remove Location
            </Button>
        </Paper>
    );
};

interface TreezPosSpecificFieldsProps {
    credentials: TreezCredentials;
    onChange: (key: string, value: any) => void;
}

const TreezPosSpecificFields = ({ credentials, onChange }: TreezPosSpecificFieldsProps) => {
    const filterSupurb = credentials?.filterSupurb || false;
    const [initialLocations] = useState(credentials?.locations || []);
    const locations = credentials?.locations || [
        {
            id: '',
            name: '',
            apiKey: '',
            funnelPreOrders: false,
        },
    ];

    useErrorToastOnDuplicateApiKey(locations);

    const onUpdateLocation = (i: number, key: string, value: any) => {
        const newLocations = [...locations];
        newLocations[i][key as keyof TreezLocation] = value;
        onChange('locations', newLocations);
    };

    const onAddLocation = () => {
        const newLocations = [...locations];
        newLocations.push({
            id: '',
            name: '',
            apiKey: '',
            funnelPreOrders: false,
        });
        onChange('locations', newLocations);
    };

    const onRemoveLocation = (i: number) => {
        const newLocations = locations.filter((location, j) => j !== i);
        onChange('locations', newLocations);
    };

    return (
        <>
            <div className="form-control repeating-fields">
                <Form.Label>Locations</Form.Label>
                <div className="repeating-fields-fields">
                    {locations.map((location, i) => (
                        <TreezLocationFields
                            key={`treez-credentials-locations-${i}`}
                            i={i}
                            initialLocation={initialLocations?.[i]}
                            onUpdateLocation={onUpdateLocation}
                            onRemoveLocation={onRemoveLocation}
                        />
                    ))}
                </div>
                <Switch
                    name="filterSupurb"
                    label='Filter "Supurb" Delivery Orders'
                    value={filterSupurb}
                    onChange={(event: { target: { checked: boolean } }) =>
                        onChange('filterSupurb', event.target.checked)
                    }
                />
                <Button
                    className="repeating-fields-button-add"
                    color="blue"
                    variant="smooth"
                    onClick={onAddLocation}
                    startIcon={<Add />}
                >
                    Add Location
                </Button>
            </div>
        </>
    );
};

export default TreezPosSpecificFields;
