import { InvoiceStatus } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';

import { TStandardColors } from '@app/types/UITypes';

const StripeInvoiceStatuses: {
    label: string;
    color: TStandardColors;
    value: InvoiceStatus | 'deleted';
}[] = [
    { label: 'Deleted', color: 'dark-gray', value: 'deleted' },
    { label: 'Draft', color: 'light-gray', value: 'draft' },
    { label: 'Open', color: 'neutral', value: 'open' },
    { label: 'Paid', color: 'green', value: 'paid' },
    { label: 'Uncollectible', color: 'red', value: 'uncollectible' },
    { label: 'Void', color: 'dark-gray', value: 'void' },
];

export const InvoiceStatusChip = ({ status }: { status: InvoiceStatus }) => {
    const invoiceStatus = StripeInvoiceStatuses.find((obj) => status === obj.value);

    if (!invoiceStatus) {
        return <></>;
    }
    return <Chip dense color={invoiceStatus.color} label={invoiceStatus.label} />;
};
