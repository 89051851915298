import { FC, ReactElement, RefObject } from 'react';

import Button from '@components/buttons/Button';
import { ChevronLeft as BackIcon } from '@components/icons';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';

import { appendClasses } from '@helpers/ui';

import './AppHeaderMobile.scss';

interface AppHeaderMobileProps {
    innerRef?: RefObject<HTMLDivElement>;
    back?: {
        to?: string;
        component?: string;
    };
    title?: string;
    subNav?: ReactElement;
    hideAppFooter?: boolean;
}

const AppHeaderMobile: FC<AppHeaderMobileProps> = ({
    innerRef,
    back,
    title,
    subNav,
    hideAppFooter = false,
}) => {
    const { history } = useApp();

    const classNamesAppended = appendClasses([
        'app-header',
        'app-header-mobile',
        subNav ? 'has-subnav' : '',
    ]);

    useAppBodyClasses(
        [
            'has-app-header-mobile',
            subNav ? 'app-header-mobile-has-subnav' : '',
            hideAppFooter ? 'hide-app-footer' : '',
        ],
        [],
    );

    return (
        <div ref={innerRef} className={classNamesAppended}>
            <div className="app-header-mobile_upper">
                {back && (
                    <Button
                        className="app-header-mobile_back"
                        variant="flat"
                        color="neutral"
                        startIcon={<BackIcon />}
                        onClick={() => {
                            history.push(back?.to || document.referrer);
                        }}
                    >
                        {back?.component}
                    </Button>
                )}
                {title && <h1>{title}</h1>}
            </div>
            {subNav && <div className="app-header-mobile_subnav">{subNav}</div>}
        </div>
    );
};

export default AppHeaderMobile;
