import { useEffect, useState } from 'react';

import { IPublicUser } from '@sparkplug/lib';

import { fetchGroupsMulti } from '@core/accounts';
import { useSparkplugUsers } from '@core/users';

import PageHeader from '@components/layout/PageHeader';
import UsersTable from '@components/tables/UsersTable';
import { PublicUserRow, UserTableHeadCells } from '@components/tables/UsersTable/UsersTable';

const getAllUsersWithGroups = async (
    users: (IPublicUser & { phoneNumberFormatted: string; key: string })[] = [],
): Promise<PublicUserRow[]> => {
    const groupsResponse = await fetchGroupsMulti();

    const groupNamesByUserId = groupsResponse.data
        .filter((group) => group?.members && group?.members?.length > 0)
        .reduce((acc: any, { members, name }) => {
            members?.forEach(({ userId }) => {
                acc[userId] = name;
            });

            return acc;
        }, {});

    const usersWithGroupNames = users.map((user) => ({
        ...user,
        fullName: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`.trim(),
        groupName: groupNamesByUserId[user._id],
        isOwner: false,
    }));

    return usersWithGroupNames;
};

const headCells = [
    UserTableHeadCells.checkbox,
    UserTableHeadCells.firstName,
    UserTableHeadCells.lastName,
    UserTableHeadCells.groupName,
    UserTableHeadCells.role,
    UserTableHeadCells.email,
    UserTableHeadCells.phoneNumber,
    UserTableHeadCells.smsStatus,
];

const CCUsersView = () => {
    const [users, setUsers] = useState<PublicUserRow[]>([]);

    const { sparkplugUsers, isSparkplugUsersReady, refetchSparkplugUsers } = useSparkplugUsers();

    useEffect(() => {
        if (isSparkplugUsersReady) {
            getAllUsersWithGroups(sparkplugUsers).then((userList) => setUsers(userList));
        }
    }, [isSparkplugUsersReady]);

    const refetchUsers = () => {
        refetchSparkplugUsers();
    };

    return (
        <div className="cc-retailer-users-view">
            <PageHeader
                title={[
                    {
                        url: `/control-center`,
                        title: 'Control Center',
                    },
                    'Users',
                ]}
                metaTitle="Users | Control Center"
            />

            <UsersTable
                variant="raised"
                isLoadingAccountUsers={users.length === 0}
                accountUsers={users}
                refetchAccountUsers={refetchUsers}
                headCells={headCells}
                searchFields={[
                    'firstName',
                    'lastName',
                    'email',
                    'phoneNumber',
                    'phoneNumberFormatted',
                    'groupName',
                    'fullName',
                ]}
            />
        </div>
    );
};

export default CCUsersView;
