import { SubscriptionStatus } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';

import { TStandardColors } from '@app/types/UITypes';

const StripeSubscriptionStatuses: {
    label: string;
    color: TStandardColors;
    value: SubscriptionStatus;
}[] = [
    { label: 'Active', color: 'green', value: 'active' },
    { label: 'Canceled', color: 'neutral', value: 'canceled' },
    { label: 'Incomplete', color: 'light-gray', value: 'incomplete' },
    { label: 'Incomplete Expired', color: 'dark-gray', value: 'incomplete_expired' },
    { label: 'Past Due', color: 'yellow', value: 'past_due' },
    { label: 'Trialing', color: 'blue', value: 'trialing' },
    { label: 'Unpaid', color: 'red', value: 'unpaid' },
];

export const SubscriptionStatusChip = ({ status }: { status: SubscriptionStatus }) => {
    const subscriptionStatus = StripeSubscriptionStatuses.find((obj) => status === obj.value);

    if (!subscriptionStatus) {
        return <></>;
    }
    return <Chip dense color={subscriptionStatus.color} label={subscriptionStatus.label} />;
};
