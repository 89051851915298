import { useState } from 'react';

import { ToastCredentials } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Add } from '@components/icons';
import Paper from '@components/layout/Paper';

interface ToastPosSpecificFieldsProps {
    credentials: ToastCredentials;
    onChange: (key: string, value: any) => void;
}

const ToastPosSpecificFields = ({ credentials, onChange }: ToastPosSpecificFieldsProps) => {
    const [initialLocations] = useState(credentials?.locations || []);
    const locations = credentials?.locations || [
        {
            name: '',
            restaurantGuid: '',
            funnelNonDineInOrders: false,
            nonDineInPosEmployeeProfileId: undefined,
        },
    ];

    const onUpdateLocation = (i: number, key: any, value: any) => {
        const newLocations = [...locations];
        (newLocations as any)[i][key] = value;
        onChange('locations', newLocations);
    };

    const onAddLocation = () => {
        const newLocations = [...locations];
        newLocations.push({
            name: '',
            restaurantGuid: '',
            funnelNonDineInOrders: false,
            nonDineInPosEmployeeProfileId: undefined,
        });
        onChange('locations', newLocations);
    };

    const onRemoveLocation = (i: number) => {
        const newLocations = locations.filter((location, j) => j !== i);
        onChange('locations', newLocations);
    };

    return (
        <div className="form-control repeating-fields">
            <Form.Label>Locations</Form.Label>
            <div className="repeating-fields-fields">
                {locations.map((location, i) => {
                    return (
                        <Paper
                            key={`toast_credentials-locations-${i}`}
                            className="repeating-field-group"
                            variant="outlined"
                        >
                            <Form.TextField
                                label="Name"
                                name={`locations.${i}.name`}
                                disabled={initialLocations?.[i]?.name?.length > 0}
                                required
                                value={location?.name}
                                onChange={(event) =>
                                    onUpdateLocation(i, 'name', event.target.value)
                                }
                            />
                            <Form.TextField
                                label="Restaurant GUID"
                                name={`locations.${i}.restaurantGuid`}
                                required
                                value={location?.restaurantGuid}
                                onChange={(event) =>
                                    onUpdateLocation(i, 'restaurantGuid', event.target.value)
                                }
                            />
                            <Form.Switch
                                label="Funnel non-dine-in transactions"
                                value={location?.funnelNonDineInOrders ?? false}
                                name={`locations.${i}.funnelNonDineInOrders`}
                                onChange={(event: any) =>
                                    onUpdateLocation(
                                        i,
                                        'funnelNonDineInOrders',
                                        event.target.checked,
                                    )
                                }
                            />
                            <Button
                                className="repeating-fields-button-remove"
                                color="neutral"
                                variant="smooth"
                                onClick={() => onRemoveLocation(i)}
                            >
                                Remove Location
                            </Button>
                        </Paper>
                    );
                })}
            </div>

            <Button
                className="repeating-fields-button-add"
                color="blue"
                variant="smooth"
                onClick={onAddLocation}
                startIcon={<Add />}
            >
                Add Location
            </Button>
        </div>
    );
};

export default ToastPosSpecificFields;
