import { useEffect } from 'react';

import ControlCenterView from '@views/control-center/ControlCenterView';

import { SuperAdminRoute, Switch, useParams, useRouteMatch } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { isValidObjectId } from '@helpers/util';

const ControlCenterRouter = () => {
    const { path } = useRouteMatch();
    const {
        accountId,
    }: {
        accountId: string;
    } = useParams();

    const { changeAccountById } = useSparkplugAccount();

    const { history } = useApp();

    useEffect(() => {
        if (isValidObjectId(accountId)) {
            changeAccountById(accountId);
        } else {
            history.push('/');
        }
    }, [accountId]);

    return (
        <Switch>
            <SuperAdminRoute path={`${path}`} component={ControlCenterView} />
        </Switch>
    );
};

export default ControlCenterRouter;
