import { useMemo } from 'react';

import CCSparkCreditsView from '@features/spark-credits/views/CCSparkCreditsView';

import Paper from '@components/layout/Paper';
import RouterTabs from '@components/layout/Tabs/RouterTabs';
import { Redirect } from '@components/router';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import CCAccountDetailsView from '../CCAccountDetailsView';
import CCSubscriptionView from '../CCSubscriptionView';
import { CCLogsView } from './CCLogsView';
import CCRetailerPOSView from './CCRetailerPOSView';
import CCRetailerUsersView from './CCRetailerUsersView';

const CCRetailerView = () => {
    const { account } = useSparkplugAccount();
    const ccAccountsPath = `/control-center/accounts/${account?._id}`;
    const TabLinks = useMemo(() => {
        return account !== undefined
            ? [
                  {
                      url: `${ccAccountsPath}/details`,
                      text: 'Account Details',
                  },
                  {
                      url: `${ccAccountsPath}/subscription`,
                      text: 'Subscription',
                  },
                  {
                      url: `${ccAccountsPath}/credits`,
                      text: 'Spark Credits',
                  },
                  {
                      url: `${ccAccountsPath}/pos-config`,
                      text: 'POS Configuration',
                  },
                  {
                      url: `${ccAccountsPath}/users`,
                      text: 'Users',
                  },
                  {
                      url: `${ccAccountsPath}/logs`,
                      text: 'SMS Logs',
                  },
              ]
            : [];
    }, [account]);

    return (
        <div className="cc-retailer-view">
            <Paper>
                <RouterTabs color="neutral" tabs={TabLinks}>
                    <RouterTabs.Tab path={`${ccAccountsPath}/details`}>
                        <CCAccountDetailsView />
                    </RouterTabs.Tab>
                    <RouterTabs.Tab path={`${ccAccountsPath}/pos-config`}>
                        <CCRetailerPOSView />
                    </RouterTabs.Tab>
                    <RouterTabs.Tab path={`${ccAccountsPath}/users`}>
                        <CCRetailerUsersView />
                    </RouterTabs.Tab>
                    <RouterTabs.Tab path={`${ccAccountsPath}/logs`}>
                        <CCLogsView />
                    </RouterTabs.Tab>
                    <RouterTabs.Tab path={`${ccAccountsPath}/subscription`}>
                        <CCSubscriptionView />
                    </RouterTabs.Tab>
                    <RouterTabs.Tab path={`${ccAccountsPath}/credits`}>
                        <CCSparkCreditsView />
                    </RouterTabs.Tab>
                    <RouterTabs.Tab path={ccAccountsPath}>
                        <Redirect from={ccAccountsPath} to={`${ccAccountsPath}/details`} />
                    </RouterTabs.Tab>
                </RouterTabs>
            </Paper>
        </div>
    );
};

export default CCRetailerView;
