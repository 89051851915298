import { FC } from 'react';

import { useVendorRetailerDataPermissions } from '@features/account-links';
import ProductInventoryTable from '@features/inventory/components/ProductInventoryTable';
import { useRetailerProductsMappedToVendor } from '@features/inventory/queries';

import { useSparkplugBrandRetailer } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

export interface RetailerInventoryViewProps {
    account: IAccount;
    userIsSuperAdmin: boolean;
}
export const RetailerInventoryView: FC<RetailerInventoryViewProps> = ({
    account,
    userIsSuperAdmin,
}) => {
    const { brandRetailer, brandRetailerId } = useSparkplugBrandRetailer();
    const { shareInventoryData } = useVendorRetailerDataPermissions({
        vendorAccountId: account?._id,
        retailerAccountId: brandRetailerId,
        isEnabled: account?.type === 'brand',
    });

    const { retailerProducts, retailerProductsAreReady } = useRetailerProductsMappedToVendor({
        vendorAccountId: account?._id ?? '',
        retailerAccountId: brandRetailer?._id ?? '',
    });

    const locationOptions = brandRetailer?.locations ?? [];

    return (
        <div className={`dashboard-view dashboard-${account?.type}-view`}>
            <ProductInventoryTable
                accountType={account?.type}
                locations={locationOptions}
                products={retailerProducts}
                inventoryEnabled={shareInventoryData}
                retailerAccountId={brandRetailer?._id ?? ''}
                isLoading={!retailerProductsAreReady}
                showLocationsDropdown={locationOptions.length > 1}
                isRetailerDashboard
                userIsSuperAdmin={userIsSuperAdmin}
            />
        </div>
    );
};

export default RetailerInventoryView;
