import { internalTrackingDropdownOptions } from '@constants/SparkConstants';

const invoiceStatusOptions = [
    {
        label: 'Invoice Status',
        value: '',
    },
    ...internalTrackingDropdownOptions.invoiceStatus,
];

const payoutStatusOptions = [
    {
        label: 'Payout Status',
        value: '',
    },
    ...internalTrackingDropdownOptions.payoutStatus,
];
const internalTrackingStatusOptions = [
    {
        label: 'Internal Tracking Status',
        value: '',
    },
    ...internalTrackingDropdownOptions.status,
];
const fulfillmentTypeOptions = [
    {
        label: 'Fulfillment Type',
        value: '',
    },
    {
        label: 'Sparkplug',
        value: 'sparkplug',
    },
    {
        label: 'External',
        value: 'external',
    },
];
const scheduleStatusOptions = [
    {
        label: 'Schedules',
        value: '',
    },
    {
        label: 'One-time',
        value: 'one-time',
    },
    {
        label: 'Recurs Daily',
        value: 'daily',
    },
    {
        label: 'Recurs Weekly',
        value: 'weekly',
    },
    {
        label: 'Recurs Twice-monthly',
        value: 'twice_monthly',
    },
    {
        label: 'Recurs Monthly',
        value: 'monthly',
    },
];

const sparkStatusOptions = [
    {
        label: 'All Statuses',
        value: 'all',
    },
    {
        label: 'Active',
        value: 'Active',
    },
    {
        label: 'Upcoming',
        value: 'Upcoming',
    },
    {
        label: 'Complete',
        value: 'Complete',
    },
    {
        label: 'Pending',
        value: 'Pending',
    },
    {
        label: 'Rejected',
        value: 'Rejected',
    },
    {
        label: 'Expired',
        value: 'Expired',
    },
];

export {
    invoiceStatusOptions,
    payoutStatusOptions,
    scheduleStatusOptions,
    sparkStatusOptions,
    fulfillmentTypeOptions,
    internalTrackingStatusOptions,
};
