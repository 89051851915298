import React, { FC, useMemo, useRef } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment/moment';

import { HydratedVendorPosProduct } from '@sparkplug/lib';

import { useFetchRetailerInventoryEnabled } from '@features/inventory/queries';

import { TableProvider } from '@contexts/TableContext';

import { DisabledDataIcon, Info } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';

import { cn } from '@app/componentLibrary/utils';
import { IPosLocation, IPosProduct } from '@app/types/PosTypes';
import { IOption } from '@app/types/UITypes';

import { RetailerDashboardInventoryToolbar } from '../RetailerDashboardInventoryToolbar/RetailerDashboardInventoryToolbar';
import { SparkDetailsInventoryToolbar } from '../SparkDetailsInventoryToolbar/SparkDetailsInventoryToolbar';
import { SuperAdminPosConfigsDisplay } from '../SuperAdminPosConfigsDisplay/SuperAdminPosConfigsDisplay';
import { useProductInventoryHeadCells } from './useProductInventoryHeadCells';
import { useProductInventoryTableFilters } from './useProductInventoryTableFilters';

import './ProductInventoryTable.scss';

export interface InventoryTableProps {
    accountType?: 'brand' | 'retailer';
    locations: IPosLocation[];
    products: HydratedVendorPosProduct[] | IPosProduct[];
    showLocationsDropdown: boolean;
    inventoryEnabled: boolean;
    retailerAccountId: string;
    isLoading: boolean;
    isRetailerDashboard?: boolean;
    userIsSuperAdmin?: boolean;
}

const ProductInventoryTable: FC<InventoryTableProps> = ({
    accountType,
    locations,
    products,
    showLocationsDropdown,
    inventoryEnabled,
    retailerAccountId,
    isLoading,
    isRetailerDashboard = false,
    userIsSuperAdmin = false,
}) => {
    const inventoryChartRef = useRef(null);

    const headCells = useProductInventoryHeadCells(isRetailerDashboard);
    const {
        inventoryEnabled: retailerInventoryEnabled,
        posConfigId: activePosConfigId,
        retailerInventoryEnabledIsReady,
    } = useFetchRetailerInventoryEnabled(retailerAccountId);

    const getCategoryLabel = (product: HydratedVendorPosProduct | IPosProduct) => {
        return product.categories.find((category) => category._id === product.categoryId)?.name;
    };

    const isHydratedVendorPosProduct = (
        product: HydratedVendorPosProduct | IPosProduct,
    ): product is HydratedVendorPosProduct => {
        return 'sparkBrand' in product;
    };

    const processProducts = (unProcessedproducts: (HydratedVendorPosProduct | IPosProduct)[]) => {
        const brandMap = new Map<string, string>(); // Map<id, name>
        const categoryMap = new Map<string, string>(); // Map<id, name>
        const productMap = new Map<string, string>(); // Map<id, name>
        let numProductsMissingInventory = 0;

        const productsByLoc = unProcessedproducts.flatMap((product) => {
            const categoryName = getCategoryLabel(product);

            if (isHydratedVendorPosProduct(product) && product.sparkBrand) {
                brandMap.set(product.sparkBrand._id, product.sparkBrand.name);
            }
            product.categories.forEach((category) => categoryMap.set(category._id, category.name));
            productMap.set(product._id, product.name);

            if (!product.inventory) {
                numProductsMissingInventory += 1;
                return [
                    {
                        ...product,
                        categoryName,
                        locationName: '--',
                        locationId: 'missing_location',
                        inventory: {
                            quantityAvailable: Number.MIN_SAFE_INTEGER,
                            unit: '',
                            updatedAt: new Date(product.lastSoldAt ?? product.updatedAt),
                        },
                        key: `${product._id}unknown`,
                    },
                ];
            }

            return Object.entries(product.inventory).map(([locationId, inventory]) => {
                const productLocation = locations.find((loc) => loc.value === locationId);
                let quantity = inventory.quantityAvailable;
                if (typeof inventory.quantityAvailable !== 'number') {
                    quantity = Number.isNaN(parseFloat(inventory.quantityAvailable))
                        ? Number.MIN_SAFE_INTEGER
                        : parseFloat(parseFloat(inventory.quantityAvailable).toFixed(2));
                }

                return {
                    ...product,
                    categoryName,
                    locationId,
                    locationName: productLocation?.label || 'DISABLED_LOCATION',
                    inventory: { ...inventory, quantityAvailable: quantity },
                    key: product._id + locationId,
                };
            });
        });

        const productsByLocation = productsByLoc.filter(
            (product) => product.locationName !== 'DISABLED_LOCATION',
        );

        const brandOptions = Array.from(brandMap).map(([id, name]) => ({
            value: id,
            label: name,
            key: id,
        }));

        const categoryOptions = Array.from(categoryMap).map(([id, name]) => ({
            value: id,
            label: name,
            key: id,
        }));

        const productOptions = Array.from(productMap).map(([id, name]) => ({
            value: id,
            label: name,
            key: id,
        }));

        return {
            numProductsMissingInventory,
            productsByLocation,
            brandOptions,
            categoryOptions,
            productOptions,
        };
    };

    const {
        numProductsMissingInventory,
        productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
    } = processProducts(products);

    const hasMissingLocations = productsByLocation.some(
        (product) => product.locationId === 'missing_location',
    );

    const locationOptions = useMemo<IOption[]>(() => {
        const chartLocations: IOption[] = [...locations];
        if (hasMissingLocations) {
            chartLocations.push({
                value: 'missing_location',
                label: 'Missing Location',
            } as IOption);
        }
        return chartLocations;
    }, [locations, hasMissingLocations]);

    const {
        searchFilter,
        applySearch,
        filters,
        tableFilters,
        updateFilters,
        onChangeSearchFilter,
        visibleBrandOptions,
        visibleCategoryOptions,
        selectedBrandCountProps,
        selectedCategoryCountProps,
        visibleLocationOptions,
        selectedLocationCountProps,
        visibleProductOptions,
        selectedProductCountProps,
    } = useProductInventoryTableFilters({
        initialFilters: {},
        isRetailerDashboard,
        initialProducts: productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    });

    let emptyState;
    if (!inventoryEnabled && accountType === 'brand') {
        emptyState = (
            <EmptyStateDisplay
                className="inventory-empty-state"
                graphic={<DisabledDataIcon />}
                label="Inventory data not available"
                smallText={<span>This Retailer has disabled inventory data sharing</span>}
            />
        );
    } else if (
        ((productsByLocation.length > 0 &&
            numProductsMissingInventory === productsByLocation.length) ||
            !retailerInventoryEnabled) &&
        !isLoading &&
        retailerInventoryEnabledIsReady &&
        accountType === 'brand'
    ) {
        emptyState = (
            <EmptyStateDisplay
                className="inventory-empty-state"
                graphic={<DisabledDataIcon />}
                label="Inventory data not available"
                smallText={
                    <span>This Retailer&apos;s Point-of-Sale does not support inventory</span>
                }
            />
        );
    }
    const lastQuarterStart = moment().subtract(1, 'quarter').startOf('quarter');

    return (
        <>
            {isLoading ? (
                <Paper className="section">
                    <Skeleton height={400} />
                </Paper>
            ) : (
                <>
                    {!emptyState ? (
                        <>
                            {isRetailerDashboard && (
                                <RetailerDashboardInventoryToolbar
                                    {...{
                                        showLocationsDropdown:
                                            showLocationsDropdown ||
                                            numProductsMissingInventory > 0,
                                        filters,
                                        updateFilters,
                                        visibleBrandOptions,
                                        selectedBrandCountProps,
                                        visibleProductOptions,
                                        selectedProductCountProps,
                                        visibleCategoryOptions,
                                        selectedCategoryCountProps,
                                        visibleLocationOptions,
                                        selectedLocationCountProps,
                                        searchFilter,
                                        onChangeSearchFilter,
                                    }}
                                />
                            )}
                            <Paper className="section">
                                <div
                                    className={cn(
                                        'product-inventory-table-container',
                                        isRetailerDashboard && 'retailer-dashboard',
                                    )}
                                >
                                    {!isRetailerDashboard && (
                                        <SparkDetailsInventoryToolbar
                                            {...{
                                                showLocationsDropdown:
                                                    showLocationsDropdown ||
                                                    numProductsMissingInventory > 0,
                                                onChangeSearchFilter,
                                                searchFilter,
                                                filters,
                                                updateFilters,
                                                visibleLocationOptions,
                                                selectedLocationCountProps,
                                            }}
                                        />
                                    )}
                                    <div className="spark-inventory-chart" ref={inventoryChartRef}>
                                        <TableProvider
                                            showPagination={false}
                                            headCells={headCells}
                                            rows={productsByLocation}
                                            isLoading={isLoading}
                                            filters={[applySearch, ...tableFilters]}
                                            defaultOptions={{
                                                orderBy: 'inventory.quantityAvailable',
                                                order: 'asc',
                                            }}
                                        >
                                            <Table
                                                useInfiniteScrollStyling
                                                useExternalProvider
                                                variant="smooth"
                                                className="product-inventory-table"
                                            >
                                                <Table.RenderHead />
                                                <Table.RenderBody
                                                    emptyStateText={
                                                        productsByLocation.length === 0 ? (
                                                            <div className="flex flex-row justify-center items-center">
                                                                No Active Products&nbsp;
                                                                <Tooltip
                                                                    placement="right"
                                                                    title={`"Active Products" are products that have sold at any location within since ${lastQuarterStart.format(
                                                                        DATE_DISPLAY_FORMAT,
                                                                    )} (start of last quarter)`}
                                                                >
                                                                    <Info
                                                                        fontSize="small"
                                                                        className="text-gray-700 !h-[13px] !w-[13px]"
                                                                    />
                                                                </Tooltip>
                                                            </div>
                                                        ) : (
                                                            'No products with these filters'
                                                        )
                                                    }
                                                    dynamicHeight={{
                                                        infiniteScroll: true,
                                                        containerRef: inventoryChartRef,
                                                        scrollToTopElement: {
                                                            getText: (itemCount) =>
                                                                `Viewing ${itemCount} products`,
                                                        },
                                                    }}
                                                    highlightRowOnHover
                                                />
                                            </Table>
                                        </TableProvider>
                                    </div>
                                </div>
                            </Paper>
                        </>
                    ) : (
                        <Paper className="section">{emptyState}</Paper>
                    )}
                    {isRetailerDashboard && userIsSuperAdmin && activePosConfigId && (
                        <SuperAdminPosConfigsDisplay
                            retailerAccountId={retailerAccountId}
                            userIsSuperAdmin={userIsSuperAdmin}
                            activePosConfigId={activePosConfigId}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default ProductInventoryTable;
