import { useEffect } from 'react';

import toast from '@components/toast';

type Location =
    | {
          apiKey: string;
      }
    | {
          clientKey: string;
      };

export const useErrorToastOnDuplicateApiKey = (locations: Location[]) => {
    useEffect(() => {
        const apiKeys = locations.map((location) => {
            if ('apiKey' in location) {
                return location.apiKey;
            }

            return location.clientKey;
        });

        const uniqueApiKeys = new Set(apiKeys);
        if (apiKeys.length !== uniqueApiKeys.size) {
            toast.error('Duplicate API/client keys are not allowed');
        }
    }, [locations]);
};
