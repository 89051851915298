import {
    CSSProperties,
    ComponentProps,
    ComponentType,
    DependencyList,
    FC,
    PropsWithChildren,
    ReactElement,
    RefObject,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { TableBody as MaterialUITableBody } from '@mui/material';

import Button from '@components/buttons/Button';

import { useTableContext } from '@hooks/TableHooks';
import { useDynamicHeight } from '@hooks/UIHooks';

import { ITableRow } from '@app/types/TableTypes';

import TableCell from '../TableCell';
import TableLoading from '../TableLoading';
import TableRenderRow from '../TableRenderRow';
import { TableRow } from '../TableRenderRow/TableRenderRow';

import './TableRenderBody.scss';

interface TableBodyProps extends ComponentProps<typeof MaterialUITableBody> {
    containerRef?: RefObject<HTMLElement>;
    staticHeightOverride?: number;
    bottomOffset?: number;
    otherDependencies?: DependencyList;
    incrementInfiniteScrollCount?: () => void;
    ScrollToTopComponent?: ComponentType<{ scrollToTop: () => void }>;
}

export const TableBody: FC<PropsWithChildren<TableBodyProps>> = ({
    containerRef,
    staticHeightOverride,
    bottomOffset = 0,
    otherDependencies = [],
    incrementInfiniteScrollCount,
    ScrollToTopComponent,
    children,
    ...tableBodyProps
}) => {
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const { tableIsLoading, tableRowsPerPage, tableHeadData } = useTableContext();
    const tableHeight = useDynamicHeight(containerRef, tableBodyRef, {
        dependencies: otherDependencies,
    });

    const onScroll = () => {
        if (tableBodyRef.current && incrementInfiniteScrollCount) {
            const { scrollTop, scrollHeight, clientHeight } = tableBodyRef.current;
            if (scrollTop + clientHeight + 300 > scrollHeight) {
                incrementInfiniteScrollCount();
            }
        }
    };

    const scrollToTop = () => {
        tableBodyRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const style = useMemo(() => {
        const updatedStyle: CSSProperties = {};

        if (staticHeightOverride) {
            updatedStyle.height = staticHeightOverride;
        } else if (containerRef && tableHeight) {
            updatedStyle.height = tableHeight - bottomOffset;
        }

        return updatedStyle;
    }, [tableHeight, staticHeightOverride, bottomOffset]);

    if (tableIsLoading) {
        return (
            <MaterialUITableBody>
                <TableLoading
                    rows={tableRowsPerPage > 0 ? tableRowsPerPage : 20}
                    colSpan={tableHeadData?.length || 1}
                />
            </MaterialUITableBody>
        );
    }

    return (
        <MaterialUITableBody
            ref={tableBodyRef}
            onScroll={() => onScroll()}
            style={style}
            {...tableBodyProps}
        >
            {children}
            {ScrollToTopComponent && <ScrollToTopComponent scrollToTop={scrollToTop} />}
        </MaterialUITableBody>
    );
};

type GetScrollToTopComponentFn = (conditionalValues: {
    scrollToTopElement?: {
        getText: (itemCount: number) => string;
        isFixed?: boolean;
    };
    infiniteScrollCount: number;
    colSpan: number;
    count: number;
}) => FC<{ scrollToTop: () => void }> | undefined;

const INFINITE_SCROLL_PAGE_SIZE = 50;

const getScrollToTopComponent: GetScrollToTopComponentFn = ({
    scrollToTopElement,
    infiniteScrollCount,
    colSpan,
    count,
}) =>
    scrollToTopElement &&
    colSpan &&
    count &&
    (scrollToTopElement.isFixed || infiniteScrollCount > count)
        ? ({ scrollToTop }) => (
              <TableRow
                  className={`table-row_scroll-to-top ${
                      scrollToTopElement.isFixed ? 'table-row_scroll-to-top--is-fixed' : ''
                  }`}
              >
                  <TableCell align="center" colSpan={colSpan}>
                      <div>
                          <span>{scrollToTopElement.getText(count)}</span>
                          {infiniteScrollCount > INFINITE_SCROLL_PAGE_SIZE && (
                              <Button
                                  variant="flat"
                                  color="blue"
                                  disableHover
                                  onClick={scrollToTop}
                              >
                                  Scroll to top
                              </Button>
                          )}
                      </div>
                  </TableCell>
              </TableRow>
          )
        : undefined;

interface SharedDynamicHeightProps {
    infiniteScroll: boolean;
    containerRef?: RefObject<HTMLElement>;
    staticHeightOverride?: number;
    bottomOffset?: number;
    otherDependencies?: DependencyList;
    scrollToTopElement?: {
        isFixed?: boolean;
        getText: (itemCount: number) => string;
    };
}

interface DefaultDynamicHeightProps extends SharedDynamicHeightProps {
    containerRef: RefObject<HTMLElement>;
    staticHeightOverride?: undefined;
}
interface StaticOverrideDynamicHeightProps extends SharedDynamicHeightProps {
    containerRef?: undefined;
    staticHeightOverride: number;
}

type DynamicHeightProps = DefaultDynamicHeightProps | StaticOverrideDynamicHeightProps;

interface TableRenderBodyProps<T> {
    rowRenderKeyFn?: (row: ITableRow<T>) => string;
    rowClassNameFn?: (row: ITableRow<T>) => string;
    emptyStateText?: string | ReactElement;
    dynamicHeight?: DynamicHeightProps;
    highlightRowOnHover?: boolean;
    onRowClick?: (rowData: any, event: any) => void;
}

const TableRenderBody = <T extends {}>({
    dynamicHeight,
    highlightRowOnHover,
    onRowClick,
    ...props
}: TableRenderBodyProps<T>) => {
    const {
        tableShowBulkEditor,
        tableHeadData,
        tablePageRows = [],
        tableShowPagination,
        tableFilteredRows = [],
        tableRows = [],
        tableSelected,
        tableOrderBy,
        tableOrder,
        footerComponent,
    } = useTableContext<T>();

    const {
        infiniteScroll,
        containerRef,
        staticHeightOverride,
        scrollToTopElement,
        bottomOffset = 0,
        otherDependencies = [],
    } = dynamicHeight ?? {};
    const [infiniteScrollCount, setInfiniteScrollCount] = useState(INFINITE_SCROLL_PAGE_SIZE);

    useEffect(() => {
        if (infiniteScroll) {
            setInfiniteScrollCount(INFINITE_SCROLL_PAGE_SIZE);
        }
    }, [infiniteScroll, tableOrderBy, tableOrder]);

    // Use headCells to see which data is displayed in the data and add value to render `key`
    const defaultRowRenderKeyFn = (row: any) => {
        const values = tableHeadData
            .map(({ id = '' }) => row?.[id])
            .filter((value) => value)
            .map((value) => String(value));

        return [row.key, ...values, tableShowBulkEditor].join('-');
    };

    const {
        rowRenderKeyFn = defaultRowRenderKeyFn,
        emptyStateText = 'No data to display',
        rowClassNameFn,
    } = props;

    const visibleRows = useMemo(() => {
        if (tableShowBulkEditor) {
            return tableRows.filter(({ key }) => tableSelected.includes(key));
        }

        if (infiniteScroll) {
            return tableFilteredRows.slice(0, infiniteScrollCount);
        }

        return tableShowPagination ? tablePageRows : tableFilteredRows;
    }, [
        tableShowPagination,
        tablePageRows,
        tableFilteredRows,
        tableShowBulkEditor,
        tableSelected,

        infiniteScroll,
        infiniteScrollCount,
    ]);

    const ScrollToTopComponent = getScrollToTopComponent({
        scrollToTopElement,
        colSpan: tableHeadData.length,
        count: tableFilteredRows.length,
        infiniteScrollCount,
    });

    return (
        <TableBody
            containerRef={containerRef}
            staticHeightOverride={staticHeightOverride}
            bottomOffset={bottomOffset}
            otherDependencies={otherDependencies}
            incrementInfiniteScrollCount={
                infiniteScroll
                    ? () => {
                          setInfiniteScrollCount(
                              (prevValue) => prevValue + INFINITE_SCROLL_PAGE_SIZE,
                          );
                      }
                    : undefined
            }
            ScrollToTopComponent={ScrollToTopComponent}
            className={`${
                scrollToTopElement?.isFixed ? 'table-body_scroll-to-top--has-fixed-footer' : ''
            }`}
        >
            {visibleRows?.length ? (
                visibleRows.map((row) => (
                    <TableRenderRow
                        onClick={onRowClick}
                        className={rowClassNameFn ? rowClassNameFn(row) : ''}
                        key={rowRenderKeyFn(row)}
                        tableHeadData={tableHeadData}
                        row={row}
                        isSelected={tableSelected.includes(row.key)}
                        hover={highlightRowOnHover}
                    />
                ))
            ) : (
                <TableRow>
                    <TableCell
                        className="empty-state"
                        align="center"
                        colSpan={tableHeadData.length}
                    >
                        {emptyStateText}
                    </TableCell>
                </TableRow>
            )}
            {footerComponent}
        </TableBody>
    );
};

export default TableRenderBody;
