import { GrowFlowCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface GrowFlowPosSpecificFieldsProps {
    credentials: GrowFlowCredentials;
    onChange: (key: string, value: any) => void;
}

const GrowFlowPosSpecificFields = ({ credentials, onChange }: GrowFlowPosSpecificFieldsProps) => {
    const { vendorKey } = credentials;

    return (
        <>
            <Form.TextField
                label="Vendor Key"
                name="vendorKey"
                required
                multiline
                defaultValue={vendorKey}
                onChange={(event) => onChange('vendorKey', event.target.value)}
            />
        </>
    );
};

export default GrowFlowPosSpecificFields;
