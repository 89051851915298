import { FC, useMemo } from 'react';

import { DetailedSparkType } from '@sparkplug/lib';

import ProductInventoryTable from '@features/inventory/components/ProductInventoryTable';
import SparkParticipantsSelector from '@features/spark-wizard/components/SparkParticipantsSelector';
import SparkPrizeCalloutMessage from '@features/spark-wizard/components/SparkPrizesFormFields/SparkPrizeCalloutMessage/SparkPrizeCalloutMessage';

import { CombinedLeaderboardGraphic, MultiLeaderboardGraphic } from '@components/graphics';
import CalloutMessage from '@components/layout/CalloutMessage';
import Grid from '@components/layout/Grid';
import SmartTabs from '@components/layout/Tabs/SmartTabs';
import SparkAwardsPanel from '@components/sparks/SparkAwardsPanel';
import SparkDetailsContent from '@components/sparks/SparkDetailsContent';
import SparkProductsTable from '@components/sparks/SparkProductsTable';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import { getSelectedLocationsFromSparkSubGroups } from '@helpers/sparks';

import './SparkApprovalModalTabs.scss';

const SparkParticipantsCalloutMessages: Partial<Record<DetailedSparkType, FC<{}>>> = {
    leaderboard: () => (
        <span>
            This is a <strong>Single Leaderboard Spark</strong>. Employees from the selected
            locations will all compete on a single leaderboard and there will only be one set of
            winners.
        </span>
    ),
    leaderboardMulti: () => (
        <span>
            This is a <strong>Multi-Leaderboard Spark</strong>. Employees from the selected
            locations will each compete on separate leaderboards. Each location will have a set of
            winners.
        </span>
    ),
    goal: () => (
        <span>
            This is an <strong>Individual Goal Spark</strong>. Employees from the selected locations
            will all be eligible to earn rewards based on their individual sales performance.
        </span>
    ),
    goalTeam: () => (
        <span>
            This is a <strong>Team Goal Spark</strong>. If the selected locations collectively hit
            the goals, each employee will be awarded the corresponding reward.
        </span>
    ),
    commissionFlat: () => (
        <span>
            This is a <strong>Commission Spark</strong>. Employees from the selected locations will
            all be eligible to earn a commission on qualifying products sold.
        </span>
    ),
    commissionPercentage: () => (
        <span>
            This is a <strong>Commission Spark</strong>. Employees from the selected locations will
            all be eligible to earn a commission on qualifying products sold.
        </span>
    ),
};

const SparkParticipantsGraphics: Partial<Record<DetailedSparkType, FC<{}>>> = {
    leaderboard: CombinedLeaderboardGraphic,
    leaderboardMulti: MultiLeaderboardGraphic,
};

const SparkApprovalParticipantsTabContent = ({
    detailedSparkType,
    updateSparkSubGroupsByLocationsAndParticipants,
}: {
    detailedSparkType: DetailedSparkType;
    updateSparkSubGroupsByLocationsAndParticipants: (locations: any[], participants: any[]) => void;
}) => {
    const Message = SparkParticipantsCalloutMessages[detailedSparkType];
    const Graphic = SparkParticipantsGraphics[detailedSparkType];

    const onSelectionChanged = (selectedLocations: any[], selectedParticipants: any[]) => {
        updateSparkSubGroupsByLocationsAndParticipants(selectedLocations, selectedParticipants);
    };

    const leftColumnSize = Message || Graphic ? 6 : 12;

    return (
        <Grid className="spark-approval-participants-tab-content">
            <Grid.Item sm={leftColumnSize}>
                <SparkParticipantsSelector
                    isBrandApprovalResponse
                    onSelectionChanged={onSelectionChanged}
                />
            </Grid.Item>
            {(Message || Graphic) && (
                <Grid.Item sm={6}>
                    {Message != null && (
                        <CalloutMessage variant="outlined" color="blue" message={<Message />} />
                    )}
                    {Graphic != null && (
                        <div className="spark-participants-type-graphic">
                            <Graphic />
                        </div>
                    )}
                </Grid.Item>
            )}
        </Grid>
    );
};

const SparkApprovalModalTabs: FC<{
    index: number;
    setIndex: (index: number) => void;
}> = ({ index, setIndex }) => {
    const {
        spark,
        detailedSparkType,
        sparkSubGroups,
        sparkPosData,
        sparkCommissionMap,
        sparkPosDataIsReady,
        sparkIsReady,
        updateSparkSubGroupsByLocationsAndParticipants,
    } = useSpark();
    const { account } = useSparkplugAccount();
    const sparkLocations = useMemo(() => {
        const subGroupsLocations = getSelectedLocationsFromSparkSubGroups(sparkSubGroups);
        return detailedSparkType === 'leaderboardMulti'
            ? subGroupsLocations
            : sparkPosData.locations;
    }, [detailedSparkType, sparkPosDataIsReady, sparkPosData.locations, sparkSubGroups]);
    const showLocationsDropdown =
        sparkIsReady &&
        sparkPosDataIsReady &&
        !['leaderboardMulti', 'goalTeam'].includes(detailedSparkType || '') &&
        sparkLocations.length > 1;

    const inventoryEnabled = account?.type === 'retailer' && spark.requestState !== 'expired';

    const tabPanels = [
        <SmartTabs.TabPanel key="details" label="Details">
            <SparkDetailsContent spark={spark} />
        </SmartTabs.TabPanel>,
        <SmartTabs.TabPanel key="participants" label="Participants">
            <SparkApprovalParticipantsTabContent
                detailedSparkType={detailedSparkType!}
                updateSparkSubGroupsByLocationsAndParticipants={
                    updateSparkSubGroupsByLocationsAndParticipants
                }
            />
        </SmartTabs.TabPanel>,
        <SmartTabs.TabPanel
            key="products"
            label={spark?.type !== 'commission' ? 'Products' : 'Commissions'}
        >
            <SparkProductsTable
                spark={spark}
                associatedProducts={sparkPosData?.associatedProducts}
                sparkCommissionMap={sparkCommissionMap}
            />
        </SmartTabs.TabPanel>,
        <SmartTabs.TabPanel key="inventory" label="Inventory">
            <>
                <ProductInventoryTable
                    accountType={account?.type}
                    locations={sparkLocations}
                    products={sparkPosData.products}
                    showLocationsDropdown={showLocationsDropdown}
                    inventoryEnabled={inventoryEnabled}
                    retailerAccountId={spark.groupId}
                    isLoading={!sparkIsReady}
                />
            </>{' '}
        </SmartTabs.TabPanel>,
    ];

    if (['leaderboard', 'goal'].includes(spark?.type)) {
        const prizeTabLabel = spark?.type === 'leaderboard' ? 'Rewards' : 'Goals';
        tabPanels.push(
            <SmartTabs.TabPanel key="awards" label={prizeTabLabel}>
                <div className="spark-awards-tab-content">
                    <SparkPrizeCalloutMessage spark={spark} sparkSubGroups={sparkSubGroups} />

                    <SparkAwardsPanel spark={spark} hideTitle variant="smooth" />
                </div>
            </SmartTabs.TabPanel>,
        );
    }

    return (
        <SmartTabs externalIndex={index} setExternalIndex={setIndex}>
            {tabPanels}
        </SmartTabs>
    );
};

export default SparkApprovalModalTabs;
