import { FC, RefObject, useMemo, useState } from 'react';

import { IEmployeeWithdrawal, IWallet, WalletEmployeeDeposit } from '@sparkplug/lib';

import MobileScrollToTopButton from '@components/buttons/MobileScrollToTopButton';
import SmartTabs from '@components/layout/Tabs/SmartTabs';

import { useApp } from '@hooks/AppHooks';

import { useCurrentUserWallet } from '../../../queries';
import { CashOutList } from './CashOutList';
import { RewardsList } from './RewardsList';

import './WalletTabs.scss';

interface WalletTabLabelProps {
    label: string;
    badgeCount?: number;
}
const WalletTabLabel: FC<WalletTabLabelProps> = ({ label, badgeCount = 0 }) => (
    <div className="wallet-tab-label_container">
        <span className="wallet-tab-label">{label}</span>
        {badgeCount > 0 && <span className="wallet-tab-label_badge">{badgeCount}</span>}
    </div>
);

const useDepositsFilter = (deposits: WalletEmployeeDeposit[] = []) => {
    return useMemo(
        () => ({
            claimed: deposits.filter(({ status }) => status === 'Claimed'),
            unclaimed: deposits.filter(({ status }) => status !== 'Claimed'),
        }),
        [deposits],
    );
};

export interface WalletTabsProps {
    initialTabIndex?: number;
    wallet?: IWallet;
    walletIsReady?: boolean;
    openRewardDetails: (reward: WalletEmployeeDeposit) => void;
    openCashOutHistoryItemDetails: (cashOutHistoryItem: IEmployeeWithdrawal) => void;
    onClickClaim: (rewardClaimId: string) => void;
    appHeaderRef?: RefObject<HTMLDivElement>;
}

export const WalletTabs: FC<WalletTabsProps> = ({
    walletIsReady,
    wallet,
    initialTabIndex = 0,
    openRewardDetails,
    openCashOutHistoryItemDetails,
    onClickClaim,
    appHeaderRef,
}) => {
    const { appIsMobile } = useApp();
    const [tabIndex, setTabIndex] = useState<number>(initialTabIndex);

    const { withdrawals = [], deposits = [] } = wallet ?? {};
    const { claimed = [], unclaimed = [] } = useDepositsFilter(deposits);

    const rewardsTabLabel = <WalletTabLabel label="Rewards" badgeCount={unclaimed.length} />;
    const cashoutTabLabel = <WalletTabLabel label="Cash Out History" />;

    const onClickDeposit = (deposit: WalletEmployeeDeposit) => {
        if (deposit.status === 'Claimed') {
            openRewardDetails(deposit);
        } else {
            onClickClaim(deposit._id);
        }
    };

    return (
        <div className="wallet-tabs">
            <SmartTabs
                stickyOnMobileScroll={{ elementRef: appHeaderRef }}
                color="blue"
                externalIndex={tabIndex}
                setExternalIndex={setTabIndex}
            >
                <SmartTabs.TabPanel key="rewards" label={rewardsTabLabel}>
                    <RewardsList
                        claimedRewards={claimed}
                        unclaimedRewards={unclaimed}
                        onClick={onClickDeposit}
                        isLoading={!walletIsReady}
                    />
                    {deposits.length > 10 && appIsMobile && <MobileScrollToTopButton />}
                </SmartTabs.TabPanel>

                <SmartTabs.TabPanel key="cashout" label={cashoutTabLabel}>
                    <CashOutList
                        withdrawals={withdrawals}
                        onClick={openCashOutHistoryItemDetails}
                        isLoading={!walletIsReady}
                    />
                    {withdrawals.length > 10 && appIsMobile && <MobileScrollToTopButton />}
                </SmartTabs.TabPanel>
            </SmartTabs>
        </div>
    );
};

const WalletTabsDataWrapper: FC<Omit<WalletTabsProps, 'walletIsReady' | 'wallet'>> = (props) => {
    const { currentUserWalletIsReady, currentUserWallet } = useCurrentUserWallet();

    return (
        <WalletTabs
            walletIsReady={currentUserWalletIsReady}
            wallet={currentUserWallet}
            {...props}
        />
    );
};

export default WalletTabsDataWrapper;
