import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import AccountsAPI from '@api/AccountsAPI';
import {
    AccountTypeOptions,
    IndustryOptions,
    SubscriptionTypeOptions,
} from '@constants/AccountConstants';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';

import { AccountStatus, AccountType, CourseType, Industry, SubscriptionType } from '@sparkplug/lib';

import {
    deleteAccountByGroupId,
    getCourseTypeByAccount,
    useSaveAccountMutation,
} from '@core/accounts';
import { useSparkplugUsers } from '@core/users';
import { getMarketOptions } from '@core/utils/getMarketOptions';

import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import {
    DeleteForever as DeleteIcon,
    InfoIcon,
    MoreVert as MoreIcon,
    SaveAlt as SaveIcon,
} from '@components/icons';
import ConfirmModal from '@components/overlays/ConfirmModal';
import { useHistory } from '@components/router';
import toast from '@components/toast';

import {
    useSparkplugAccount,
    useSparkplugAccountUsers,
    useSparkplugAccounts,
} from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';
import { CheckedEvent, IOption } from '@app/types/UITypes';

import MutableLink from './components/MutableLink';

import './CCAccountDetailsView.scss';

const useOwnerOptions = (): IOption[] => {
    const { accountUsers = [] } = useSparkplugAccountUsers();
    const getAccountOwnerUserOptions = () =>
        accountUsers
            .filter((user) => user.role === 'group-admin' && user.userId)
            .map(({ firstName, lastName, userId }) => ({
                value: userId ?? '',
                label: `${firstName} ${lastName}`,
            }));

    return useMemo(getAccountOwnerUserOptions, [accountUsers.length]);
};

const useSuperAdminUserOptions = (): IOption[] => {
    const { sparkplugUsers: superAdminUsers = [] } = useSparkplugUsers({ role: 'super-admin' });

    const getSuperAdminUserOptions = () =>
        superAdminUsers.map(({ firstName, lastName, _id }) => ({
            value: _id,
            label: `${firstName} ${lastName}`,
        }));

    return useMemo(getSuperAdminUserOptions, [superAdminUsers.length]);
};

interface CCAccountDetailsViewProps {
    account: IAccount;
}

const CCAccountDetailsView: FC<CCAccountDetailsViewProps> = ({ account }) => {
    const history = useHistory();
    const { refetchAccounts } = useSparkplugAccounts();

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [accountName, setAccountName] = useState<string>(account.name);
    const [accountType, setAccountType] = useState<AccountType>(account.type);
    const [connectEnabled, setConnectEnabled] = useState<boolean>(!account.connectDisabled);
    const [trainingEnabled, setTrainingEnabled] = useState<CourseType | 'none'>(
        getCourseTypeByAccount(account) ?? 'none',
    );
    const [managerSparksEnabled, setManagerSparksEnabled] = useState<boolean>(
        account.managerSparksEnabled,
    );
    const [hubspotId, setHubspotId] = useState<string>(account.hubspotId || '');
    const [industries, setIndustries] = useState<Industry[]>(account.industries || []);
    const [markets, setMarkets] = useState<string[]>(account.metaData?.markets || []);
    const [newLogo, setNewLogo] = useState<File>();
    const [accountStatus, setAccountStatus] = useState<AccountStatus>(account.status);

    const [assignedSuperAdminUserId, setAssignedSuperUserId] = useState<string>(
        account.assignedSuperAdminUserId || '',
    );
    const [assignedOwnerId, setAssignedOwnerId] = useState<string | undefined>(
        account.assignedOwnerUserId,
    );

    const [stripeCustomerId, setStripeCustomerId] = useState<string>(
        account.stripeCustomerId || '',
    );
    const [subscriptionType, setSubscriptionType] = useState<SubscriptionType>(
        account.metaData?.subscriptionType || 'none',
    );
    const [referralUrl, setReferralUrl] = useState<string>(account.referralUrl || '');
    const [rulesBasedSparksEnabled, setRulesBasedSparksEnabled] = useState<boolean>(
        Boolean(account.rulesBasedSparksEnabled),
    );
    const [snapsEnabled, setSnapsEnabled] = useState<boolean>(Boolean(account.snapsEnabled));

    const [inventoryEnabled, setInventoryEnabled] = useState<boolean>(
        Boolean(account.inventoryEnabled),
    );

    const [showAllSparksReportModal, setShowAllSparksReportModal] = useState<boolean>(false);

    const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

    const userCanChangeAccountStatus = useMemo(
        () => !['created', 'integrated'].includes(account.status),
        [account],
    );

    const AutomatedAccountStatusOptions: Array<{
        label: string;
        value: AccountStatus;
    }> = [
        { label: 'Created', value: 'created' },
        { label: 'Integrated', value: 'integrated' },
    ];
    const AccountStatusOptions: Array<{
        label: string;
        value: AccountStatus;
    }> = useMemo(
        () => [
            { label: 'Onboarding', value: 'onboarding' },
            { label: 'Active', value: 'active' },
            ...(account.type === 'retailer'
                ? [{ label: 'Inactive', value: 'inactive' }]
                : ([] as any)),
            { label: 'Churned', value: 'churned' },
        ],
        [account],
    );

    const TrainingOptions = [
        { value: 'none', label: 'None' },
        { value: 'zoltrain', label: 'ZolTrain' },
        { value: 'seedTalent', label: 'Seed Talent' },
    ];

    // Reset form fields on account switch
    useEffect(() => {
        setAccountName(account.name);
        setAccountType(account.type);
        setConnectEnabled(!account.connectDisabled);
        setTrainingEnabled(getCourseTypeByAccount(account) ?? 'none');
        setManagerSparksEnabled(account.managerSparksEnabled);
        setHubspotId(account.hubspotId || '');
        setFormSubmitted(false);
        setIndustries(account.industries || []);
        setMarkets(account.metaData?.markets || []);
        setNewLogo(undefined);
        setAssignedSuperUserId(account.assignedSuperAdminUserId || '');
        setAssignedOwnerId(account.assignedOwnerUserId);
        setStripeCustomerId(account.stripeCustomerId || '');
        setSubscriptionType(account.metaData?.subscriptionType || 'none');
        setReferralUrl(account.referralUrl || '');
    }, [account]);

    const SuperAdminUserOptions = useSuperAdminUserOptions();
    const AccountOwnerOptions = useOwnerOptions();
    const MarketOptions = useMemo(() => getMarketOptions(account.type), [account.type]);

    const { saveAccount } = useSaveAccountMutation({ account });

    const onDeleteAccount = useCallback(() => {
        toast.promise(deleteAccountByGroupId(account._id), {
            loading: null,
            success: () => {
                refetchAccounts();
                history.push(`/control-center/accounts`);
                return `Deleted ${accountName}`;
            },
            error: `Something went wrong`,
        });
    }, [account, accountName]);

    const shouldRequireAccountOwner = !!AccountOwnerOptions?.length;
    const finalValidation = () => {
        // there are some form field types that don't show validation by default, handle here
        setFormSubmitted(true);

        if (
            !hubspotId ||
            !assignedSuperAdminUserId ||
            (!assignedOwnerId && shouldRequireAccountOwner) ||
            !markets?.length ||
            !industries?.length
        ) {
            toast.error('Some required fields are missing - they are highlighted in red');
            return;
        }

        setFormSubmitted(false);
        setShowConfirmModal(true);
    };

    return (
        <div className="cc-account-details-view">
            <Form>
                <Form.Grid>
                    <Form.GridItem sm={4}>
                        <Form.TextField
                            required
                            label="Account Name"
                            name="name"
                            value={accountName}
                            onChange={(event) => {
                                setAccountName(event.target.value);
                            }}
                        />

                        <Form.Select
                            required
                            label="Account Type"
                            name="type"
                            value={accountType}
                            disabled={!!account?._id && process.env.REACT_APP_API === 'production'}
                            onChange={(event) => {
                                setAccountType(event.target.value as AccountType);
                            }}
                            options={AccountTypeOptions}
                        />

                        <Form.Select
                            required
                            label="Subscription Type"
                            name="subscriptionType"
                            value={subscriptionType}
                            disabled={!!account?._id && process.env.REACT_APP_API === 'production'}
                            onChange={(event) =>
                                setSubscriptionType(event.target.value as SubscriptionType)
                            }
                            options={SubscriptionTypeOptions}
                        />

                        <MutableLink
                            initialValue={stripeCustomerId}
                            label="Stripe Account"
                            name="stripeCustomerId"
                            url={`https://dashboard.stripe.com/${
                                process.env.REACT_APP_ENV === 'prod' ? '' : 'test/'
                            }customers/${stripeCustomerId}`}
                            onUpdate={setStripeCustomerId}
                        />
                        <Form.Select
                            requiredWithExternalValidation
                            label="Assigned Super User"
                            name="assignedSuperAdminUser"
                            value={assignedSuperAdminUserId}
                            onChange={(event) => setAssignedSuperUserId(event.target.value)}
                            options={SuperAdminUserOptions}
                            error={formSubmitted && !assignedSuperAdminUserId}
                        />
                        <Form.Select
                            requiredWithExternalValidation={shouldRequireAccountOwner}
                            label="Assigned Owner"
                            name="assignedOwner"
                            value={assignedOwnerId}
                            onChange={(event) => setAssignedOwnerId(event.target.value)}
                            options={AccountOwnerOptions}
                            error={shouldRequireAccountOwner && formSubmitted && !assignedOwnerId}
                        />
                        <Form.TextField
                            label="Referral Url"
                            name="referralUrl"
                            value={referralUrl}
                            disabled={!!account?._id && process.env.REACT_APP_API === 'production'}
                            onChange={(event) => {
                                setReferralUrl(event.target.value);
                            }}
                        />
                    </Form.GridItem>

                    <Form.GridItem sm={4}>
                        <Form.TextField
                            disabled
                            readOnly
                            label="Account Create Date"
                            name="accountCreateDate"
                            value={moment(account?.createdAt).format('L')}
                        />

                        {account?.type === 'brand' ? (
                            <Form.TreeSelect
                                required
                                className="hide-toggles hide-search"
                                label="Markets"
                                name="markets"
                                selected={markets}
                                disabled={
                                    !!account?._id && process.env.REACT_APP_API === 'production'
                                }
                                onChange={(values: string[]) => {
                                    setMarkets(values);
                                }}
                                options={MarketOptions}
                                error={formSubmitted && !markets?.length}
                            />
                        ) : (
                            <Form.SearchSelect
                                required
                                label="Markets"
                                name="markets"
                                disabled={
                                    !!account?._id && process.env.REACT_APP_API === 'production'
                                }
                                value={markets?.[0]}
                                onChange={({ value }) => {
                                    setMarkets([value]);
                                }}
                                options={MarketOptions}
                                error={formSubmitted && !markets?.length}
                            />
                        )}

                        <Form.SearchSelect
                            required
                            label="Industries"
                            name="industries"
                            disabled={!!account?._id && process.env.REACT_APP_API === 'production'}
                            value={industries?.[0]}
                            onChange={({ value }) => {
                                setIndustries([value as Industry]);
                            }}
                            options={IndustryOptions}
                            error={formSubmitted && !industries?.length}
                        />

                        <MutableLink
                            required
                            error={formSubmitted && !hubspotId}
                            initialValue={hubspotId}
                            label="Hubspot Account"
                            name="hubspotId"
                            url={`https://app.hubspot.com/contacts/9234261/record/2-4005971/${hubspotId}`}
                            onUpdate={setHubspotId}
                        />

                        <Form.Select
                            label="Training Integration"
                            name="trainingEnabled"
                            value={trainingEnabled}
                            onChange={(event) => setTrainingEnabled(event.target.value)}
                            options={TrainingOptions}
                        />

                        <div className="ccAccountToggles">
                            <Form.Label>SparkPlug Connect</Form.Label>
                            <Form.Switch
                                label="SparkPlug Connect Enabled"
                                value={connectEnabled}
                                name="connectEnabled"
                                onChange={(event: any) => setConnectEnabled(event.target.checked)}
                            />
                            <Form.Switch
                                label="Retailer Inventory"
                                value={inventoryEnabled}
                                name="inventoryEnabled"
                                disabled
                                tooltip="This is an entitlement override for triage purposes. The inventory feature is now enabled via Stripe subscriptions."
                                onChange={(event: any) => setInventoryEnabled(event.target.checked)}
                            />

                            <Form.Label>Spark Snaps</Form.Label>
                            <Form.Switch
                                label="Snaps Entitlement Override"
                                value={snapsEnabled}
                                name="snapsEnabled"
                                disabled
                                tooltip="This is an entitlement override for triage purposes. The snaps feature is now enabled via Stripe subscriptions."
                                onChange={(event: any) => setSnapsEnabled(event.target.checked)}
                            />
                            {account?.type === 'brand' && (
                                <>
                                    <Form.Label>Manager Sparks</Form.Label>
                                    <Form.Switch
                                        label="Manager Sparks Enabled"
                                        value={managerSparksEnabled}
                                        name="managerSparksEnabled"
                                        onChange={(event: CheckedEvent) =>
                                            setManagerSparksEnabled(event.target.checked)
                                        }
                                    />
                                    <Form.Label>Tags Rules-based Sparks</Form.Label>
                                    <Form.Switch
                                        label="Product Tags Enabled"
                                        value={rulesBasedSparksEnabled}
                                        name="rulesBasedSparksEnabled"
                                        onChange={(event: CheckedEvent) =>
                                            setRulesBasedSparksEnabled(event.target.checked)
                                        }
                                    />
                                </>
                            )}
                        </div>
                    </Form.GridItem>

                    <Form.GridItem sm={4}>
                        <Form.ImageField
                            label="Account Logo"
                            btnLabel="Add a Logo"
                            defaultValue={account?.photo ?? undefined}
                            onChange={(value) => setNewLogo(value)}
                        />
                        <Form.Select
                            label="Account Status"
                            name="accountStatus"
                            value={accountStatus}
                            onChange={(event) =>
                                setAccountStatus(event.target.value as AccountStatus)
                            }
                            disabled={!userCanChangeAccountStatus}
                            options={
                                userCanChangeAccountStatus
                                    ? AccountStatusOptions
                                    : AutomatedAccountStatusOptions
                            }
                        />
                        <Form.Button
                            className="input-group-text all-sparks-button"
                            color="blue"
                            variant="smooth"
                            onClick={() => setShowAllSparksReportModal(true)}
                        >
                            All Sparks Report
                        </Form.Button>
                        {account.subscriptionConfig?.entitlements &&
                            Object.keys(account.subscriptionConfig.entitlements).length > 0 && (
                                <Box mt={2}>
                                    <div className="text-gray-800 mb-4">
                                        <Tooltip title="These are entitlements based on the products on the stripe subscription">
                                            <InfoIcon fontSize="small" />
                                        </Tooltip>{' '}
                                        Feature Entitlements:
                                    </div>
                                    <ul style={{ listStyle: 'disc', paddingLeft: '20px' }}>
                                        {Object.keys(account.subscriptionConfig.entitlements).map(
                                            (key) => {
                                                const entitlement =
                                                    account.subscriptionConfig?.entitlements?.[key];
                                                return (
                                                    <li key={key} className="mb-2">
                                                        <span className="font-bold capitalize text-gray-800">
                                                            {key}
                                                        </span>
                                                        {entitlement?.markets &&
                                                            entitlement.markets.length > 0 && (
                                                                <div className="text-sm text-gray-500 mt-0.5">
                                                                    Markets:{' '}
                                                                    {entitlement.markets.join(', ')}
                                                                </div>
                                                            )}
                                                    </li>
                                                );
                                            },
                                        )}
                                    </ul>
                                </Box>
                            )}
                    </Form.GridItem>
                </Form.Grid>

                <Form.ActionGroup>
                    <Form.Button
                        className="input-group-text config-save-button"
                        color="blue"
                        variant="smooth"
                        onClick={finalValidation}
                        startIcon={<SaveIcon />}
                        customOnError={() =>
                            toast.error(
                                'Some required fields are missing - they are highlighted in red',
                            )
                        }
                    >
                        Save
                    </Form.Button>

                    <Dropdown>
                        <Dropdown.IconButton>
                            <MoreIcon />
                        </Dropdown.IconButton>
                        <Dropdown.Menu>
                            <Dropdown.MenuItem
                                StartIcon={DeleteIcon}
                                color="red"
                                onClick={() => setShowDeleteModal(true)}
                            >
                                Delete
                            </Dropdown.MenuItem>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.ActionGroup>
            </Form>

            <ConfirmModal
                title={`Update ${account?.name}?`}
                message="Are you sure you wish to update this account information?"
                isVisible={showConfirmModal}
                onConfirm={() => {
                    const metaMarkets = markets.includes('all')
                        ? MarketOptions?.[0]?.children.map(({ value }: any) => value)
                        : markets;

                    const payload = {
                        name: accountName,
                        type: accountType,
                        connectDisabled: !connectEnabled,
                        trainingEnabled: trainingEnabled === 'none' ? undefined : trainingEnabled,
                        managerSparksEnabled,
                        metaData: {
                            subscriptionType,
                            markets: metaMarkets,
                        },
                        industries,
                        stripeCustomerId,
                        hubspotId,
                        assignedSuperAdminUserId,
                        assignedOwnerUserId: assignedOwnerId,
                        referralUrl,
                        snapsEnabled,
                        inventoryEnabled,
                        status: accountStatus,
                        ...(newLogo && { photo: newLogo }),
                        ...(account?.type === 'brand' && { rulesBasedSparksEnabled }),
                    };

                    saveAccount(payload);
                }}
                onClose={() => setShowConfirmModal(false)}
            />

            <ConfirmModal
                title={`Delete ${account?.name}?`}
                message="Are you sure you wish to delete this account?"
                isVisible={showDeleteModal}
                onConfirm={onDeleteAccount}
                onClose={() => setShowDeleteModal(false)}
            />

            <ConfirmModal
                title="All Sparks Report"
                message="Email this report to yourself? This may take several minutes to prepare"
                isVisible={showAllSparksReportModal}
                onConfirm={() => {
                    AccountsAPI.requestAllSparksReport(account._id);
                }}
                onClose={() => setShowAllSparksReportModal(false)}
            />
        </div>
    );
};

export default () => {
    const { account } = useSparkplugAccount();

    if (!account) {
        return null;
    }

    return <CCAccountDetailsView account={account} />;
};
