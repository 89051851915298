import RetailerUsersTable from '@components/tables/RetailerUsersTable';

import { useSparkplugAccount, useSparkplugAccountUsers } from '@hooks/SparkplugAccountsHooks';

const CCRetailerUsersView = () => {
    const { account } = useSparkplugAccount();

    const { accountUsersAreReady, accountUsers, refetchAccountUsers } =
        useSparkplugAccountUsers(true);

    return (
        <div className="cc-retailer-users-view">
            <RetailerUsersTable
                variant="flat"
                account={account}
                isLoadingAccountUsers={!accountUsersAreReady}
                accountUsers={accountUsers}
                refetchAccountUsers={refetchAccountUsers}
            />
        </div>
    );
};

export default CCRetailerUsersView;
