import { Dispatch, SetStateAction } from 'react';

import { Dictionary } from 'lodash';
import moment from 'moment-timezone';

import { ControlCenterSpark, PaymentHealthResponseBody, formatCurrency } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import {
    CashStackIcon,
    Leaderboard,
    MonetizationOnTwoTone,
    Notes,
    ShoppingCartCheckout,
    TargetIcon,
    WarningAmber,
} from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';

import { THeadCell } from '@app/types/TableTypes';

export const PAYMENT_THRESHOLD = 3000;

export type CCSparkTableRow = ReturnType<typeof buildCCSparkTableRow>;

const SparkTypeIcons = {
    goal: <TargetIcon />,
    leaderboard: <Leaderboard />,
    commission: <CashStackIcon />,
};

const statusColorMap = {
    failed: 'red',
    paid: 'green',
    'not-paid': 'neutral',
    'not-sent': 'neutral',
    default: 'neutral',
} as any;

const payoutAmountHeadCell = {
    id: 'payoutAmount',
    sortType: 'string',
    label: 'Payout Amount',
    render: (row: CCSparkTableRow) => (
        <Table.Cell align="left">{formatCurrency(row?.payoutAmount || 0)}</Table.Cell>
    ),
};

const invoiceStatusHeadCell = {
    id: 'invoiceStatus',
    label: 'Invoice Status',
    sortType: 'string',
    render: (row: CCSparkTableRow) => (
        <Tooltip
            placement="top"
            title={
                row?.invoiceStatus === 'failed' &&
                `A payment attempt has failed. ${
                    row?.invoiceLastFinalizationError && row?.invoiceLastFinalizationError
                }`
            }
        >
            <Table.Cell align="left">
                <div>
                    <Chip
                        color={
                            statusColorMap[
                                (row?.invoiceStatus as keyof typeof statusColorMap) || 'default'
                            ]
                        }
                        // This field being undefined is equivalent to 'Not Sent' as far as the API is concerned
                        // See `listControlCenterSparksV2` in `@sparkplug/api/src/controllers/sparks`
                        label={row?.invoiceStatus || 'Not Sent'}
                    />
                </div>
            </Table.Cell>
        </Tooltip>
    ),
};

const paymentStatusHeadCell = {
    id: 'paymentStatus',
    label: 'Payment Status',
    sortType: 'string',
    render: (row: CCSparkTableRow) => (
        <Table.Cell align="left">
            <Chip
                color={
                    statusColorMap[(row?.paymentStatus as keyof typeof statusColorMap) || 'default']
                } // This field being undefined is equivalent to 'Not Paid' as far as the API is concerned
                // See `listControlCenterSparksV2` in `@sparkplug/api/src/controllers/sparks`
                label={row?.paymentStatus || 'Not Paid'}
            />
        </Table.Cell>
    ),
};

const scheduleHeadCell = {
    label: 'Schedule',
    id: 'recurringScheduleInterval',
    sortType: 'string',
    render: (row: CCSparkTableRow) => (
        <Table.Cell align="left" style={{ textTransform: 'capitalize' }}>
            {row?.schedule}
        </Table.Cell>
    ),
};

const notesCell = {
    id: 'notes',
    label: '',
    render: (row: CCSparkTableRow) => (
        <Table.Cell align="left" className="cc-internal-status-cell">
            {(row?.internalNotes?.length || 0) > 0 ? (
                <Tooltip title={row.internalNotes}>
                    <Notes className="table-note-tooltip" />
                </Tooltip>
            ) : (
                <></>
            )}
        </Table.Cell>
    ),
};
const endDateHeadCell = {
    id: 'endDate',
    label: 'End Date',
    sortType: 'date',
    render: (row: CCSparkTableRow) => <Table.Cell align="left">{row?.endDateFormatted}</Table.Cell>,
};

const sparkTypeHeadCell = {
    id: 'type',
    label: 'Spark Type',
    sortType: 'string',
    render: (row: CCSparkTableRow) => (
        <Table.Cell align="center" style={{ color: '#B9C3D1' }}>
            <Tooltip
                placement="top"
                title={`${row.type} ${
                    row.detailedSparkType !== undefined ? `(${row.detailedSparkType})` : ''
                }`}
            >
                {SparkTypeIcons[row.type]}
            </Tooltip>
        </Table.Cell>
    ),
};

const vendorRetailerNameHeadCell = [
    {
        id: 'name',
        label: 'Name',
        sortType: 'string',
        render: (row: CCSparkTableRow) => (
            <Tooltip placement="top" title={row?.name}>
                <Table.Cell align="left" className="elipsisStyle">
                    <a href={`/${row.originatorGroupId || row.groupId}/sparks/${row._id}`}>
                        {row?.name}
                    </a>
                </Table.Cell>
            </Tooltip>
        ),
    },
    {
        id: 'vendorName',
        label: 'Vendor',
        sortType: 'string',
        render: (row: CCSparkTableRow) => (
            <Tooltip placement="top" title={row?.vendorName}>
                <Table.Cell className="elipsisStyle" align="left">
                    {row?.vendorName || '--'}
                </Table.Cell>
            </Tooltip>
        ),
    },
    {
        id: 'retailerName',
        sortType: 'string',
        label: 'Retailer',
        render: (row: CCSparkTableRow) => (
            <Tooltip placement="top" title={row?.retailerName}>
                <Table.Cell align="left" className="elipsisStyle">
                    {row?.retailerName}
                </Table.Cell>
            </Tooltip>
        ),
    },
] as any;

const CCFulfilledSparksTableHeadCells = (): THeadCell<CCSparkTableRow>[] => [
    ...vendorRetailerNameHeadCell,
    endDateHeadCell,
    {
        id: 'finalizedAt',
        label: 'Finalized',
        sortType: 'date',
        render: (row: CCSparkTableRow) => (
            <Table.Cell align="left">{moment(row?.finalizedAt).format('MM/DD/YYYY')}</Table.Cell>
        ),
    },
    sparkTypeHeadCell,
    invoiceStatusHeadCell,
    payoutAmountHeadCell,
    paymentStatusHeadCell,
    scheduleHeadCell,
    {
        id: 'fulfilledBySparkplug',
        sortType: 'boolean',
        label: 'FBS?',
        render: (row) => (
            <Table.Cell align="left">{row?.fulfilledBySparkplug ? 'YES' : 'NO'}</Table.Cell>
        ),
    },
    notesCell,
];
const CCDNPSparksTableHeadCells = (): THeadCell<CCSparkTableRow>[] => [
    ...vendorRetailerNameHeadCell,
    endDateHeadCell,
    {
        id: 'finalizedAt',
        label: 'Finalized',
        sortType: 'date',
        render: (row: CCSparkTableRow) => (
            <Table.Cell align="left">{moment(row?.finalizedAt).format('MM/DD/YYYY')}</Table.Cell>
        ),
    },
    sparkTypeHeadCell,
    invoiceStatusHeadCell,
    payoutAmountHeadCell,
    paymentStatusHeadCell,
    scheduleHeadCell,
    notesCell,
];
const buildCCSparkTableRow = (
    spark: ControlCenterSpark,
    paymentHealthMap?: Dictionary<PaymentHealthResponseBody>,
) => {
    const retailerName = spark.retailerName;
    const vendorName = spark.vendorName;
    const fulfilledBySparkplug =
        spark?.type === 'commission' ||
        (spark?.fulfillmentTypes || []).some((fulfillmentType) => {
            return fulfillmentType === 'sparkplug';
        });
    return {
        key: spark._id,
        originatorGroupId: spark.originatorGroupId,
        groupId: spark.groupId,
        _id: spark._id,
        vendorName,
        retailerName,
        name: spark.name,
        endDate: spark.endDate,
        endDateFormatted: moment(spark.endDate).format('MM/DD/YYYY'),
        type: spark.type,
        detailedSparkType: spark.detailedSparkType,
        fulfilledBySparkplug,
        commissionRulesType: spark.commissionRules?.type,
        status: spark.internalTracking?.status,
        payoutAmount: spark.payoutAmount || 0,
        paymentStatus: spark.internalTracking?.payoutStatus,
        paymentHealth: paymentHealthMap?.[spark.originatorGroupId || spark.groupId],
        schedule: spark.recurringScheduleInterval,
        finalizedAt: spark.finalizedAt,
        internalStatus: spark.internalTracking?.status,
        internalNotes: spark.internalTracking?.notes,
        invoiceStatus: spark.internalTracking?.invoiceStatus,
        invoiceLastFinalizationError: spark.internalTracking?.invoiceLastFinalizationError,
        missingCommissionCount: spark.missingCommissionCount || 0,
    };
};

export const getFlagsAndRenderElement = (
    row: CCSparkTableRow,
    setQuickCheckoutSparkId: Dispatch<SetStateAction<string | undefined>>,
) => {
    const flags: string[] = [];
    let renderElement = <WarningAmber style={{ color: '#FFD8A5', cursor: 'default' }} />;
    const flagConditions = [
        {
            condition: row?.payoutAmount > PAYMENT_THRESHOLD,
            flag: `Payment exceeds ${formatCurrency(PAYMENT_THRESHOLD)}`,
        },
        {
            condition: !row.paymentHealth?.isInGoodHealth,
            flag: 'Payment health is poor',
        },
        {
            condition: row.detailedSparkType === 'leaderboardMulti',
            flag: 'Multi Leaderboard Requires Approval',
        },
        {
            condition: row.internalStatus === 'do-not-payout',
            flag: 'Do Not Payout',
        },
        {
            condition: row.type === 'commission' && row.missingCommissionCount > 0,
            flag: `${row.missingCommissionCount} products missing commissions.`,
        },
        // Add more conditions here
    ];

    flagConditions.forEach(({ condition, flag }) => {
        if (condition) {
            flags.push(flag);
        }
    });

    if (flags.length === 0) {
        renderElement = (
            <ShoppingCartCheckout
                onClick={(e) => {
                    e?.stopPropagation();
                    setQuickCheckoutSparkId(row._id);
                }}
            />
        );
    }

    return { flags, renderElement };
};

const CCSparksTableHeadCells = ({
    setQuickCheckoutSparkId,
}: {
    setQuickCheckoutSparkId: Dispatch<SetStateAction<string | undefined>>;
}): THeadCell<CCSparkTableRow>[] => [
    ...vendorRetailerNameHeadCell,
    endDateHeadCell,
    sparkTypeHeadCell,
    invoiceStatusHeadCell,
    payoutAmountHeadCell,
    paymentStatusHeadCell,
    {
        id: 'paymentHealth',
        info: 'Payment status is good if the last transaction on the default payment method was good.',
        label: () => <>Payment Health</>,
        render: (row: CCSparkTableRow) => {
            const { hasPaymentMethods, isInGoodHealth, stripeCustomerId, latestChargeStatus } =
                row?.paymentHealth || {};
            const environment = process.env.NODE_ENV;
            return (
                <Table.Cell align="left">
                    {row?.paymentHealth ? (
                        <Tooltip
                            className="payment-health-tooltip"
                            placement="top"
                            title={
                                <ul>
                                    <li>{hasPaymentMethods ? 'Has' : 'No'} payment methods</li>
                                    <li>Previous charge {latestChargeStatus}</li>
                                    <li>
                                        <a
                                            href={`https://dashboard.stripe.com/${
                                                environment !== 'production' ? 'test/' : ''
                                            }customers/${stripeCustomerId}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <span className="white-underlined">
                                                View customer in Stripe &rarr;
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            }
                        >
                            <MonetizationOnTwoTone color={isInGoodHealth ? 'success' : 'warning'} />
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </Table.Cell>
            );
        },
    },
    scheduleHeadCell,
    notesCell,
    {
        id: 'flags',
        label: '',
        render: (row: CCSparkTableRow) => {
            const { flags, renderElement } = getFlagsAndRenderElement(row, setQuickCheckoutSparkId);

            return (
                <Table.Cell align="left" style={{ color: '#B9C3D1' }}>
                    <Tooltip
                        placement="left"
                        title={
                            flags.length > 0
                                ? flags.map((flag) => <div key={flag}>{flag}</div>)
                                : 'Finalize Payment Amounts'
                        }
                    >
                        {renderElement}
                    </Tooltip>
                </Table.Cell>
            );
        },
    },
];

export {
    buildCCSparkTableRow,
    CCSparksTableHeadCells,
    CCDNPSparksTableHeadCells,
    CCFulfilledSparksTableHeadCells,
    SparkTypeIcons,
};
