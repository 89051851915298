import { Link, Redirect, Route, Switch } from 'react-router-dom';

import { CCSparkBrandsView } from '@features/spark-brands';
import CCSparkCreditsView from '@features/spark-credits/views/CCSparkCreditsView';

import Paper from '@components/layout/Paper';
import Tabs from '@components/layout/Tabs';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useRouteTabs } from '@hooks/UIHooks';

import CCAccountDetailsView from '../CCAccountDetailsView';
import CCSubscriptionView from '../CCSubscriptionView';
import CCBrandPermissionsView from './CCBrandPermissionsView';
import CCBrandUsersView from './CCBrandUsersView';

const CCBrandView = () => {
    const { account } = useSparkplugAccount();
    const { tabValue, currentPath } = useRouteTabs();

    const ccAccountsPath = `/control-center/accounts/${account?._id}`;
    const TabLinks =
        account !== undefined
            ? [
                  {
                      url: `${ccAccountsPath}/details`,
                      text: 'Account Details',
                  },
                  {
                      url: `${ccAccountsPath}/spark-brands`,
                      text: 'Spark Brands',
                  },
                  {
                      url: `${ccAccountsPath}/subscription`,
                      text: 'Subscription',
                  },
                  {
                      url: `${ccAccountsPath}/credits`,
                      text: 'Spark Credits',
                  },
                  {
                      url: `${ccAccountsPath}/users`,
                      text: 'Users',
                  },
              ]
            : [];

    return (
        <div className="cc-brand-view">
            <Paper>
                <Tabs color="neutral" value={tabValue}>
                    {TabLinks.map(({ url, text }) => (
                        <Tabs.Tab key={url} label={text} value={url} component={Link} to={url} />
                    ))}
                </Tabs>
                <Switch>
                    <Route path={`${currentPath}/details`} component={CCAccountDetailsView} />
                    <Route path={`${currentPath}/spark-brands`} component={CCSparkBrandsView} />
                    <Route path={`${currentPath}/permissions`} component={CCBrandPermissionsView} />
                    <Route path={`${currentPath}/users`} component={CCBrandUsersView} />
                    <Route path={`${currentPath}/subscription`} component={CCSubscriptionView} />
                    <Route path={`${currentPath}/credits`} component={CCSparkCreditsView} />
                    <Redirect from={`${currentPath}`} to={`${currentPath}/details`} />
                </Switch>
            </Paper>
        </div>
    );
};

export default CCBrandView;
