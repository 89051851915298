import { FC, useMemo } from 'react';

import { keyBy } from 'lodash';

import { VendorTotalsChartResponseBody } from '@sparkplug/lib';

import { ComparisonColors } from '@core/charts/constants/ChartsConstants';
import { useVendorChartContext } from '@core/charts/contexts/VendorChartContext';

import PercentDifferenceChip from '@components/chips/PercentDifferenceChip';
import { Dot as DotIcon } from '@components/icons';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useSearch } from '@hooks/UIHooks';

import { getChartMetricLabel } from '@helpers/charts';

import { THeadCell } from '@app/types/TableTypes';

import { useVendorTableChartData } from './hooks/useVendorTableChartData';

import './VendorTableChart.scss';

type VendorTableChartRow = VendorTotalsChartResponseBody['rows'][number];

const getAllHeadCells = (
    metricLabel: string,
    formatter: (value: number) => string,
): THeadCell<VendorTableChartRow>[] => [
    {
        id: 'value',
        sortType: 'numeric',
        label: metricLabel,
        render: (row) => <Table.Cell>{formatter(row.value ?? 0)}</Table.Cell>,
    },
    {
        id: 'previousValue',
        sortType: 'numeric',
        label: metricLabel,
        render: (row) => <Table.Cell>{formatter(row.previousValue ?? 0)}</Table.Cell>,
    },
    {
        id: 'comparisonValue',
        sortType: 'numeric',
        label: 'Comparison',
        render: (row) => (
            <Table.Cell>
                <PercentDifferenceChip
                    currentValue={row.value ?? 0}
                    prevValue={row.previousValue!}
                />
            </Table.Cell>
        ),
    },
    {
        id: 'label',
        sortType: 'string',
        label: ' ',
        render: (row) => <Table.Cell>{row.label}</Table.Cell>,
    },
    {
        id: 'retailerName',
        sortType: 'string',
        label: 'Retailer',
        render: (row) => <Table.Cell>{row.retailerName}</Table.Cell>,
    },
    {
        id: 'brandName',
        sortType: 'string',
        label: 'Brand',
        render: (row) => <Table.Cell>{row.brandName}</Table.Cell>,
    },
    {
        id: 'locationName',
        sortType: 'string',
        label: 'Location',
        render: (row) => <Table.Cell>{row.locationName}</Table.Cell>,
    },
];

const SEARCH_KEYS = getAllHeadCells('', () => '')
    .map((cell) => cell.id)
    .filter((id) => !['value', 'previousValue', 'comparisonValue'].includes(id));

interface VendorTableChartProps {}

/**
 * This component is used to render the table for the Vendor Market Charts, it handles both the
 * comparison windows as well as the breakdowns.
 */
const VendorTableChart: FC<VendorTableChartProps> = () => {
    const { chartSettings, chartValueFormatter } = useVendorChartContext();
    const { isLoading, data } = useVendorTableChartData();

    const { metric } = chartSettings;
    const { showComparisonPeriod = false, keys = [], rows = [] } = data;

    const metricLabel = getChartMetricLabel(metric);

    const headCells = useMemo(() => {
        const allHeadCells = getAllHeadCells(metricLabel, chartValueFormatter);
        const allHeadCellsByKey = keyBy(allHeadCells, 'id');

        // Update the head cells when comparison windows is turned on so the they will show the labels with the dots
        if (showComparisonPeriod) {
            allHeadCellsByKey.value.label = () => (
                <>
                    <DotIcon style={{ color: ComparisonColors[0] }} />
                    {metricLabel}
                </>
            );
            allHeadCellsByKey.previousValue.label = () => (
                <>
                    <DotIcon style={{ color: ComparisonColors[1] }} />
                    {metricLabel}
                </>
            );
        }

        return keys.map(
            (key, i) =>
                allHeadCellsByKey[key] ?? {
                    id: i,
                    label: `headcell not found: ${key}`,
                    render: () => <></>,
                },
        );
    }, [metricLabel, keys]);

    const { applySearch, searchFilter, onChangeSearchFilter } = useSearch(SEARCH_KEYS);

    const rowRenderKeyFn = (row: { key: string }) => row.key;

    return (
        <>
            <Toolbar>
                <Toolbar.Search
                    className="toolbar-group-start"
                    name="search"
                    defaultValue={searchFilter}
                    onChange={onChangeSearchFilter}
                />
            </Toolbar>
            <Table
                isLoading={isLoading}
                variant="raised"
                rows={rows}
                headCells={headCells}
                filters={[applySearch]}
                defaultOptions={{
                    rowsPerPage: 20,
                    orderBy: 'value',
                    order: 'desc',
                }}
            >
                <Table.RenderHead />
                <Table.RenderBody rowRenderKeyFn={rowRenderKeyFn} />
            </Table>
        </>
    );
};

export default VendorTableChart;
