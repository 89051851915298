import { useCallback, useEffect, useState } from 'react';

import { getAllRetailers, saveSparkplugConfig } from '@core/accounts';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { SaveAlt } from '@components/icons';

// import './POSConfigurationForm.scss';

const BrandPermissionsForm = ({ account }: { account: any }) => {
    const {
        sparkplugConfig: {
            brandSettings: { alternateNames, retailerGroupIds },
        },
    } = account;

    const alternameNamesChips = (alternateNames || []).map((name: string) => ({
        label: name,
        value: name,
    }));

    const [namesOfABrand, setNamesOfABrand] = useState(alternameNamesChips);
    const [brandRetailerIds, setBrandRetailerIds] = useState<string[]>(retailerGroupIds || []);
    const [allRetailers, setAllRetailers] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            setAllRetailers(await getAllRetailers());
        })();
    }, []);

    const onSave = useCallback(() => {
        (async () => {
            await saveSparkplugConfig(account.sparkplugConfig._id, {
                brandSettings: {
                    alternateNames: namesOfABrand.map((obj: any) => obj.value),
                    retailerGroupIds: brandRetailerIds,
                },
            });
        })();
    }, [namesOfABrand, brandRetailerIds]);

    return (
        <Form className="brand-permissions-form">
            <Form.ChipListInput label="Names" values={namesOfABrand} onChange={setNamesOfABrand} />

            <Form.TreeSelect
                label="Retailers"
                selected={brandRetailerIds}
                options={allRetailers}
                onChange={setBrandRetailerIds}
            />
            <Form.ActionGroup>
                <Button
                    className="input-group-text config-save-button"
                    color="blue"
                    variant="smooth"
                    onClick={onSave}
                    startIcon={<SaveAlt />}
                >
                    Save
                </Button>
            </Form.ActionGroup>
        </Form>
    );
};

export default BrandPermissionsForm;
