import { ShopifyShopCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

const ShopifyPosSpecificFields = ({
    credentials: { shop },
}: {
    credentials: ShopifyShopCredentials;
}) => {
    return (
        <>
            <Form.TextField label="Shop Name" name="shop" disabled defaultValue={shop} />
        </>
    );
};

export default ShopifyPosSpecificFields;
