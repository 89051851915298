import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import POSConfigurationForm from './components/POSConfigurationForm';

const CCRetailerPOSView = () => {
    const { account } = useSparkplugAccount();

    return (
        <div className="cc-retailer-pos-configuration-view">
            <POSConfigurationForm account={account!} />
        </div>
    );
};

export default CCRetailerPOSView;
