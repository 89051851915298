import { useState } from 'react';

import { useErrorToastOnDuplicateApiKey } from '@views/control-center/retailers/components/useToastOnDuplicateApiKey';

import { MeadowCredentials } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Add } from '@components/icons';
import Paper from '@components/layout/Paper';

interface MeadowPosSpecificFieldsProps {
    credentials: MeadowCredentials;
    onChange: (key: string, value: any) => void;
}

const MeadowPosSpecificFields = ({ credentials, onChange }: MeadowPosSpecificFieldsProps) => {
    const [initialLocations] = useState(credentials?.locations || []);
    const locations = credentials?.locations || [
        {
            name: '',
            clientKey: '',
            funnelPreOrders: false,
        },
    ];

    useErrorToastOnDuplicateApiKey(locations);

    const onUpdateLocation = (i: number, key: any, value: any) => {
        const newLocations = [...locations];
        (newLocations as any)[i][key] = value;
        onChange('locations', newLocations);
    };

    const onAddLocation = () => {
        const newLocations = [...locations];
        newLocations.push({
            name: '',
            clientKey: '',
            funnelPreOrders: false,
        });
        onChange('locations', newLocations);
    };

    const onRemoveLocation = (i: number) => {
        const newLocations = locations.filter((location, j) => j !== i);
        onChange('locations', newLocations);
    };

    return (
        <>
            <div className="form-control repeating-fields">
                <Form.Label>Locations</Form.Label>
                <div className="repeating-fields-fields">
                    {locations.map((location, i) => {
                        return (
                            <Paper
                                key={`meadow_credentials-locations-${i}`}
                                className="repeating-field-group"
                                variant="outlined"
                            >
                                <Form.TextField
                                    label="Name"
                                    name={`locations.${i}.name`}
                                    disabled={initialLocations?.[i]?.name?.length > 0}
                                    required
                                    value={location?.name}
                                    onChange={(event) =>
                                        onUpdateLocation(i, 'name', event.target.value)
                                    }
                                />
                                <Form.TextField
                                    label="Client Key"
                                    name={`locations.${i}.clientKey`}
                                    required
                                    value={location?.clientKey}
                                    onChange={(event) =>
                                        onUpdateLocation(i, 'clientKey', event.target.value)
                                    }
                                />
                                <Form.Switch
                                    label="Funnel pre-ordered transactions"
                                    value={location?.funnelPreOrders ?? false}
                                    name={`locations.${i}.funnelPreOrders`}
                                    onChange={(event: any) =>
                                        onUpdateLocation(i, 'funnelPreOrders', event.target.checked)
                                    }
                                />
                                <Button
                                    className="repeating-fields-button-remove"
                                    color="neutral"
                                    variant="smooth"
                                    onClick={() => onRemoveLocation(i)}
                                >
                                    Remove Location
                                </Button>
                            </Paper>
                        );
                    })}
                </div>

                <Button
                    className="repeating-fields-button-add"
                    color="blue"
                    variant="smooth"
                    onClick={onAddLocation}
                    startIcon={<Add />}
                >
                    Add Location
                </Button>
            </div>
        </>
    );
};

export default MeadowPosSpecificFields;
