import { useEffect, useMemo } from 'react';

import axios from 'axios';
import { isEmpty, uniq } from 'lodash';

import {
    GetAccountSDKTokenResponseBody,
    GetAccountSnapSdkCredentialsResponseBody,
    GetAccountSnapTemplateResponseBody,
    GetSnapResponseBody,
    ListAccountSnapTemplatesResponseBody,
    ListAccountSparkSnapsResponseBody,
    ListEngagementEventCSVResponseBody,
    ListRawGlobalSnapTemplatesResponseBody,
    ListSnapEventsRequestParams,
    ListSnapEventsResponseBody,
} from '@sparkplug/lib';

import toast from '@components/toast';

import { useAdvancedQuery, useQueries, useQueryClient } from '@hooks/QueryHooks';

import { downloadStringAsTxt } from '@helpers/util';

const API = {
    getStorifymeSDKToken: async (accountId: string) => {
        return (
            await axios.get<GetAccountSDKTokenResponseBody>(
                `/api/v1/accounts/${accountId}/storifyme-sdk-token`,
            )
        ).data;
    },
    getEngagementCSVData: async ({ accountId, snapId }: { accountId: string; snapId: number }) => {
        return (
            await axios.get<ListEngagementEventCSVResponseBody>(
                `/api/v1/accounts/${accountId}/${snapId}/engagement-csv`,
            )
        ).data;
    },
    getSnapTemplate: async ({
        accountId,
        snapTemplateId,
    }: {
        accountId: string;
        snapTemplateId: number;
    }) => {
        return (
            await axios.get<GetAccountSnapTemplateResponseBody>(
                `/api/v1/accounts/${accountId}/snap-templates/${snapTemplateId}`,
            )
        ).data;
    },
    getAccountSnapSdkCredentials: async ({ accountId }: { accountId: string }) => {
        return (
            await axios.get<GetAccountSnapSdkCredentialsResponseBody>(
                `/api/v1/accounts/${accountId}/snaps-sdk`,
            )
        ).data;
    },
    getSparkSnaps: async ({ accountId }: { accountId: string }) => {
        return (
            await axios.get<ListAccountSparkSnapsResponseBody>(
                `/api/v1/accounts/${accountId}/spark-snaps`,
            )
        ).data;
    },
    getSnap: async ({ accountId, snapId }: { accountId: string; snapId: number }) => {
        return (
            await axios.get<GetSnapResponseBody>(`/api/v1/accounts/${accountId}/snaps/${snapId}`)
        ).data;
    },
    getSnapByStorifymeAccountId: async ({
        storifymeAccountId,
        snapId,
    }: {
        storifymeAccountId: string;
        snapId: number;
    }) => {
        return (
            await axios.get<GetSnapResponseBody>(`/api/v1/snaps/${snapId}/${storifymeAccountId}`)
        ).data;
    },
    getSnapTemplates: async ({ accountId }: { accountId: string }) => {
        return (
            await axios.get<ListAccountSnapTemplatesResponseBody>(
                `/api/v1/accounts/${accountId}/snap-templates`,
            )
        ).data;
    },
    listGlobalSnapTemplates: async () => {
        return (
            await axios.get<ListRawGlobalSnapTemplatesResponseBody>(
                '/api/v1/snaps/global-templates',
            )
        ).data;
    },
    listSnapEvents: async (params: ListSnapEventsRequestParams) => {
        return (
            await axios.get<ListSnapEventsResponseBody>('/api/v1/snaps/events', {
                params,
            })
        ).data;
    },
    listUserSnapEvents: async (params: { eventType: string }) => {
        return (
            await axios.get<ListSnapEventsResponseBody>('/api/v1/snaps/events/user', {
                params: {
                    eventTypes: params.eventType,
                },
            })
        ).data;
    },
};

export const downloadSnapEngagementCSV = async ({
    accountId,
    snapId,
    sparkId,
    filename,
}: {
    accountId: string;
    snapId: number;
    sparkId?: string;
    filename?: string;
}) => {
    toast.loading('Exporting Snap analytics...');
    return API.getEngagementCSVData({ accountId, snapId })
        .then((data) => {
            toast.dismiss();

            if (!data?.length) {
                toast.success('No engagements yet', { icon: '' });
                return;
            }

            const headers = uniq(data.flatMap((dataItem) => Object.keys(dataItem)));
            const contentRows = data.map((row) =>
                headers.map((fieldName) => String(row[fieldName] ?? '').replace(/,/g, '')),
            );
            const csv = `${headers.join(',')}\n${contentRows
                .map((row) => row.join(','))
                .join('\n')}`;
            downloadStringAsTxt(
                csv,
                filename || `spark-${sparkId}-snap-${snapId}-engagement`,
                '.csv',
            );
        })
        .catch(() => {
            toast.dismiss();
            toast.error('Failed to export snap analytics');
        });
};

export const getSnapTemplateKey = (accountId: string, snapTemplateId: number) => [
    'snap-template',
    accountId,
    snapTemplateId,
];
export const useSnapTemplateQuery = ({
    accountId,
    snapTemplateId,
    enabled = true,
}: {
    accountId: string;
    snapTemplateId: number;
    enabled?: boolean;
}) => {
    const {
        isFetched: snapTemplateIsReady,
        data,
        isFetching,
    } = useAdvancedQuery(
        getSnapTemplateKey(accountId, snapTemplateId),
        () =>
            API.getSnapTemplate({
                accountId,
                snapTemplateId,
            }),
        { enabled },
    );

    return {
        snapTemplateIsReady,
        snapTemplate: data?.snapTemplate,
        snapTemplateIsLoading: isFetching,
    };
};

export const getStorifymeSDKTokenKey = (accountId: string) => ['storify-me-token', accountId];
export const useStorifymeSDKTokenQuery = (accountId: string, snapsEnabled: boolean) => {
    const queryClient = useQueryClient();

    const { isFetched: storifymeTokenIsReady, data } = useAdvancedQuery(
        getStorifymeSDKTokenKey(accountId),
        () => API.getStorifymeSDKToken(accountId),
        {
            enabled: !!accountId && snapsEnabled,
            /**
             * In order to insure that we always have a valid token,
             * we want to refresh the storifyme sdk token when msUntilExpiration is reached.
             */
            onSuccess: (d) => {
                queryClient.setQueryDefaults(getStorifymeSDKTokenKey(accountId), {
                    refetchOnWindowFocus: true,
                    refetchInterval: d.msUntilExpiration,
                    refetchIntervalInBackground: true,
                    cacheTime: d.msUntilExpiration,
                    staleTime: d.msUntilExpiration,
                });
            },
        },
    );

    return {
        storifymeTokenIsReady,
        storifymeSDKToken: data?.token,
    };
};

export const useAccountSnapSdkQuery = (
    { accountId }: { accountId: string },
    isEnabled: boolean = true,
) => {
    const { isFetched: accountSnapSdkCredentialsAreReady, data } = useAdvancedQuery(
        ['snap-sdk-credentials', accountId],
        () =>
            API.getAccountSnapSdkCredentials({
                accountId,
            }),
        {
            enabled: isEnabled,
        },
    );

    return { accountSnapSdkCredentialsAreReady, accountSnapSdkCredentials: data };
};

export const getAccountSparkSnapsQueryKey = (accountId: string) => ['spark-snaps', accountId];
export const useAccountSparkSnapsQuery = (
    { accountId }: { accountId: string },
    isEnabled: boolean = true,
) => {
    const {
        isFetched: accountSparkSnapsAreReady,
        data,
        refetch: refetchAccountSparkSnaps,
    } = useAdvancedQuery(
        getAccountSparkSnapsQueryKey(accountId),
        () =>
            API.getSparkSnaps({
                accountId,
            }),
        {
            enabled: isEnabled,
        },
    );

    useEffect(() => {
        // When this hook mounts, we need to fetch the account's spark snaps because query invalidation is an unreliable refetch trigger due to some of the async behavior on StorifyMe's side.
        // Using `refetch` creates a better UX than setting the cacheTime to 0.
        if (isEnabled) refetchAccountSparkSnaps();
    }, [isEnabled]);

    return { accountSparkSnapsAreReady, accountSparkSnaps: data?.snaps, refetchAccountSparkSnaps };
};

export const getSnapQueryKey = (accountId: string, snapId: number) => ['snap', accountId, snapId];
export const getSnapByStorifyMeAccountQueryKey = (storifymeAccountId: string, snapId: number) => [
    'snap',
    storifymeAccountId,
    snapId,
];

interface UseAccountIdParams {
    accountId: string;
    snapId: number;
}

interface UseStorifymeAccountIdParams {
    storifymeAccountId: string;
    snapId: number;
}

type GetSnapAccountParams = UseAccountIdParams | UseStorifymeAccountIdParams;

const areAccountParams = (params: GetSnapAccountParams): params is UseAccountIdParams => {
    return (params as UseAccountIdParams).accountId !== undefined;
};

export const useSnapQuery = (accountInfo: GetSnapAccountParams, isEnabled: boolean = true) => {
    const useAccountId = areAccountParams(accountInfo);
    const { isFetched: snapIsReady, data } = useAdvancedQuery(
        useAccountId
            ? getSnapQueryKey(accountInfo.accountId, accountInfo.snapId)
            : getSnapByStorifyMeAccountQueryKey(accountInfo.storifymeAccountId, accountInfo.snapId),
        () =>
            useAccountId
                ? API.getSnap({
                      accountId: accountInfo.accountId,
                      snapId: accountInfo.snapId,
                  })
                : API.getSnapByStorifymeAccountId({
                      storifymeAccountId: accountInfo.storifymeAccountId,
                      snapId: accountInfo.snapId,
                  }),
        {
            enabled: isEnabled,
        },
    );

    return { snapIsReady, snap: data?.snap, snapThumbnail: data?.thumbnailUrl };
};

export const useSnapsById = (
    { accountId, snapIds }: { accountId: string; snapIds: number[] },
    isEnabled = true,
) => {
    const results = useQueries(
        snapIds.map((snapId) => {
            return {
                queryKey: getSnapQueryKey(accountId, snapId),
                queryFn: () =>
                    API.getSnap({
                        accountId,
                        snapId,
                    }),
                isEnabled,
            };
        }),
    );

    return useMemo(() => {
        return {
            snapsAreReady: results.every((result) => result.isFetched),
            snaps: results.map((result) => result.data?.snap).filter((snap) => !isEmpty(snap)),
        };
    }, [results]);
};

export const getSnapTemplatesQueryKey = (accountId: string) => ['snap-templates', accountId];
export const useSnapTemplatesQuery = ({ accountId }: { accountId: string }, isEnabled = true) => {
    const { isFetched: snapTemplatesAreReady, data } = useAdvancedQuery(
        getSnapTemplatesQueryKey(accountId),
        () =>
            API.getSnapTemplates({
                accountId,
            }),
        {
            enabled: isEnabled,
        },
    );

    return { snapTemplatesAreReady, snapTemplates: data?.snapTemplates };
};

export const getGlobalSnapTemplatesQueryKey = 'global-snap-templates';
export const useGlobalSnapTemplatesQuery = () => {
    const { isFetched: globalSnapTemplatesAreReady, data } = useAdvancedQuery(
        getGlobalSnapTemplatesQueryKey,
        API.listGlobalSnapTemplates,
    );

    return { globalSnapTemplatesAreReady, globalSnapTemplates: data?.globalTemplates };
};

export const getUserSnapEventsQueryKey = (eventType: string) => ['user-snap-events', eventType];
export const useUserSnapEventsQuery = (params: { eventType: string }) => {
    const { isFetched: userSnapEventsAreReady, data } = useAdvancedQuery(
        getUserSnapEventsQueryKey(params.eventType),
        () => API.listUserSnapEvents(params),
        {
            queryKey: getUserSnapEventsQueryKey(params.eventType),
        },
    );

    return { userSnapEventsAreReady, userSnapEvents: data?.data };
};
export const getSnapEventsQueryKey = (params: ListSnapEventsRequestParams) => [
    'snap-events',
    JSON.stringify(params),
];

export const useSnapEventsQuery = (
    params: ListSnapEventsRequestParams,
    enabled: boolean = true,
) => {
    const {
        isFetched: snapEventsAreReady,
        data,
        refetch,
    } = useAdvancedQuery(getSnapEventsQueryKey(params), () => API.listSnapEvents(params), {
        queryKey: getSnapEventsQueryKey(params),
        enabled,
    });

    return { snapEventsAreReady, snapEvents: data?.data, refetch };
};
