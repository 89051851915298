import { FullChartResponseBody, GetChartResponseBody, TChartDataMetric } from '../chart';
import { z } from 'zod';
import { DAY_NUMBERS } from '../calendar';
import { PagedApiResponseBody } from '../http';
import { ConfirmRewardRequestBody, IPublicReward, UpdateRewardRequestBody } from '../reward';
import {
  courseCompleteUsersSchema,
  courseTypeEnumSchema,
  courseViewedUsersSchema,
} from '../training-course';
import {
  commissionRulesSchema,
  ControlCenterSpark,
  DETAILED_SPARK_TYPES,
  getSparkIdPathParamsSchema,
  percentIncreaseSchema,
  RECURRING_SPARK_SCHEDULE_OPTIONS,
  retailerSparkFiltersSchema,
  Spark,
  SPARK_FULFILLMENT_TYPES,
  SPARK_GOAL_TYPES,
  SPARK_METRICS,
  SPARK_TYPES,
  sparkAwardSchema,
  sparkCommissionSchema,
  sparkGoalSchema,
  SparkInternalStatus,
  sparkParticipantFiltersSchema,
  sparkProductFiltersSchema,
  sparkSnapDataSchema,
  TEAM_TYPES,
  vendorSparkFiltersSchema,
} from './spark.core';
import { TagColorOption } from '../product-tags';
import { ProductLabels } from '../products';

type SparkArchiveChart = Omit<FullChartResponseBody, 'productsWithSales'>;

export interface SparkArchiveParticipant {
  firstName: string;
  lastName: string;
  fullName: string;
  locationIds: string[];
  lastTransactionLocationId: string;
  posEmployeeProfileIds: string[];
}
export interface SparkArchiveProducts {
  [productId: string]: {
    name: string;
    brandIds: string[];
    categoryIds: string[];
  };
} // PosProduct

/**
 * Spark participant details enriched with spark performance data
 */
export interface SparkEmployeePerformance {
  flexibleEmployeeId: string;
  userId?: string;
  posEmployeeProfileIds: string[];
  locationIds: string[];
  lastTransactionLocationId?: string;
  managedLocationIds: string[];
  fullName: string;
  firstName: string;
  lastName: string;
  locationName: string;
  value?: number;
  unitsCount?: number;
  transactionCount: number;
  percentIncreaseComparison?: {
    previousTotal: number;
    currentTotal: number;
  };
}

export interface SparkArchiveProductTags {
  [tagId: string]: {
    color: TagColorOption;
    productIds: string[];
    tagName: string;
    tagGroupName: string;
  };
}

export interface SparkArchivePosData {
  locations: {
    [locationId: string]: string; // [locationId]: [displayName]
  }; // PosLocation

  participants: {
    [flexibleEmployeeId: string]: SparkArchiveParticipant;
  }; // AccountUser

  nonParticipantsWithSales: {
    [flexibleEmployeeId: string]: SparkArchiveParticipant;
  }; // AccountUser

  brands: {
    [brandId: string]: string; // [brandId]: [name]
  }; // PosBrand

  categories: {
    [categoryId: string]: string; // [categoryId]: [name]
  }; // PosCategories

  products: SparkArchiveProducts;

  productTags?: SparkArchiveProductTags;
}

export interface SparkArchiveCharts {
  charts: {
    sparkPeriod?: SparkArchiveChart | GetChartResponseBody;
    previousPeriodMatchDay?: SparkArchiveChart;
    previousYearMatchDay?: SparkArchiveChart;
    previousPeriod?: SparkArchiveChart;
    previousYear?: SparkArchiveChart;
    employeeLocations?: {
      [locationId: string]: Pick<
        FullChartResponseBody,
        'employeeBuckets' | 'employeeTotals' | 'employeeTransactionCounts' | 'commissions'
      >;
    };
  };
}

export interface SparkArchiveOtherMetricCharts {
  otherCharts?: Partial<
    Record<TChartDataMetric, Omit<SparkArchiveCharts['charts'], 'employeeLocations'>>
  >;
}

export interface PublicSparkPosArchive
  extends SparkArchivePosData,
    SparkArchiveCharts,
    SparkArchiveOtherMetricCharts {
  _id: string;
  sparkId: string;
}

export interface CreateSparkPosArchiveRequestBody {
  sparkId: string;
}

export interface GetSparkPosArchiveQueryParams {
  sparkId: string;
}

export type GetSparkPosArchiveResponse = PublicSparkPosArchive;

export interface GetSparkRewardsQueryParams {
  sparkId: string;
}

export type UpsertManySparkRewardsPathParams = {
  sparkId: string;
};
export type UpsertManySparkRewardsRequestBody = (Omit<UpdateRewardRequestBody, 'sparkId'> & {
  rewardId?: string;
})[];

export type FinalizeSparkPathParams = {
  sparkId: string;
};
export type FinalizeSparkRequestBody = ConfirmRewardRequestBody;

export type TSparkCheckoutPayoutStatus =
  | 'belowMinimum'
  | 'noSales'
  | 'pending'
  | 'approved'
  | 'claimed';

export interface SparkCheckoutPayout extends Partial<IPublicReward> {
  flexibleEmployeeId: string;
  ui?: {
    status?: TSparkCheckoutPayoutStatus;
    isModified?: boolean;
  };
}

export type GetSparkRewardsResponse = SparkCheckoutPayout[];

export interface GetSparkHistoryQueryParams {
  sparkId: string;
}

export interface GetSparkHistoryResponse {
  snapshots: any[];
}

export interface ListSparksQueryParams {
  group_id?: string;
  originator_group_id?: string;
  locationId?: string;
  tag?: string;
  limit?: number;
  offset?: number;
  cursor?: string;
  schedule?: string;
  lean?: boolean;
  startDate?: string;
  endDate?: string;
  types?: string;
  training?: string;
  status?: string;
  requestState?: string;
  userTimezone?: string;
  orderBy?: string;
  order?: string;
}

export interface ListControlCenterSparksQueryParams {
  offset?: number;
  limit?: number;
  invoiceStatus?: string;
  payoutStatus?: string;
  schedule?: string;
  startDate?: string;
  endDate?: string;
  allUnfullfilledSparks?: boolean;
  search?: string;
  orderBy?: string;
  order?: string;
  isFulfilled?: boolean;
  isUnfulfilled?: boolean;
  internalStatus?: SparkInternalStatus;
  fulfillmentType?: string;
}

export type ListSparksResponseBody = PagedApiResponseBody<Spark>;

export interface ListControlCenterSparksResponseBody {
  data: ControlCenterSpark[];
  meta?: {
    totalDocuments?: number;
    totalPages?: number;
  };
}
export interface ControlCenterMetaResponseBody {
  specialPayoutCount: number;
  totalSpecialPayoutAmountDue: number;
  allUnfullfilledSparksCount: number;
  failedPaymentCount: number;
  totalFailedPaymentAmountDue: number;
}

export interface GetSparkPathParams {
  sparkId: string;
}
export interface GetSparkResponseBody {
  data: Spark;
}

export interface UpdateSparkPathParams {
  sparkId: string;
}

export const sparkParticipantGroupSchema = z.object({
  locationIds: z.array(z.string().min(1)),
  posEmployeeProfileIds: z.array(z.string().min(1)),
  excludedPosEmployeeProfileIds: z.array(z.string().min(1)).optional(),
});

export type SparkParticipantGroup = z.infer<typeof sparkParticipantGroupSchema>;

const dayNumberSchema = z.union([
  z.literal(DAY_NUMBERS[0]),
  z.literal(DAY_NUMBERS[1]),
  z.literal(DAY_NUMBERS[2]),
  z.literal(DAY_NUMBERS[3]),
  z.literal(DAY_NUMBERS[4]),
  z.literal(DAY_NUMBERS[5]),
  z.literal(DAY_NUMBERS[6]),
]);

export const sparkRecurringScheduleSchema = z.object({
  interval: z.enum(RECURRING_SPARK_SCHEDULE_OPTIONS),
  startDate: z.string().min(1),
  daysOfTheWeek: z.array(dayNumberSchema).optional(),
});

export const sparkCourseDataSchema = z
  .object({
    accessToken: z.string().optional(),
    courseDescription: z.string().min(1),
    courseId: z.string().min(1),
    courseLength: z.number().optional(),
    courseTitle: z.string().optional(),
    completedUsersByCourseId: courseCompleteUsersSchema,
    viewedUsersByCourseId: courseViewedUsersSchema,
    courseType: courseTypeEnumSchema,
    required: z.boolean().optional(),
  })
  .refine(
    (data) => {
      if (data.courseType === 'zoltrain') {
        return !!data.accessToken;
      }

      return true;
    },
    { message: `accessToken is required for courseType === 'zoltrain'` },
  );

export const timelineUpdatePropsSchema = z.object({
  daysOfWeek: z.array(z.number()),
  timeOfDay: z.string().regex(/^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/),
  timeZone: z.string().min(1),
  enabled: z.boolean(),
  publishOnlyToParticipants: z.boolean(),
});

export const multiRetailerSparkParticipantGroupSchema = z.object({
  retailerAccountId: z.string().min(1),
  participantGroups: z.array(sparkParticipantGroupSchema),
});

export type MultiRetailerSparkParticipantGroup = z.infer<
  typeof multiRetailerSparkParticipantGroupSchema
>;

export const SparkDeclineReasons = [
  'Preference for team-based Sparks',
  'Not enough inventory',
  'Pause on outside incentives',
  'Product/Brand not carried',
  'Thresholds are too high',
  'Issue with approval process',
  'Spark already accepted or duplicate',
  'Spark dates adjustment',
  'Reward preferences',
  'Other',
] as const;
export type SparkDeclineReasons = typeof SparkDeclineReasons[number];

export const sparkDeclineOptions = SparkDeclineReasons.map((reason) => ({
  label: reason,
  value: reason,
}));

export type SparkDeclineResponse = {
  reason?: SparkDeclineReasons;
  message?: string;
};

export const respondToSparkInviteSchema = z.object({
  accepted: z.boolean(),
  groupId: z.string().min(1),
  sparkId: z.string().min(1),

  participantGroups: z.array(sparkParticipantGroupSchema),

  /**
   * @deprecated This is the legacy method for displaying spark decline response
   */
  responseText: z.string().optional(),

  declineResponse: z
    .object({
      reason: z.string().optional(),
      message: z.string().optional(),
    })
    .optional(),
});
respondToSparkInviteSchema.refine(
  (data) =>
    data.accepted &&
    !!data.participantGroups?.some((group) => !!group.posEmployeeProfileIds?.length),
  'Accepted sparks must include employees',
);

export type RespondToSparkInviteRequestBody = z.infer<typeof respondToSparkInviteSchema>;

export const createUpdateSparkSchema = z.object({
  endDate: z.string().min(1).optional(),
  startDate: z.string().min(1).optional(),
  awards: z
    .array(sparkAwardSchema)
    .refine(
      (awards) => {
        if (awards) {
          for (const award of awards) {
            const result = sparkAwardSchema.safeParse(award);
            if (!result.success) {
              return false;
            }
          }
        }
        return true;
      },
      { message: 'Invalid award object in array' },
    )
    .optional(),
  commissions: z
    .array(sparkCommissionSchema)
    .refine(
      (commissions) => {
        if (commissions) {
          for (const commission of commissions) {
            const result = sparkCommissionSchema.safeParse(commission);
            if (!result.success) {
              return false;
            }
          }
        }
        return true;
      },
      { message: 'Invalid commission object in array' },
    )
    .optional(),
  description: z.string().min(1).optional(),
  emoji: z.string().min(1).optional(),
  /**
   * @deprecated `goalType` is deprecated in favor of `detailedSparkType`
   */
  goalType: z.enum(SPARK_GOAL_TYPES).optional(),
  goals: z
    .array(sparkGoalSchema)
    .refine(
      (goals) => {
        if (goals) {
          for (const goal of goals) {
            const result = sparkGoalSchema.safeParse(goal);
            if (!result.success) {
              return false;
            }
          }
        }
        return true;
      },
      { message: 'Invalid goal object in array' },
    )
    .optional(),
  isPublic: z.boolean().optional(),
  fulfillmentTypes: z.array(z.enum(SPARK_FULFILLMENT_TYPES)).optional(),
  minimumThresholdToQualify: z.number().optional(),
  minimumTransactionsToQualify: z.number().optional(),
  productFilters: sparkProductFiltersSchema.optional(),
  participantFilters: sparkParticipantFiltersSchema.optional(),
  originatorGroupId: z.string().min(1).optional(),
  posBrandIds: z.array(z.string().min(1)).optional(),
  posCategoryIds: z.array(z.string().min(1)).optional(),
  posProductIds: z.array(z.string().min(1)).optional(),
  splitPayout: z.boolean().optional(),
  timelineUpdateProps: timelineUpdatePropsSchema.optional(),
  detailedSparkType: z.enum(DETAILED_SPARK_TYPES).optional(),
  recurringSchedule: sparkRecurringScheduleSchema.optional(),
  trainingCourseId: z.string().min(1).optional(),
  trainingEnabled: z.boolean().optional(),
  courseData: sparkCourseDataSchema.optional(),
  sparkBrandId: z.string().min(1).optional(),
  retailerFilters: retailerSparkFiltersSchema.partial().optional().or(z.object({}).optional()),
  vendorFilters: vendorSparkFiltersSchema.partial().optional().or(z.object({}).optional()),
  commissionRules: commissionRulesSchema.optional().or(z.object({}).optional()),
  percentIncreaseData: percentIncreaseSchema.optional(),
  teamType: z.enum(TEAM_TYPES).optional(),
  snaps: sparkSnapDataSchema.optional(),
  templateId: z.string().min(1).optional(),
  clonedFromSparkId: z.string().min(1).optional(),
});

const preprocessCreateUpdateSparkSchema = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((data: z.TypeOf<T>) => {
    // Remove course data if training is not enabled
    if (!data.trainingEnabled) {
      delete data.courseData;
    }

    return data;
  }, schema);

export const createSparkSchema = preprocessCreateUpdateSparkSchema(
  createUpdateSparkSchema.extend({
    requestForSparkId: z.string().min(1).optional(),
    participantGroups: z.array(sparkParticipantGroupSchema),
    groupId: z.string().min(1),
    metric: z.enum(SPARK_METRICS).optional(),
    name: z.string().min(1),
    type: z.enum(SPARK_TYPES),
  }),
).refine(
  (data) => {
    if (data.metric === 'percent_increase') {
      return !!data.percentIncreaseData;
    }

    return true;
  },
  { message: `must include percentIncreaseData for percent increase spark` },
);

export type CreateSparkSchema = z.infer<typeof createSparkSchema>;
export type CreateSparkRequestBody = CreateSparkSchema;

export const updateSparkSchema = preprocessCreateUpdateSparkSchema(
  createUpdateSparkSchema.extend({
    participantGroups: z.array(sparkParticipantGroupSchema).optional(),
    groupId: z.string().min(1).optional(),
    metric: z.enum(SPARK_METRICS).optional(),
    name: z.string().min(1).optional(),
    type: z.enum(SPARK_TYPES).optional(),
    brandOverrideIsEnabled: z.boolean().optional(),
  }),
).refine(
  (data) => {
    if (data.metric === 'percent_increase') {
      return !!data.percentIncreaseData;
    }

    return true;
  },
  { message: `must include percentIncreaseData for percent increase spark` },
);

export type UpdateSparkSchema = z.infer<typeof updateSparkSchema>;
export type UpdateSparkRequestBody = UpdateSparkSchema;

export type QuickAcceptRequestParams = {
  sparkId: string;
  quickApprovalCode: string;
};

export type QuickAcceptSparkDetails = {
  spark: Spark;
  // if another user already accepted this spark, we'll show a slightly different success message
  alreadyAccepted?: boolean;
};

export interface ListSparkProductLabelsResponseBody {
  data: ProductLabels[];
}

export const updateSparkSnapsPathParamsSchema = z.object({
  sparkId: z.string().min(1),
});
export type UpdateSparkSnapsPathParams = z.infer<typeof getSparkIdPathParamsSchema>;

export const updateSparkSnapsRequestBodySchema = sparkSnapDataSchema;
export type UpdateSparkSnapsRequestBody = z.infer<typeof updateSparkSnapsRequestBodySchema>;
export type UpdateSparkSnapsResponseBody = void;
