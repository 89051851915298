import { AlleavesCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface IAlleavesPosSpecificFieldsProps {
    credentials?: AlleavesCredentials;
    onChange: (key: string, value: any) => void;
}

const AlleavesPosSpecificFields = ({ credentials, onChange }: IAlleavesPosSpecificFieldsProps) => {
    const { username = '', password = '' } = credentials || {};

    return (
        <>
            <Form.TextField
                label="Username"
                name="username"
                required
                defaultValue={username}
                onChange={(event) => onChange('username', event.target.value)}
            />
            <Form.TextField
                label="Password"
                name="password"
                required
                defaultValue={password}
                onChange={(event) => onChange('password', event.target.value)}
            />
        </>
    );
};

export default AlleavesPosSpecificFields;
