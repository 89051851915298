import { clsx } from 'clsx';

import { Spark, SparkAward, SparkType } from '@sparkplug/lib';

import { SparkTemplate } from '@features/spark-dashboard/types';

import Dropdown from '@components/dropdown/Dropdown';
import {
    CircleCheck as CircleCheckIcon,
    Crown as CrownIcon,
    Info as InfoIcon,
    Spark as SparkIcon,
    UnlimitedGoalIcon,
} from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import Tooltip from '@components/layout/Tooltip';

import { useApp } from '@hooks/AppHooks';

import { getOrdinal } from '@helpers/util';

import MinThresholdCallout from '../MinThresholdCallout';
import useOrderedAwardBlock from './useOrderedAwardBlock';
import { usePayoutCalloutMessage } from './usePayoutCalloutMessage';

import './SparkAwardsPanel.scss';

export const FulfilledBySparkplugIcon = () => (
    <Tooltip title="SparkPlug will automatically pay out this prize to employees at the end of the Spark.">
        <SparkIcon className="spark-icon" />
    </Tooltip>
);

const BlockCountTrackers = {
    leaderboard: ({ count }: { count: number }) => {
        const ordinal = getOrdinal(count);
        return (
            <div className="block-counter-tracker">
                <CrownIcon className="crown-icon" />
                <span className="number">{count}</span>
                <sup>{ordinal}</sup>
                <span>Place</span>
            </div>
        );
    },

    goal: ({ count, isUnlimited }: { count: number; isUnlimited: boolean }) => {
        if (isUnlimited) {
            return (
                <div className="block-counter-tracker">
                    <UnlimitedGoalIcon />
                    <span className="number">Unlimited Goal</span>
                </div>
            );
        }

        return (
            <div className="block-counter-tracker">
                <CircleCheckIcon />
                <span className="number">{`Goal ${count}`}</span>
            </div>
        );
    },
};

export type AwardBlockProps = {
    award: SparkAward;
    threshold?: string;
    isUnlimited?: boolean;
    fulfilledBySparkplug: boolean;
    sparkType: 'leaderboard' | 'goal';
    blockCount: number;
};

const AwardBlock = ({
    award,
    threshold,
    isUnlimited = false,
    fulfilledBySparkplug,
    sparkType,
    blockCount,
}: AwardBlockProps) => {
    const BlockCountTracker = BlockCountTrackers[sparkType];

    let thresholdMessage;
    if (threshold) {
        thresholdMessage = isUnlimited ? `For every ${threshold} sold` : `Sell ${threshold}`;
    }

    return (
        <div className="award-block">
            <div className={clsx(fulfilledBySparkplug && 'fulfilled-by-sparkplug', 'award')}>
                <div className="count-container">
                    <BlockCountTracker count={blockCount} isUnlimited={isUnlimited} />
                    <section className="threshold">{thresholdMessage}</section>
                </div>

                <div className="label-container">
                    <section className="label-main">
                        <span className="award-label">{award.award}</span>
                        {fulfilledBySparkplug ? <FulfilledBySparkplugIcon /> : null}
                    </section>
                </div>
            </div>
        </div>
    );
};

const GoalInfoButton = () => (
    <Dropdown>
        <Dropdown.IconButton color="neutral">
            <InfoIcon className="info-icon" />
        </Dropdown.IconButton>
        <Dropdown.Popover>
            <div className="goal-spark-popover">
                <h3>
                    <span>About Spark</span>
                    &nbsp;
                    <span role="img" aria-label="lightning">
                        ⚡️
                    </span>
                    &nbsp;
                    <span>Goal</span>
                </h3>
                <p>
                    Once a user has reached a goal, they will be eligible for the prize associated
                    with the goal they accomplished.
                </p>
            </div>
        </Dropdown.Popover>
    </Dropdown>
);

const HeaderTitles: Partial<Record<SparkType, string>> = {
    leaderboard: 'Prizes',
    goal: 'Goals',
};

export const AwardsHeaderTile = ({
    spark,
    userIsAdmin,
}: {
    spark: Spark | SparkTemplate;
    userIsAdmin: boolean;
}) => {
    const { type: sparkType } = spark;
    return (
        <h2>
            {sparkType === 'goal' && userIsAdmin && <GoalInfoButton />}
            {HeaderTitles[sparkType!]}
        </h2>
    );
};

function SparkAwardsPanel({
    spark,
    hideTitle = false,
    variant = 'raised',
}: {
    spark: Spark;
    hideTitle?: boolean;
    variant?: 'raised' | 'smooth';
}) {
    const { userIsAdmin } = useApp();
    const { type: sparkType } = spark;

    const showMinThresholdCallout =
        sparkType === 'leaderboard' &&
        (!!spark.minimumThresholdToQualify || !!spark.minimumTransactionsToQualify);

    const payoutCalloutMessage = usePayoutCalloutMessage(spark);

    const orderedAwardBlockProps = useOrderedAwardBlock(spark);

    return (
        <Paper className="awards-container" variant={variant}>
            {showMinThresholdCallout && <MinThresholdCallout />}

            {!hideTitle && sparkType && (
                <div className="awards-header">
                    <AwardsHeaderTile spark={spark} userIsAdmin={userIsAdmin} />

                    {payoutCalloutMessage && (
                        <CalloutMessage
                            hideIcon
                            className="awards-callout"
                            message={payoutCalloutMessage}
                            color="blue"
                            variant="outlined"
                        />
                    )}
                </div>
            )}

            <div className="awards-content">
                {orderedAwardBlockProps.map((awardBlockProps, i) => (
                    <AwardBlock key={`${i}-${awardBlockProps.award.award}`} {...awardBlockProps} />
                ))}
            </div>
        </Paper>
    );
}

export default SparkAwardsPanel;
