import { typedMemo } from '@helpers/react/typedMemo';
import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';

import { TableRow as MaterialUITableRow } from '@mui/material';

import Tooltip from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import { ITableRow, THeadCell } from '@app/types/TableTypes';

import TableRenderCell from '../TableRenderCell';

import './TableRenderRow.scss';

interface TableRowProps {
    className?: string;
    hover?: boolean;
    onClick?: (event: any) => void;
    tabIndex?: number;
    tooltip?: string;
}

export const TableRow: FC<PropsWithChildren<TableRowProps>> = ({
    className,
    tooltip,
    ...props
}) => {
    const classNameAppended = [className, 'sparkplug-table-row'].join(' ');

    if (tooltip) {
        return (
            <Tooltip title={tooltip} placement="top-start">
                <MaterialUITableRow className={classNameAppended} {...props} />
            </Tooltip>
        );
    }

    return <MaterialUITableRow className={classNameAppended} {...props} />;
};

interface TableRenderRowProps<T> {
    tableHeadData: THeadCell<T>[];
    row: ITableRow<T>;
    isSelected?: boolean;
    hover?: boolean;
    onClick?: ((row: any, event: any) => {} | void) | undefined;
    className?: string;
}

const TableRenderRow = typedMemo(
    <T extends {}>({
        tableHeadData,
        row: _row,
        isSelected = false,
        hover = false,
        onClick,
        className,
    }: TableRenderRowProps<T>) => {
        const [rowOverride, setRow] = useState(_row);
        const itemsRef = useRef<any>([]);

        useEffect(() => {
            itemsRef.current = itemsRef.current.slice(0, tableHeadData.length);
        }, [tableHeadData]);

        const row = useMemo(
            () => ({
                ..._row,
                ...rowOverride,
            }),
            [_row, rowOverride],
        );
        const classNamesAppended = appendClasses([className, isSelected ? 'is-selected' : '']);

        return (
            <TableRow
                hover={hover}
                className={classNamesAppended}
                onClick={(event) => onClick && onClick(row, event)}
            >
                {tableHeadData.map(
                    (headCell, i) =>
                        !headCell.isHidden && (
                            <TableRenderCell
                                ref={(el) => {
                                    itemsRef.current[i] = el;
                                }}
                                key={i}
                                headCell={headCell}
                                row={row}
                                setRow={setRow}
                            />
                        ),
                )}
            </TableRow>
        );
    },
);

export default TableRenderRow;
