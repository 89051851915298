import { FC, useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import { keyBy } from 'lodash';

import { AccountSparkBrand } from '@sparkplug/lib';

import { IntercomContactMessage } from '@components/buttons/IntercomContactMessage/IntercomContactMessage';
import Chip from '@components/chips/Chip';
import RadioGroup from '@components/inputs/RadioGroup';
import Skeleton from '@components/layout/Skeleton';

import './SparkBrandDropdown.scss';

const SparkBrandLogo: FC<{ name: string; photo: string }> = ({ name, photo }) => (
    <div className="spark-brand-dropdown_spark-brand-logo">
        <img src={photo} alt={name} />
    </div>
);

const SparkBrandChipLabel: FC<{ name: string }> = ({ name }) => {
    if (name.length > 10) {
        return (
            <Tooltip title={name} placement="top">
                <span>{name}</span>
            </Tooltip>
        );
    }

    return <span>{name}</span>;
};

interface SparkBrandDropdownProps {
    accountId: string;
    retailerAccount?: { id: string; name: string };
    disabled?: boolean;
    defaultValue?: string;
    onChange: (updatedSparkBrandId: string, updatedSparkBrandName: string) => void;
    sparkBrands: AccountSparkBrand[];
    sparkBrandsAreReady: boolean;
    isCreatingMultiRetailerSpark: boolean;
}

const SparkBrandDropdown: FC<SparkBrandDropdownProps> = ({
    accountId,
    retailerAccount,
    disabled,
    defaultValue,
    onChange,
    sparkBrands,
    sparkBrandsAreReady,
    isCreatingMultiRetailerSpark,
}) => {
    const [value, setValue] = useState(defaultValue);

    const enabledBrandOptions = sparkBrands.filter(
        ({ _id, isEnabled }) => isEnabled || _id === value,
    );

    useEffect(() => {
        // If there is only one Spark Brand, and there's a retailer selected, and that retailer has recently sold products for the brand select it by default
        if (!value && enabledBrandOptions?.length === 1) {
            const [brand] = enabledBrandOptions;
            if (retailerAccount?.id && brand.productsAvailableByRetailer?.[retailerAccount?.id]) {
                setValue(brand._id);
                onChange(brand._id, brand.name);
            }
        }
    }, [enabledBrandOptions, value, retailerAccount?.id]);

    useEffect(() => {
        // if the user switches retailers, the originally selected brand may no longer have associated products, so we should reset
        if (value) {
            const selectedBrand = sparkBrands.find(({ _id }) => _id === value);
            if (
                selectedBrand &&
                retailerAccount?.id &&
                !selectedBrand.productsAvailableByRetailer?.[retailerAccount?.id]
            ) {
                setValue(undefined);
                onChange('', '');
            }
        }
    }, [defaultValue, retailerAccount?.id]);

    if (!sparkBrandsAreReady || !sparkBrands?.length) {
        return <Skeleton className="brand-card-skeleton" count={1} width="100%" height={160} />;
    }

    const brandsById = keyBy(sparkBrands, '_id');

    return (
        <div className="spark-brand-selector">
            <RadioGroup
                label="Spark Brand"
                color="neutral"
                variant="button"
                required
                alignment="center"
                disabled={disabled}
                options={enabledBrandOptions.map((sparkBrand) => ({
                    value: sparkBrand._id,
                    label: (
                        <Chip
                            dense
                            className="spark-brand-chip"
                            color="blue"
                            variant="flat"
                            label={<SparkBrandChipLabel name={sparkBrand.name} />}
                        />
                    ),
                    icon: <SparkBrandLogo {...sparkBrand} />,
                    disabled:
                        !sparkBrand.isEnabled ||
                        (!sparkBrand.productsAvailableByRetailer?.[retailerAccount?.id ?? ''] &&
                            !isCreatingMultiRetailerSpark),
                    tooltipProps: {
                        title:
                            !sparkBrand.productsAvailableByRetailer?.[retailerAccount?.id ?? ''] &&
                            !isCreatingMultiRetailerSpark &&
                            retailerAccount?.name
                                ? `${retailerAccount?.name} hasn't sold any ${sparkBrand?.name} products in the last 60 days. Questions? Contact support!`
                                : '',
                    },
                }))}
                value={value}
                onChange={(event) => {
                    const brandId = event.target.value;
                    const brand = brandsById[brandId];
                    setValue(brandId);
                    onChange(brandId, brand.name);
                }}
            />
            <IntercomContactMessage prompt="Need to make an update?" />
        </div>
    );
};

export default SparkBrandDropdown;
