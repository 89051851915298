import { useState } from 'react';

import IconButton from '@components/buttons/IconButton';
import Form from '@components/form/Form';
import { Done, Edit } from '@components/icons';

import './MutableLink.scss';

interface MutableLinkProps {
    label: string;
    url: string;
    name: string;
    initialValue: string;
    required?: boolean;
    error?: boolean;
    onUpdate: (id: string) => void;
}

const MutableLink = ({
    label,
    url,
    initialValue,
    onUpdate,
    name,
    required = false,
    error = false,
}: MutableLinkProps) => {
    const [editing, setEditing] = useState<boolean>(false);
    const [value, setValue] = useState<string>(initialValue);

    const updateIconButton = (
        <IconButton
            onClick={() => {
                onUpdate(value);
                setEditing(false);
            }}
        >
            <Done fontSize="small" />
        </IconButton>
    );

    return (
        <div className="mutable-link">
            {editing ? (
                <div>
                    <Form.TextField
                        required
                        label={label}
                        name={name}
                        value={value}
                        color="blue"
                        onChange={(event) => {
                            setValue(event.target.value);
                        }}
                        endIcon={updateIconButton}
                    />
                </div>
            ) : (
                <div>
                    <Form.Label error={error} required={required}>
                        {label}
                    </Form.Label>
                    <div className="readonly-row">
                        <div className="readonly-border">
                            <a href={url} target="_blank" rel="noreferrer">
                                {value}
                            </a>
                            <IconButton onClick={() => setEditing(true)}>
                                <Edit fontSize="small" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MutableLink;
