import { useHistory } from 'react-router';

import { SidebarLayout } from '@componentLibrary';

import Button from '@components/buttons/Button';
import { Add as AddIcon } from '@components/icons';
import PageHeader from '@components/layout/PageHeader';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import SnapsFeatureSidebar from '../../components/SnapsFeatureSidebar';
import SparkSnapsTemplatesView from '../AdminAccountSnapsView/SparkSnapsTemplatesView';

const TemplatesView = () => {
    const { account, hasSnapsEntitlement } = useSparkplugAccount();
    const history = useHistory();

    return (
        <SidebarLayout
            header={
                <PageHeader title={`${account?.name || ''}`.trim()} heading="Snap Templates">
                    <div className="flex justify-end flex-1">
                        {hasSnapsEntitlement && (
                            <Button
                                startIcon={<AddIcon />}
                                className="toolbar-group-end"
                                variant="filled"
                                onClick={() => {
                                    history.push('?create=true');
                                }}
                            >
                                Create Template
                            </Button>
                        )}
                    </div>
                </PageHeader>
            }
            sidebar={<SnapsFeatureSidebar />}
            content={<SparkSnapsTemplatesView />}
        />
    );
};

export default TemplatesView;
