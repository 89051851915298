import { useCallback, useEffect, useState } from 'react';

import moment from 'moment';

import {
    AlleavesCredentials,
    BiotrackCredentials,
    BlazeCredentials,
    CloverCredentials,
    CovaCredentials,
    DEFAULT_RECONCILE_LOOKBACK_HOURS,
    FlourishCredentials,
    FlowhubCredentials,
    GoTabCredentials,
    GreenbitsCredentials,
    GrowFlowCredentials,
    IHeartJaneCredentials,
    IndicaOnlineCredentials,
    LeafLogixCredentials,
    LightspeedCredentials,
    MJFreewayCredentials,
    MeadowCredentials,
    Portal42Credentials,
    PosConfig,
    PosConfigType,
    PosConfigTypes,
    PosabitCredentials,
    RPowerCredentials,
    RevelCredentials,
    ShopifyShopCredentials,
    SquareMerchantCredentials,
    SweedCredentials,
    ToastCredentials,
    TreezCredentials,
    VendCredentials,
} from '@sparkplug/lib';

import {
    reimportPosConfig,
    savePosConfig,
    useFetchPosConfigsByGroupId,
    verifyPosConfigCredentials,
} from '@core/accounts';

import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import { Add, Info, MoreHoriz, Refresh, SaveAlt, VerifiedUser } from '@components/icons';
import Grid from '@components/layout/Grid';
import Paper from '@components/layout/Paper';
import toast from '@components/toast';

import { uuid } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';

import AlleavesPosSpecificFields from './AlleavesPosSpecificFields';
import BlazePosSpecificFields from './BlazePosSpecificFields';
import CloverPosSpecificFields from './CloverPosSpecificFields';
import CovaPosSpecificFields from './CovaPosSpecificFields';
import GoTabPosSpecificFields from './GoTabPosSpecificFields';
import GrowFlowPosSpecificFields from './GrowFlowPosSpecificFields';
import IHeartJanePosSpecificFields from './IHeartJanePosSpecificField';
import IndicaOnlinePosSpecificFields from './IndicaOnlinePosSpecificFields';
import LeafLogixPosSpecificFields from './LeafLogixPosSpecificFields';
import LightspeedPosSpecificFields from './LightspeedPosSpecificFields';
import MeadowPosSpecificFields from './MeadowPosSpecificFields';
import POSConfigDetailsModal from './POSConfigDetailsModal';
import Portal42PosSpecificFields from './Portal42PosSpecificFields';
import PosabitPosSpecificFields from './PosabitPosSpecificFields';
import RPowerPosSpecificFields from './RPowerPosSpecificFields';
import { ReimportConfirmModal } from './ReimportConfirmModal';
import RevelPosSpecificFields from './RevelPosSpecificFields';
import ShopifyPosSpecificFields from './ShopifyPosSpecificFields';
import SquarePosSpecificFields from './SquarePosSpecificFields';
import SweedPosSpecificFields from './SweedPosSpecificFields';
import ToastPosSpecificFields from './ToastPosSpecificFields';
import TreezPosSpecificFields from './TreezPosSpecificFields';
import VendPosSpecificFields from './VendPosSpecificFields';

import './POSConfigurationForm.scss';

type PosConfigState = PosConfig & {
    key: string;
    testMessage?: string;
    testError?: string;
    testing?: boolean;
};

const pointOfSaleList: Array<{ label: string; value: PosConfigType }> = [
    { label: 'Alleaves', value: 'alleaves' },
    { label: 'BioTrack', value: 'biotrack' },
    { label: 'Blaze', value: 'blaze' },
    { label: 'Cova', value: 'cova' },
    { label: 'Flourish', value: 'flourish' },
    { label: 'Flowhub', value: 'flowhub' },
    { label: 'GoTab', value: 'gotab' },
    { label: 'Greenbits', value: 'greenbits' },
    { label: 'GrowFlow', value: 'growflow' },
    { label: 'I Heart Jane', value: 'iheartjane' },
    { label: 'Indica Online', value: 'indicaonline' },
    { label: 'Leaf Logix', value: 'leaflogix' },
    { label: 'Lightspeed', value: 'lightspeed' },
    { label: 'Meadow', value: 'meadow' },
    { label: 'MJ Freeway', value: 'mjfreeway' },
    { label: 'Portal42', value: 'portal42' },
    { label: 'POSaBit', value: 'posabit' },
    { label: 'Revel', value: 'revel' },
    { label: 'RPower', value: 'rpower' },
    { label: 'Shopify', value: 'shopify' },
    { label: 'Square', value: 'square' },
    { label: 'Sweed', value: 'sweed' },
    { label: 'Toast', value: 'toast' },
    { label: 'Treez', value: 'treez' },
    { label: 'Vend', value: 'vend' },
];

// Some POS types are created via Oauth - we don't want to manually create/edit these in the CC, but we do want to properly display them
const readOnlyPointOfSaleList: Array<{ label: string; value: PosConfigType }> = [
    { label: 'Clover', value: 'clover' },
];

const _getLabelByValue = (objectList: any[], value: any) => {
    const listObj = objectList.find((obj) => obj.value === value);
    if (listObj != null && listObj.label != null) {
        return listObj.label;
    } else {
        return '';
    }
};

const _newConfigState = (additionalProperties: any) => {
    return {
        lookbackError: '',
        modified: false,
        testError: '',
        testMessage: '',
        testing: false,
        ...additionalProperties,
    };
};

const PosSpecificFields = ({
    posConfigState,
    onChangePosCredentials,
}: {
    posConfigState: PosConfigState;
    onChangePosCredentials: any;
}) => {
    switch (posConfigState.type) {
        case 'posabit':
            return (
                <PosabitPosSpecificFields
                    credentials={posConfigState.credentials as PosabitCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'meadow':
            return (
                <MeadowPosSpecificFields
                    credentials={posConfigState.credentials as MeadowCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'alleaves':
            return (
                <AlleavesPosSpecificFields
                    credentials={posConfigState.credentials as AlleavesCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'flourish':
            return (
                <>
                    <Form.TextField
                        label="Username"
                        name="username"
                        required
                        value={(posConfigState.credentials as FlourishCredentials)?.username ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(
                                posConfigState.key,
                                'username',
                                event.target.value,
                            )
                        }
                    />
                    <Form.TextField
                        label="API Key"
                        name="apiKey"
                        required
                        value={(posConfigState.credentials as FlourishCredentials)?.apiKey ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'apiKey', event.target.value)
                        }
                    />
                </>
            );
        case 'flowhub':
            return (
                <>
                    <Form.TextField
                        label="API Key"
                        name="apiKey"
                        required
                        value={(posConfigState.credentials as FlowhubCredentials)?.apiKey ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'apiKey', event.target.value)
                        }
                    />
                    <Form.TextField
                        label="Client ID"
                        name="clientId"
                        required
                        value={(posConfigState.credentials as FlowhubCredentials)?.clientId ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(
                                posConfigState.key,
                                'clientId',
                                event.target.value,
                            )
                        }
                    />
                </>
            );
        case 'greenbits':
            return (
                <>
                    <Form.TextField
                        label="Username"
                        name="username"
                        required
                        value={(posConfigState.credentials as GreenbitsCredentials)?.username ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(
                                posConfigState.key,
                                'username',
                                event.target.value,
                            )
                        }
                    />
                    <Form.TextField
                        label="Password"
                        name="password"
                        required
                        value={(posConfigState.credentials as GreenbitsCredentials)?.password ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(
                                posConfigState.key,
                                'password',
                                event.target.value,
                            )
                        }
                    />
                </>
            );
        case 'cova':
            return (
                <CovaPosSpecificFields
                    credentials={posConfigState.credentials as CovaCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'biotrack':
            return (
                <>
                    <Form.TextField
                        label="Username"
                        name="username"
                        required
                        value={(posConfigState.credentials as BiotrackCredentials)?.username ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(
                                posConfigState.key,
                                'username',
                                event.target.value,
                            )
                        }
                    />
                    <Form.TextField
                        label="Password"
                        name="password"
                        required
                        value={(posConfigState.credentials as BiotrackCredentials)?.password ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(
                                posConfigState.key,
                                'password',
                                event.target.value,
                            )
                        }
                    />
                    <Form.TextField
                        label="Host"
                        name="host"
                        required
                        value={(posConfigState.credentials as BiotrackCredentials)?.host ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'host', event.target.value)
                        }
                    />
                    <Form.TextField
                        label="CA"
                        name="ca"
                        multiline
                        rows={3}
                        value={(posConfigState.credentials as BiotrackCredentials)?.ca ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'ca', event.target.value)
                        }
                    />
                    <Form.TextField
                        label="Key"
                        name="key"
                        multiline
                        rows={3}
                        value={(posConfigState.credentials as BiotrackCredentials)?.key ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'key', event.target.value)
                        }
                    />
                    <Form.TextField
                        label="Cert"
                        name="cert"
                        multiline
                        rows={3}
                        value={(posConfigState.credentials as BiotrackCredentials)?.cert ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'cert', event.target.value)
                        }
                    />
                </>
            );
        case 'mjfreeway':
            return (
                <>
                    <Form.TextField
                        label="API Key"
                        name="apiKey"
                        required
                        value={(posConfigState.credentials as MJFreewayCredentials)?.apiKey ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'apiKey', event.target.value)
                        }
                    />
                    <Form.TextField
                        label="Org ID"
                        name="orgId"
                        required
                        value={(posConfigState.credentials as MJFreewayCredentials)?.orgId ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'orgId', event.target.value)
                        }
                    />
                    <Form.TextField
                        label="User ID"
                        name="userId"
                        required
                        value={(posConfigState.credentials as MJFreewayCredentials)?.userId ?? ''}
                        onChange={(event) =>
                            onChangePosCredentials(posConfigState.key, 'userId', event.target.value)
                        }
                    />
                </>
            );
        case 'revel':
            return (
                <RevelPosSpecificFields
                    credentials={posConfigState.credentials as RevelCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'square':
            return (
                <SquarePosSpecificFields
                    credentials={posConfigState.credentials as SquareMerchantCredentials}
                />
            );
        case 'shopify':
            return (
                <ShopifyPosSpecificFields
                    credentials={posConfigState.credentials as ShopifyShopCredentials}
                />
            );
        case 'treez':
            return (
                <TreezPosSpecificFields
                    credentials={posConfigState.credentials as TreezCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'vend':
            return (
                <VendPosSpecificFields
                    credentials={posConfigState.credentials as VendCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'growflow':
            return (
                <GrowFlowPosSpecificFields
                    credentials={posConfigState.credentials as GrowFlowCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'iheartjane':
            return (
                <IHeartJanePosSpecificFields
                    credentials={posConfigState.credentials as IHeartJaneCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'indicaonline':
            return (
                <IndicaOnlinePosSpecificFields
                    credentials={posConfigState.credentials as IndicaOnlineCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );

        case 'leaflogix':
            return (
                <LeafLogixPosSpecificFields
                    credentials={posConfigState.credentials as LeafLogixCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'blaze':
            return (
                <BlazePosSpecificFields
                    credentials={posConfigState.credentials as BlazeCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'toast':
            return (
                <ToastPosSpecificFields
                    credentials={posConfigState.credentials as ToastCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'sweed':
            return (
                <SweedPosSpecificFields
                    credentials={posConfigState.credentials as SweedCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'portal42':
            return (
                <>
                    <Portal42PosSpecificFields
                        credentials={posConfigState.credentials as Portal42Credentials}
                        onChange={(key, value) => {
                            onChangePosCredentials(posConfigState.key, key, value);
                        }}
                    />
                </>
            );
        case 'lightspeed':
            return (
                <LightspeedPosSpecificFields
                    credentials={posConfigState.credentials as LightspeedCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'clover':
            return (
                <CloverPosSpecificFields
                    credentials={posConfigState.credentials as CloverCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'gotab':
            return (
                <GoTabPosSpecificFields
                    credentials={posConfigState.credentials as GoTabCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        case 'rpower':
            return (
                <RPowerPosSpecificFields
                    credentials={posConfigState.credentials as RPowerCredentials}
                    onChange={(key, value) => {
                        onChangePosCredentials(posConfigState.key, key, value);
                    }}
                />
            );
        default:
            return null;
    }
};

const PosGenericFields = ({
    posConfigState,
    onChangePosSettings,
}: {
    posConfigState: PosConfigState;
    onChangePosSettings: any;
}) => {
    const timezoneOptions = moment.tz.names().map((name) => {
        return {
            label: name,
            value: name,
        };
    });

    const {
        lookback,
        reconcileLookbackHours = DEFAULT_RECONCILE_LOOKBACK_HOURS,
        timeZone,
    } = posConfigState;

    return (
        <>
            <Grid>
                <Grid.Item sm={5}>
                    <Form.DatePicker
                        id={`date-pickers-${posConfigState.key}`}
                        required
                        label="Lookback Date"
                        value={lookback}
                        onChange={(value) =>
                            onChangePosSettings(posConfigState.key, 'lookback', value)
                        }
                    />
                </Grid.Item>

                <Grid.Item sm={7}>
                    <Form.TextField
                        type="number"
                        name={`${posConfigState.key}[reconcileLookbackHours]`}
                        required
                        label="Reconcile Lookback Hours"
                        max={24 * 7 * 4}
                        min={24}
                        value={reconcileLookbackHours}
                        onChange={(event) =>
                            onChangePosSettings(
                                posConfigState.key,
                                'reconcileLookbackHours',
                                event.target.value,
                            )
                        }
                    />
                </Grid.Item>

                <Grid.Item sm={12}>
                    <Form.Select
                        required
                        label="Timezone"
                        value={timeZone}
                        onChange={(event) =>
                            onChangePosSettings(posConfigState.key, 'timeZone', event.target.value)
                        }
                        options={timezoneOptions}
                    />
                </Grid.Item>
            </Grid>

            <Form.Checkbox
                label="Enabled"
                value={posConfigState.enabled}
                onChange={(event) =>
                    onChangePosSettings(posConfigState.key, 'enabled', event.target.checked)
                }
            />
        </>
    );
};

interface ConfigButtonProps {
    configState: PosConfigState;
    onReimport: any;
    onTestCredentials: any;
    onShowDetails: any;
}

const ConfigButtons = ({
    configState,
    onReimport,
    onTestCredentials,
    onShowDetails,
}: ConfigButtonProps) => {
    return (
        <div className="config-buttons">
            <Dropdown>
                <Dropdown.IconButton>
                    <MoreHoriz />
                </Dropdown.IconButton>
                <Dropdown.Menu>
                    <Dropdown.MenuItem StartIcon={Refresh} onClick={() => onReimport(configState)}>
                        Re-import Data
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                        StartIcon={VerifiedUser}
                        onClick={() => onTestCredentials(configState)}
                        hidden={configState.testing}
                    >
                        Verify Credentials
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                        StartIcon={Info}
                        onClick={() => onShowDetails(configState)}
                        hidden={configState._id == null}
                    >
                        POS Details
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

const AddPosConfigButton = ({ onAddPointOfSaleConfig }: any) => {
    return (
        <Dropdown>
            <Dropdown.Button color="neutral" variant="filled" startIcon={<Add />}>
                Add Point of Sale
            </Dropdown.Button>
            <Dropdown.Menu>
                {pointOfSaleList.map((pos) => (
                    <Dropdown.MenuItem
                        key={`add-pos-config-${pos.value}`}
                        onClick={() => onAddPointOfSaleConfig(pos.value)}
                    >
                        {pos.label}
                    </Dropdown.MenuItem>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const POSConfigurationForm = ({ account }: { account: IAccount }) => {
    // const { handleSubmit } = useFormContext();

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [saveError] = useState<string>();
    const [posConfigAction, setPosConfigAction] = useState<string | null>();
    const [selectedPosConfig, setSelectedPosConfig] = useState<any>();
    const [configStates, setConfigStates] = useState<PosConfigState[]>([]);

    const { posConfigs, posConfigsAreReady } = useFetchPosConfigsByGroupId(account._id);
    useEffect(() => {
        if (posConfigsAreReady && posConfigs) {
            setConfigStates(
                posConfigs.map((config) => {
                    return _newConfigState({
                        _id: config._id,
                        key: config._id, // key is used by the UI components because _id doesn't always exist
                        groupId: config.groupId,
                        lookback: moment.tz(config.lookback, config.timeZone).toDate(),
                        timeZone: config.timeZone || moment.tz.guess(),
                        type: config.type,
                        enabled: config.enabled,
                        credentials: config.credentials,
                        reconcileLookbackHours: config.reconcileLookbackHours || 24,
                    });
                }),
            );
        }
    }, [posConfigsAreReady, posConfigs]);

    const updateConfigState = (configStateKey: string, fieldName: string, value: any) => {
        setConfigStates((prevState) => {
            return prevState.map((configState) => {
                if (configState.key === configStateKey) {
                    return {
                        ...configState,
                        [fieldName]: value,
                    };
                } else {
                    return configState;
                }
            });
        });
    };

    const updatePosConfigCredentials = (
        configStateKey: keyof PosConfigState,
        fieldName: string,
        value: any,
    ) => {
        setConfigStates((prevState) => {
            return prevState.map((configState) => {
                if (configState.key === configStateKey) {
                    const change: any = {};
                    change[fieldName] = value;

                    return Object.assign(configState, {
                        credentials: Object.assign(configState.credentials, change),
                        modified: true,
                    });
                } else {
                    return configState;
                }
            });
        });
    };

    const doReimport = (
        configState: PosConfigState,
        options: { lookback?: string; notify?: boolean },
    ) => {
        (async () => {
            try {
                await reimportPosConfig(configState.key, options);
                toast.success('Reimport initiated.');
            } catch (error) {
                toast.error('Reimport failed.');
                // eslint-disable-next-line no-console
                console.error(`Reimport error: `, error);
            }
        })();
    };

    const onReimport = useCallback((configState: PosConfigState) => {
        setSelectedPosConfig(configState);
        setPosConfigAction('reimport');
    }, []);

    const onTestCredentials = useCallback(({ key, type, credentials }: PosConfigState) => {
        updateConfigState(key, 'testing', true);

        toast.promise(
            verifyPosConfigCredentials(type, { credentials }).finally(() => {
                updateConfigState(key, 'testing', false);
            }),
            {
                loading: 'Verifying credentials...',
                success: `Credentials verified successfully at ${moment().format('LLLL')}`,
                error: `Credentials failed verification at ${moment().format('LLLL')}`,
            },
        );
    }, []);

    const onShowDetails = (configState: PosConfigState) => {
        setSelectedPosConfig(configState);
        setPosConfigAction('viewDetails');
    };

    const onSavePosConfiguration = (configState: PosConfigState) => {
        return () => {
            setSubmitDisabled(true);
            savePosConfig(configState._id, configState)
                .then((newConfigState) => {
                    toast.success('POS Config saved');
                    updateConfigState(configState.key, '_id', newConfigState._id);
                })
                .catch(() => {
                    toast.error('Could not save POS Config.');
                })
                .finally(() => {
                    setSubmitDisabled(false);
                });
        };
    };

    const onAddPointOfSaleConfig = useCallback(
        (posType: typeof PosConfigTypes) => {
            if (account == null) {
                return;
            }

            const newConfigState = _newConfigState({
                key: uuid(),
                groupId: account._id,
                lookback: moment().startOf('day').subtract(1, 'month').toDate(), // start value for lookback date
                timeZone: moment.tz.guess() || moment.tz.setDefault(),
                credentials: {},
                enabled: false,
                type: posType,
                modified: true,
                reconcileLookbackHours: DEFAULT_RECONCILE_LOOKBACK_HOURS,
            });

            setConfigStates((prevState) => {
                return [...prevState, newConfigState];
            });
        },
        [account],
    );

    return (
        <div className="pos-configuration-form">
            <Grid className="pos-configs">
                {configStates.map((configState) => {
                    return (
                        <Grid.Item key={configState.key}>
                            <Paper className="pos-config-card" variant="outlined">
                                <Form>
                                    <div className="config-title">
                                        <h4>
                                            {_getLabelByValue(
                                                [...pointOfSaleList, ...readOnlyPointOfSaleList],
                                                configState.type,
                                            )}
                                        </h4>
                                        <ConfigButtons
                                            configState={configState}
                                            onReimport={onReimport}
                                            onTestCredentials={onTestCredentials}
                                            onShowDetails={onShowDetails}
                                        />
                                    </div>
                                    <div className="pos-config-fields">
                                        <PosSpecificFields
                                            posConfigState={configState}
                                            onChangePosCredentials={updatePosConfigCredentials}
                                        />

                                        {configState.testMessage !== '' ? (
                                            <div className="test-message-text test-success-text ml-auto mt-1">
                                                {configState.testMessage}
                                            </div>
                                        ) : null}

                                        {configState.testError !== '' ? (
                                            <div className="test-message-text test-failed-text ml-auto mt-1">
                                                {configState.testError}
                                            </div>
                                        ) : null}

                                        <PosGenericFields
                                            posConfigState={configState}
                                            onChangePosSettings={updateConfigState}
                                        />
                                    </div>
                                    <Form.ActionGroup>
                                        <Form.Button
                                            className="input-group-text config-save-button"
                                            color="blue"
                                            variant="smooth"
                                            onClick={onSavePosConfiguration(configState)}
                                            disabled={submitDisabled}
                                            startIcon={<SaveAlt />}
                                        >
                                            Save
                                        </Form.Button>
                                    </Form.ActionGroup>
                                </Form>
                            </Paper>
                        </Grid.Item>
                    );
                })}

                {saveError !== '' ? (
                    <div className="error-text ml-auto mr-3">{saveError}</div>
                ) : null}
            </Grid>

            <Form.ActionGroup>
                <AddPosConfigButton onAddPointOfSaleConfig={onAddPointOfSaleConfig} />
            </Form.ActionGroup>

            <ReimportConfirmModal
                isVisible={posConfigAction === 'reimport'}
                onConfirm={(options) => {
                    doReimport(selectedPosConfig, options);
                }}
                onClose={() => setPosConfigAction(null)}
            />

            <POSConfigDetailsModal
                configId={selectedPosConfig?._id}
                isVisible={posConfigAction === 'viewDetails'}
                onClose={() => setPosConfigAction(null)}
            />
        </div>
    );
};

export default POSConfigurationForm;
