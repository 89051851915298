import clsx from 'clsx';

import { Add as AddIcon } from '@components/icons';
import ClickableArea from '@components/layout/ClickableArea';

export interface CreateFeaturedSnapOptionDetails {
    label: string;
    imgSrc: string;
    value: string;
    activeSnapId?: number;
}

export interface CreateFeaturedSnapOptionProps extends CreateFeaturedSnapOptionDetails {
    onClick: (optionDetails: CreateFeaturedSnapOptionDetails) => void;
}
export const CreateFeaturedSnapOption = ({
    onClick,
    ...optionDetails
}: CreateFeaturedSnapOptionProps) => {
    const { label, imgSrc, activeSnapId } = optionDetails;
    return (
        <ClickableArea
            className="flex flex-col items-center gap-2 px-2 "
            onClick={() => onClick(optionDetails)}
        >
            <div
                className={clsx(
                    'mt-[1px] w-[74px] h-[74px] rounded-full flex items-center justify-center',
                    !activeSnapId ? 'bg-gray-300' : 'bg-gradient-to-br from-[#07A0C3] to-[#F9B24A]',
                )}
            >
                <div className="w-[70px] h-[70px] rounded-full bg-[white] flex items-center justify-center relative p-2">
                    <img
                        src={imgSrc}
                        alt={`${label} logo`}
                        className="rounded-full w-[68px] h-[68px] object-fit"
                    />
                    {!activeSnapId && (
                        <span className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4 rounded-full p-1 bg-gray-25 flex items-center justify-center">
                            <AddIcon
                                className="w-[20px] h-[20px] rounded-full bg-[#07A0C3]"
                                style={{ color: 'white' }}
                            />
                        </span>
                    )}
                </div>
            </div>
            <span className="text-xs font-normal leading-4 tracking-[0.0175em] text-center w-full truncate max-w-[100px] text-gray-950">
                {label}
            </span>
        </ClickableArea>
    );
};

export interface CreateFeaturedSnapSectionProps {
    options: CreateFeaturedSnapOptionDetails[];
    onOptionClick: (optionDetails: CreateFeaturedSnapOptionDetails) => void;
}
export const CreateFeaturedSnapSection = ({
    options = [],
    onOptionClick,
}: CreateFeaturedSnapSectionProps) => {
    return (
        <div className="flex flex-nowrap overflow-x-auto gap-3">
            {options.map((option) => (
                <CreateFeaturedSnapOption key={option.value} onClick={onOptionClick} {...option} />
            ))}
        </div>
    );
};
