import BrandUsersTable from '@components/tables/BrandUsersTable';

import { useSparkplugAccount, useSparkplugAccountUsers } from '@hooks/SparkplugAccountsHooks';

const CCBrandUsersView = () => {
    const { account } = useSparkplugAccount();

    const { accountUsersAreReady, accountUsers, refetchAccountUsers } = useSparkplugAccountUsers();

    return (
        <div className="cc-retailer-users-view">
            <BrandUsersTable
                variant="flat"
                account={account}
                isLoadingAccountUsers={!accountUsersAreReady}
                accountUsers={accountUsers}
                refetchAccountUsers={refetchAccountUsers}
            />
        </div>
    );
};

export default CCBrandUsersView;
