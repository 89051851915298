import React from 'react';

import { ListControlCenterSparksQueryParams, formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { queryToString, useHistory, useQueryParams } from '@components/router';

import { useGetControlCenterMetaDataQuery } from '../../hooks/useCCSparksQuery';

const FilterButtons: React.FC = () => {
    const { p, limit, ...queryParams }: { p?: number } & ListControlCenterSparksQueryParams =
        useQueryParams();
    const { controlCenterMetaDataAreReady, controlCenterMetaData: ccMeta } =
        useGetControlCenterMetaDataQuery({
            startDate: queryParams.startDate,
            endDate: queryParams.endDate,
        });
    const history = useHistory();

    return (
        <div>
            <div className="filter-buttons">
                <Button
                    variant="outlined"
                    color="blue"
                    className={`filter-button ${
                        queryParams.payoutStatus === 'special-payout' && 'is-active'
                    }`}
                    onClick={() => {
                        const { payoutStatus } = queryParams;
                        history.push({
                            search: queryToString({
                                p: 0,
                                limit: limit || 10,
                                ...(!payoutStatus
                                    ? {
                                          payoutStatus: 'special-payout',
                                      }
                                    : {}),
                            }),
                        });
                    }}
                >
                    <>
                        Special Payouts{' '}
                        {ccMeta?.specialPayoutCount && `(${ccMeta?.specialPayoutCount})`}
                        <div className="stat">
                            {formatCurrency(ccMeta?.totalSpecialPayoutAmountDue || 0)}
                        </div>
                    </>
                </Button>
                <Button
                    variant="outlined"
                    color="red"
                    className={`filter-button ${
                        queryParams.invoiceStatus === 'failed' && 'is-active'
                    }`}
                    onClick={() => {
                        const { invoiceStatus } = queryParams;
                        history.push({
                            search: queryToString({
                                p: 0,
                                limit: limit || 10,
                                ...(!invoiceStatus
                                    ? {
                                          invoiceStatus: 'failed',
                                      }
                                    : {}),
                            }),
                        });
                    }}
                >
                    <>
                        Failed Payment{' '}
                        {ccMeta?.failedPaymentCount && `(${ccMeta?.failedPaymentCount})`}
                        <div className="stat">
                            {formatCurrency(ccMeta?.totalFailedPaymentAmountDue || 0)}
                        </div>
                    </>
                </Button>
            </div>
        </div>
    );
};

export default FilterButtons;
