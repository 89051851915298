import axios from 'axios';
import { isNil, omitBy } from 'lodash';

import {
    ControlCenterMetaResponseBody,
    ListControlCenterSparksQueryParams,
    ListControlCenterSparksResponseBody,
} from '@sparkplug/lib';

import { queryToString } from '@components/router';

import { useAdvancedQuery } from '@hooks/QueryHooks';

const API = {
    async getControlCenterMetaData(params: {
        startDate?: string;
        endDate?: string;
    }): Promise<ControlCenterMetaResponseBody> {
        const controlCenterMetaData = (
            await axios.get('/api/v1/pos/sparks/control-center-meta-data', {
                params,
            })
        ).data;
        return controlCenterMetaData;
    },

    async getSparks(
        params: ListControlCenterSparksQueryParams,
    ): Promise<ListControlCenterSparksResponseBody> {
        const recentCCSparks = (
            await axios.get<ListControlCenterSparksResponseBody>(
                '/api/v1/pos/sparks/control-center-sparks-v2',
                {
                    params: {
                        ...omitBy(params, isNil),
                        offset: params.offset ?? 0,
                        limit: params.limit ?? 1000,
                    },
                },
            )
        ).data;
        return recentCCSparks;
    },
};
export const useGetControlCenterMetaDataQuery = (params: {
    startDate?: string;
    endDate?: string;
}) => {
    const { isFetched, data, isLoading, refetch } = useAdvancedQuery(
        'control-center-meta-data',
        () => API.getControlCenterMetaData(params),
        {
            enabled: !!params.startDate && !!params.endDate,
        },
    );

    return {
        controlCenterMetaDataAreReady: isFetched,
        isLoading,
        controlCenterMetaData: data,
        refetch,
    };
};
export const useGetSparksQuery = (params: ListControlCenterSparksQueryParams) => {
    const { isFetched, data, isLoading, refetch } = useAdvancedQuery(
        queryToString(params),
        () => API.getSparks(params),
        {
            enabled: !Number.isNaN(params.offset) && !!params.startDate && !!params.endDate,
            keepPreviousData: true,
        },
    );

    return {
        sparksAreReady: isFetched,
        isLoading,
        sparks: data?.data ?? [],
        meta: data?.meta,
        refetch,
    };
};
