import { ListSparksQueryParams } from '@sparkplug/lib';

import { useAdvancedQuery } from '@hooks/QueryHooks';

import { getSparks } from '@helpers/sparks';

import { IAccount } from '@app/types/AccountsTypes';

export const getSparksQueryKey = (accountId: string, sparkFilters?: ListSparksQueryParams) => [
    'account',
    accountId,
    'sparks',
    JSON.stringify(sparkFilters),
];
export const useGetSparksQuery = ({
    account,
    sparkFilters,
    isAdminApp,
    accountIsReady,
}: {
    account?: IAccount;
    sparkFilters?: ListSparksQueryParams;
    isAdminApp: boolean;
    accountIsReady: boolean;
}) => {
    const {
        isLoading,
        data: sparks = [],
        refetch,
        isFetched,
    } = useAdvancedQuery(
        getSparksQueryKey(account?._id ?? '', sparkFilters),
        () => {
            if (isAdminApp && accountIsReady && !!account?._id) {
                if (account?.type === 'retailer') {
                    return getSparks(account._id, undefined, sparkFilters);
                } else {
                    return getSparks(undefined, account._id, sparkFilters);
                }
            } else {
                return Promise.resolve([]);
            }
        },
        {
            enabled: isAdminApp && accountIsReady && !!account?._id,
        },
    );

    return { isLoading, sparks, refetchSparks: refetch, sparksAreReady: isFetched };
};
