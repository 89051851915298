import { VendCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface VendPosSpecificFieldsProps {
    credentials: VendCredentials;
    onChange: (key: string, value: any) => void;
}

const VendPosSpecificFields = ({ credentials, onChange }: VendPosSpecificFieldsProps) => {
    const { personalToken, domainPrefix } = credentials;

    return (
        <>
            <Form.TextField
                label="Personal Token"
                name="personalToken"
                required
                defaultValue={personalToken}
                onChange={(event) => onChange('personalToken', event.target.value)}
            />
            <Form.TextField
                label="Domain Prefix"
                name="domainPrefix"
                required
                defaultValue={domainPrefix}
                onChange={(event) => onChange('domainPrefix', event.target.value)}
            />
        </>
    );
};

export default VendPosSpecificFields;
