import { FC, useEffect, useState } from 'react';

import SparkplugAPI from '@api/SparkplugAPI';
import moment from 'moment-timezone';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import SMSEnrollmentChip from '@components/chips/SMSEnrollmentChip';
import Form from '@components/form/Form';
import { NoUsersGraphic } from '@components/graphics';
import { CheckCircleOutline, ErrorOutlineRounded } from '@components/icons';
import { IListItem } from '@components/inputs/ListSelector/ListSelector';
import SearchSelect from '@components/inputs/SearchSelect';
import Select from '@components/inputs/Select';
import CalloutMessage from '@components/layout/CalloutMessage';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';
import InfoModal from '@components/overlays/InfoModal';
import { useParams } from '@components/router';

import { useSparkplugAccountUsers } from '@hooks/SparkplugAccountsHooks';

import { IAccountUser } from '@app/types/UsersTypes';

import './CCLogsView.scss';

interface ISmsLogEntry {
    body: string;
    direction: string;
    dateSent: string;
    sid: string;
    errorCode?: number;
    errorMessage?: string;
    status: string;
}

const longDateTimeFormat = 'dddd, MMMM Do YYYY, h:mm:ss a';

export function mapLogEntry(
    { errorMessage, status, direction, sid, body, dateSent, errorCode }: ISmsLogEntry,
    i: number,
    openDetails: any,
) {
    const detailedErrorMessage = errorMessage || (status === 'failed' ? 'Failed' : '');
    const deliveryStatusIcon = detailedErrorMessage ? (
        <ErrorOutlineRounded color="error" fontSize="large" />
    ) : (
        <CheckCircleOutline htmlColor="#068f6e" fontSize="large" />
    );

    const divClass = `message ${direction}`;
    return (
        <li key={sid + i}>
            <div className={divClass}>
                {body}
                <div className="date">
                    <em>{moment(dateSent).format(longDateTimeFormat)}</em>
                </div>
            </div>
            <Button
                className="status"
                disabled={!detailedErrorMessage}
                onClick={() => openDetails(detailedErrorMessage, errorCode)}
            >
                {deliveryStatusIcon}
            </Button>
        </li>
    );
}

const SmsLog = ({ smsLogs, currentUser }: { smsLogs?: ISmsLogEntry[]; currentUser: any }) => {
    if (!smsLogs) {
        return <></>;
    }

    const [selectedSortOption, setSelectedSortOption] = useState<string>('dateDesc');
    const [errorDetailsModalVisible, setErrorDetailsModalVisible] = useState<boolean>(false);
    const [currentErrorDetails, setCurrentErrorDetails] = useState<{
        message: string;
        code: number | null;
        link?: string;
    }>({ message: '', code: null });

    const openDetails = (message: string = 'Unknown error', code: number = 0) => {
        const errorDetails = {
            message,
            code,
            ...(code > 0 && { link: `https://www.twilio.com/docs/api/errors/${code}` }),
        };

        setCurrentErrorDetails(errorDetails);
        setErrorDetailsModalVisible(true);
    };

    const closeDetails = () => {
        setErrorDetailsModalVisible(false);
        setCurrentErrorDetails({ message: '', code: null });
    };

    const smsLogEntries = smsLogs
        .filter(({ body }) => body)
        .map((logEntry, i) => mapLogEntry(logEntry, i, openDetails));

    const smsLogErrors = smsLogs
        .filter(({ status, body }) => body && !['sent', 'delivered', 'received'].includes(status))
        .map((errorSms: ISmsLogEntry) => {
            if (['failed', 'undelivered'].includes(errorSms.status) && !errorSms.errorMessage) {
                // Twilio does not provide an errorMessage on some errors :shrug
                // eslint-disable-next-line
                errorSms.errorMessage = 'Failed';
            }
            return errorSms;
        });

    const logSortOptions = [
        { value: 'dateAsc', label: 'Date - Ascending' },
        { value: 'dateDesc', label: 'Date - Descending' },
    ];

    if (smsLogErrors?.length > 0) {
        logSortOptions.push({ value: 'failures', label: 'Delivery Failures' });
    }

    const onSortChange = (value: string) => {
        smsLogs.sort((a, b) => {
            setSelectedSortOption(value);
            switch (value) {
                case 'dateAsc':
                    return a.dateSent > b.dateSent ? 1 : -1;
                case 'failures':
                    return a.errorMessage ? -1 : 1;
                default:
                    // descending is default
                    return a.dateSent < b.dateSent ? 1 : -1;
            }
        });
    };

    return (
        <div className="list-wrapper">
            <div className="details">
                <span>
                    <strong>Status</strong>{' '}
                    <SMSEnrollmentChip
                        status={currentUser.smsStatus}
                        isMuted={currentUser.muteScheduledSms}
                    />
                </span>
                <span>
                    <strong>Delivery Failures</strong>{' '}
                    <Chip
                        dense
                        color={smsLogErrors.length > 0 ? 'red' : 'green'}
                        label={smsLogErrors?.length.toString()}
                    />
                </span>
                <span className="sort">
                    <Select
                        options={logSortOptions}
                        value={selectedSortOption}
                        onChange={(e) => onSortChange(e.target.value)}
                    />
                </span>
            </div>
            <ul>{smsLogEntries}</ul>
            <InfoModal
                isVisible={errorDetailsModalVisible}
                title="SMS Delivery Error Details"
                onClose={closeDetails}
            >
                {currentErrorDetails.message} (Code: {currentErrorDetails.code})
                {currentErrorDetails.link && (
                    <div className="twilio-link">
                        <a href={currentErrorDetails.link} target="_blank" rel="noreferrer">
                            Learn more about this error
                        </a>
                    </div>
                )}
            </InfoModal>
        </div>
    );
};

export const CCLogsView: FC = () => {
    const { accountUsersAreReady, accountUsers } = useSparkplugAccountUsers();
    const { accountId }: { accountId: string } = useParams();
    const [loadingSmsLogs, setLoadingSmsLogs] = useState<boolean>(false);
    const [smsLogs, setSmsLogs] = useState<ISmsLogEntry[] | undefined>(undefined);
    const [currentUser, setCurrentUser] = useState<any>(undefined);
    const [apiError, setApiError] = useState<any>(undefined);
    const [availableUsers, setAvailableUsers] = useState<IListItem[]>([]);

    const getLogs = (user: IAccountUser) => {
        if (!accountId || !user.posEmployeeProfileIds.length) {
            return;
        }

        setLoadingSmsLogs(true);

        setCurrentUser(user);

        SparkplugAPI.getTwilioMessages(accountId, user.posEmployeeProfileIds[0])
            .then((result) => setSmsLogs(result))
            .catch((err) => setApiError(err))
            .finally(() => setLoadingSmsLogs(false));
    };

    useEffect(() => {
        if (accountUsersAreReady) {
            const posUsers = accountUsers
                .filter(
                    ({ posEmployeeProfileIds, phoneNumber }) =>
                        posEmployeeProfileIds.length && phoneNumber,
                )
                .map((obj) => {
                    return {
                        ...obj,
                        label: `${obj.fullName} - ${obj.phoneNumberFormatted} - ${obj.smsStatus}`,
                        value: obj.posEmployeeProfileIds[0],
                    };
                });
            setAvailableUsers(posUsers);
        }
    }, [accountId, accountUsersAreReady]);

    const sendUser = (user: any) => {
        getLogs(user);
    };

    if (apiError) {
        const message = apiError.message || `An unknown error occurred retrieving the SMS logs`;
        return (
            <Paper>
                <Paper.Title>Error retrieving SMS logs</Paper.Title>
                <CalloutMessage className="margin-bottom" message={message} color="red" />
            </Paper>
        );
    }

    if (!accountUsersAreReady) {
        return <Spinner />;
    }

    if (!availableUsers?.length) {
        return <Paper>No users with a registered phone number exist for this Account</Paper>;
    }

    return (
        <Paper>
            <h2>View logs for a user</h2>
            <Form.Group>
                {accountUsersAreReady ? (
                    <SearchSelect
                        placeholder="Type or choose user..."
                        options={availableUsers}
                        value=""
                        onChange={(u) => sendUser(u)}
                    />
                ) : (
                    <Spinner />
                )}
            </Form.Group>
            {!currentUser ? (
                <div className="no-user-selected">
                    <NoUsersGraphic />
                </div>
            ) : (
                <></>
            )}
            {loadingSmsLogs ? (
                <div className="spinner-wrapper">
                    <Spinner />
                </div>
            ) : (
                <SmsLog smsLogs={smsLogs} currentUser={currentUser} />
            )}
        </Paper>
    );
};
