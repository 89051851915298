import { useMemo, useState } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment-timezone';

import { TExtendedListItem } from '@components/inputs/ListSelector/ListSelector';
import List from '@components/layout/List';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useSearch } from '@hooks/UIHooks';

import { IPosBrand, IPosCategory, IPosProduct } from '@app/types/PosTypes';
import { THeadCell } from '@app/types/TableTypes';
import { IOptionTree } from '@app/types/UITypes';

const BrandsTable = ({ brands }: { brands: IPosBrand[] }) => {
    const headCells: THeadCell<any>[] = [
        {
            id: 'name',
            label: 'Name',
            sortType: 'string',
            render: ({ name }) => <Table.Cell>{name}</Table.Cell>,
        },
        {
            id: '_id',
            label: 'ID',
            sortType: 'string',
            render: ({ _id }) => <Table.Cell>{_id}</Table.Cell>,
        },
    ];

    if (!brands?.length) {
        return <div>No brands</div>;
    }

    const brandRows = brands.map((brand) => ({
        ...brand,
        key: brand._id,
    }));

    return (
        <Table
            rows={brandRows}
            headCells={headCells}
            variant="flat"
            showPagination={false}
            defaultOptions={{
                orderBy: 'name',
            }}
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};

const CategoriesTable = ({ categories }: { categories: IPosCategory[] }) => {
    const headCells: THeadCell<any>[] = [
        {
            id: 'name',
            label: 'Name',
            sortType: 'string',
            render: ({ name }) => <Table.Cell>{name}</Table.Cell>,
        },
        {
            id: '_id',
            label: 'ID',
            sortType: 'string',
            render: ({ _id }) => <Table.Cell>{_id}</Table.Cell>,
        },
    ];

    if (!categories?.length) {
        return <div>No categories</div>;
    }

    const categoryRows = categories.map((category) => ({
        ...category,
        key: category._id,
    }));

    return (
        <Table
            rows={categoryRows}
            headCells={headCells}
            variant="flat"
            showPagination={false}
            defaultOptions={{
                orderBy: 'name',
            }}
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};

const ProductsTable = ({
    products,
    brands,
    categories,
}: {
    products: IPosProduct[];
    brands: IPosBrand[];
    categories: IPosCategory[];
}) => {
    const [filters, setFilters] = useState<{
        brandIds: TExtendedListItem<string>[];
        categoryIds: TExtendedListItem<string>[];
    }>({
        brandIds: [],
        categoryIds: [],
    });

    const productRows = useMemo(() => {
        return products.map((product) => {
            let fallbackBrand = 'None';
            if (product?.brands?.length > 1) {
                fallbackBrand = product?.brands?.map(({ name }) => name)?.join(', ');
            }
            let fallbackCategory = 'None';
            if (product?.categories?.length > 1) {
                fallbackCategory = product?.categories?.map(({ name }) => name)?.join(', ');
            }

            const brand =
                brands?.find(({ _id }) => _id === product?.brandId)?.name ?? fallbackBrand;
            const category =
                categories?.find(({ _id }) => _id === product?.categoryId)?.name ??
                fallbackCategory;

            return {
                ...product,
                key: product._id,
                brand,
                brandId: product.brandId !== undefined ? product.brandId : 'unbranded',
                category,
                categoryId: product.categoryId !== undefined ? product.categoryId : 'uncategorized',
            };
        });
    }, [products, brands, categories]);

    const headCells: THeadCell<any>[] = [
        {
            id: 'name',
            label: 'Name',
            sortType: 'string',
            render: ({ name }) => <Table.Cell>{name}</Table.Cell>,
        },
        {
            id: 'lastSoldAt',
            label: 'Last Sold At',
            sortType: 'date',
            render: ({ lastSoldAt }) => (
                <Table.Cell title={lastSoldAt}>
                    {lastSoldAt ? moment(lastSoldAt).format(DATE_DISPLAY_FORMAT) : '--'}
                </Table.Cell>
            ),
        },
        {
            id: 'brand',
            label: 'Brand',
            sortType: 'string',
            render: ({ brand }) => <Table.Cell>{brand}</Table.Cell>,
        },
        {
            id: 'category',
            sortType: 'string',
            label: 'Category',
            render: ({ category }) => <Table.Cell>{category}</Table.Cell>,
        },
        {
            id: '_id',
            label: 'ID',
            sortType: 'string',
            render: ({ _id }) => <Table.Cell>{_id}</Table.Cell>,
        },
    ];

    const { onChangeSearchFilter, applySearch } = useSearch(['name', 'brand', 'category', '_id']);
    const applyBrandFilters = (rows: any[]) => {
        if (!filters.brandIds.length) {
            return rows;
        }

        return rows.filter(({ brandId }) => {
            return filters.brandIds.map(({ value }) => value).includes(brandId);
        });
    };

    const applyCategoryFilters = (rows: any[]) => {
        if (!filters.categoryIds.length) {
            return rows;
        }

        return rows.filter(({ categoryId }) => {
            return filters.categoryIds.map(({ value }) => value).includes(categoryId);
        });
    };

    const brandOptions: IOptionTree<any>[] = [
        {
            label: 'None',
            value: 'unbranded',
            key: 'unbranded',
        },
    ].concat(
        brands.map((brand) => ({
            ...brand,
            value: brand._id,
            key: brand._id,
            label: brand.name,
        })),
    );

    const categoryOptions: IOptionTree<any>[] = [{ label: 'None', value: 'uncategorized' }].concat(
        categories.map((category) => ({
            ...category,
            value: category._id,
            label: category.name,
        })),
    );

    return (
        <div className="products-table">
            <Toolbar>
                <Toolbar.Search
                    className="toolbar-group-start"
                    name="products-search"
                    onChange={onChangeSearchFilter}
                />
                <Toolbar.DropdownListSelector
                    label={`${
                        filters.brandIds?.length === brandOptions.length && brandOptions.length
                            ? '(All) '
                            : ''
                    }Brands`}
                    options={brandOptions}
                    selected={filters.brandIds || []}
                    onApply={(updatedBrands: TExtendedListItem<string>[]) => {
                        setFilters((prevValue) => ({
                            ...prevValue,
                            brandIds: updatedBrands,
                        }));
                    }}
                    showCount={
                        !!filters.brandIds?.length &&
                        filters.brandIds?.length !== brandOptions.length
                    }
                    clear={{
                        active: !!filters.brandIds?.length,
                        onClear: () => {
                            setFilters((prevValue) => ({
                                ...prevValue,
                                brandIds: [],
                            }));
                        },
                    }}
                />
                <Toolbar.DropdownListSelector
                    label={`${
                        filters.categoryIds?.length === categoryOptions.length &&
                        categoryOptions.length
                            ? '(All) '
                            : ''
                    }Categories`}
                    options={categoryOptions}
                    selected={filters.categoryIds || []}
                    onApply={(updatedCategories: TExtendedListItem<string>[]) => {
                        setFilters((prevValue) => ({
                            ...prevValue,
                            categoryIds: updatedCategories,
                        }));
                    }}
                    showCount={
                        !!filters.categoryIds?.length &&
                        filters.categoryIds?.length !== categoryOptions.length
                    }
                    clear={{
                        active: !!filters.categoryIds?.length,
                        onClear: () => {
                            setFilters((prevValue) => ({
                                ...prevValue,
                                categoryIds: [],
                            }));
                        },
                    }}
                />
            </Toolbar>

            <Table
                variant="flat"
                rows={productRows}
                filters={[applySearch, applyBrandFilters, applyCategoryFilters]}
                headCells={headCells}
                defaultOptions={{
                    orderBy: 'name',
                }}
            >
                <Table.RenderHead />
                <Table.RenderBody />
            </Table>
        </div>
    );
};

const PosConfigProductInfo = ({
    brands,
    categories,
    products,
    loaded,
}: {
    products: IPosProduct[];
    brands: IPosBrand[];
    categories: IPosCategory[];
    loaded: boolean;
}) => {
    const [currentView, setCurrentView] = useState('products');

    const views = [
        {
            title: `Products (${products.length})`,
            slug: 'products',
        },
        {
            title: `Brands (${brands.length})`,
            slug: 'brands',
        },
        {
            title: `Categories (${categories.length})`,
            slug: 'categories',
        },
    ];

    if (!loaded) {
        return null;
    }

    return (
        <div className="product-info pos-config-detail-content has-sidebar">
            <div className="pos-config-detail-content-sidebar">
                <List>
                    {views.map(({ title, slug }) => (
                        <List.Item
                            key={slug}
                            button
                            selected={currentView === slug}
                            className={currentView === slug ? 'is-active' : 'is-not-active'}
                            onClick={() => {
                                setCurrentView(slug);
                            }}
                        >
                            {title}
                        </List.Item>
                    ))}
                </List>
            </div>

            <div className="pos-config-detail-content-content">
                {currentView === 'brands' && (
                    <div className="brands">
                        <h2>Brands</h2>

                        <BrandsTable brands={brands} />
                    </div>
                )}

                {currentView === 'categories' && (
                    <div className="categories">
                        <h2>Categories</h2>

                        <CategoriesTable categories={categories} />
                    </div>
                )}

                {currentView === 'products' && (
                    <div className="products">
                        <ProductsTable
                            categories={categories}
                            products={products}
                            brands={brands}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default PosConfigProductInfo;
