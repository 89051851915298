import { useCallback, useState } from 'react';

import moment from 'moment';

import { EmployeeSpark, Spark, SparksViewFilters } from '@sparkplug/lib';

import { TAccountType } from '@app/types/AccountsTypes';
import { IPosLocation } from '@app/types/PosTypes';
import { IOption } from '@app/types/UITypes';

export type ExtendedSparksViewFilters = SparksViewFilters & {
    location: IOption<IPosLocation>[];
};
type SparkFilterType = keyof ExtendedSparksViewFilters;

type FilterFn = (
    value: string | any[],
    accountType?: TAccountType,
) => (spark: Spark | EmployeeSpark) => boolean;

export const FilterFns: Record<SparkFilterType, FilterFn> = {
    // TODO: Move `accountType` param out and move to `SparksView`
    status: (value, accountType) => {
        return (spark) => {
            if (value === 'all') {
                return true;
            }

            const isPending = spark?.requestState === 'pending';
            const isRejected = spark?.requestState === 'rejected';
            const isExpired = spark?.requestState === 'expired';

            if (value === 'inbox/sent') {
                if (isPending || isExpired) {
                    return true;
                }

                if (accountType === 'brand' && isRejected) {
                    return true;
                }

                return false;
            }

            if (isPending || isRejected || isExpired) {
                return false;
            }

            const now = moment();
            const start = moment(spark.startDate);
            const end = moment(spark.endDate);
            if (value === 'active') {
                return now.isSameOrAfter(start, 'date') && now.isSameOrBefore(end, 'date');
            } else if (value === 'complete') {
                return now.isAfter(end, 'date');
            } else if (value === 'upcoming') {
                return now.isBefore(start, 'date');
            }
            return false;
        };
    },
    type: (value) => {
        return (spark) => {
            if (!value?.length) {
                return true;
            }

            return value.includes(spark.type);
        };
    },
    location: (value) => {
        return (spark) => {
            if (!Array.isArray(value) || !(spark as Spark).locationIds?.length) {
                return true;
            }

            const isIncluded = (spark as Spark).locationIds.some((locationId) => {
                return value.some((valueItem) => valueItem.value === locationId);
            });
            return isIncluded;
        };
    },
    schedule: (value) => {
        return (spark) => {
            if (!value?.length) {
                return true;
            }

            const filterBySchedule =
                !!spark.recurringSchedule && value.includes(spark.recurringSchedule.interval);
            const filterByOneTime = value.includes('');

            if (filterByOneTime) {
                return value.length === 1
                    ? !spark.recurringSchedule
                    : !spark.recurringSchedule || filterBySchedule;
            }
            return filterBySchedule;
        };
    },
    training: (value) => {
        return (spark) => {
            if (!value?.length) {
                return true;
            }

            return !!(
                (value.includes('none') && !spark.trainingEnabled) ||
                (value.includes('required') &&
                    spark.trainingEnabled &&
                    spark.courseData?.required) ||
                (value.includes('optional') && spark.trainingEnabled && !spark.courseData?.required)
            );
        };
    },
    requestState: (value) => {
        return (spark) => {
            switch (value) {
                case 'pending':
                    return spark.requestState === 'pending';
                case 'rejected':
                    return spark.requestState === 'rejected';
                case 'expired':
                    return spark.requestState === 'expired';
                default:
                    return true;
            }
        };
    },
};

export const useSparksFilters = <T extends Spark | EmployeeSpark>({
    accountType,
    initialFilters,
}: {
    accountType?: TAccountType;
    initialFilters?: Partial<ExtendedSparksViewFilters>;
}) => {
    const [filters, setFilters] = useState<ExtendedSparksViewFilters>({
        location: [],
        type: [],
        status: 'active',
        schedule: [],
        training: [],
        ...initialFilters,
    });

    const updateFilters = (newFilters: any) => {
        setFilters((prevFilters) => {
            return {
                ...prevFilters,
                ...newFilters,
            };
        });
    };

    const applyFilters = useCallback(
        (sparks: T[]): T[] =>
            Object.entries(filters).reduce((res, [filter, value]) => {
                const filterFn = FilterFns[filter as SparkFilterType](value, accountType);
                return res.filter(filterFn);
            }, sparks),
        [filters],
    );

    return {
        sparkFilters: filters,
        updateSparkFilters: updateFilters,
        applySparkFilters: applyFilters,
    };
};
