import { useState } from 'react';

import { Add } from '@mui/icons-material';
import { Paper } from '@mui/material';

import { Portal42Credentials } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';

interface Portal42PosSpecificFieldsProps {
    credentials?: Portal42Credentials;
    onChange: (vendorApiKey: string, value: any) => void;
}

const Portal42PosSpecificFields = ({ credentials, onChange }: Portal42PosSpecificFieldsProps) => {
    const { vendorApiKey } = credentials || {};
    const [initialLocations] = useState(credentials?.locations || []);
    const locations = credentials?.locations || [
        {
            name: '',
            externalId: '',
        },
    ];

    const onUpdateLocation = (i: number, key: any, value: any) => {
        const newLocations = [...locations];
        (newLocations as any)[i][key] = value;
        onChange('locations', newLocations);
    };

    const onAddLocation = () => {
        const newLocations = [...locations];
        newLocations.push({
            name: '',
            externalId: '',
        });
        onChange('locations', newLocations);
    };

    const onRemoveLocation = (i: number) => {
        const newLocations = locations.filter((location: any, j: any) => j !== i);
        onChange('locations', newLocations);
    };

    return (
        <>
            <Form.TextField
                label="Vendory API Key"
                name="vendorApiKey"
                required
                defaultValue={vendorApiKey}
                onChange={(event) => onChange('vendorApiKey', event.target.value)}
            />
            <div className="form-control repeating-fields">
                <Form.Label>Locations</Form.Label>
                <div className="repeating-fields-fields">
                    {locations.map((location, i) => {
                        return (
                            <Paper
                                key={`portal42_credentials-locations-${i}`}
                                className="repeating-field-group"
                                variant="outlined"
                            >
                                <Form.TextField
                                    label="Name"
                                    name={`locations.${i}.name`}
                                    disabled={initialLocations?.[i]?.name?.length > 0}
                                    required
                                    value={location?.name}
                                    onChange={(event) =>
                                        onUpdateLocation(i, 'name', event.target.value)
                                    }
                                />
                                <Form.TextField
                                    label="External Id"
                                    name={`locations.${i}.externalId`}
                                    required
                                    value={location?.externalId}
                                    onChange={(event) =>
                                        onUpdateLocation(i, 'externalId', event.target.value)
                                    }
                                />
                                <Button
                                    className="repeating-fields-button-remove"
                                    color="neutral"
                                    variant="smooth"
                                    onClick={() => onRemoveLocation(i)}
                                >
                                    Remove Location
                                </Button>
                            </Paper>
                        );
                    })}
                </div>

                <Button
                    className="repeating-fields-button-add"
                    color="blue"
                    variant="smooth"
                    onClick={onAddLocation}
                    startIcon={<Add />}
                >
                    Add Location
                </Button>
            </div>
        </>
    );
};

export default Portal42PosSpecificFields;
