import { ComponentProps, FC } from 'react';

import UsersTable from '../UsersTable';
import { UserTableHeadCells } from '../UsersTable/UsersTable';

import './BrandUsersTable.scss';

const headCells = [
    UserTableHeadCells.checkbox,
    UserTableHeadCells.firstName,
    UserTableHeadCells.lastName,
    UserTableHeadCells.role,
    UserTableHeadCells.email,
];

interface IBrandUsersTableProps
    extends Pick<
        ComponentProps<typeof UsersTable>,
        'variant' | 'account' | 'accountUsers' | 'refetchAccountUsers' | 'isLoadingAccountUsers'
    > {}

const BrandUsersTable: FC<IBrandUsersTableProps> = ({
    isLoadingAccountUsers,
    variant = 'raised',
    account,
    accountUsers,
    refetchAccountUsers,
}) => {
    return (
        <UsersTable
            variant={variant}
            account={account}
            isLoadingAccountUsers={isLoadingAccountUsers}
            accountUsers={accountUsers}
            refetchAccountUsers={refetchAccountUsers}
            headCells={headCells}
            searchFields={['firstName', 'lastName', 'email']}
        />
    );
};

export default BrandUsersTable;
