import { FC } from 'react';

import moment from 'moment';

import { WalletEmployeeDeposit } from '@sparkplug/lib';

import { RightArrow } from '@components/icons';
import MobileList from '@components/layout/MobileList';
import { SparkCardLogo } from '@components/sparks/SparkCardLogo';

import { CashDiffLabel } from '../../CashDiffLabel';
import { ClaimButton } from './ClaimButton';

import './RewardsListItem.scss';

interface RewardsListItemProps {
    reward: WalletEmployeeDeposit;
    onClick: () => void;
}

const ClaimedRewardLabel: FC<{ reward: WalletEmployeeDeposit }> = ({ reward }) => {
    if (reward.amount) {
        return <CashDiffLabel type="deposit" amount={reward.amount} />;
    }

    return <span className="non-cash-reward-label">See Details</span>;
};

export const RewardsListItem: FC<RewardsListItemProps> = ({ reward, onClick }) => {
    const { spark } = reward;

    const formattedStartDate = moment(spark.startDate).format('M/D/YY');
    const formattedEndDate = moment(spark.endDate).format('M/D/YY');
    const description = `${formattedStartDate} - ${formattedEndDate}`;

    const isClaimed = reward.status === 'Claimed';
    const endElement = isClaimed ? (
        <>
            <ClaimedRewardLabel reward={reward} />
            <RightArrow className="claimed-arrow" />
        </>
    ) : (
        <ClaimButton />
    );

    return (
        <MobileList.Item
            className="spark-rewards-list-item"
            label={spark.name}
            description={description}
            startElement={
                <SparkCardLogo
                    emoji={spark.emoji}
                    sparkBrand={spark.sparkBrand}
                    recurringSchedule={spark.recurringSchedule}
                />
            }
            endElement={endElement}
            onClick={onClick}
        />
    );
};
