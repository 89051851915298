import { AccountPosImportStatus } from '@sparkplug/lib';

import {
    WarningRounded as CautionIcon,
    AddCircle as FailingIcon,
    Error as InactiveIcon,
    Dot as PendingIcon,
    CheckCircle as SuccessIcon,
} from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

import { appendClasses } from '@helpers/ui';

import './PosStatusIcon.scss';

export const statusIcons: Record<AccountPosImportStatus, any> = {
    pending: <PendingIcon />,
    failing: <FailingIcon style={{ transform: 'rotate(-45deg)' }} />,
    caution: <CautionIcon />,
    working: <SuccessIcon />,
    inactive: <InactiveIcon />,
};

const getPosStatusIcon = (status?: AccountPosImportStatus, details?: string) => {
    switch (status) {
        case 'pending':
            return (
                <Tooltip
                    title={
                        details ??
                        "Imports haven't started, or there are no POS Configs on this account"
                    }
                >
                    {statusIcons.pending}
                </Tooltip>
            );
        case 'failing':
            return (
                <Tooltip
                    title={
                        details ?? "There haven't been any successful imports in the last 4 hours"
                    }
                >
                    {statusIcons.failing}
                </Tooltip>
            );
        case 'caution':
            return (
                <Tooltip
                    title={
                        details ?? 'Successful imports AND failures occurred in the last 4 hours'
                    }
                >
                    {statusIcons.caution}
                </Tooltip>
            );
        case 'working':
            return <Tooltip title={details ?? 'All clear'}>{statusIcons.working}</Tooltip>;
        case 'inactive':
            return (
                <Tooltip title={details ?? 'All POS configs are disabled'}>
                    {statusIcons.inactive}
                </Tooltip>
            );
        default:
            return '--';
    }
};

interface PosStatusIconProps {
    status?: AccountPosImportStatus;
    details?: string;
}

const PosStatusIcon = ({ status, details }: PosStatusIconProps) => {
    const classNamesAppended = appendClasses(['pos-status-icon', status]);

    return <div className={classNamesAppended}>{getPosStatusIcon(status, details)}</div>;
};

export default PosStatusIcon;
