import { useEffect, useRef } from 'react';

import CCAccountsView from '@views/control-center/CCAccountsView/CCAccountsView';
import CCRecentSparksView from '@views/control-center/CCRecentSparksView';
import CCUsersView from '@views/control-center/CCUsersView';
import ControlCenterView from '@views/control-center/ControlCenterView';
import Mousetrap from 'mousetrap';

import { CCGiftCardsView } from '@features/gift-cards';

import Form from '@components/form/Form';
import { Business, GiftCardInvertedIcon, OfflineBolt, People, Search } from '@components/icons';
import { SidebarMenuItem } from '@components/layout/AppSidebar';
import Helmet from '@components/layout/Helmet';
import {
    Redirect,
    Route,
    SuperAdminRoute,
    Switch,
    useRouteMatch,
    withHistory,
} from '@components/router';

import { useApp, withAppSidebar } from '@hooks/AppHooks';

import { IAuthUser } from '@app/types/UsersTypes';

import CCAccountRouter from './CCAccountRouter';

const CCAccountAddView = () => {
    return <ControlCenterView showAdd />;
};

const ControlCenterRouter = () => {
    const { path } = useRouteMatch();

    return (
        <>
            <Helmet>
                <link id="favicon" rel="icon" href="/favicon-16x16-bw.png" type="image/png" />
                <link id="favicon" rel="icon" href="/favicon-32x32-bw.png" type="image/png" />
            </Helmet>
            <Switch>
                <SuperAdminRoute path={`${path}/gift-cards`} component={CCGiftCardsView} />
                <SuperAdminRoute path={`${path}/recent-sparks`} component={CCRecentSparksView} />
                <SuperAdminRoute path={`${path}/users`} component={CCUsersView} />
                <SuperAdminRoute path={`${path}/accounts/add`} component={CCAccountAddView} />
                <Route path={`${path}/accounts/:accountId`} component={CCAccountRouter} />
                <SuperAdminRoute path={`${path}/accounts`} component={CCAccountsView} />
                <Redirect path={path} to={`${path}/accounts`} />
            </Switch>
        </>
    );
};

const AccountSearch = withHistory(
    ({
        history,
        accounts,
        searchRef,
    }: {
        history: any;
        accounts: IAuthUser['accounts'];
        searchRef: any;
    }) => {
        return (
            <Form.SearchSelect
                required
                showClear
                inputRef={searchRef}
                startIcon={<Search />}
                placeholder="Find account"
                mode="simpleSelect"
                value=""
                onChange={(newAccount) => {
                    history.push(`/control-center/accounts/${newAccount?._id}/details`);
                }}
                options={accounts}
            />
        );
    },
);

export default () => {
    const { user } = useApp();

    const searchRef = useRef<any>();

    useEffect(() => {
        Mousetrap.bind(['command+k', 'ctrl+k'], () => {
            if (searchRef.current) {
                searchRef.current.select.focus();
            }
            return false;
        });

        return () => {
            Mousetrap.unbind(['command+k', 'ctrl+k']);
        };
    }, []);

    const sidebarItems: SidebarMenuItem[] = [
        {
            key: '',
            title: '',
            component: <AccountSearch searchRef={searchRef} accounts={user?.accounts || []} />,
        },
        {
            key: 'Accounts',
            startIcon: <Business />,
            title: 'Accounts',
            to: '/control-center/accounts',
        },

        {
            key: 'Recent Sparks',
            startIcon: <OfflineBolt />,
            title: 'Recent Sparks',
            // explicitly setting the initial query params so the page doesn't need to.
            to: '/control-center/recent-sparks?p-0&limit=10&order=asc&orderBy=endDate',
        },
        {
            key: 'Users',
            startIcon: <People />,
            title: 'Users',
            to: '/control-center/users',
        },
        {
            key: 'Gift Cards',
            startIcon: <GiftCardInvertedIcon />,
            title: ' Gift Cards',
            to: '/control-center/gift-cards',
        },
    ];

    const WrappedComponent = withAppSidebar({ sidebarItems })(ControlCenterRouter);

    return <WrappedComponent />;
};
