import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    AccountTypeOptions,
    PosStatusOptions,
    SubscriptionTypeOptions,
} from '@constants/AccountConstants';
import moment from 'moment';

import {
    AccountPosImportStatus,
    AccountStatuses,
    IPublicAccount,
    IPublicUser,
    SubscriptionType,
} from '@sparkplug/lib';

import { useSparkplugUsers } from '@core/users';

import { Add as AddIcon, ArrowRight as ArrowRightIcon } from '@components/icons';
import AccountLabel from '@components/labels/AccountLabel';
import PageHeader from '@components/layout/PageHeader';
import AddAccountModal from '@components/overlays/AddAccountModal';
import SparkStatusIcon from '@components/sparks/SparkStatusIcon';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useQueryParamsState } from '@hooks/QueryParamsHooks/QueryParamsHooks';
import { useSparkplugAccounts } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import { capitalize } from '@helpers/util';

import { THeadCell } from '@app/types/TableTypes';

import PosStatusIcon, { statusIcons } from './components/PosStatusIcon/PosStatusIcon';

import './CCAccountsView.scss';

interface AccountTableRowProps extends IPublicAccount {
    assignedSuperAdminName?: string;
    pendingUserPercent: number;
    enrolledUserPercent: number;
    adminLoginPercent: number;
    lastAdminLogin: Date;
    key: string;
    subscriptionType: SubscriptionType;
    hubspotUrl?: string;
}

const accountTypeOptions = [{ label: 'All Account Types', value: 'all' }, ...AccountTypeOptions];
const accountStatusOptions = [
    { label: 'All Account Statuses', value: 'all' },
    ...AccountStatuses.map((status) => ({ label: capitalize(status), value: status })),
];
const posStatusOptions = [{ label: 'All POS Statuses', value: 'all' }, ...PosStatusOptions].map(
    (option) => {
        return {
            ...option,
            startIcon: statusIcons?.[option.value as AccountPosImportStatus],
        };
    },
);
const subscriptionTypeOptions = [
    { label: 'All Subscription Types', value: 'all' },
    ...SubscriptionTypeOptions,
];

const accountTypeLabels: any = AccountTypeOptions.reduce((res, { value, label }) => {
    return {
        ...res,
        [value]: label,
    };
}, {});

const subscriptionTypeLabels: any = SubscriptionTypeOptions.reduce((res, { value, label }) => {
    return {
        ...res,
        [value]: label,
    };
}, {});

const getSuperAdminUserOptions = (superAdminUsers: IPublicUser[]) => {
    const superAdminUserOptions = superAdminUsers.map(({ _id, firstName, lastName }) => ({
        value: _id,
        label: `${firstName} ${lastName}`,
    }));

    return [{ label: 'All Assignees', value: 'all' }, ...superAdminUserOptions];
};

const getTimeSinceLabel = (date?: Date) => {
    if (!date) {
        return '--';
    }

    const now = moment();
    const hour = now.get('hour');
    const minute = now.get('minute');

    const formattedDate = moment(date).set('hour', hour).set('minute', minute);
    const daysAgo = now.diff(formattedDate, 'days');

    if (daysAgo === 1) {
        return 'yesterday';
    }

    const timeSinceLabel = formattedDate.isSame(now, 'date')
        ? 'today'
        : moment(formattedDate).fromNow();

    return timeSinceLabel;
};

const headCells: THeadCell<AccountTableRowProps>[] = [
    {
        id: 'name',
        sortType: 'string',
        label: 'Account Name',
        render: (account) => {
            const { history } = useApp();

            return (
                <Table.Cell
                    onMouseDown={(event) => {
                        if (event.metaKey) {
                            const url = new URL(
                                `/control-center/accounts/${account._id}`,
                                window.location.origin,
                            );
                            window.open(url.toString(), '_blank');
                        } else {
                            history.push(`/control-center/accounts/${account._id}`);
                        }
                    }}
                >
                    <AccountLabel
                        label={account.name}
                        accountType={account.type}
                        subLabel={accountTypeLabels[account.type]}
                    />
                </Table.Cell>
            );
        },
    },
    {
        id: 'posImportStatus',
        sortType: 'string',
        label: 'POS Status',
        render: (account) => {
            return (
                <Table.Cell>
                    <PosStatusIcon
                        status={account.posImportStatus}
                        details={account.posImportStatusDetails}
                    />
                </Table.Cell>
            );
        },
    },
    {
        id: 'subscriptionType',
        sortType: 'string',
        label: 'Subscription Type',
        render: ({ subscriptionType }) => {
            return <Table.Cell>{subscriptionTypeLabels[subscriptionType]}</Table.Cell>;
        },
    },
    {
        id: 'lastAdminLogin',
        sortType: 'date',
        label: 'Last Admin Login',
        render: ({ userMetrics }) => (
            <Table.Cell>{getTimeSinceLabel(userMetrics.admin.lastLogin)}</Table.Cell>
        ),
    },
    {
        id: 'adminLoginPercent',
        sortType: 'numeric',
        label: 'Admin Users',
        render: ({ userMetrics }) => {
            const totalAdminsCount = Object.values(userMetrics.admin.userLoginDetails).length;
            const loggedInAdminsCount = Object.values(userMetrics.admin.userLoginDetails).filter(
                (lastLogin) => !!lastLogin,
            ).length;

            const adminUsersLabel = `${loggedInAdminsCount} / ${totalAdminsCount}`;
            return (
                <Table.Cell>
                    <span className="admin-users-label">{adminUsersLabel}</span>
                </Table.Cell>
            );
        },
    },
    {
        id: 'userMetrics.enrollmentMetrics.activeCount',
        sortType: 'numeric',
        label: 'Active Users',
        render: ({ userMetrics }) => (
            <Table.Cell>{userMetrics.enrollmentMetrics.activeCount || 0}</Table.Cell>
        ),
    },
    {
        id: 'pendingUserPercent',
        sortType: 'numeric',
        label: 'Pending Users',
        render: ({ pendingUserPercent }) => <Table.Cell>{pendingUserPercent}%</Table.Cell>,
    },
    {
        id: 'enrolledUserPercent',
        sortType: 'numeric',
        label: 'Enrolled Users',
        render: ({ enrolledUserPercent }) => <Table.Cell>{enrolledUserPercent}%</Table.Cell>,
    },
    {
        id: 'sparkCount',
        sortType: 'numeric',
        component: () => {
            const options = [
                {
                    value: 'sparkCount.active',
                    label: 'Active',
                    startIcon: <SparkStatusIcon status="active" />,
                },
                {
                    value: 'sparkCount.upcoming',
                    label: 'Upcoming',
                    startIcon: <SparkStatusIcon status="upcoming" />,
                },
                {
                    value: 'sparkCount.completed',
                    label: 'Completed',
                    startIcon: <SparkStatusIcon status="completed" />,
                },
                {
                    value: 'sparkCount.pending',
                    label: 'Pending',
                    startIcon: <SparkStatusIcon status="pending" />,
                },
            ];

            return (
                <Table.DropdownSortHeadCell
                    label="Sparks"
                    options={options}
                    id="sparkCount"
                    defaultSortDirection="desc"
                />
            );
        },
        render: (account) => {
            return (
                <Table.Cell className="table-cell_spark-count">
                    <div>
                        <span className="spark-count-group">
                            <SparkStatusIcon status="active" />
                            <span className="spark-count-group_value">
                                {account.sparkCount.active}
                            </span>
                        </span>
                        <span className="spark-count-group">
                            <SparkStatusIcon status="upcoming" />
                            <span className="spark-count-group_value">
                                {account.sparkCount.upcoming}
                            </span>
                        </span>
                        <span className="spark-count-group">
                            <SparkStatusIcon status="completed" />
                            <span className="spark-count-group_value">
                                {account.sparkCount.completed}
                            </span>
                        </span>
                        <span className="spark-count-group">
                            <SparkStatusIcon status="pending" />
                            <span className="spark-count-group_value">
                                {account.sparkCount.pending}
                            </span>
                        </span>
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'hubspot',
        label: 'Hubspot',
        render: ({ hubspotUrl }) => {
            return (
                <Table.Cell className="table-cell_hubspot-url">
                    {hubspotUrl ? (
                        <a href={hubspotUrl} target="_blank" rel="noreferrer">
                            <span>Hubspot</span>
                            <ArrowRightIcon />
                        </a>
                    ) : (
                        '--'
                    )}
                </Table.Cell>
            );
        },
    },
    {
        id: 'assignedSuperAdminName',
        label: 'Assignee',
        render: ({ assignedSuperAdminName }) => {
            return (
                <Table.Cell className="table-cell_assigned-super-user">
                    {`${assignedSuperAdminName ?? '--'}`}
                </Table.Cell>
            );
        },
    },
];

const mapAccountTableData = ({
    accounts,
    superAdminUsers,
}: {
    accounts: IPublicAccount[];
    superAdminUsers: IPublicUser[];
}): AccountTableRowProps[] => {
    const superAdminNamesByUserId = superAdminUsers.reduce((map, { _id, firstName, lastName }) => {
        return map.set(_id, `${firstName} ${lastName}`);
    }, new Map<string, string>());

    return accounts.map((account) => {
        const { assignedSuperAdminUserId, userMetrics, metaData } = account;
        const assignedSuperAdminName = superAdminNamesByUserId.get(
            assignedSuperAdminUserId as string,
        );

        const adminLogins =
            Object.values(userMetrics.admin.userLoginDetails).filter((login) => !!login)?.length ||
            0;
        const totalAdmins = Object.values(userMetrics.admin.userLoginDetails)?.length || 0;

        const { activeCount, pendingCount, enrolledCount } = userMetrics.enrollmentMetrics;

        return {
            ...account,
            assignedSuperAdminName,
            lastAdminLogin: userMetrics.admin.lastLogin || moment(0).toDate(),
            adminLoginPercent: Math.round((adminLogins / totalAdmins || 0) * 100),
            enrolledUserPercent: Math.round((enrolledCount / activeCount || 0) * 100),
            pendingUserPercent: Math.round((pendingCount / activeCount || 0) * 100),
            subscriptionType: metaData?.subscriptionType ?? 'none',
            key: account._id,
        };
    });
};

const AccountsTable = ({
    isReady,
    accounts,
    filters = [],
}: {
    isReady: boolean;
    accounts: AccountTableRowProps[];
    filters: (<T>(items: T[]) => T[])[];
}) => {
    return (
        <Table
            isLoading={!isReady}
            variant="raised"
            rows={accounts}
            headCells={headCells}
            filters={filters}
            defaultOptions={{
                orderBy: 'name',
            }}
        >
            <Table.RenderHead />
            <Table.RenderBody />
        </Table>
    );
};

interface CCAccountsViewProps {
    isReady: boolean;
    accounts: IPublicAccount[];
    superAdminUsers: IPublicUser[];
    refetch: () => void;
}

export const CCAccountsView = ({
    isReady,
    accounts,
    superAdminUsers,
    refetch,
}: CCAccountsViewProps) => {
    const [showAddAccountModal, setShowAddAccountModal] = useState(false);

    const initialQueryParams = {
        type: 'all',
        subscriptionType: 'all',
        posImportStatus: 'all',
        assignedSuperAdminUser: 'all',
        productVersion: 'all',
        accountStatus: 'all',
    };
    const [query, setQuery] = useQueryParamsState(initialQueryParams);

    const {
        type: accountTypeFilter,
        subscriptionType: subscriptionTypeFilter,
        posImportStatus: posImportStatusFilter,
        assignedSuperAdminUser: assignedSuperAdminUserFilter,
        accountStatus: accountStatusFilter,
    } = query;

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch(['name']);

    useEffect(() => {
        refetch();
    }, []);

    const applyTypeFilter = (list: any[]) => {
        if (accountTypeFilter === 'all') {
            return list;
        }

        return list.filter((account) => {
            return account?.type === accountTypeFilter;
        });
    };
    const applyAccountStatusFilter = (list: any[]) => {
        if (accountStatusFilter === 'all') {
            return list;
        }

        return list.filter((account) => {
            return account?.status === accountStatusFilter;
        });
    };
    const posStatusFilter = (list: any[]) => {
        if (posImportStatusFilter === 'all') {
            return list;
        }

        return list.filter((account) => {
            return account?.posImportStatus === posImportStatusFilter;
        });
    };

    const applySubscriptionTypeFilter = (list: any[]) => {
        if (subscriptionTypeFilter === 'all') {
            return list;
        }

        return list.filter((account) => {
            return account?.metaData?.subscriptionType === subscriptionTypeFilter;
        });
    };

    const applyAssignedSuperAdminFilter = (list: any[]) => {
        if (assignedSuperAdminUserFilter === 'all') {
            return list;
        }

        return list.filter((account) => {
            return account.assignedSuperAdminUserId === assignedSuperAdminUserFilter;
        });
    };

    const superAdminUserOptions = useMemo(
        () => getSuperAdminUserOptions(superAdminUsers),
        [superAdminUsers],
    );

    const accountTableRowData = useMemo(
        () => mapAccountTableData({ accounts, superAdminUsers }),
        [accounts, superAdminUsers],
    );

    return (
        <>
            <div className="cc-accounts-view">
                <PageHeader title="Accounts" metaTitle="Accounts | Control Center">
                    <Toolbar>
                        {process.env.REACT_APP_QUICK_VIEWS && (
                            <Toolbar.QuickViewQueryManager
                                quickViewTag="cc-accounts-table"
                                initialQueryParams={initialQueryParams}
                                queryParams={query}
                                setQueryParams={setQuery}
                            />
                        )}
                        <Toolbar.Button
                            className="toolbar-group-end"
                            variant="filled"
                            startIcon={<AddIcon />}
                            onClick={() => setShowAddAccountModal(true)}
                        >
                            Create New Account
                        </Toolbar.Button>
                    </Toolbar>
                </PageHeader>

                <Toolbar className="toolbar-content-start" scrollOnMobile>
                    <Toolbar.Search
                        name="account-search"
                        defaultValue={searchFilter}
                        onChange={onChangeSearchFilter}
                    />

                    <Toolbar.Dropdown
                        label={null}
                        value={accountTypeFilter}
                        onSelect={(v) => setQuery({ type: v })}
                        options={accountTypeOptions}
                        clear={{
                            active: accountTypeFilter !== 'all',
                            onClear: () => setQuery({ type: 'all' }),
                        }}
                    />

                    <Toolbar.Dropdown
                        label={null}
                        value={posImportStatusFilter}
                        onSelect={(v) => setQuery({ posImportStatus: v })}
                        options={posStatusOptions}
                        clear={{
                            active: posImportStatusFilter !== 'all',
                            onClear: () => setQuery({ posImportStatus: 'all' }),
                        }}
                    />

                    <Toolbar.Dropdown
                        label={null}
                        value={subscriptionTypeFilter}
                        onSelect={(v) => setQuery({ subscriptionType: v })}
                        options={subscriptionTypeOptions}
                        clear={{
                            active: subscriptionTypeFilter !== 'all',
                            onClear: () => setQuery({ subscriptionType: 'all' }),
                        }}
                    />

                    <Toolbar.Dropdown
                        label={null}
                        value={assignedSuperAdminUserFilter}
                        onSelect={(superAdminUserId) =>
                            setQuery({ assignedSuperAdminUser: superAdminUserId })
                        }
                        options={superAdminUserOptions}
                        clear={{
                            active: assignedSuperAdminUserFilter !== 'all',
                            onClear: () => setQuery({ assignedSuperAdminUser: 'all' }),
                        }}
                    />
                    <Toolbar.Dropdown
                        label={null}
                        value={accountStatusFilter}
                        onSelect={(v) => setQuery({ accountStatus: v })}
                        options={accountStatusOptions}
                        clear={{
                            active: accountStatusFilter !== 'all',
                            onClear: () => setQuery({ accountStatus: 'all' }),
                        }}
                    />
                </Toolbar>

                <AccountsTable
                    isReady={isReady}
                    accounts={accountTableRowData}
                    filters={[
                        applySearch,
                        applyTypeFilter,
                        applyAccountStatusFilter,
                        posStatusFilter,
                        applySubscriptionTypeFilter,
                        applyAssignedSuperAdminFilter,
                    ]}
                />
            </div>

            <AddAccountModal
                isVisible={showAddAccountModal}
                onClose={(didCreate?: boolean) => {
                    if (didCreate) {
                        refetch();
                    }
                    setShowAddAccountModal(false);
                }}
            />
        </>
    );
};

// CCAccountsView Wrapper Logic

// TODO: Temporary fix
const formatGroupData = ({
    userMetrics,
    ...group
}: IPublicAccount): IPublicAccount & { hubspotUrl?: string } => ({
    ...group,
    userMetrics: {
        ...userMetrics,
        admin: {
            ...userMetrics?.admin,
            userLoginDetails: userMetrics?.admin?.userLoginDetails || {},
        },
    },
});

export default () => {
    const { sparkplugUsers: superAdminUsers = [], refetchSparkplugUsers: refetchSuperUsers } =
        useSparkplugUsers({ role: 'super-admin' });
    const { accountsAreReady, accounts, refetchAccounts } = useSparkplugAccounts();

    const refetch = useCallback(() => Promise.all([refetchSuperUsers, refetchAccounts]), []);

    if (!superAdminUsers?.length || !accounts?.length) {
        // TODO: figure out why table component isn't re-rendering properly and remove this conditional
        return null;
    }

    return (
        <CCAccountsView
            isReady={accountsAreReady}
            accounts={accounts?.map((account) => formatGroupData(account))}
            superAdminUsers={superAdminUsers}
            refetch={refetch}
        />
    );
};
