import axios from 'axios';

import { CreateCreditRequest, CreateLedgerEntryRequestBody } from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { getGroupSparkCreditEntriesKey } from '../queries';

const API = {
    async createSparkCreditEntry(
        groupId: string,
        body: CreateLedgerEntryRequestBody,
    ): Promise<void> {
        await axios.post(`/api/v1/ledger/${groupId}`, body);
    },
};

export const useCreateCreditMutation = (accountId: string) => {
    const { mutate: createCredit } = useAdvancedMutation(
        async (payload: CreateCreditRequest) => API.createSparkCreditEntry(accountId, payload),
        {
            updateQuery: { queryKey: getGroupSparkCreditEntriesKey(accountId) },
            toastOptions: {
                loading: null,
                success: 'Success',
                error: 'Error. Unable to create new credit',
            },
        },
    );
    return { createCredit };
};
