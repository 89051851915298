import { Box, Storefront } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

import { IPosLocation } from '@app/types/PosTypes';

import './AccountLabel.scss';

const AccountLabel = ({
    label,
    locations,
    accountType,
    subLabel: _subLabel,
    afterLabel,
}: {
    label: string;
    accountType: 'brand' | 'retailer';
    locations?: IPosLocation[];
    subLabel?: string;
    afterLabel?: string;
}) => {
    const locationCount = (locations || []).length;
    const isBrandAccount = accountType === 'brand';

    const locationLabel = !isBrandAccount
        ? `${locationCount} ${locationCount === 1 ? 'Location' : 'Locations'}`
        : '';
    const subLabel = _subLabel ?? locationLabel;

    return (
        <div className="account-label">
            {isBrandAccount ? <Box /> : <Storefront />}
            <div>
                <Tooltip disabled={label.length < 35} title={label} placement="top">
                    {afterLabel ? (
                        <h4>
                            {label}
                            <span className="after-label">{afterLabel}</span>
                        </h4>
                    ) : (
                        <h4>{label}</h4>
                    )}
                </Tooltip>
                {subLabel && <span>{subLabel}</span>}
            </div>
        </div>
    );
};

AccountLabel.defaultProps = {
    locations: undefined,
};

export default AccountLabel;
