import { useState } from 'react';

import { CovaCredentials } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Add } from '@components/icons';
import Paper from '@components/layout/Paper';

interface CovaPosSpecificFieldsProps {
    credentials?: CovaCredentials;
    onChange: (key: string, value: any) => void;
}

const CovaPosSpecificFields = ({ credentials, onChange }: CovaPosSpecificFieldsProps) => {
    const {
        username,
        password,
        clientId,
        clientSecret,
        companyId,
        locationFilter = [],
    } = credentials || {};

    const [showLocationFilter, setShowLocationFilterValue] = useState(!!locationFilter?.length);

    function setShowLocationFilter(newValue: boolean) {
        if (newValue === false) {
            onChange('locationFilter', []);
        } else {
            onChange('locationFilter', ['']);
        }
        setShowLocationFilterValue(newValue);
    }

    const onUpdateLocation = (i: number, value: any) => {
        const newlocationFilter = [...locationFilter];
        (newlocationFilter as any)[i] = value;
        onChange('locationFilter', newlocationFilter);
    };

    const onAddLocation = () => {
        const newlocationFilter = [...locationFilter];
        newlocationFilter.push('');
        onChange('locationFilter', newlocationFilter);
    };

    const onRemoveLocation = (i: number) => {
        const newlocationFilter = locationFilter.filter((location, j) => j !== i);
        onChange('locationFilter', newlocationFilter);
    };

    return (
        <>
            <Form.TextField
                label="Username"
                name="username"
                required
                value={username ?? ''}
                onChange={(event) => onChange('username', event.target.value)}
            />
            <Form.TextField
                label="Password"
                name="password"
                required
                value={password ?? ''}
                onChange={(event) => onChange('password', event.target.value)}
            />
            <Form.TextField
                label="Client ID"
                name="clientId"
                required
                value={clientId ?? ''}
                onChange={(event) => onChange('clientId', event.target.value)}
            />
            <Form.TextField
                label="Client Secret"
                name="clientSecret"
                required
                value={clientSecret ?? ''}
                onChange={(event) => onChange('clientSecret', event.target.value)}
            />
            <Form.TextField
                label="Company ID"
                name="companyId"
                required
                value={companyId ?? ''}
                onChange={(event) => onChange('companyId', event.target.value)}
            />
            <Form.Switch
                label="Import only specified location ids"
                value={showLocationFilter}
                name="showLocationFilter"
                onChange={(event: any) => setShowLocationFilter(event.target.checked)}
            />
            {showLocationFilter && (
                <div className="form-control repeating-fields">
                    <Form.Label>Locations</Form.Label>
                    <div className="repeating-fields-fields">
                        {locationFilter.map((location, i) => {
                            return (
                                <Paper
                                    key={`cova_credentials-locations-${i}`}
                                    className="repeating-field-group"
                                    variant="outlined"
                                >
                                    <Form.TextField
                                        label="Location Id"
                                        name={`locations.${i}`}
                                        required
                                        value={location}
                                        onChange={(event) =>
                                            onUpdateLocation(i, event.target.value)
                                        }
                                    />
                                    <Button
                                        className="repeating-fields-button-remove"
                                        color="neutral"
                                        variant="smooth"
                                        onClick={() => onRemoveLocation(i)}
                                    >
                                        Remove Location
                                    </Button>
                                </Paper>
                            );
                        })}
                    </div>
                    <Button
                        className="repeating-fields-button-add"
                        color="blue"
                        variant="smooth"
                        onClick={onAddLocation}
                        startIcon={<Add />}
                    >
                        Add Location
                    </Button>
                </div>
            )}
        </>
    );
};

export default CovaPosSpecificFields;
