import { ReactElement, useRef, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { ArrowDropDown } from '@mui/icons-material';
// eslint-disable-next-line no-restricted-imports
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Popper } from '@mui/material';

import Paper from '@components/layout/Paper';

import Button from '../Button';

import './SplitButton.scss';

interface SplitButtonOption {
    text: string;
    details?: string;
    action: () => void;
}

export type SplitButtonProps =
    | {
          disabled?: boolean;
          dropdownBtnComponent?: ReactElement;
          dropdownComponents?: never;
          options: SplitButtonOption[];
      }
    | {
          disabled?: boolean;
          dropdownBtnComponent?: ReactElement;
          dropdownComponents: ReactElement[];
          options?: never;
      };

const SplitButton = ({
    options,
    dropdownComponents,
    disabled,
    dropdownBtnComponent,
}: SplitButtonProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleMenuItemClick = (index: number) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div className="split-button-grid">
            <ButtonGroup
                className="button-group"
                variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
            >
                {options ? (
                    <Button
                        color="blue"
                        variant="filled"
                        disabled={disabled}
                        onClick={() => options?.[selectedIndex]?.action()}
                    >
                        {options[selectedIndex]?.text}
                    </Button>
                ) : null}
                <Button
                    color="blue"
                    variant="filled"
                    onClick={handleToggle}
                    data-testid="split-button-toggle"
                >
                    {dropdownBtnComponent || <ArrowDropDown />}
                </Button>
            </ButtonGroup>
            <Popper
                className="popper"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-end' ? 'center top' : 'center bottom',
                        }}
                    >
                        <div>
                            {options && (
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList id="split-button-menu">
                                            {options.map(({ text, details }, index) => (
                                                <MenuItem
                                                    key={text}
                                                    selected={index === selectedIndex}
                                                    onClick={() => handleMenuItemClick(index)}
                                                >
                                                    <div className="button-options">
                                                        <h3>{text}</h3>
                                                        {details && <div>{details}</div>}
                                                    </div>
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            )}
                            {dropdownComponents && (
                                <Paper className="flex flex-col gap-1 !p-1 bg-white">
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <div>
                                            {dropdownComponents.map((component, index) => (
                                                <div key={index}>{component}</div>
                                            ))}
                                        </div>
                                    </ClickAwayListener>
                                </Paper>
                            )}
                        </div>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default SplitButton;
