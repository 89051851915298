import { FC, useState } from 'react';

import moment from 'moment';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import Modal from '@components/overlays/Modal';

import { appendClasses } from '@helpers/ui';

import './ReimportConfirmModal.scss';

type TConfirmModal = {
    isVisible: boolean;
    onConfirm: (options: { lookback?: string; notify?: boolean }) => void;
    onClose: () => void;
    confirmText?: string;
};

export const ReimportConfirmModal: FC<TConfirmModal> = ({
    isVisible,
    onConfirm,
    onClose,
    confirmText = 'Confirm',
}) => {
    const classNamesAppended = appendClasses(['confirm-modal']);
    const [lookback, setLookback] = useState<string | undefined>(moment().toISOString());
    const [fullReimport, setFullReimport] = useState(true);
    const [notify, setNotify] = useState(false);

    return (
        <Modal className={classNamesAppended} isVisible={isVisible} onClose={onClose}>
            <Modal.Title>Confirm Re-import</Modal.Title>

            <Modal.Content>
                <Form>
                    <Form.Switch
                        name="full-reimport"
                        label="Full Re-import"
                        value={fullReimport}
                        onChange={(event: any) => setFullReimport(event.target.checked)}
                    />
                    <Form.DatePicker
                        id="lookback"
                        name="lookback"
                        label="Partial Lookback"
                        value={lookback}
                        disabled={fullReimport}
                        onChange={(event) => {
                            setLookback(event as any);
                            setFullReimport(false);
                        }}
                    />
                    <br />
                    <br />
                    <Form.Switch
                        name="notify"
                        label="Notify me on completion"
                        value={notify}
                        onChange={(event: any) => setNotify(event.target.checked)}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button color="neutral" variant="flat" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    color="blue"
                    variant="filled"
                    onClick={() => {
                        onConfirm({
                            lookback: fullReimport ? undefined : moment(lookback).toISOString(),
                            notify,
                        });
                        onClose();
                    }}
                >
                    {confirmText}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
