import BillingAPI from '@api/BillingAPI';
import { capitalize } from 'lodash';

import {
    Invoice,
    ListInvoicesResponseBody,
    ListPaymentMethodsResponseBody,
    ListSubscriptionsResponseBody,
    Subscription,
} from '@sparkplug/lib';

import { InvoiceStatusChip } from '@components/chips/InvoiceStatusChip';
import { SubscriptionStatusChip } from '@components/chips/SubscriptionStatusChip';
import { CardIcon, NoCreditCardIcon, NoInvoicesIcon } from '@components/icons';
import Grid from '@components/layout/Grid';
import Paper from '@components/layout/Paper';
import { SparkplugIcon } from '@components/logos';
import Table from '@components/table/Table';

import { useAdvancedQuery } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { formatNumberToCurrency } from '@helpers/ui';

import { THeadCell } from '@app/types/TableTypes';

import './CCSubscriptionView.scss';

const getBillingText = (subscription: Subscription) => {
    if (subscription.interval.cadence === 'month' && subscription.interval.frequency === 1) {
        return 'monthly';
    }

    return `every ${subscription.interval.frequency} ${subscription.interval.cadence}s`;
};

const headCells: THeadCell<Invoice>[] = [
    {
        id: 'amount',
        sortType: 'string',
        label: 'Amount',
        render: (invoice) => {
            return (
                <Table.Cell>
                    <span className="invoice-total-cell-text">
                        {formatNumberToCurrency(invoice.total, true)}
                    </span>
                    {invoice.status && (
                        <span>
                            &nbsp;&nbsp;
                            <InvoiceStatusChip status={invoice.status} />
                        </span>
                    )}
                </Table.Cell>
            );
        },
    },
    {
        id: 'invoiceNumber',
        sortType: 'string',
        label: 'Invoice Number',
        render: (invoice) => <Table.Cell>{invoice._id}</Table.Cell>,
    },
    {
        id: 'billingEmail',
        sortType: 'string',
        label: 'Billing Email',
        render: (invoice) => <Table.Cell>{invoice.billingEmail}</Table.Cell>,
    },
    {
        id: 'due',
        sortType: 'numeric',
        label: 'Due',
        render: (invoice) => (
            <Table.Cell>{formatNumberToCurrency(invoice.amountDue, true)}</Table.Cell>
        ),
    },
    {
        id: 'created',
        sortType: 'date',
        label: 'Created',
        render: (invoice) => <Table.Cell>{invoice.createdAt}</Table.Cell>,
    },
];

const NoInvoices = ({ groupName }: { groupName: string }) => {
    return (
        <Table.Row tabIndex={-1}>
            <Table.Cell align="center" colSpan={headCells.length + 2}>
                <NoInvoicesIcon />
                <h2 className="no-invoices">No Invoices</h2>
                <span>There are no invoices for {groupName} </span>
            </Table.Cell>
        </Table.Row>
    );
};

const InvoicesTable = ({
    invoices,
    groupName,
    isLoading,
}: {
    invoices: Invoice[];
    groupName: string;
    isLoading: boolean;
}) => {
    return (
        <Table
            variant="raised"
            rows={invoices.map((invoice) => ({ ...invoice, key: invoice._id }))}
            headCells={headCells}
            defaultOptions={{
                orderBy: 'created',
            }}
        >
            <Table.RenderHead />
            {isLoading && <Table.Loading rows={10} colSpan={headCells.length + 1} />}
            {!isLoading &&
                (invoices.length ? <Table.RenderBody /> : <NoInvoices groupName={groupName} />)}
        </Table>
    );
};

const useStripeSubscriptions = (groupId: string) => {
    const { data: subscriptionsResponse, isLoading } = useAdvancedQuery<
        ListSubscriptionsResponseBody,
        any
    >(['stripe-subscriptions', groupId], () => BillingAPI.listSubscriptions({ groupId }), {
        enabled: !!groupId,
    });

    return {
        subscriptions: subscriptionsResponse?.subscriptions,
        isLoading,
    };
};

const useStripeInvoices = (groupId: string) => {
    const { data: invoicesResponse, isLoading } = useAdvancedQuery<ListInvoicesResponseBody, any>(
        ['stripe-invoices', groupId],
        () => BillingAPI.listInvoices({ groupId }),
        {
            enabled: !!groupId,
        },
    );

    return {
        invoices: invoicesResponse?.invoices,
        isLoading,
    };
};

const useStripePaymentMethods = (groupId: string) => {
    const { data: paymentMethodsResponse, isLoading } = useAdvancedQuery<
        ListPaymentMethodsResponseBody,
        any
    >(
        ['stripe-default-payment-methods', groupId],
        () => BillingAPI.listPaymentMethods({ groupId }),
        {
            enabled: !!groupId,
        },
    );

    return {
        paymentMethods: paymentMethodsResponse?.data,
        isLoading,
    };
};

const CCSubscriptionView = () => {
    const { account } = useSparkplugAccount();
    if (!account?._id) {
        return <div />;
    }

    const { subscriptions, isLoading: isLoadingSubscriptions } = useStripeSubscriptions(
        account._id,
    );
    const { invoices, isLoading: isLoadingInvoices } = useStripeInvoices(account._id);
    const { paymentMethods, isLoading: isLoadingPaymentMethods } = useStripePaymentMethods(
        account._id,
    );

    return (
        <div className="cc-subscription-view">
            <Grid className="subscription-details">
                <Grid.Item>
                    <h3>Subscription</h3>
                    <Paper className="subscription-card">
                        {isLoadingSubscriptions && (
                            <div className="subscription-card-inner">
                                <div className="subscription-row">
                                    <SparkplugIcon />
                                    <div className="subscription-text no-subscription-text">
                                        <strong> Loading subscriptions...</strong>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!isLoadingSubscriptions &&
                            (subscriptions?.length ? (
                                <div className="subscription-card-inner">
                                    {subscriptions.map((subscription, i) => (
                                        <div
                                            key={i}
                                            className="subscription-row subscription-list-item"
                                        >
                                            <SparkplugIcon />
                                            <div className="subscription-text">
                                                <div>
                                                    <strong>{subscription.title}</strong>
                                                    &nbsp;&nbsp;
                                                    <SubscriptionStatusChip
                                                        status={subscription.status}
                                                    />
                                                </div>
                                                <div className="billing-text">
                                                    Billing {getBillingText(subscription)} - Next
                                                    invoice on {subscription.nextInvoiceDue} for{' '}
                                                    {formatNumberToCurrency(
                                                        subscription.nextInvoiceCost,
                                                        true,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="subscription-card-inner">
                                    <div className="subscription-row">
                                        <SparkplugIcon />
                                        <div className="subscription-text no-subscription-text">
                                            <strong>
                                                No subscription found for {account.name}
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </Paper>
                </Grid.Item>
                <Grid.Item>
                    <h3>Payment Method</h3>
                    <Paper className="subscription-card">
                        {isLoadingPaymentMethods && (
                            <div className="payment-method-row no-payment-method">
                                <CardIcon />
                                <span className="payment-method-text">
                                    Loading payment source...
                                </span>
                            </div>
                        )}
                        {!isLoadingPaymentMethods &&
                            (paymentMethods?.length ? (
                                paymentMethods.map((paymentMethod, i) => (
                                    <div key={i} className="payment-method-row">
                                        <CardIcon />
                                        <span className="payment-method-text">
                                            {capitalize(paymentMethod.type)}{' '}
                                            {paymentMethod.last4
                                                ? `ending in ${paymentMethod.last4}`
                                                : ''}
                                        </span>
                                    </div>
                                ))
                            ) : (
                                <div className="payment-method-row no-payment-method">
                                    <NoCreditCardIcon />
                                    <span className="payment-method-text">
                                        No payment method on file
                                    </span>
                                </div>
                            ))}
                    </Paper>
                </Grid.Item>
            </Grid>
            <div>
                <h3>Invoices</h3>
                <InvoicesTable
                    invoices={invoices ?? []}
                    groupName={account.name}
                    isLoading={isLoadingInvoices}
                />
            </div>
        </div>
    );
};

export default CCSubscriptionView;
