import HighchartsReact from 'highcharts-react-official';
import { FC, useMemo, useRef } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import Highcharts from 'highcharts';
import { capitalize, isEmpty } from 'lodash';
import moment, { MomentInput } from 'moment';
import pluralize from 'pluralize';

import { BucketsKeyedByLabel, FullChartResponseBody } from '@sparkplug/lib';

import { BucketFactory } from '@core/charts/hooks/ChartsHooks';
import { ComparisonDatesKeyedByCurrentDate, ComparisonRange } from '@core/charts/types/ChartsTypes';
import {
    getBucketFromTooltipContext,
    getDefaultChartOptions,
    transformChartDataToBarSeries,
    transformChartDataToLineSeries,
} from '@core/charts/utils/ChartsUtils';

import SliceTooltip from '@components/charts/SliceTooltip';
import { ComparisonColors } from '@components/charts/nivo/Nivo.Constants';
import PercentDifferenceChip from '@components/chips/PercentDifferenceChip';
import Skeleton from '@components/layout/Skeleton';

import { getChartMetricLabel } from '@helpers/charts/transformCloudChartData';

import { IChartDataSettings } from '@app/types/ChartDataTypes';

import { useChartContext } from '../AdvancedChart/ChartContext';
import AdvancedTableChart from '../AdvancedTableChart';
import { useComparisonChartContext } from './ComparisonChartContext';

import './AdvancedComparisonChart.scss';

function formatRange(startDate: MomentInput, endDate: MomentInput) {
    const startDateFormatted = moment(startDate).format('MM/DD/YYYY');
    const endDateFormatted = moment(endDate).format('MM/DD/YYYY');

    return `${startDateFormatted} - ${endDateFormatted}`;
}

export const transformChartDataToSeries = ({
    settings,
    data: initialData,
    comparisonDatesByCurrentDate,
    currentPeriodLabel,
    comparisonRange,
    buckets,
}: {
    settings: Pick<IChartDataSettings, 'dateStart' | 'dateEnd' | 'type' | 'metric'>;
    data: BucketsKeyedByLabel;
    comparisonDatesByCurrentDate: ComparisonDatesKeyedByCurrentDate;
    currentPeriodLabel: string;
    comparisonRange: ComparisonRange;
    buckets: string[];
}): Highcharts.SeriesOptionsType[] => {
    const data = isEmpty(initialData)
        ? ({ currentPeriod: {} } as BucketsKeyedByLabel)
        : initialData;
    const { previousDateStart, previousDateEnd } = comparisonRange;

    // Previous Period date buckets need to be remapped to the current period date buckets so that they overlap
    if (data.previousPeriod) {
        const currentDatesByComparisonDates = Object.fromEntries(
            Object.entries(comparisonDatesByCurrentDate).map(([currentDate, comparisonDate]) => [
                comparisonDate,
                currentDate,
            ]),
        );

        data.previousPeriod = Object.fromEntries(
            Object.entries(data.previousPeriod).map(([previousBucketName, value]) => [
                currentDatesByComparisonDates[previousBucketName] ?? previousBucketName,
                value,
            ]),
        );
    }

    const [defaultCurrentSeries, defaultComparisonSeries] =
        settings.type === 'line'
            ? Object.entries(data).flatMap(([label, periodBuckets], i) =>
                  transformChartDataToLineSeries({
                      metric: settings.metric,
                      data: { [label]: periodBuckets },
                      buckets,
                      // We want the current period to show only show up to the current date, but the
                      // previous should show through the end of the chart
                      showFutureDates: !!i,
                  }),
              )
            : Object.entries(data).flatMap(([label, periodBuckets], i) =>
                  transformChartDataToBarSeries({
                      data: { [label]: periodBuckets },
                      buckets,
                      stack: false,
                      // We want the current period to show only show up to the current date, but the
                      // previous should show through the end of the chart
                      showFutureDates: !!i,
                  }),
              );

    // Override default current period series label and color and other values
    const updatedCurrentSeries = {
        ...defaultCurrentSeries,
        name: `${currentPeriodLabel} (${formatRange(settings.dateStart, settings.dateEnd)})`,
        color: ComparisonColors[0],
        zIndex: 1,
    };

    if (defaultComparisonSeries) {
        // Override default comparison period series label and color and other values
        const updatedComparisonSeries = {
            ...defaultComparisonSeries,
            name: `Previous Period (${formatRange(previousDateStart, previousDateEnd)})`,
            color: ComparisonColors[1],
            /**
             * When the chart is a line chart, we want the previous period series to display as
             * an area instead of a line chart for pure design reasons
             */
            type: settings.type === 'line' ? 'areaspline' : 'column',
            zIndex: 0,
            fillOpacity: 0.2,
        } as Highcharts.SeriesOptionsType;

        return [updatedCurrentSeries, updatedComparisonSeries];
    }

    return [updatedCurrentSeries];
};

interface ComparisonBucketsKeyedByPeriod {
    currentPeriod: {
        [bucket: string]: number;
    };
    previousPeriod?: {
        [bucket: string]: number;
    };
}

interface AdvancedChartProps {
    settings: Pick<IChartDataSettings, 'dateStart' | 'dateEnd' | 'type' | 'metric'>;
    data: BucketsKeyedByLabel; // Actually this should be `ComparisonBucketsKeyedByPeriod`, but this requires type maintenance upstream
    comparisonDatesByCurrentDate: ComparisonDatesKeyedByCurrentDate;
    currentPeriodLabel: string;
    breakdownLabel: string;
    breakdownItemLabels: string[];
    comparisonRange: ComparisonRange;
    hideLegend?: boolean;
    chartValueFormatter: (value: number) => string;
    chartBucketFactory: InstanceType<BucketFactory>;
}

export const AdvancedComparisonChart: FC<AdvancedChartProps> = ({
    settings,
    data,
    comparisonDatesByCurrentDate,
    currentPeriodLabel,
    breakdownLabel,
    breakdownItemLabels,
    comparisonRange,
    hideLegend,
    chartValueFormatter,
    chartBucketFactory,
}) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>();

    const series = useMemo(
        () =>
            transformChartDataToSeries({
                settings,
                data,
                comparisonDatesByCurrentDate,
                currentPeriodLabel,
                comparisonRange,
                buckets: chartBucketFactory.getAllBucketNames(settings.dateStart, settings.dateEnd),
            }),
        [settings, data, comparisonDatesByCurrentDate],
    );

    const defaultTooltipFormatter = (tooltipContext: Highcharts.TooltipFormatterContextObject) => {
        const { points = [] } = tooltipContext;
        const title = getChartMetricLabel(settings.metric);
        const subTitle =
            breakdownItemLabels.length > 1
                ? `All ${breakdownItemLabels.length} ${capitalize(pluralize(breakdownLabel))}`
                : breakdownItemLabels?.[0] ?? 'Unknown Breakdown Label';

        const currentBucketName = moment(getBucketFromTooltipContext(tooltipContext)).format(
            'YYYY-MM-DD',
        );
        const previousBucketName = comparisonDatesByCurrentDate[currentBucketName];

        const showChip = points.length > 1;
        const currentValue = points[0]?.point?.y ?? 0;
        const previousValue = points[1]?.point?.y ?? 0;

        return renderToStaticMarkup(
            <SliceTooltip
                className="highcharts-tooltip comparison-slice-tooltip"
                title={title}
                subTitle={subTitle}
                chip={
                    showChip ? (
                        <PercentDifferenceChip
                            prevValue={previousValue}
                            currentValue={currentValue}
                        />
                    ) : undefined
                }
                points={points.map(({ point }, i) => ({
                    name: chartBucketFactory.getComparisonLabel(
                        (i === 0 && point.color === '#07A0C3'
                            ? currentBucketName
                            : previousBucketName) ?? '',
                    ),
                    value: chartValueFormatter(point.y ?? 0),
                    color: point.color?.toString() ?? '#ff0000',
                }))}
            />,
        );
    };

    const chartOptions: Highcharts.Options = {
        ...getDefaultChartOptions({
            chartValueFormatter,
            chartBucketFactory,
            hideLegend,
            showXAxisGridLine: settings.type === 'line',
        }),
        tooltip: {
            formatter() {
                return defaultTooltipFormatter(this);
            },
            useHTML: true,
            shared: true,
        },
        series,
    };

    const chartProps: HighchartsReact.Props = {
        ref: chartComponentRef,
        highcharts: Highcharts,
        options: chartOptions,
    };

    return (
        <div className="advanced-comparison-chart">
            <HighchartsReact {...chartProps} />
        </div>
    );
};

/**
 * @deprecated This uses context with the old charting endpoints. Now that we are starting to separate
 * these expensive endpoints into individual use-case-specific endpoints, we will create new components
 * for each use case that will use the shared `AdvancedComparisonChart` component.
 *
 * See `VendorMarketComparisonChart` for an example of how to use the `AdvancedComparsionChart` directly
 */
export const DeprecatedAdvancedComparisonChart: FC<{
    hideLegend?: boolean;
    breakdownLabel?: string;
    customCurrentPeriodLabel?: string;
}> = ({ breakdownLabel = 'location', hideLegend = false, customCurrentPeriodLabel }) => {
    const {
        chartSettings: settings,
        chartLocations: locations,
        chartProducts: products,
        cloudChartData: currentPeriodData,
        chartIsReady: currentPeriodIsReady,
        chartValueFormatter,
        chartBucketFactory,
    } = useChartContext();

    const {
        comparisonChartIsReady,
        showComparisonWindows: showComparisonPeriod,
        comparisonPeriodCloudChartData,
        comparisonDatesByCurrentDate,
        comparisonRange,
        currentPeriodLabel,
        periodBuckets,
    } = useComparisonChartContext();

    if (!currentPeriodIsReady || (showComparisonPeriod && !comparisonChartIsReady)) {
        return <Skeleton height={400} />;
    }

    if (settings.type === 'table') {
        return (
            <AdvancedTableChart
                showComparisonPeriod={showComparisonPeriod}
                cloudChartData={currentPeriodData as FullChartResponseBody}
                comparisonPeriodCloudChartData={
                    comparisonPeriodCloudChartData as FullChartResponseBody
                }
                chartSettings={settings}
                chartLocations={locations}
                chartProducts={products}
            />
        );
    }

    return (
        <AdvancedComparisonChart
            settings={settings}
            data={periodBuckets}
            comparisonDatesByCurrentDate={comparisonDatesByCurrentDate}
            currentPeriodLabel={customCurrentPeriodLabel ?? currentPeriodLabel}
            breakdownLabel={breakdownLabel}
            breakdownItemLabels={locations.map(({ label }) => label)}
            comparisonRange={comparisonRange}
            hideLegend={hideLegend}
            chartBucketFactory={chartBucketFactory}
            chartValueFormatter={chartValueFormatter}
        />
    );
};
