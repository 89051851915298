import { useCallback, useState } from 'react';

import { useSendSMSMessageByUserIdsMutation } from '@core/users';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import ParticipantsSelectorFormFields from '@components/forms/ParticipantsSelectorFormFields';
import SMSMessageFormFields from '@components/forms/SMSMessageFormFields';
import Modal from '@components/overlays/Modal';

import { withModal } from '@hooks/ModalHooks';

import { IPosLocation } from '@app/types/PosTypes';
import { IAccountUser } from '@app/types/UsersTypes';

const StageNames = ['Recipients', 'Message'];

export const StepperButtons = ({
    isBusy,
    onBack,
    onContinue,
    onSubmit,
    stage,
    totalStages,
}: {
    isBusy: boolean;
    onBack: () => void;
    onContinue: () => void;
    onSubmit: () => void;
    stage: number;
    totalStages: number;
}) => {
    return (
        <div className="stepper-button-container">
            {stage > 0 && (
                <Button color="neutral" variant="flat" onClick={onBack} disabled={isBusy}>
                    Back
                </Button>
            )}
            {stage === totalStages - 1 ? (
                <Form.Button color="blue" variant="smooth" onClick={onSubmit} disabled={isBusy}>
                    Send
                </Form.Button>
            ) : (
                <Button color="blue" variant="smooth" onClick={onContinue} disabled={isBusy}>
                    Continue
                </Button>
            )}
        </div>
    );
};

const ModalContent = withModal(
    ({
        stageValidationFn,
        updateValidationFn,
        currentStage,
        back,
        next,
        onClose,
    }: {
        stageValidationFn: () => boolean;
        updateValidationFn: (fn: () => boolean) => void;
        currentStage: number;
        back: () => void;
        next: () => void;
        onClose: () => void;
    }) => {
        const [selectedLocations, setSelectedLocations] = useState<IPosLocation[]>([]);
        const [selectedRecipients, setSelectedRecipients] = useState<IAccountUser[]>([]);
        const [message, setMessage] = useState('');

        const { sendSMSMessageByUserIds } = useSendSMSMessageByUserIdsMutation();

        const clearAndClose = useCallback(() => {
            setSelectedLocations([]);
            setSelectedRecipients([]);
            setMessage('');
            onClose();
        }, [onClose]);

        const onSend = useCallback(() => {
            if (stageValidationFn()) {
                sendSMSMessageByUserIds(
                    {
                        message,
                        userIds: selectedRecipients.map((user) => user.flexibleEmployeeId),
                    },
                    {
                        onSuccess: () => {
                            clearAndClose();
                        },
                    },
                );
            }
        }, [selectedRecipients, message, clearAndClose, stageValidationFn]);

        return (
            <Form>
                <Modal.Title onClose={() => clearAndClose()}>Send SMS Message</Modal.Title>

                <Modal.Stepper stageNames={StageNames} />

                <Modal.Content>
                    {currentStage === 0 && (
                        <ParticipantsSelectorFormFields
                            enrolled
                            setStageValidationFn={updateValidationFn}
                            selectedLocations={selectedLocations}
                            setSelectedLocations={setSelectedLocations as any}
                            selectedParticipants={selectedRecipients as any}
                            setSelectedParticipants={setSelectedRecipients as any}
                        />
                    )}
                    {currentStage === 1 && (
                        <SMSMessageFormFields message={message} setMessage={setMessage} />
                    )}
                </Modal.Content>

                <Modal.Actions>
                    <StepperButtons
                        {...{
                            isBusy: false,
                            onBack: back,
                            onContinue: next,
                            onSubmit: onSend,
                            stage: currentStage,
                            totalStages: StageNames.length,
                        }}
                    />
                </Modal.Actions>
            </Form>
        );
    },
);

const SendSMSModal = ({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) => {
    return (
        <Modal className="send-sms-modal wide" isVisible={isVisible} onClose={() => onClose()}>
            <ModalContent onClose={onClose} />
        </Modal>
    );
};

export default SendSMSModal;
