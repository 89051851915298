import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@mui/material';
import { capitalize, debounce } from 'lodash';

import {
    REQUEST_FOR_SPARK_STATUSES,
    RequestForSparkStatus,
    Spark,
    accountCanCreateSpark,
} from '@sparkplug/lib';

import { useGetPendingSparksCountQuery } from '@core/sparks/queries/GetPendingSparksQuery';
import { useGetTotalSparksCountQuery } from '@core/sparks/queries/GetTotalSparksQuery';

import { useGetRequestForSparksTotal } from '@features/request-for-spark';
import { RequestSparkButton } from '@features/request-for-spark/components/RequestSparkButton';
import RequestsForSparksTable from '@features/request-for-spark/components/RequestsForSparksTable';
import { SparkDashboardContent } from '@features/spark-dashboard/components/SparkDashboardContent';
import { SparkWizardOriginRoute } from '@features/spark-wizard/views/SparkWizardView/SparkWizardView';

import {
    useSparkManagementModal,
    wrapWithSparkManagementModal,
} from '@contexts/SparkManagementModalContext';
import { SparksProvider, useSparks } from '@contexts/SparksContext';

import { NoInboxSparksGraphic, NoSparksGraphic } from '@components/graphics';
import AppContentWithSidebar from '@components/layout/AppContentWithSidebar';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import PageHeader from '@components/layout/PageHeader';
import UpgradeModal from '@components/overlays/UpgradeModal';
import { queryToString, useLocation, useQueryParams } from '@components/router';
import CreateSparkButton from '@components/sparks/CreateSparkButton';
import {
    FromTemplateButton,
    StartFromBlankButton,
} from '@components/sparks/CreateSparkButton/CreateSparkButton';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp, useAppBodyClasses, withMobileView } from '@hooks/AppHooks';
import { useQueryClient } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import { applyFilterFns } from '@helpers/ui';

import { IBrandLink } from '@app/types/BrandLinksTypes';
import { IPosLocation } from '@app/types/PosTypes';
import { IOption, IOptionTree } from '@app/types/UITypes';

import {
    SPARK_REQUEST_STATE_OPTIONS,
    SPARK_SCHEDULE_OPTIONS,
    SPARK_TRAINING_OPTIONS,
    SPARK_TYPE_OPTIONS,
} from '../../SparksView.constants';
import SparkStatusButtons from '../../components/SparkStatusButtons';
import SparksViewMobile from '../SparksViewMobile';
import SparkPickerModal from './SparkPickerModal';

import './SparksView.scss';

interface ILocationOptions {
    optionTree: IOptionTree<{}>[];
    allLocations: IOption<{ key: string }>[];
}

const getLocationOptionsForBrands = (
    brandLinks: IBrandLink[] = [],
    includeDisabledLocations?: boolean,
): ILocationOptions => {
    const formatLocations = (locations: IPosLocation[]) =>
        locations.map((location) => ({
            label: location.displayName ?? location.name,
            value: location._id,
            key: location._id,
        }));

    let optionTree: IOptionTree<{}>[] = [];
    let allLocations: IOption<{ key: string }>[] = [];
    if (brandLinks.length) {
        optionTree = brandLinks
            .filter(({ locations }) => locations?.length)
            .map(({ label, retailerAccountId, locations = [], disabledLocations = [] }) => {
                const formattedLocations = formatLocations([
                    ...locations,
                    ...(includeDisabledLocations ? disabledLocations : []),
                ]);
                allLocations = allLocations.concat(formattedLocations);

                return {
                    label,
                    value: retailerAccountId,
                    children: formattedLocations,
                };
            });
    }

    return {
        optionTree,
        allLocations,
    };
};

const getLocationOptionsForRetailers = (
    accountLocations: IOption<IPosLocation>[] = [],
): ILocationOptions => {
    return {
        optionTree: accountLocations,
        allLocations: accountLocations.map((location) => ({ ...location, key: location._id })),
    };
};

const requestStatusOptions: IOption[] = REQUEST_FOR_SPARK_STATUSES.map((status) => ({
    label: `${capitalize(status)} Requests`,
    value: status,
}));

type SparksViewProps = {
    showPicker?: boolean;
    sparkPickerType?: 'leaderboard' | 'goal' | 'commission' | null;
    forceSparkApprovalModal?: {
        sparkId: string;
    };
};
export const SparksViewWithManagementModal = wrapWithSparkManagementModal(
    ({ showPicker = false, forceSparkApprovalModal }: SparksViewProps) => {
        const { history, userIsAdmin, user, isAdminApp, userIsSuperAdmin } = useApp();

        const { sparks, refetchSparks, sparkFilters, updateSparkFilters, applySparkFilters } =
            useSparks();
        const { openCreateModal, openApprovalModal } = useSparkManagementModal();
        const { state } = useLocation<{
            state: {
                originRoute: SparkWizardOriginRoute;
            };
        }>();

        const { account, connectEnabled, userCan } = useSparkplugAccount();
        const userCanCreateSpark = userCan('createSpark');
        const queryParams = useQueryParams();
        const searchRef = useRef<HTMLInputElement>(null);
        useEffect(() => {
            if (searchRef.current) {
                searchRef.current.focus();
            }
        }, [queryParams]);

        const { count: openRequestCount } = useGetRequestForSparksTotal({ account });
        const { totalSparksCount = 0 } = useGetTotalSparksCountQuery({
            account,
            isAdminApp,
        });

        useAppBodyClasses(['dashboard-app-content'], []);

        const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
            'name',
            'description',
            account?.type === 'brand' ? 'sparkRetailer.name' : 'sparkVendor.name',
            'sparkBrand.name',
            'sparkCreator.fullName',
        ]);

        const [showSparkPickerModal, setShowSparkPickerModal] = useState(showPicker);
        const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);

        const { count: pendingSparkCount = 0 } = useGetPendingSparksCountQuery({
            account,
            isAdminApp: userIsAdmin,
        });

        const locationOptions: ILocationOptions = useMemo(() => {
            // we want to be able to view "complete" sparks at all locations - even if that location has since been disabled
            if (sparkFilters.status === 'complete') {
                // this is ugly, but because we are now showing complete sparks for disabled locations, we need to include that location in the "Locations" filter drop down when on the complete tab.  But, because disabled=true on those locations, the option on the dropdown also becomes disabled - and cannot be altered in that state.  But, if we alter the "disabled" prop directly on the locations array, we alter it on the core object itself, effectively breaking any display of "disabled" for that location anywhere else.  Adding a custom prop to the object allows us to keep the option enabled without altering the actual "disabled" prop on the location object itself.
                const locationOptionTree =
                    account?.type === 'brand'
                        ? getLocationOptionsForBrands(account?.brandLinks, true)
                        : getLocationOptionsForRetailers(account?.allLocations);

                locationOptionTree.optionTree.forEach((locationOption: any) => {
                    if (locationOption.disabled) {
                        // eslint-disable-next-line no-param-reassign
                        locationOption.keepOptionEnabled = true;
                    }
                });
                return locationOptionTree;
            } else {
                return account?.type === 'brand'
                    ? getLocationOptionsForBrands(account?.brandLinks)
                    : getLocationOptionsForRetailers(account?.locations);
            }
        }, [account, sparkFilters.status]);

        const onClickCreateSparkButton = () => setShowSparkPickerModal(true);

        const onSparkCardSelected = useCallback(
            (spark: Pick<Spark, '_id' | 'requestState'>) => {
                if (spark?.requestState === 'pending' && account?.type !== 'brand') {
                    openApprovalModal(spark._id);
                } else {
                    history.push(`/${account?._id}/sparks/${spark._id}`, {
                        prevUrl: document.location.pathname,
                    });
                }
            },
            [account, openApprovalModal],
        );

        const queryClient = useQueryClient();
        useEffect(() => {
            refetchSparks();
            // Currently, query invalidations from SparkContext.saveSpark aren't working as expected.
            queryClient.invalidateQueries('requestForSpark');
        }, []);

        useEffect(() => {
            if (forceSparkApprovalModal?.sparkId) {
                openApprovalModal(forceSparkApprovalModal?.sparkId);
            }
        }, [forceSparkApprovalModal]);

        useEffect(() => {
            updateSparkFilters({ location: locationOptions?.allLocations });
        }, [locationOptions]);

        const filteredSparks = useMemo(
            () => applyFilterFns<Spark>([applySparkFilters, applySearch], sparks),
            [applySparkFilters, applySearch, sparks],
        );

        const updateRequestForSparkStatus = (requestStatus?: RequestForSparkStatus) => {
            const { status, ...rest } = queryParams;
            history.push(
                `?${queryToString({
                    ...rest,
                    ...(requestStatus && { status: requestStatus }),
                })}`,
            );
        };

        useEffect(() => {
            if (showPicker && user && account) {
                const accountHasSparkEntitlement = userIsSuperAdmin || accountCanCreateSpark;

                const canCreateSpark = accountCanCreateSpark(account);

                if (!canCreateSpark && !accountHasSparkEntitlement) {
                    setShowSparkPickerModal(false);
                    setUpgradeModalIsVisible(true);
                }
            }
        }, [showPicker, account, user]);

        const handleCloseSparkPickerModal = () => {
            setShowSparkPickerModal(false);
            history.goBack();
        };

        let emptyStateComponent: ReactElement | null = null;
        const isInboxOrSent = sparkFilters.status === 'inbox/sent';
        const showRequestsForSparks =
            sparkFilters.status === 'inbox/requests' &&
            account?.type === 'brand' &&
            process.env.REACT_APP_REQUEST_FOR_SPARK === 'true';

        if (isInboxOrSent && account?.type === 'retailer') {
            emptyStateComponent = (
                <EmptyStateDisplay graphic={<NoInboxSparksGraphic />} label="no new spark offers" />
            );
        } else if (showRequestsForSparks) {
            emptyStateComponent = (
                <EmptyStateDisplay
                    graphic={<NoInboxSparksGraphic />}
                    label="no new spark requests"
                />
            );
        } else if (totalSparksCount === 0) {
            emptyStateComponent = (
                <EmptyStateDisplay
                    layout="horizontal"
                    addPaperWrapper
                    label={
                        account?.type === 'brand'
                            ? 'Incentivize Retailers to sell your products!'
                            : 'Enhance Sales and Team Engagement with Sparks'
                    }
                    smallText={
                        account?.type === 'brand'
                            ? 'Create incentives to motivate employees, boost sales, and enhance product visibility. Monitor performance with detailed analytics.'
                            : 'Create Spark incentives to motivate employees with dynamic rewards and prizes, boost sales, and track performance.'
                    }
                    graphic={
                        <img src="/static/images/no-sparks-placeholder.jpg" alt="placeholder" />
                    }
                    actionButton={<CreateSparkButton onClick={onClickCreateSparkButton} />}
                />
            );
        } else {
            emptyStateComponent = (
                <EmptyStateDisplay
                    contentClassName="pb-4"
                    graphic={<NoSparksGraphic />}
                    label={
                        isInboxOrSent ? 'no sparks found' : `no ${sparkFilters.status} sparks found`
                    }
                    actionButton={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                                marginTop: '8px',
                                width: '335px',
                            }}
                        >
                            {userCanCreateSpark && (
                                <>
                                    <StartFromBlankButton className="w-full" />
                                    <FromTemplateButton className="w-full" />
                                </>
                            )}
                            <RequestSparkButton />
                        </Box>
                    }
                />
            );
        }

        return (
            <div className="sparks-view">
                <PageHeader
                    className="m-0"
                    title={`${account?.name || ''}`.trim()}
                    heading="Sparks"
                >
                    <Toolbar collapseOnMobile>
                        <Toolbar.Group className="toolbar-group-end">
                            <RequestSparkButton sx={{ marginRight: '1rem' }} />
                            <CreateSparkButton onClick={onClickCreateSparkButton} />
                        </Toolbar.Group>
                    </Toolbar>
                </PageHeader>

                <Toolbar className="spark-view__status-toolbar" scrollOnMobile>
                    <SparkStatusButtons
                        value={sparkFilters.status}
                        onSelect={({ value, to }) => {
                            history.push(`/${account?._id}${to}`);
                            updateSparkFilters({ status: value });
                        }}
                        isBrand={account?.type === 'brand'}
                        inboxCount={pendingSparkCount}
                        requestCount={openRequestCount}
                        isAdmin={userIsAdmin}
                        connectEnabled={connectEnabled}
                    />
                </Toolbar>

                <AppContentWithSidebar
                    sidebar={
                        <SparkStatusButtons
                            value={sparkFilters.status}
                            onSelect={({ value, to }) => {
                                history.push(`/${account?._id}${to}`);
                                updateSparkFilters({ status: value });
                            }}
                            isBrand={account?.type === 'brand'}
                            inboxCount={pendingSparkCount}
                            requestCount={openRequestCount}
                            isAdmin={userIsAdmin}
                            connectEnabled={connectEnabled}
                        />
                    }
                    content={
                        <div className="flex flex-col">
                            {showRequestsForSparks ? (
                                <Toolbar className="spark-view__filter-toolbar" scrollOnMobile>
                                    <Toolbar.Search
                                        name="sparks-search"
                                        defaultValue={queryParams.search || ''}
                                        inputRef={searchRef}
                                        onChange={debounce(({ target }) => {
                                            const { search, ...rest } = queryParams;
                                            history.push(
                                                `?${queryToString({
                                                    ...rest,
                                                    ...(target.value && { search: target.value }),
                                                })}`,
                                            );
                                        }, 250)}
                                    />
                                    <Toolbar.Dropdown
                                        label={null}
                                        titlePlaceholder="All Requests"
                                        value={queryParams.status || undefined}
                                        onSelect={(status) => {
                                            updateRequestForSparkStatus(status);
                                        }}
                                        clear={{
                                            active: !!queryParams.status,
                                            onClear: () => {
                                                updateRequestForSparkStatus(undefined);
                                            },
                                        }}
                                        options={requestStatusOptions}
                                    />
                                </Toolbar>
                            ) : (
                                <>
                                    {totalSparksCount > 0 && (
                                        <Toolbar
                                            className="spark-view__filter-toolbar"
                                            scrollOnMobile
                                        >
                                            <Toolbar.Search
                                                name="sparks-search"
                                                defaultValue={searchFilter}
                                                onChange={onChangeSearchFilter}
                                            />

                                            {sparkFilters.status === 'inbox/sent' && (
                                                <Toolbar.Dropdown
                                                    label={null}
                                                    titlePlaceholder="Spark Status"
                                                    options={SPARK_REQUEST_STATE_OPTIONS}
                                                    value={sparkFilters.requestState}
                                                    onSelect={(value) =>
                                                        updateSparkFilters({ requestState: value })
                                                    }
                                                    clear={{
                                                        active: !!sparkFilters.requestState,
                                                        onClear: () =>
                                                            updateSparkFilters({
                                                                requestState: undefined,
                                                            }),
                                                    }}
                                                />
                                            )}

                                            <Toolbar.DropdownListSelector
                                                label={
                                                    account?.type === 'brand'
                                                        ? 'Retailer Locations'
                                                        : 'Locations'
                                                }
                                                selected={
                                                    sparkFilters.location ||
                                                    locationOptions.allLocations
                                                }
                                                options={locationOptions.optionTree}
                                                onSelectionChanged={(v) => {
                                                    updateSparkFilters({ location: v });
                                                }}
                                                clear={{
                                                    active:
                                                        (sparkFilters.location || []).length !==
                                                        locationOptions.allLocations.length,

                                                    onClear: () => {
                                                        updateSparkFilters({
                                                            location: locationOptions.allLocations,
                                                        });
                                                    },
                                                }}
                                            />

                                            <Toolbar.MultiSelectDropdown
                                                label="Types"
                                                options={SPARK_TYPE_OPTIONS}
                                                selected={sparkFilters.type}
                                                onApply={(value) =>
                                                    updateSparkFilters({ type: value })
                                                }
                                            />

                                            <Toolbar.MultiSelectDropdown
                                                label="Schedules"
                                                options={SPARK_SCHEDULE_OPTIONS}
                                                selected={sparkFilters.schedule}
                                                onApply={(value) =>
                                                    updateSparkFilters({ schedule: value })
                                                }
                                            />

                                            {(account?.zoltrainEnabled ||
                                                account?.seedTalentEnabled) && (
                                                <Toolbar.MultiSelectDropdown
                                                    label="Training"
                                                    options={SPARK_TRAINING_OPTIONS}
                                                    selected={sparkFilters.training}
                                                    onApply={(value) =>
                                                        updateSparkFilters({ training: value })
                                                    }
                                                />
                                            )}

                                            <div className="column-widget-placeholder" />
                                            <div className="export-to-csv-placeholder" />
                                        </Toolbar>
                                    )}
                                </>
                            )}
                            {showRequestsForSparks ? (
                                <RequestsForSparksTable account={account} />
                            ) : (
                                <SparkDashboardContent
                                    sparkFilters={sparkFilters}
                                    emptyStateComponent={emptyStateComponent}
                                    sparks={filteredSparks}
                                    onSparkSelected={onSparkCardSelected}
                                />
                            )}
                        </div>
                    }
                />

                <SparkPickerModal
                    isVisible={showSparkPickerModal}
                    onClose={handleCloseSparkPickerModal}
                    onNewSpark={(selected) => {
                        setShowSparkPickerModal(false);
                        openCreateModal(selected, state?.state?.originRoute || 'spark-dashboard');
                    }}
                />

                <UpgradeModal
                    isVisible={upgradeModalIsVisible}
                    onClose={() => setUpgradeModalIsVisible(false)}
                />
            </div>
        );
    },
);
export const SparksView = (sparksViewProps: SparksViewProps) => {
    return (
        <SparksProvider>
            <SparksViewWithManagementModal {...sparksViewProps} />
        </SparksProvider>
    );
};
export default withMobileView(SparksView, SparksViewMobile, ({ userIsAdmin }) => {
    return !userIsAdmin;
});
