import SparkCheckoutView from '@views/sparks/SparkCheckoutView';
import { useSparkLeaderboardTableData } from '@views/sparks/SparkManageView/useSparkLeaderboardTableData';

import { SparkWizardAccountProvider } from '@features/spark-wizard/views/SparkWizardView/SparkWizardAccountProvider';

import Button from '@components/buttons/Button';
import { OpenInNew } from '@components/icons';

import { useSpark, useSparkChartData } from '@hooks/SparksHooks';

const SparkApprovalModal = ({
    onClose,
    refetchRecentCCSparks,
}: {
    onClose: () => void;
    refetchRecentCCSparks: () => void;
}) => {
    const { isLoading: isLoadingTableData, headCells, rows } = useSparkLeaderboardTableData();
    const { spark } = useSpark();
    const { sparkStandings: locationLeaderboardStandings } = useSparkChartData({
        spark,
        isEnabled: spark.detailedSparkType === 'leaderboardLocation',
    });

    const finalizeCallback = () => {
        if (spark.detailedSparkType !== 'leaderboardMulti') {
            refetchRecentCCSparks();
            onClose();
        }
    };
    const environment = process.env.NODE_ENV;
    const rootPath = window.location.origin;

    return (
        <SparkWizardAccountProvider accountId={spark.originatorGroupId || spark.groupId || ''}>
            <div>
                <div className="direct-checkout-header">
                    <div className="direct-checkout-name">
                        <div>{spark.name}</div>
                        <div className="direct-checkout-description">{spark.description}</div>
                    </div>
                    <div>
                        {spark.internalTracking?.invoiceId && (
                            <a
                                href={`https://dashboard.stripe.com/${
                                    environment !== 'production' ? 'test/' : ''
                                }invoices/${spark.internalTracking?.invoiceId}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button variant="smooth" endIcon={<OpenInNew />}>
                                    View Invoice in Stripe
                                </Button>
                            </a>
                        )}

                        <a
                            href={`${rootPath}/${
                                spark?.originatorGroupId || spark?.groupId
                            }/sparks/${spark?._id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button variant="smooth" endIcon={<OpenInNew />}>
                                Open Spark in new tab
                            </Button>
                        </a>
                        <Button onClick={onClose} variant="filled">
                            Close
                        </Button>
                    </div>
                </div>
                <SparkCheckoutView
                    isLoadingTableData={isLoadingTableData}
                    tableHeadCells={headCells}
                    tableRows={rows}
                    locationLeaders={locationLeaderboardStandings}
                    finalizeCallback={finalizeCallback}
                />
            </div>
        </SparkWizardAccountProvider>
    );
};

export default SparkApprovalModal;
