import { FC } from 'react';
import Linkify from 'react-linkify';

import './NonCashRewardClaimInstructions.scss';

interface NonCashRewardClaimInstructionsProps {
    claimInstructions?: string;
}

const NonCashRewardClaimInstructions: FC<NonCashRewardClaimInstructionsProps> = ({
    claimInstructions,
}) => {
    return (
        <div className="claim-instructions-container">
            <div className="claim-instructions-header">Claim Instructions:</div>
            <div className="claim-instructions">
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    {claimInstructions && claimInstructions.length
                        ? claimInstructions
                        : 'Talk to manager'}
                </Linkify>
            </div>
        </div>
    );
};

export default NonCashRewardClaimInstructions;
