import PointOfSaleAPI from '@api/PointOfSaleAPI';
import SparkplugAPI from '@api/SparkplugAPI';
import SparksAPI from '@api/SparksAPI';
import {
    CommissionTypeOptions,
    DayNameFromNumber,
    ESparkStatusColors,
    SparkRequestStatuses,
} from '@constants/SparkConstants';
import { groupBy, sumBy, uniqBy } from 'lodash';
import moment, { Moment } from 'moment-timezone';

import {
    ControlCenterSpark,
    DetailedSparkType,
    EmployeeSpark,
    ITrainingCourse,
    ListSparksQueryParams,
    PosProduct,
    PublicSparkPosArchive,
    RecurringSparkSchedule,
    RecurringSparkScheduleOption,
    Spark,
    SparkArchiveParticipant,
    SparkArchiveProducts,
    SparkCommission,
    SparkDeclineResponse,
    SparkParticipantGroup,
    SparkRequestState,
    SparkType,
    UpsertManySparkRewardsRequestBody,
    formatCurrency as formatNumberToCurrency,
} from '@sparkplug/lib';

import { keyAccountUsersByPosEmployeeProfileIds } from '@core/users';

import { SparkTemplate } from '@features/spark-dashboard/types';

import { fetchBatchedData } from '@helpers/api';

import { IAccount } from '@app/types/AccountsTypes';
import { IBrandLink } from '@app/types/BrandLinksTypes';
import { IPosBrand, IPosCategory, IPosLocation, IPosProduct } from '@app/types/PosTypes';
import {
    ISparkCommissionMap,
    ISparkPayout,
    ISparkPosData,
    ISparkSubGroup,
    SparkParticipant,
    TSparkStatus,
    TSparkStatusColor,
} from '@app/types/SparksTypes';
import { IAccountUser, IAuthUser } from '@app/types/UsersTypes';

import { currencyFormatterFactory } from '../chartFormatters';
import { claimReward, deleteReward, getRewards } from '../rewards';
// helpers
import { buildObjsFromArray, mapListToSelectOptions, sortByString } from '../ui';
import { isEmpty } from '../util';

export const isPercentIncreaseSpark = (
    spark?: Spark,
): spark is Spark & { percentIncreaseData: NonNullable<Spark['percentIncreaseData']> } => {
    return !!spark && spark.metric === 'percent_increase';
};

export const userCanCloneSpark = (user: IAuthUser, account: IAccount, spark: Spark) => {
    return (
        ['super-admin', 'brand-admin'].includes(user.role) ||
        (user.role === 'retailer-admin' &&
            !spark.originatorGroupId &&
            account.metaData?.subscriptionType === 'paid')
    );
};

export const getSparkPaymentFromPayouts = (
    sparkPayouts: ISparkPayout[],
): { paymentLabel: string; paymentValue: number } => {
    const totalPayments = sparkPayouts.reduce((result, payout) => {
        return result + (payout?.amount || 0);
    }, 0);

    return {
        paymentLabel: formatNumberToCurrency(totalPayments, true),
        paymentValue: totalPayments,
    };
};

export const isSparkExternallyCreated = (spark: Spark): boolean => {
    return Boolean(spark?.originatorGroupId && spark?.originatorGroupId !== spark?.groupId);
};

export const getMinThresholdLabel = (spark: Spark): string | null => {
    const minThreshold = spark?.minimumThresholdToQualify ?? 0;

    if (spark?.type === 'goal' || !minThreshold) {
        return null;
    }

    if (spark?.type === 'commission') {
        return currencyFormatterFactory(2)(minThreshold);
    }

    // The spark.type must be 'leaderboard' here
    if (isPercentIncreaseSpark(spark)) {
        return `${minThreshold}% increase`;
    }

    switch (spark.metric) {
        case 'total_sales':
            return currencyFormatterFactory(2)(minThreshold);
        case 'order_average':
            return `Order average of ${currencyFormatterFactory(2)(minThreshold)}`;
        case 'transaction_count':
            return `${minThreshold} transactions`;
        case 'units_per_transaction':
            return `${minThreshold} units per transaction`;
        case 'percent_of_total_sales':
            return `${minThreshold} percent of total sales`;
        case 'total_units':
        default:
            return `${minThreshold} units`;
    }
};

export function formatSparkCommissionItemValue(commissionItem: SparkCommission) {
    const prefix = commissionItem.type === 'flat' ? '$' : '';
    const suffix = commissionItem.type === 'percentage' ? '%' : '';
    const value = commissionItem.value.toFixed(2);

    return `${prefix}${value}${suffix}`;
}

export const fetchSparks = async (
    accountId?: string,
    tag?: string,
    originatorGroupId?: string,
    lean?: boolean,
    orderBy?: string,
    order?: string,
    filters?: ListSparksQueryParams,
): Promise<{
    data: Spark[];
}> => {
    const fetchFn = (offset: number, limit: number) => {
        return SparksAPI.getSparks({
            group_id: accountId,
            tag,
            originator_group_id: originatorGroupId,
            offset,
            limit,
            lean,
            orderBy,
            order,
            status: filters?.status,
            userTimezone: filters?.userTimezone,
        });
    };

    return fetchBatchedData(fetchFn);
};

/**
 * Compress multi-leaderboard sparks, which are linked by tag. Return all other sparks untouched.
 */
export const dedupeSparksByTag = <T extends Spark | ControlCenterSpark>(
    sparks: (T & { groupedSparks?: T[] })[] = [],
): T[] => {
    const sparksByTag = groupBy(sparks, 'tag');
    return Object.entries(sparksByTag).flatMap(([tag, rawSparks = []]) => {
        const isNonMLSpark = ['undefined', 'null'].includes(tag);
        if (isNonMLSpark) {
            return rawSparks;
        }

        const compressedMLSpark = {
            ...rawSparks[0],
            groupedSparks: rawSparks,
            participantCount: sumBy(rawSparks, 'participantCount'),
            payoutAmount: sumBy(rawSparks, 'payoutAmount'),
            unitsSold: sumBy(rawSparks, 'unitsSold'),
        };
        return compressedMLSpark;
    });
};
export const getSparks = async (
    retailerAccountId?: string,
    originatorGroupId?: string,
    filters?: ListSparksQueryParams,
): Promise<Array<Spark & { groupedSparks?: Spark[] }>> => {
    const userTimezone = moment.tz.guess();
    const allSparks = (
        await fetchSparks(
            retailerAccountId,
            undefined,
            originatorGroupId,
            true,
            'createdAt',
            'desc',
            {
                ...filters,
                userTimezone,
            },
        )
    ).data;
    // TODO: remove dedupeSparksByTag once the backend is updated to return deduped sparks
    return dedupeSparksByTag<Spark>(allSparks);
};

// Can pass one requestState or an Array of requestStates
export const filterSparksByRequestState = (
    sparks: Spark[] = [],
    requestStates: SparkRequestState | SparkRequestState[],
): Spark[] => {
    const comparer = Array.isArray(requestStates) ? requestStates : [requestStates];
    const matchingSparks =
        sparks.filter((spark) => spark?.requestState && comparer.includes(spark.requestState)) ||
        [];
    return matchingSparks;
};

export const areSparkActionsDisabled = (spark: Spark, user?: IAuthUser): boolean => {
    if (user === null) {
        return true;
    }
    const isBrandCreatedSpark =
        spark?.originatorGroupId != null && spark?.originatorGroupId !== spark?.groupId;
    return user?.role !== 'super-admin' && isBrandCreatedSpark;
};

export const getSparksByTag = async (accountId: string, tag: string): Promise<Spark[]> => {
    const sparksResponse = await fetchSparks(accountId, tag);
    return sparksResponse.data;
};

export const getDetailedLeaderboardType = (spark: Spark | SparkTemplate): DetailedSparkType => {
    if (spark.detailedSparkType) {
        return spark.detailedSparkType;
    } else if (spark.tag == null || (spark?.tag || '').length === 0) {
        return 'leaderboard';
    }
    return 'leaderboardMulti';
};

export const getDetailedSparkType = (
    spark: Spark | SparkTemplate,
): DetailedSparkType | undefined => {
    if (spark.detailedSparkType) {
        return spark.detailedSparkType;
    }

    switch (spark.type) {
        case 'leaderboard':
            return getDetailedLeaderboardType(spark);
        case 'goal':
            return spark.goalType === 'team' ? 'goalTeam' : 'goal';
        case 'commission':
            // eslint-disable-next-line no-case-declarations
            const commissionType = (
                spark?.commissions != null && spark?.commissions.length > 0
                    ? CommissionTypeOptions.find((option) => {
                          return option.value === spark?.commissions?.[0]?.type;
                      }) || CommissionTypeOptions[0]
                    : CommissionTypeOptions[0]
            ).value;

            return commissionType === 'percentage' ? 'commissionPercentage' : 'commissionFlat';
        default:
            return undefined;
    }
};

/**
 * @deprecated
 *
 * since brandLinks is deprecated so nothing is returned if the account is a retailer
 *
 */
export const getSparkGroupLink = ({
    spark,
    account,
}: {
    spark: Pick<Spark, 'originatorGroupId' | 'groupId'>;
    account?: IAccount;
}): IBrandLink | undefined => {
    if (!account || !account?.brandLinks?.length) {
        return undefined;
    }

    let otherGroup;
    if (account?.type === 'brand') {
        otherGroup = account?.brandLinks.find(
            (brandLink) => brandLink?.retailerAccountId === spark.groupId,
        );
    } else if (spark?.originatorGroupId) {
        // retailer accounts
        otherGroup = account?.brandLinks.find(
            (brandLink) => brandLink?.vendorAccountId === spark?.originatorGroupId,
        );
    }

    return otherGroup;
};

export const getOtherGroupId = ({ spark, account }: { spark: Spark; account?: IAccount }) => {
    const isInternalSpark = !spark?.originatorGroupId;
    if (!account || isInternalSpark) {
        return undefined;
    }

    const sparkGroupLink = getSparkGroupLink({ spark, account });
    return account.type === 'brand'
        ? sparkGroupLink?.retailerAccountId
        : sparkGroupLink?.vendorAccountId;
};

export const getOtherGroupName = (
    spark: Pick<Spark, 'sparkBrand' | 'originatorGroupId' | 'groupId'>,
    account?: IAccount,
): string => {
    if (account?.type === 'retailer' && spark.sparkBrand?.name) {
        return spark.sparkBrand?.name ?? '';
    }

    return getSparkGroupLink({ spark, account })?.label ?? '';
};

const SparkTypeDisplayNames: Record<SparkType, string> = {
    leaderboard: 'Leaderboard',
    goal: 'Goal',
    commission: 'Commission',
};
export const getSparkTypeDisplayName = (sparkType: SparkType) =>
    SparkTypeDisplayNames[sparkType] ?? '';

const DetailedSparkTypeDisplayNames: Record<DetailedSparkType, string> = {
    leaderboard: 'Single Leaderboard',
    leaderboardMulti: 'Multi-Leaderboard',
    leaderboardLocation: 'Location Leaderboard',
    goal: 'Goal',
    goalTeam: 'Team Goal',
    goalManager: 'Manager Goal',
    commissionFlat: 'Flat Commission',
    commissionPercentage: 'Percentage Commission',
};
export const getDetailedSparkTypeDisplayName = (detailedSparkType: DetailedSparkType) =>
    DetailedSparkTypeDisplayNames[detailedSparkType] ?? '';

const getFirstCycleByRecurringSchedule = (
    recurringSchedule?: RecurringSparkSchedule,
): {
    startDate?: Moment;
    endDate?: Moment;
} => {
    if (recurringSchedule) {
        const startDate = moment(recurringSchedule.startDate);
        const endDate = moment(recurringSchedule.startDate).clone();

        if (recurringSchedule.interval === 'weekly') {
            endDate.add(6, 'days');
        }

        if (recurringSchedule.interval === 'twice_monthly') {
            endDate.add(13, 'days');
        }

        if (recurringSchedule.interval === 'monthly') {
            endDate.add(1, 'month').subtract(1, 'day');
        }

        return {
            startDate,
            endDate,
        };
    }

    return { startDate: undefined, endDate: undefined };
};

const RecurringSparkScheduleLabels: Record<RecurringSparkScheduleOption, string> = {
    daily: 'Recurs Daily',
    weekly: 'Recurs Weekly',
    twice_monthly: 'Recurs Twice-monthly',
    monthly: 'Recurs Monthly',
};

type TFormatSparkInfoFn = (
    startDate: string,
    endDate: string,
    requestState?: SparkRequestState,
    recurringSchedule?: RecurringSparkSchedule,
) => {
    formattedStartDate: string;
    formattedEndDate: string;
    range: string;
    progress: number;
    status: TSparkStatus;
    color: TSparkStatusColor;
    currentDays: number;
    totalDays: number;
    remainingDays: number | null;
    daysUntilStart: number | null;
    schedule: string;
};
export const formatSparkInfo: TFormatSparkInfoFn = (
    startDate,
    endDate,
    requestState,
    recurringSchedule,
) => {
    // TODO pull functionality into `SparkContext`?
    const isRecurringSpark = !!recurringSchedule;
    const { startDate: firstCycleStartDate, endDate: firstCycleEndDate } =
        getFirstCycleByRecurringSchedule(recurringSchedule);
    const now = moment();

    const start = (
        isRecurringSpark && !startDate ? firstCycleStartDate! : moment(startDate)
    ).startOf('day');
    const formattedStartDate = start.format('MMMM D, YYYY');

    const end = (isRecurringSpark && !endDate ? firstCycleEndDate! : moment(endDate)).endOf('day');
    const formattedEndDate = end.format('MMMM D, YYYY');

    const totalDays = Math.ceil(end.diff(start, 'days', true));

    let currentDays = Math.max(now.diff(start, 'days'), 0);
    let remainingDays: number | null = null;
    let daysUntilStart: number | null = null;
    let progress = 0;
    let status: TSparkStatus = 'Upcoming';

    if (!!requestState && ['rejected', 'expired', 'pending'].includes(requestState)) {
        // progress remains 0
        currentDays = 0;
        status = SparkRequestStatuses[requestState] as TSparkStatus;
    } else if (now.isAfter(end)) {
        currentDays = totalDays;
        progress = 100;
        status = 'Complete';
    } else if (now.isAfter(start)) {
        remainingDays = end.diff(moment(now).startOf('day'), 'days');
        progress = Math.floor((currentDays / totalDays) * 100);
        status = 'Active';
    } else if (now.isBefore(start)) {
        daysUntilStart = start.diff(moment(now).startOf('day'), 'days');
    }

    let schedule = 'One-time';

    if (recurringSchedule?.interval) {
        if (recurringSchedule.interval === 'daily') {
            const daysString = recurringSchedule?.daysOfTheWeek?.reduce(
                (newDaysString, dayOfTheWeek) => {
                    const dayOfWeekShort = DayNameFromNumber[dayOfTheWeek].short;

                    if (newDaysString.length > 0) {
                        return `${newDaysString}, ${dayOfWeekShort}`;
                    }

                    return dayOfWeekShort;
                },
                '',
            );
            schedule = `${
                RecurringSparkScheduleLabels[recurringSchedule.interval]
            } on ${daysString}`;
        } else {
            schedule = RecurringSparkScheduleLabels[recurringSchedule.interval];
        }
    }

    return {
        formattedStartDate,
        formattedEndDate,
        range: `${start.format('M/D/YY')} - ${end.format('M/D/YY')}`,
        progress,
        status,
        color: ESparkStatusColors[status],
        currentDays,
        totalDays,
        remainingDays,
        daysUntilStart,
        schedule,
    };
};

export const sparkIsCompleted = (spark: Spark | EmployeeSpark): boolean => {
    const { startDate, endDate, requestState } = spark;

    const { status } = formatSparkInfo(startDate, endDate, requestState);

    return status === 'Complete';
};

export const fetchSpark = (sparkId: string) => {
    return SparksAPI.getSpark({ sparkId });
};

export const getSpark = async (sparkId: string): Promise<Spark | undefined> => {
    const { data } = await fetchSpark(sparkId);
    if (data) {
        return data;
    }
    throw new Error(`Failed to fetch spark with id ${sparkId}`);
};

export const generateSparkPosProductData = ({
    spark,
    accountPosBrands,
    accountPosCategories,
    accountPosProducts,
}: {
    spark?: Spark;
    accountPosBrands: IPosBrand[];
    accountPosCategories: IPosCategory[];
    accountPosProducts: IPosProduct[];
}) => {
    if (!spark) {
        return {
            brands: [],
            categories: [],
            products: [],
        };
    }

    // check if spark has a filtered scope (by brands, or categories, or products)
    let listOfBrands: IPosBrand[] = [];
    let listOfCategories: IPosCategory[] = [];
    let associatedProducts: IPosProduct[] = [];

    let gotProductsByIds = false;
    let gotProductsByBrands = false;
    let gotProductsByCategories = false;

    const isManualCommissionSpark =
        spark?.type === 'commission' &&
        isEmpty(spark.vendorFilters) &&
        isEmpty(spark.retailerFilters);

    const posProductIds = isManualCommissionSpark
        ? spark.commissions.map(({ posProductId }) => posProductId)
        : spark.posProductIds;

    const hasUniqueProductIds = posProductIds && posProductIds.length > 0;
    const isRulesBasedSpark = !isEmpty(spark.retailerFilters) || !isEmpty(spark.vendorFilters);

    const posCategoryIds: string[] = hasUniqueProductIds
        ? spark?.productFilters?.categoryIds || []
        : spark.posCategoryIds;
    const posBrandIds: string[] = hasUniqueProductIds
        ? spark?.productFilters?.brandIds || []
        : spark.posBrandIds;

    // Get correct associatedProducts based on spark data
    if ((posProductIds != null && posProductIds.length > 0) || isRulesBasedSpark) {
        gotProductsByIds = true;

        const posProducts = accountPosProducts.filter(({ _id }) => posProductIds.includes(_id));
        // for vendor filter-based sparks, we only display products sold in the last 60 days unless
        // it is a commission spark that is set explicitly to `allTime` products
        associatedProducts =
            spark?.vendorFilters?.lastSoldAt !== 'allTime' &&
            spark?.retailerFilters?.lastSoldAt !== 'allTime'
                ? posProducts.filter(({ lastSoldAt }) =>
                      moment(lastSoldAt)
                          .endOf('day')
                          .isSameOrAfter(
                              // if spark is in the future, use today as the filter for lastSoldAt
                              // otherwise, use the spark's start date
                              (moment(spark.startDate)
                                  .utc()
                                  .endOf('day')
                                  .isSameOrBefore(moment().utc().endOf('day'))
                                  ? moment(spark.startDate).utc().endOf('day')
                                  : moment().utc().endOf('day')
                              ).subtract(60, 'days'),
                              'day',
                          ),
                  )
                : posProducts;
    } else if (posBrandIds != null && posBrandIds.length > 0) {
        // If product list is not unique, fetch associatedProduct list by brand(s)
        if (!gotProductsByIds) {
            gotProductsByBrands = true;

            associatedProducts = accountPosProducts.filter(({ brands }) => {
                return (brands || []).some(({ _id: brandId }) => posBrandIds.includes(brandId));
            });
        }
    } else if (posCategoryIds != null && posCategoryIds.length > 0) {
        // If product list is not unique AND was not fetched by brands,
        // fetch associatedProduct list by category(s)
        if (!gotProductsByIds && !gotProductsByBrands) {
            gotProductsByCategories = true;

            associatedProducts = accountPosProducts.filter(({ categories }) => {
                return (categories || []).some(({ _id: categoryId }) =>
                    posCategoryIds.includes(categoryId),
                );
            });
        } else if (gotProductsByBrands) {
            // If product list was fetched by brand(s), filter by category(s)
            associatedProducts = associatedProducts.filter((product) => {
                return product?.categories?.some(({ _id }) => spark.posCategoryIds.includes(_id));
            });
        }
    }

    /**
     * Re-initialize brands/categories, and re-filter based on associated products.
     * This way, only relevant brands/categories are showed in the spark details
     */
    const flattenNestedIds = (
        key: 'brands' | 'categories',
        productsToFlatten: IPosProduct[],
    ): string[] =>
        Array.from(
            productsToFlatten
                .filter((products) => products[key] !== undefined)
                .flatMap((products) => products[key]?.map(({ _id }) => _id))
                .filter((productId): productId is string => !!productId)
                .reduce((acc, cur) => acc.set(cur, true), new Map<string, boolean>())
                .keys(),
        );

    const refilteredPosBrandIds = flattenNestedIds('brands', associatedProducts);
    const refilteredPosCategoryIds = flattenNestedIds('categories', associatedProducts);

    listOfCategories =
        refilteredPosCategoryIds.length > 0
            ? accountPosCategories.filter(({ _id }) => refilteredPosCategoryIds.includes(_id))
            : [];
    listOfBrands =
        refilteredPosBrandIds.length > 0
            ? accountPosBrands.filter(({ _id }) => refilteredPosBrandIds.includes(_id))
            : [];

    if (spark.groupId) {
        if (!gotProductsByIds && !gotProductsByBrands && !gotProductsByCategories) {
            associatedProducts = accountPosProducts;
        }
    }

    const sortedProducts = mapListToSelectOptions(associatedProducts).sort((a, b) => {
        return a.label.localeCompare(b.label);
    });

    return {
        brands: mapListToSelectOptions(listOfBrands).sort((a, b) => {
            return a.label.localeCompare(b.label);
        }),
        categories: mapListToSelectOptions(listOfCategories).sort((a, b) => {
            return a.label.localeCompare(b.label);
        }),
        products: sortedProducts,
    };
};

export const generateSparkPosData = (
    spark: Spark,
    accountAllPosLocations: IPosLocation[] = [],
    accountUsers: IAccountUser[] = [],
    accountPosBrands: IPosBrand[] = [],
    accountPosCategories: IPosCategory[] = [],
    accountPosProducts: IPosProduct[] = [],
): ISparkPosData => {
    const detailedSparkType = getDetailedSparkType(spark);
    // check if spark has a filtered scope (by brands, or categories, or products)
    let locations: IPosLocation[] = [];
    let participants: SparkParticipant[] = [];

    if (spark.groupId) {
        locations = accountAllPosLocations.filter((obj) => {
            return spark.locationIds.includes(obj._id);
        });

        participants = accountUsers
            .filter(({ posEmployeeProfileIds }) => !!posEmployeeProfileIds?.length)
            .filter(({ groupRole, posEmployeeProfileIds = [], locationIds = [] }) => {
                const userIsActive = groupRole !== 'none';
                const userIsSparkParticipant = (spark.posEmployeeProfileIds ?? []).some((posEpId) =>
                    posEmployeeProfileIds.includes(posEpId),
                );

                const userBelongsToSparkLocation = locationIds.some((locationId) =>
                    spark.locationIds.includes(locationId),
                );

                return (
                    userIsSparkParticipant ||
                    // If pending brand spark request
                    (spark.requestState === 'pending' &&
                        detailedSparkType !== 'goalManager' &&
                        userIsActive &&
                        userBelongsToSparkLocation) ||
                    // If 'goalTeam' add posEmployeeProfile if they are in team
                    (detailedSparkType === 'goalTeam' &&
                        !spark.teamType &&
                        userIsActive &&
                        userBelongsToSparkLocation)
                );
            })
            .map((user) => ({
                ...user,
                value: user.flexibleEmployeeId,
                label: user.fullName,
            }));
    }

    const sparkPosProductData = generateSparkPosProductData({
        spark,
        accountPosBrands,
        accountPosCategories,
        accountPosProducts,
    });

    return {
        locations,
        participants,
        associatedProducts: sparkPosProductData.products,
        ...sparkPosProductData,
    };
};

export const generateSparkCommissionMap = (
    spark: Spark,
    accountPosProducts: PosProduct[],
): ISparkCommissionMap | undefined => {
    if (spark.type === 'commission') {
        const commissionMap: ISparkCommissionMap = new Map();
        const posProductMap = new Map();

        const lookupMap = new Map();
        spark.commissions.forEach((commission) => {
            lookupMap.set(commission.posProductId, true);
        });
        accountPosProducts.forEach((posProduct) => {
            if (lookupMap.get(posProduct._id) != null) {
                posProductMap.set(posProduct._id, posProduct);
            }
        });

        spark.commissions.forEach((commission) => {
            const posProduct = posProductMap.get(commission.posProductId);
            if (posProduct != null) {
                commissionMap.set(posProduct._id, {
                    ...commission,
                    enabled: true,
                    name: posProduct.name,
                });
            } else {
                // eslint-disable-next-line no-console
                console.error(`Unable to find product for ${commission.posProductId}`);
            }
        });
        return commissionMap;
    }

    return undefined;
};

export const generateSparkCommissionMapForArchivedSpark = (
    spark: Spark,
    archivedProductObj: SparkArchiveProducts,
): ISparkCommissionMap | undefined => {
    if (spark.type === 'commission') {
        const commissionMap: ISparkCommissionMap = new Map();

        const lookupMap = new Map();
        spark.commissions.forEach((commission) => {
            lookupMap.set(commission.posProductId, true);
        });

        spark.commissions.forEach((commission) => {
            const posProduct = archivedProductObj[commission.posProductId];
            if (posProduct != null) {
                commissionMap.set(commission.posProductId, {
                    ...commission,
                    enabled: true,
                    name: posProduct.name,
                });
            } else {
                // eslint-disable-next-line no-console
                console.error(`Unable to find product for ${commission.posProductId}`);
            }
        });
        return commissionMap;
    }

    return undefined;
};

/**
 * Because sparks only use 'posBrandIds', 'posCategoryIds', or 'posProductIds'
 * to filter which products the incentives are for, we have to find which property
 * is used for a spark to correctly assign the right posData to filter the calculators.
 */
export const generateSparkCalculatorFilters = (spark: Spark, sparkPosData: ISparkPosData) => {
    const filterTypeMap = {
        posBrandIds: 'brands',
        posCategoryIds: 'categories',
        posProductIds: 'products',
    } as const;

    const filters: any = {};

    // Check the spark uses filtering
    const sparkProperty = Object.keys(filterTypeMap).find(
        (property) => spark?.[property as keyof typeof filterTypeMap]?.length > 0,
    );

    if (sparkProperty != null) {
        // Forcing filtering by products, rather than brands/categories because of incorrect transaction summaries
        filters.products = sparkPosData.products;
    }

    return filters;
};

export const fetchSparkCommissionMap = async (
    spark: Spark,
): Promise<ISparkCommissionMap | undefined> => {
    if (spark.type === 'commission') {
        const { data } = await PointOfSaleAPI.getAllProducts({ group_id: spark.groupId });

        return generateSparkCommissionMap(spark, data);
    }

    return undefined;
};

// TODO: Temporary until https://app.clickup.com/t/1r4ura2 is fixed
const getUniqueSparksByLocationIds = (sparkSubGroup: Spark[]): Spark[] => {
    if (isEmpty(sparkSubGroup)) {
        return [];
    }

    const subGroupDict = sparkSubGroup.reduce<{ [locationDictKey: string]: Spark }>(
        (dict, subGroup) => {
            const locationIds = subGroup?.locationIds || [];
            const locationDictKey = locationIds.join('');

            if (!locationDictKey || dict[locationDictKey]) {
                return dict;
            }

            return {
                ...dict,
                [locationDictKey]: subGroup,
            };
        },
        {},
    );

    return Object.values(subGroupDict) || [];
};

export const fetchSparkSubGroups = async ({
    _id,
    groupId,
    tag,
    locationIds,
    posEmployeeProfileIds,
    finalizedAt,
}: Spark): Promise<ISparkSubGroup[]> => {
    if (tag) {
        const sparks = await getSparksByTag(groupId, tag);

        // TODO: Inital bug fixed with https://app.clickup.com/t/10536392/SPRK-621
        // But we need to go back and retroactively remove extra hidden sparks from
        // the cloning bug in https://app.clickup.com/t/10536392/SPRK-705
        const uniqueSparksByLocations: Spark[] = getUniqueSparksByLocationIds(sparks);

        return uniqueSparksByLocations.map((spark) => {
            const sparkLocationIds = spark.locationIds || [];
            const sparkPosEmployeeProfileIds = spark.posEmployeeProfileIds || [];

            // TODO: https://app.clickup.com/t/10536392/SPRK-1244
            // This dedupe fixes the issue, but we should investigate the underlying bug/cause
            const dedupedSparkPosEmployeeProfileIds = Array.from(
                new Set(sparkPosEmployeeProfileIds),
            );

            return {
                sparkId: spark?._id,
                locationIds: sparkLocationIds,
                posEmployeeProfileIds: dedupedSparkPosEmployeeProfileIds,
                locations: [],
                participants: [],
                internalTracking: spark.internalTracking,
                finalizedAt: spark.finalizedAt,
                archivedAt: spark.archivedAt,
            };
        });
    }

    return [
        {
            sparkId: _id,
            locationIds,
            posEmployeeProfileIds,
            locations: [],
            participants: [],
            finalizedAt,
        },
    ];
};

export const applySparkSubGroupsPosData = (
    sparkSubGroups: ISparkSubGroup[],
    accountLocations: IPosLocation[],
    accountUsers: SparkParticipant[],
): ISparkSubGroup[] => {
    const accountLocationsObj = buildObjsFromArray(accountLocations);
    const accountUsersByPosEpId = keyAccountUsersByPosEmployeeProfileIds(accountUsers);

    return sparkSubGroups.reduce<ISparkSubGroup[]>((res, sparkSubGroup) => {
        const participants = sparkSubGroup.posEmployeeProfileIds
            .reduce<SparkParticipant[]>((subParticipants, posEmployeeProfileId) => {
                const accountUser = accountUsersByPosEpId[posEmployeeProfileId] ?? {
                    flexibleEmployeeId: posEmployeeProfileId,
                    posEmployeeProfileIds: [posEmployeeProfileId],
                    fullName: 'Participant not found...',
                };

                subParticipants.push({
                    ...accountUser,
                    label: accountUser.fullName,
                    value: accountUser.flexibleEmployeeId,
                });

                return subParticipants;
            }, [])
            .sort(sortByString('label', 'asc'));

        return [
            ...res,
            {
                ...sparkSubGroup,
                locations: sparkSubGroup.locationIds.map((id) => {
                    return (
                        accountLocationsObj?.[id] ?? {
                            _id: id,
                            value: id,
                            label: 'Location not found...',
                            fullName: 'Location not found...',
                        }
                    );
                }),
                participants: uniqBy(participants, 'value'),
            },
        ];
    }, []);
};

export const buildSparkSubGroups = (
    detailedSparkType: DetailedSparkType | undefined,
    selectedLocations: IPosLocation[],
    selectedParticipants: SparkParticipant[],
    activeParticipantOptions: IAccountUser[] = [],
): ISparkSubGroup[] => {
    const sparkSubGroups: ISparkSubGroup[] = [];

    if (detailedSparkType === 'leaderboardMulti') {
        const deselectedParticipantsAtAllLocations = activeParticipantOptions.filter(
            (accountUser) => {
                return !selectedParticipants.some((selectedParticipant) => {
                    return (
                        selectedParticipant.flexibleEmployeeId === accountUser.flexibleEmployeeId
                    );
                });
            },
        );

        selectedLocations.forEach((location) => {
            const { value: locationId } = location;

            const excludedPosEmployeeProfileIds =
                deselectedParticipantsAtAllLocations.length > 0
                    ? deselectedParticipantsAtAllLocations
                          .filter((deselectedParticipant) => {
                              return deselectedParticipant.lastTransactionLocationId
                                  ? locationId === deselectedParticipant.lastTransactionLocationId
                                  : deselectedParticipant.locationIds?.includes(locationId);
                          })
                          .flatMap(({ posEmployeeProfileIds }) => posEmployeeProfileIds)
                    : [];

            const sparkSubGroup: ISparkSubGroup = {
                locationIds: [locationId],
                locations: [location],
                participants: [],
                posEmployeeProfileIds: [],
                excludedPosEmployeeProfileIds,
            };

            selectedParticipants.forEach((accountUser) => {
                const userIsLocationParticipant = accountUser.lastTransactionLocationId
                    ? locationId === accountUser.lastTransactionLocationId
                    : accountUser.locationIds?.includes(locationId);

                if (userIsLocationParticipant) {
                    sparkSubGroup.posEmployeeProfileIds =
                        sparkSubGroup.posEmployeeProfileIds.concat(
                            accountUser.posEmployeeProfileIds,
                        );
                    sparkSubGroup.participants.push(accountUser);
                }
            });

            sparkSubGroups.push(sparkSubGroup);
        });
    } else {
        sparkSubGroups.push({
            locationIds: selectedLocations.map(({ value }) => value),
            locations: selectedLocations,
            posEmployeeProfileIds: selectedParticipants.flatMap(
                ({ posEmployeeProfileIds }) => posEmployeeProfileIds,
            ),
            participants: selectedParticipants,
        });
    }

    return sparkSubGroups;
};

export const getSelectedLocationsFromSparkSubGroups = (sparkSubGroups: ISparkSubGroup[]) => {
    let locations: IPosLocation[] = [];

    sparkSubGroups.forEach((sparkSubGroup) => {
        if (sparkSubGroup?.locations != null) {
            locations = locations.concat(
                sparkSubGroup.locations.map((location) => ({
                    ...location,
                    key: location._id,
                })),
            );
        }
    });

    return locations;
};

export const getSelectedParticipantsFromSparkSubGroups = (
    sparkSubGroups: ISparkSubGroup[],
): SparkParticipant[] => {
    let participants: SparkParticipant[] = [];

    sparkSubGroups.forEach((sparkSubGroup) => {
        if (sparkSubGroup.locations?.length && sparkSubGroup.participants?.length) {
            const parentKey = sparkSubGroup.locationIds.join('|');

            participants = participants.concat(
                sparkSubGroup.participants.map((accountUser) => ({
                    ...accountUser,
                    key: [parentKey, accountUser.flexibleEmployeeId].join('.'),
                })),
            );
        }
    });

    return uniqBy(participants, 'flexibleEmployeeId').sort(sortByString('label', 'asc'));
};

export const updateSparkById = (sparkId: string, sparkData: Partial<Spark>) => {
    return SparksAPI.updateSpark(sparkId, sparkData);
};

export const createUpdateSpark = async (
    sparkId: string | null | undefined,
    sparkProperties: Spark,
    detailedSparkType: DetailedSparkType,
    sparkSubGroups: SparkParticipantGroup[] = [],
    requestForSparkId?: string,
) => {
    const participantGroups: SparkParticipantGroup[] =
        sparkProperties.detailedSparkType === 'leaderboardMulti'
            ? sparkSubGroups.map(
                  ({ locationIds, posEmployeeProfileIds, excludedPosEmployeeProfileIds }) => ({
                      locationIds,
                      posEmployeeProfileIds,
                      excludedPosEmployeeProfileIds,
                  }),
              )
            : [
                  {
                      locationIds: sparkProperties.locationIds,
                      posEmployeeProfileIds: sparkProperties.posEmployeeProfileIds,
                  },
              ];

    const updatedSparkProperties = {
        ...sparkProperties,
        detailedSparkType,
        participantGroups,
        requestForSparkId,
    };

    if (isEmpty(sparkProperties.retailerFilters)) {
        updatedSparkProperties.retailerFilters = undefined;
    }

    return sparkId
        ? SparksAPI.updateSpark(sparkId, updatedSparkProperties)
        : SparksAPI.createSpark(updatedSparkProperties);
};

export const sendBrandSparkRequest = async (
    sparkData: Spark,
    detailedSparkType: DetailedSparkType,
    sparkSubGroups: ISparkSubGroup[] = [],
    requestForSparkId?: string,
) => {
    const { originatorGroupId } = sparkData;

    if (!originatorGroupId) {
        throw new Error('`originatorGroupId` is required');
    }

    const participantGroups: SparkParticipantGroup[] =
        sparkData.detailedSparkType === 'leaderboardMulti'
            ? sparkSubGroups.map(
                  ({ locationIds, posEmployeeProfileIds, excludedPosEmployeeProfileIds }) => ({
                      locationIds,
                      posEmployeeProfileIds,
                      excludedPosEmployeeProfileIds,
                  }),
              )
            : [
                  {
                      locationIds: sparkData.locationIds,
                      posEmployeeProfileIds: sparkData.posEmployeeProfileIds,
                  },
              ];

    return SparkplugAPI.sendSparkRequest(
        originatorGroupId,
        { ...sparkData, detailedSparkType },
        participantGroups,
        requestForSparkId,
    );
};

export const respondToBrandSparkRequest = async (
    sparkData: Spark,
    sparkSubGroups: ISparkSubGroup[],
    accepted: boolean,
    declineResponse?: SparkDeclineResponse,
) => {
    const { groupId } = sparkData;

    const participantGroups: SparkParticipantGroup[] =
        sparkData.detailedSparkType === 'leaderboardMulti'
            ? sparkSubGroups.map(
                  ({ locationIds, posEmployeeProfileIds, excludedPosEmployeeProfileIds }) => ({
                      locationIds,
                      posEmployeeProfileIds,
                      excludedPosEmployeeProfileIds,
                  }),
              )
            : [
                  {
                      locationIds: sparkData.locationIds,
                      posEmployeeProfileIds: sparkData.posEmployeeProfileIds,
                  },
              ];

    await SparkplugAPI.respondToSparkRequest({
        groupId,
        sparkId: sparkData._id,
        participantGroups,
        accepted,
        declineResponse,
    });
};

export const deleteSpark = (sparkId: string): Promise<void> => {
    return SparksAPI.deleteSpark(sparkId);
};

export const findAndUpdateSparkPayout = ({
    payouts,
    flexibleEmployeeId,
    updatedData,
}: {
    payouts: ISparkPayout[];
    flexibleEmployeeId: string;
    updatedData: Partial<ISparkPayout>;
}): ISparkPayout => {
    const payout = payouts.find((obj) => obj.flexibleEmployeeId === flexibleEmployeeId) || {};

    return {
        ...payout,
        ...updatedData,
    } as ISparkPayout;
};

export const saveSparkPayouts = async (spark: Spark, payouts: ISparkPayout[]): Promise<void> => {
    const { _id: sparkId } = spark;

    const payload = payouts
        .filter(({ status }) => status === 'pending')
        .map((payout) => {
            const {
                _id,
                text,
                amount,
                userId,
                posEmployeeProfileId,
                posEmployeeProfileIds,
                fulfilledBySparkplug = false,
                claimInstructions,
            } = payout;

            const type = fulfilledBySparkplug ? 'payout' : 'other';

            const rewardData: UpsertManySparkRewardsRequestBody[number] = {
                rewardId: _id,
                amount: 0,
                userId,
                posEmployeeProfileId,
                posEmployeeProfileIds,
                type,
                claimInstructions,
            };

            if (fulfilledBySparkplug) {
                // For the RewardsAPI amount must be in cents
                rewardData.amount = amount * 100;
            } else {
                rewardData.name = text;
            }

            return rewardData;
        });

    await SparksAPI.upsertManySparkRewards(sparkId, payload);
};

export interface IConfirmSparkPayoutsResponse {
    finalizedAt: string;
}

export const finalizeSpark = async (spark: Spark): Promise<IConfirmSparkPayoutsResponse> => {
    await SparksAPI.finalizeSpark(spark._id, {});

    const finalizedAt = moment().toISOString();

    return {
        finalizedAt,
    };
};

export const resetSparkPayouts = async (sparkId: string) => {
    const promises: Promise<any>[] = [
        updateSparkById(sparkId, {
            finalizedAt: '',
            internalTracking: {
                invoiceId: '',
                status: 'none',
                payoutStatus: 'not-paid',
                invoiceStatus: 'not-sent',
                notes: '',
            },
        }),
    ];

    const rewards = await getRewards(sparkId);

    const rewardIds = rewards.map(({ _id }) => _id);

    rewardIds.forEach((rewardId) => {
        promises.push(deleteReward(rewardId));
    });

    return Promise.all(promises);
};

export const claimSparkPayout = async (payout: ISparkPayout): Promise<string> => {
    const rewardId = payout?._id;

    const promises: Promise<any>[] = [];

    if (rewardId != null) {
        promises.push(claimReward(rewardId));
    }

    const responses = await Promise.all(promises);

    const deliveryLink = responses?.[0]?.deliveryLink || '';

    return deliveryLink;
};

export const getSparkPosArchive = async (sparkId: string | undefined) => {
    return sparkId
        ? (await SparksAPI.getSparkPosArchive(sparkId)).data
        : Promise.resolve(undefined);
};

const mapArchiveUserToHydratedSparkParticipant = (
    flexibleEmployeeId: string,
    participantArchive: SparkArchiveParticipant,
    sparkLocations: { [key: string]: string },
): SparkParticipant => {
    const {
        firstName,
        lastName,
        fullName,
        locationIds,
        posEmployeeProfileIds,
        lastTransactionLocationId,
    } = participantArchive;

    const locationNames = locationIds.map((x) => {
        return sparkLocations[x];
    });

    return {
        flexibleEmployeeId,
        value: flexibleEmployeeId,
        label: fullName,
        locationIds,
        posEmployeeProfileIds,
        lastTransactionLocationId,
        locationNames,
        firstName,
        lastName,
        fullName,
    };
};

export const hydrateSparkPosArchive = (
    sparkPosArchive?: PublicSparkPosArchive,
    spark?: Spark,
): {
    locations: IPosLocation[];
    participants: SparkParticipant[];
    nonParticipantsWithSales: SparkParticipant[];
    brands: IPosBrand[];
    categories: IPosCategory[];
    products: IPosProduct[];
    associatedProducts: IPosProduct[];
} => {
    if (!sparkPosArchive) {
        return {
            locations: [],
            participants: [],
            nonParticipantsWithSales: [],
            brands: [],
            categories: [],
            products: [],
            associatedProducts: [],
        };
    }

    const createdAt = moment().toISOString();

    /**
     * For a period of time, Spark POS Archives were storing old products that did not qualify. This
     * is a workaround to filter out those products, but we need to also create a script to clean
     * these Spark Pos Archives up.
     * */
    let productEntries = Object.entries(sparkPosArchive.products);
    if (spark?.type === 'commission') {
        const commissionProductIds = Object.fromEntries(
            spark.commissions.map(({ posProductId }) => [posProductId, true]),
        );
        productEntries = productEntries.filter(([productId]) => commissionProductIds[productId]);
    }

    const products: IPosProduct[] = productEntries
        .map(
            ([productId, { name, brandIds, categoryIds }]) =>
                ({
                    _id: productId,
                    name,
                    brands: brandIds.map((_id) => ({
                        _id,
                        name: sparkPosArchive.brands[_id],
                    })),
                    categories: categoryIds.map((_id) => ({
                        _id,
                        name: sparkPosArchive.categories[_id],
                    })),

                    createdAt,
                    value: productId,
                    label: name,
                    searchName: name.toLowerCase(),
                } as any),
        )
        .sort(sortByString('label', 'asc'));

    return {
        locations: Object.entries(sparkPosArchive.locations)
            .map(
                ([locationId, label]) =>
                    ({
                        _id: locationId,
                        value: locationId,
                        label,
                        disabled: false,
                        parentId: '',

                        createdAt,
                        name: label,
                    } as IPosLocation),
            )
            .sort(sortByString('label', 'asc')),
        participants: Object.entries(sparkPosArchive.participants)
            .map(([flexibleEmployeeId, participantArchive]) =>
                mapArchiveUserToHydratedSparkParticipant(
                    flexibleEmployeeId,
                    participantArchive,
                    sparkPosArchive.locations,
                ),
            )
            .sort(sortByString('label', 'asc')),
        nonParticipantsWithSales: Object.entries(sparkPosArchive.nonParticipantsWithSales)
            .map(([flexibleEmployeeId, participantArchive]) =>
                mapArchiveUserToHydratedSparkParticipant(
                    flexibleEmployeeId,
                    participantArchive,
                    sparkPosArchive.locations,
                ),
            )
            .sort(sortByString('label', 'asc')),
        brands: Object.entries(sparkPosArchive.brands)
            .map(([brandId, label]) => ({
                _id: brandId,
                value: brandId,
                label,

                createdAt,
                name: label,
                searchName: label.toLowerCase(),
            }))
            .sort(sortByString('label', 'asc')) as IPosBrand[],
        categories: Object.entries(sparkPosArchive.categories)
            .map(([categoryId, label]) => ({
                _id: categoryId,
                value: categoryId,
                label,

                createdAt,
                name: label,
                searchName: label.toLowerCase(),
            }))
            .sort(sortByString('label', 'asc')) as IPosCategory[],
        products,
        associatedProducts: products,
    };
};

export const checkIfIsSparkOriginatorAccount = ({
    spark,
    accountId,
}: {
    spark: Spark;
    accountId?: string;
}): boolean => {
    if (!accountId) {
        return false;
    }

    return spark.originatorGroupId
        ? spark.originatorGroupId === accountId
        : spark.groupId === accountId;
};

export const userHasViewedTrainingPrompt = ({
    user,
    courseData,
}: {
    user?: IAuthUser;
    courseData?: ITrainingCourse;
}): boolean => {
    return Boolean(user && courseData?.viewedUsersByCourseId?.[courseData?.courseId]?.[user._id]);
};

export const getMinThresholdDescription = (spark: Spark): string | null => {
    return spark.detailedSparkType === 'leaderboardLocation'
        ? 'Set a minimum threshold a location must achieve before qualifying for a top prize.'
        : 'Set a minimum threshold an employee must achieve before qualifying for a top prize.';
};
