import { useEffect } from 'react';

import moment from 'moment-timezone';

import { ListControlCenterSparksQueryParams } from '@sparkplug/lib';

import RouterTabs from '@components/layout/Tabs/RouterTabs';
import { queryToString, useHistory, useQueryParams } from '@components/router';

import CCDNPSparksView from './CCDNPSparksView';
import CCFulfilledSparksView from './CCFulfilledSparksView';
import CCUnfulfilledSparksView from './CCUnfulfilledSparksView';
import { useGetControlCenterMetaDataQuery } from './hooks/useCCSparksQuery';

import './styles.scss';

const CCRecentSparksView = () => {
    const { p, limit, ...queryParams }: { p?: number } & ListControlCenterSparksQueryParams =
        useQueryParams();
    const history = useHistory();

    const { controlCenterMetaDataAreReady, controlCenterMetaData: ccMeta } =
        useGetControlCenterMetaDataQuery({
            startDate: queryParams.startDate,
            endDate: queryParams.endDate,
        });
    const routes = [
        {
            url: '/control-center/recent-sparks',
            text: `Unfulfilled  (${ccMeta?.allUnfullfilledSparksCount})`,
        },

        { url: '/control-center/recent-sparks/do-not-payout', text: 'Do Not Payout' },
        { url: '/control-center/recent-sparks/fulfilled', text: 'Fulfilled ' },
    ];

    useEffect(() => {
        if (!queryParams.startDate && !queryParams.endDate) {
            history.push({
                search: queryToString({
                    ...queryParams,
                    startDate: moment().subtract(91, 'days').format('YYYY-MM-DD'),
                    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                }),
            });
        }
    }, [queryParams]);

    return (
        <div className="cc-recent-sparks-view" data-testid="cc-recent-sparks-view">
            <div className="cc-recent-sparks-page-title">
                <h1>Recent Sparks</h1>
            </div>
            <RouterTabs color="blue" tabs={routes}>
                <RouterTabs.Tab path="/control-center/recent-sparks">
                    <CCUnfulfilledSparksView />
                </RouterTabs.Tab>
                <RouterTabs.Tab path="/control-center/recent-sparks/do-not-payout">
                    <CCDNPSparksView />
                </RouterTabs.Tab>
                <RouterTabs.Tab path="/control-center/recent-sparks/fulfilled">
                    <CCFulfilledSparksView />
                </RouterTabs.Tab>
            </RouterTabs>
        </div>
    );
};
export default CCRecentSparksView;
