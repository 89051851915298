import { useState } from 'react';

import moment from 'moment-timezone';

import Dropdown from '@components/dropdown/Dropdown';
import List from '@components/layout/List';

import { IAuthUser } from '@app/types/UsersTypes';

const UserTable = ({ users }: { users: IAuthUser[] }) => {
    const headCells: any[] = [
        {
            id: 'fullName',
            label: 'Name',
        },
        {
            id: '_id',
            label: 'ID',
        },
        {
            id: 'externalId',
            label: 'External ID',
        },
    ];

    if (users == null || users?.length === 0) {
        return <div>No users</div>;
    }

    return (
        <table className="html-table">
            <thead>
                <tr>
                    {headCells.map(({ label }, i) => (
                        <th key={i}>{label}</th>
                    ))}
                </tr>
            </thead>

            <tbody>
                {users.map((user) => (
                    <tr className="user" key={user._id}>
                        {headCells.map(({ id }) => (
                            <td key={`${user._id}-${id}`}>{(user as any)[id]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const PosConfigLocations = ({
    locations,
    locationInfos,
}: // locationStates,
// setState,
{
    locations: any[];
    locationInfos: any;
}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    if (locations == null) {
        return null;
    }

    return (
        <div className="locations pos-config-detail-content has-sidebar">
            <div className="location-list pos-config-detail-content-sidebar">
                <List>
                    {locations.map(({ _id, name }, i) => (
                        <List.Item
                            key={_id}
                            button
                            selected={currentIndex === i}
                            className={currentIndex === i ? 'is-active' : 'is-not-active'}
                            onClick={() => {
                                setCurrentIndex(i);
                            }}
                        >
                            {name}
                        </List.Item>
                    ))}
                </List>
            </div>
            <div className="location-content pos-config-detail-content-content">
                {locations.map((location, i) => {
                    const locationInfo = locationInfos.get(location._id);
                    const { locationConfigs, users, transactionSummaryCount } = locationInfo;

                    const locationImportConfig = (locationConfigs || []).find(
                        (locationConfig: any) => {
                            return locationConfig.type === 'location-import-config';
                        },
                    );
                    const locationSummaryConfig = (locationConfigs || []).find(
                        (locationConfig: any) => {
                            return locationConfig.type === 'location-summary-config';
                        },
                    );

                    if (currentIndex !== i) {
                        return null;
                    }

                    return (
                        <div className="location" key={location._id}>
                            <h2>
                                <span>{location.name}</span>

                                {locationImportConfig != null && (
                                    <Dropdown>
                                        <Dropdown.Button>View Raw</Dropdown.Button>
                                        <Dropdown.Popover>
                                            <div className="block-set">
                                                {locationImportConfig != null && (
                                                    <div className="block">
                                                        <div className="title">Import Data</div>
                                                        <div className="raw-code">
                                                            <pre>
                                                                <code>
                                                                    {JSON.stringify(
                                                                        locationImportConfig,
                                                                        null,
                                                                        2,
                                                                    )}
                                                                </code>
                                                            </pre>
                                                        </div>
                                                    </div>
                                                )}

                                                {locationSummaryConfig != null && (
                                                    <div className="block">
                                                        <div className="title">
                                                            Transaction Summary Data
                                                        </div>
                                                        <div className="raw-code">
                                                            <pre>
                                                                <code>
                                                                    {JSON.stringify(
                                                                        locationSummaryConfig,
                                                                        null,
                                                                        2,
                                                                    )}
                                                                </code>
                                                            </pre>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </Dropdown.Popover>
                                    </Dropdown>
                                )}
                            </h2>

                            <div className="property">
                                <div className="label">Location</div>
                                <div className="value">{`${location.name} (${location._id})`}</div>
                            </div>

                            <div className="property">
                                <div className="label">External Id</div>
                                <div className="value">{location.externalId}</div>
                            </div>

                            <div className="property">
                                <div className="label">Users (count)</div>
                                <div className="value">{users.length}</div>
                            </div>

                            <div className="property">
                                <div className="label">Transaction Summaries (count)</div>
                                <div className="value">{transactionSummaryCount}</div>
                            </div>

                            {locationImportConfig && (
                                <>
                                    <h3>Import</h3>

                                    <div className="property">
                                        <div className="label">Original Lookback</div>
                                        <div className="value">
                                            {moment(locationImportConfig.originalLookback).format(
                                                'LLLL',
                                            )}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Current Lookback</div>
                                        <div className="value">
                                            {moment(locationImportConfig.currentLookback).format(
                                                'LLLL',
                                            )}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Currently Running</div>
                                        <div className="value">
                                            {locationImportConfig.isRunning ? 'Yes' : 'No'}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Last Run</div>
                                        <div className="value">
                                            {moment(locationImportConfig.lastRun).format('LLLL')}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Error</div>
                                        <div className="value">
                                            {locationImportConfig.error != null
                                                ? locationImportConfig.error
                                                : 'None'}
                                        </div>
                                    </div>
                                </>
                            )}

                            {locationSummaryConfig && (
                                <>
                                    <h3>Summary</h3>

                                    <div className="property">
                                        <div className="label">Original Lookback</div>
                                        <div className="value">
                                            {locationSummaryConfig.originalLookBackDate}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Current Lookback</div>
                                        <div className="value">
                                            {locationSummaryConfig.currentLookBackDate}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Currently Running</div>
                                        <div className="value">
                                            {locationSummaryConfig.isRunning ? 'Yes' : 'No'}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Last Run</div>
                                        <div className="value">
                                            {moment(locationSummaryConfig.lastRun).format('LLLL')}
                                        </div>
                                    </div>

                                    <div className="property">
                                        <div className="label">Error</div>
                                        <div className="value">
                                            {locationSummaryConfig.error != null
                                                ? locationSummaryConfig.error
                                                : 'None'}
                                        </div>
                                    </div>
                                </>
                            )}

                            <div className="users">
                                <h3>Users</h3>

                                <UserTable users={users} />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PosConfigLocations;
