import { useState } from 'react';

import { CloverCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';
import Paper from '@components/layout/Paper';

interface CloverPosSpecificFieldsProps {
    credentials?: CloverCredentials;
    onChange: (key: string, value: any) => void;
}

const CloverPosSpecificFields = ({ credentials, onChange }: CloverPosSpecificFieldsProps) => {
    const [initialLocations] = useState(credentials?.locations || []);
    const locations = credentials?.locations || [
        {
            apiToken: '',
            merchantId: '',
            region: 'us',
        },
    ];

    return (
        <>
            <div className="form-control repeating-fields">
                <Form.Label>Locations</Form.Label>
                <div className="repeating-fields-fields">
                    {locations.map((location, i) => {
                        return (
                            <Paper
                                key={`indica-online_credentials-locations-${i}`}
                                className="repeating-field-group"
                                variant="outlined"
                            >
                                <Form.TextField
                                    label="Merchant ID"
                                    name={`locations.${i}.merchantId`}
                                    required
                                    defaultValue={location?.merchantId}
                                    disabled
                                />
                                <Form.TextField
                                    label="API Token"
                                    name={`locations.${i}.apiToken`}
                                    required
                                    value={location?.apiToken}
                                    disabled
                                />
                                <Form.Select
                                    label="Region"
                                    name={`locations.${i}.region`}
                                    value={location?.region}
                                    disabled
                                    options={[
                                        { label: 'United States', value: 'us' },
                                        { label: 'Europe', value: 'eu' },
                                        { label: 'Latin America', value: 'la' },
                                    ]}
                                />
                            </Paper>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default CloverPosSpecificFields;
