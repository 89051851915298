import { useCallback, useEffect, useMemo, useState } from 'react';

import Form from '@components/form/Form';
import ListSelector from '@components/inputs/ListSelector';
import { IListItem } from '@components/inputs/ListSelector/ListSelector';
import Grid from '@components/layout/Grid';
import Skeleton from '@components/layout/Skeleton';

import { useSparkplugAccount, useSparkplugAccountUsers } from '@hooks/SparkplugAccountsHooks';

import { IOption } from '@app/types/UITypes';
import { IAccountUser } from '@app/types/UsersTypes';

import './ParticipantsSelectorFormFields.scss';

const log = require('../../log/Log')('ParticipantsSelectorForm');

const initialFilters = [
    {
        label: 'Enrolled',
        active: false,
        fn: (newList: IAccountUser[]) => {
            return newList.filter((user) => {
                return user.phoneNumber != null;
            });
        },
    },
];

const ParticipantsSelectorFormFields = ({
    enrolled,
    setStageValidationFn,

    selectedLocations,
    setSelectedLocations,
    selectedParticipants,
    setSelectedParticipants,
}: {
    enrolled: boolean;
    setStageValidationFn: (fn: () => boolean) => void;
    selectedLocations: IListItem[];
    setSelectedLocations: (locations: IListItem[]) => void;
    selectedParticipants: IListItem[];
    setSelectedParticipants: (participants: IListItem[]) => void;
}) => {
    const { account } = useSparkplugAccount();
    const { accountUsersAreReady, accountUsers } = useSparkplugAccountUsers();

    const locationOptions = useMemo<IListItem[]>(() => {
        return account?.locations ?? [];
    }, [account]);

    const eligibleParticipants = useMemo<IListItem[]>(() => {
        let newEligibleParticipants: IOption<IAccountUser>[] = [];

        if (accountUsersAreReady) {
            const selectedLocationIds = selectedLocations.map((obj) => obj.value);

            newEligibleParticipants = accountUsers
                .filter(({ role, posEmployeeProfileIds, locationIds }) => {
                    return (
                        role !== 'none' &&
                        posEmployeeProfileIds?.length &&
                        locationIds?.some((locId) => selectedLocationIds.includes(locId))
                    );
                })
                .map((obj) => {
                    return {
                        ...obj,
                        label: obj.fullName,
                        value: obj.flexibleEmployeeId,
                    };
                });

            if (enrolled) {
                newEligibleParticipants = newEligibleParticipants.filter((user) => {
                    return !!user.phoneNumber;
                });
            }
        }

        return newEligibleParticipants;
    }, [account?.locations, selectedLocations, accountUsersAreReady, accountUsers, enrolled]);

    const [validated, setValidated] = useState(false);

    const checkValidation = useCallback(() => {
        const validation = [
            () => {
                if (selectedLocations.length === 0 || selectedParticipants.length === 0) {
                    log.d(`Missing locations or participants`);
                    return false;
                }
                return true;
            },
        ];

        const allValid = validation.every((fn) => fn());
        setValidated(true);
        return allValid;
    }, [selectedLocations, selectedParticipants]);

    useEffect(() => {
        setStageValidationFn(checkValidation);
    }, [checkValidation, setStageValidationFn]);

    const onSelectLocations = (newSelectedLocations: IListItem[]) => {
        setSelectedLocations(newSelectedLocations);
    };

    const onSelectParticipants = (newSelectedParticipants: IListItem[]) => {
        newSelectedParticipants.sort((a, b) => {
            return a.label.localeCompare(b.label);
        });
        setSelectedParticipants(newSelectedParticipants || []);
    };

    return (
        <>
            <Grid className="participants-container">
                <Grid.Item>
                    <Form.Group>
                        <Form.Label required error={validated && selectedLocations.length === 0}>
                            Select Location(s)
                        </Form.Label>
                        <ListSelector
                            list={locationOptions || []}
                            selected={selectedLocations}
                            onSelectionChanged={onSelectLocations}
                        />
                    </Form.Group>
                </Grid.Item>

                <Grid.Item>
                    <Form.Group>
                        <Form.Label required error={validated && selectedParticipants.length === 0}>
                            Select Participant(s)
                        </Form.Label>
                        {accountUsersAreReady ? (
                            <ListSelector
                                list={eligibleParticipants}
                                initialFilters={enrolled ? [] : (initialFilters as any[])}
                                selected={selectedParticipants}
                                onSelectionChanged={onSelectParticipants}
                            />
                        ) : (
                            <Skeleton height={440} />
                        )}
                    </Form.Group>
                </Grid.Item>
            </Grid>
        </>
    );
};

export default ParticipantsSelectorFormFields;
