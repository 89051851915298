import moment from 'moment-timezone';

import { PosConfig } from '@sparkplug/lib';

import Button from '@components/buttons/Button';

const PosConfigDetails = ({
    config,
    group,
    configsShowRaw,
    setState,
}: {
    config: PosConfig;
    group: any;
    configsShowRaw: boolean;
    setState: (state: any) => void;
}) => {
    if (config == null) {
        return null;
    }

    return (
        <div className="configs pos-config-detail-content">
            <div className="property">
                <div className="label">Group</div>
                <div className="value">{`${group.name} (${config.groupId})`}</div>
            </div>

            <div className="property">
                <div className="label">Type</div>
                <div className="value">{config.type}</div>
            </div>

            <div className="property">
                <div className="label">Enabled</div>
                <div className="value">{config.enabled ? 'Yes' : 'No'}</div>
            </div>

            <div className="property">
                <div className="label">Config ID</div>
                <div className="value">{config._id}</div>
            </div>

            <div className="property">
                <div className="label">Time Zone</div>
                <div className="value">{config.timeZone}</div>
            </div>

            <div className="property">
                <div className="label">Lookback</div>
                <div className="value">
                    {moment(config.lookback).tz(config.timeZone).format('LLLL')}
                </div>
            </div>

            <div className="button-container">
                {configsShowRaw ? (
                    <Button
                        color="blue"
                        variant="flat"
                        onClick={() => setState({ configsShowRaw: false })}
                    >
                        Hide Raw
                    </Button>
                ) : (
                    <Button
                        color="blue"
                        variant="flat"
                        onClick={() => setState({ configsShowRaw: true })}
                    >
                        Show Raw
                    </Button>
                )}
            </div>

            {configsShowRaw && (
                <div className="block-set">
                    <div className="block">
                        <div className="title">Primary Config</div>
                        <div className="raw-code">
                            <pre>
                                <code>{JSON.stringify(config, null, 2)}</code>
                            </pre>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PosConfigDetails;
