import { RevelCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface RevelPosSpecificFieldsProps {
    credentials?: RevelCredentials;
    onChange: (key: string, value: any) => void;
}

const RevelPosSpecificFields = ({ credentials, onChange }: RevelPosSpecificFieldsProps) => {
    const { key, secret, subdomain } = credentials || {};

    return (
        <>
            <Form.TextField
                label="API Key"
                name="key"
                required
                defaultValue={key}
                onChange={(event) => onChange('key', event.target.value)}
            />
            <Form.TextField
                label="API Secret"
                name="secret"
                required
                defaultValue={secret}
                onChange={(event) => onChange('secret', event.target.value)}
            />
            <Form.TextField
                label="Subdomain"
                name="subdomain"
                required
                defaultValue={subdomain}
                onChange={(event) => onChange('subdomain', event.target.value)}
            />
        </>
    );
};

export default RevelPosSpecificFields;
