import { RPowerCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface RPowerPosSpecificFieldsProps {
    credentials?: RPowerCredentials;
    onChange: (key: string, value: any) => void;
}

const RPowerPosSpecificFields = ({ credentials, onChange }: RPowerPosSpecificFieldsProps) => {
    const { apiKey } = credentials || {};
    return (
        <>
            <Form.TextField
                label="API Key"
                name="apiKey"
                required
                defaultValue={apiKey}
                onChange={(event) => onChange('apiKey', event.target.value)}
            />
        </>
    );
};

export default RPowerPosSpecificFields;
