import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import BrandPermissionsForm from './components/BrandPermissionsForm';

import './CCBrandPermissionsView.scss';

const CCBrandPermissionsView = () => {
    const { account } = useSparkplugAccount();

    return (
        <div className="cc-brand-permissions-view">
            <BrandPermissionsForm account={account} />
        </div>
    );
};

export default CCBrandPermissionsView;
