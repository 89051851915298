import React, { FC } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment';

import { useFetchPosConfigsByGroupId } from '@core/accounts/queries/AccountQueries';

import { CircleCheck, CircleXIcon } from '@components/icons';
import Paper from '@components/layout/Paper';

import { sortTypes } from '@hooks/TableHooks';

import { cn } from '@app/componentLibrary/utils';

const BooleanIconWithLabel: FC<{ value: boolean; label: string }> = ({ value, label }) => {
    return (
        <div className="flex items-center pb-2">
            {value ? (
                <CircleCheck className="mr-2 h-[14px] w-[14px] [&_path]:fill-aqua-800" />
            ) : (
                <CircleXIcon className="mr-2 h-[14px] w-[14px] [&_path]:fill-sienna-800" />
            )}
            <span>{label}</span>
        </div>
    );
};

interface SuperAdminPosConfigsDisplayProps {
    retailerAccountId: string;
    userIsSuperAdmin: boolean;
    activePosConfigId: string;
}

export const SuperAdminPosConfigsDisplay: React.FC<SuperAdminPosConfigsDisplayProps> = ({
    retailerAccountId,
    userIsSuperAdmin,
    activePosConfigId,
}) => {
    const { posConfigs, posConfigsAreReady } = useFetchPosConfigsByGroupId(
        retailerAccountId,
        userIsSuperAdmin,
    );

    const sortFunction = sortTypes.date('lookback', 'desc');
    const sortedPosConfigs = posConfigs?.sort(sortFunction);

    return (
        <>
            {posConfigsAreReady && (
                <Paper className="p-4">
                    <span className="font-bold">For Super-Admins Only:</span>
                    <div className="flex flex-row flex-wrap gap-4 items-stretch mt-2 [&_.MuiFormControlLabel-label]:!ml-0">
                        {sortedPosConfigs?.map((config) => {
                            const isActiveConfig = config._id === activePosConfigId;
                            return (
                                <Paper
                                    key={config._id}
                                    className={cn(
                                        'flex flex-col !mt-0 h-[145px]',
                                        isActiveConfig && 'border-solid !border-2 border-aqua-800',
                                    )}
                                >
                                    <span className="font-bold pb-1">{config.type}</span>
                                    <span className="pb-2">
                                        Lookback:&nbsp;
                                        {moment(config.lookback).format(DATE_DISPLAY_FORMAT)}
                                    </span>
                                    <span>
                                        <BooleanIconWithLabel
                                            label="Supports Inventory"
                                            value={config.posInventoryEnabled ?? false}
                                        />
                                    </span>
                                    <span>
                                        <BooleanIconWithLabel
                                            label="Enabled"
                                            value={config.enabled}
                                        />
                                    </span>
                                    {isActiveConfig && (
                                        <span className="self-center p-2">Active</span>
                                    )}
                                </Paper>
                            );
                        })}
                    </div>
                </Paper>
            )}
        </>
    );
};
