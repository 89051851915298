import { useState } from 'react';

import { useErrorToastOnDuplicateApiKey } from '@views/control-center/retailers/components/useToastOnDuplicateApiKey';

import { BlazeCredentials, BlazeLocation } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Add } from '@components/icons';
import Paper from '@components/layout/Paper';

interface IBlazeLocationFieldsProps {
    i: number;
    initialLocation: BlazeLocation;
    onUpdateLocation: (i: number, key: string, value: any) => void;
    onRemoveLocation: (i: number) => void;
}

const BlazeLocationFields = ({
    i,
    initialLocation,
    onUpdateLocation,
    onRemoveLocation,
}: IBlazeLocationFieldsProps) => {
    return (
        <Paper className="repeating-field-group" variant="outlined">
            <Form.TextField
                label="API Key"
                name={`locations.${i}.apiKey`}
                required
                defaultValue={initialLocation?.apiKey}
                onChange={(event) => onUpdateLocation(i, 'apiKey', event.target.value)}
            />
            <Button
                className="repeating-fields-button-remove"
                color="neutral"
                variant="smooth"
                onClick={() => onRemoveLocation(i)}
            >
                Remove Location
            </Button>
        </Paper>
    );
};

interface IBlazePosSpecificFieldsProps {
    credentials?: BlazeCredentials;
    onChange: (key: string, value: any) => void;
}

const BlazePosSpecificFields = ({ credentials, onChange }: IBlazePosSpecificFieldsProps) => {
    const { partnerKey = '' } = credentials || {};
    const [initialLocations] = useState<BlazeLocation[]>(credentials?.locations || []);
    const locations = credentials?.locations || [
        {
            apiKey: '',
        },
    ];

    useErrorToastOnDuplicateApiKey(locations);

    const onUpdateLocation = (i: number, key: string, value: string) => {
        const newLocations = [...locations];
        newLocations[i][key as keyof BlazeLocation] = value;
        onChange('locations', newLocations);
    };

    const onAddLocation = () => {
        const newLocations = [
            ...locations,
            {
                apiKey: '',
            },
        ];
        onChange('locations', newLocations);
    };

    const onRemoveLocation = (i: number) => {
        const newLocations = locations.filter((location, j) => j !== i);
        onChange('locations', newLocations);
    };

    return (
        <>
            <Form.TextField
                label="Partner Key"
                name="partnerKey"
                required
                value={partnerKey}
                onChange={(event) => onChange('partnerKey', event.target.value)}
            />

            <div className="form-control repeating-fields">
                <Form.Label>Locations</Form.Label>
                <div className="repeating-fields-fields">
                    {locations.map((location, i) => (
                        <BlazeLocationFields
                            key={`blaze-credentials-locations-${i}`}
                            i={i}
                            initialLocation={initialLocations?.[i]}
                            onUpdateLocation={onUpdateLocation}
                            onRemoveLocation={onRemoveLocation}
                        />
                    ))}
                </div>
                <Button
                    className="repeating-fields-button-add"
                    color="blue"
                    variant="smooth"
                    onClick={onAddLocation}
                    startIcon={<Add />}
                >
                    Add Location
                </Button>
            </div>
        </>
    );
};

export default BlazePosSpecificFields;
