import React, { ReactElement } from 'react';

import { debounce } from 'lodash';

import { ListControlCenterSparksQueryParams } from '@sparkplug/lib';

import { CalendarMonth, Flag, Send } from '@components/icons';
import { queryToString, useHistory, useQueryParams } from '@components/router';
import FilterSegmentToolbar from '@components/toolbar/FilterSegmentToolbar';
import Toolbar from '@components/toolbar/Toolbar';
import ToolbarSearch from '@components/toolbar/ToolbarSearch';

import {
    fulfillmentTypeOptions,
    internalTrackingStatusOptions,
    invoiceStatusOptions,
    payoutStatusOptions,
    scheduleStatusOptions,
} from '../../utils/filterOptions';

const DATE_FORMAT = 'YYYY-MM-DD';
type DropdownOption = {
    key: keyof ListControlCenterSparksQueryParams;
    isActive?: boolean;
    options: any[];
    label: string;
    Icon?: ReactElement;
};
const FilterToolbar: React.FC = () => {
    const { p, ...queryParams }: { p?: number } & ListControlCenterSparksQueryParams =
        useQueryParams();
    const history = useHistory();
    const searchRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, [queryParams]);
    const handleDropdownChange =
        (key: keyof ListControlCenterSparksQueryParams, options: any[]) => (v: any) => {
            // remove specialPayout and failedPayment from queryParams so they don't conflict with other filters
            const { [key]: _, ...rest } = queryParams;
            history.push(`?${queryToString({ ...rest, ...(v && { [key]: v }) })}`);
        };

    const handleClear = (key: keyof ListControlCenterSparksQueryParams) => () => {
        const { [key]: _, ...rest } = queryParams;
        history.push(`?${queryToString(rest)}`);
    };

    const dropdowns: DropdownOption[] = [
        {
            key: 'invoiceStatus',
            label: 'Invoice Status',
            options: invoiceStatusOptions,
            isActive: true,
        },
        {
            key: 'payoutStatus',
            label: 'Payout Status',
            options: payoutStatusOptions,
            isActive: true,
        },
        {
            key: 'schedule',
            label: 'Schedule',
            options: scheduleStatusOptions,
            Icon: <CalendarMonth />,
        },
        {
            key: 'fulfillmentType',
            label: 'Fulfillment Type',
            options: fulfillmentTypeOptions,
            Icon: <Send />,
        },
        {
            key: 'internalStatus',
            label: 'Internal Status',
            options: internalTrackingStatusOptions,
            Icon: <Flag />,
        },
    ];
    const segments = [
        {
            label: '',
            isActive: true,
            element: (
                <Toolbar.DateRangeSelector
                    id="dateRange"
                    label="Completed"
                    dateStart={queryParams.startDate}
                    dateEnd={queryParams.endDate}
                    onApply={(dateStart, dateEnd) => {
                        const { startDate, endDate, ...rest } = queryParams;
                        history.push(
                            `?${queryToString({
                                ...rest,
                                ...(dateStart && { startDate: dateStart.format(DATE_FORMAT) }),
                                ...(dateEnd && { endDate: dateEnd.format(DATE_FORMAT) }),
                            })}`,
                        );
                    }}
                />
            ),
        },
        ...dropdowns.map(({ key, options, isActive, label, Icon }) => ({
            startIcon: Icon,
            label,
            isActive: isActive || !!queryParams[key],
            element: (
                <Toolbar.Dropdown
                    key={key}
                    label={null}
                    value={(queryParams[key] || '').toString()}
                    onSelect={handleDropdownChange(key, options)}
                    options={options}
                    clear={{
                        active: !!queryParams[key],
                        onClear: handleClear(key),
                    }}
                />
            ),
        })),
    ];

    return (
        <div>
            <Toolbar className="toolbar-content-start" scrollOnMobile>
                <ToolbarSearch
                    name="sparks-search"
                    className="sparks-search"
                    defaultValue={queryParams.search || ''}
                    inputRef={searchRef}
                    onChange={debounce(({ target }) => {
                        const { search, ...rest } = queryParams;
                        history.push(
                            `?${queryToString({
                                ...rest,
                                ...(target.value && { search: target.value }),
                            })}`,
                        );
                    }, 250)}
                />

                <FilterSegmentToolbar label="" segments={segments} addButtonText="" />
            </Toolbar>{' '}
        </div>
    );
};

export default FilterToolbar;
