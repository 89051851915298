import { GoTabCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface IGoTabPosSpecificFieldsProps {
    credentials?: GoTabCredentials;
    onChange: (key: string, value: any) => void;
}

const GoTabPosSpecificFields = ({ credentials, onChange }: IGoTabPosSpecificFieldsProps) => {
    const { accessId = '', accessSecret = '', refreshToken = '' } = credentials || {};

    return (
        <>
            <Form.TextField
                label="Access ID"
                name="accessId"
                required
                defaultValue={accessId}
                onChange={(event) => onChange('accessId', event.target.value)}
            />
            <Form.TextField
                label="Access Secret"
                name="accessSecret"
                required
                defaultValue={accessSecret}
                onChange={(event) => onChange('accessSecret', event.target.value)}
            />
            <Form.TextField
                label="Refresh Token"
                name="refreshToken"
                defaultValue={refreshToken}
                disabled
                onChange={(event) => onChange('refreshToken', event.target.value)}
            />
        </>
    );
};

export default GoTabPosSpecificFields;
