import { useCallback, useMemo, useState } from 'react';

import { useSearch } from '@hooks/UIHooks';

import { sortByString } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

import { ProductInventoryRow } from './useProductInventoryHeadCells';

interface VisibleOptionsParams {
    allOptions: IOption[];
    products: ProductInventoryRow[];
    filters: ProductInventoryFilters;
    optionType: 'brand' | 'category' | 'product' | 'location';
}

const getVisibleOptions = ({
    allOptions,
    products,
    filters,
    optionType,
}: VisibleOptionsParams): IOption[] => {
    const filterSets = {
        sparkBrands: new Set(filters.sparkBrands?.map((b) => b.value) || []),
        categories: new Set(filters.categories?.map((c) => c.value) || []),
        products: new Set(filters.products?.map((p) => p.value) || []),
        locations: new Set(filters.locations?.map((l) => l.value) || []),
    };

    const otherFiltersApplied = Object.entries(filterSets).some(
        ([key, set]) =>
            key !== (optionType === 'brand' ? 'sparkBrands' : `${optionType}s`) && set.size > 0,
    );

    if (!otherFiltersApplied) {
        return allOptions.sort(sortByString('label', 'asc'));
    }

    const filteredProducts = products.filter((product) => {
        if (
            filterSets.sparkBrands.size &&
            !filterSets.sparkBrands.has(product.sparkBrand?._id || '')
        )
            return false;
        if (filterSets.categories.size && !filterSets.categories.has(product.categoryId))
            return false;
        if (filterSets.products.size && !filterSets.products.has(product._id)) return false;
        if (filterSets.locations.size && !filterSets.locations.has(product?.locationId || ''))
            return false;
        return true;
    });

    const getRelevantId = (product: ProductInventoryRow): string => {
        switch (optionType) {
            case 'brand':
                return product.sparkBrand?._id || '';
            case 'category':
                return product.categoryId;
            case 'product':
                return product._id;
            case 'location':
                return product?.locationId || '';
            default:
                throw new Error(`Unexpected optionType: ${optionType}`);
        }
    };

    const relevantIds = new Set(filteredProducts.map(getRelevantId));

    return allOptions
        .filter((option) => relevantIds.has(option.value))
        .sort(sortByString('label', 'asc'));
};

export interface ProductInventoryFilters {
    sparkBrands: IOption[];
    categories: IOption[];
    products: IOption[];
    locations: IOption[];
    outOfStock: boolean;
}

export const useProductInventoryTableFilters = ({
    initialFilters = {},
    isRetailerDashboard,
    initialProducts: products,
    brandOptions,
    categoryOptions,
    productOptions,
    locationOptions,
}: {
    initialFilters: Partial<ProductInventoryFilters>;
    isRetailerDashboard: boolean;
    initialProducts: ProductInventoryRow[];
    brandOptions: IOption[];
    categoryOptions: IOption[];
    productOptions: IOption[];
    locationOptions: IOption[];
}) => {
    const [filters, setFilters] = useState<ProductInventoryFilters>({
        sparkBrands: [],
        categories: [],
        products: [],
        locations: [],
        outOfStock: false,
        ...initialFilters,
    });

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'name',
        'brand',
        'category',
    ]);

    const updateFilters = useCallback((updatedFilters: Partial<ProductInventoryFilters>) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...updatedFilters,
        }));
    }, []);

    const applySparkBrandsFilter = useCallback(
        (rows: ProductInventoryRow[]) => {
            if (!filters.sparkBrands.length) return rows;
            return rows.filter((product) =>
                filters.sparkBrands.some(
                    (brand) => brand.value === (product.sparkBrand?._id || ''),
                ),
            );
        },
        [filters.sparkBrands],
    );

    const applyCategoryFilter = useCallback(
        (rows: ProductInventoryRow[]) => {
            if (!filters.categories.length) return rows;
            return rows.filter((product) =>
                product.categories.some((category) =>
                    filters.categories.some((filter) => filter.value === category._id),
                ),
            );
        },
        [filters.categories],
    );

    const applyProductFilter = useCallback(
        (rows: ProductInventoryRow[]) => {
            if (!filters.products.length) return rows;
            return rows.filter((product) =>
                filters.products.some((filter) => filter.value === product._id),
            );
        },
        [filters.products],
    );

    const applyLocationFilter = useCallback(
        (rows: ProductInventoryRow[]) => {
            if (!filters.locations.length) return rows;
            return rows.filter((product) => {
                if (!product.locationId || product.locationId === '') {
                    return filters.locations.some((filter) => filter.value === '');
                }
                return filters.locations.some((filter) => filter.value === product.locationId);
            });
        },
        [filters.locations],
    );

    const applyOutOfStockFilter = useCallback(
        (rows: ProductInventoryRow[]) => {
            if (!filters.outOfStock) return rows;
            return rows.filter((product) => product.inventory.quantityAvailable === 0);
        },
        [filters.outOfStock],
    );

    const visibleBrandOptions = useMemo(
        () =>
            getVisibleOptions({ allOptions: brandOptions, products, filters, optionType: 'brand' }),
        [brandOptions, products, filters],
    );

    const visibleCategoryOptions = useMemo(
        () =>
            getVisibleOptions({
                allOptions: categoryOptions,
                products,
                filters,
                optionType: 'category',
            }),
        [categoryOptions, products, filters],
    );

    const visibleProductOptions = useMemo(
        () =>
            getVisibleOptions({
                allOptions: productOptions,
                products,
                filters,
                optionType: 'product',
            }),
        [productOptions, products, filters],
    );
    const visibleLocationOptions = useMemo(() => {
        const options = getVisibleOptions({
            allOptions: locationOptions,
            products,
            filters,
            optionType: 'location',
        });

        // Move "Missing Location" to the end of the list if present
        const missingLocationIndex = options.findIndex(
            (option) => option.value === 'missing_location',
        );
        if (missingLocationIndex !== -1) {
            const missingLocation = options.splice(missingLocationIndex, 1)[0];
            options.push(missingLocation);
        }

        return options;
    }, [locationOptions, products, filters]);

    const selectedBrandCountProps = useMemo(() => {
        const hiddenCount = brandOptions.length - visibleBrandOptions.length;

        const isOrAre = hiddenCount > 1 ? 'brands are' : 'brand is';
        const tooltipMessage = `${hiddenCount} ${isOrAre} hidden because there are other filters applied`;

        return {
            tooltipMessage: hiddenCount > 0 ? tooltipMessage : undefined,
            pluralUnitLabel: 'brands',
        };
    }, [brandOptions, visibleBrandOptions]);

    const selectedCategoryCountProps = useMemo(() => {
        const hiddenCount = categoryOptions.length - visibleCategoryOptions.length;

        const isOrAre = hiddenCount > 1 ? 'categories are' : 'category is';
        const tooltipMessage = `${hiddenCount} ${isOrAre} hidden because there are other filters applied`;

        return {
            tooltipMessage: hiddenCount > 0 ? tooltipMessage : undefined,
            pluralUnitLabel: 'categories',
        };
    }, [categoryOptions, visibleCategoryOptions]);

    const selectedLocationCountProps = useMemo(
        () => ({
            tooltipMessage:
                visibleLocationOptions.length < locationOptions.length
                    ? `${locationOptions.length - visibleLocationOptions.length} location${
                          locationOptions.length - visibleLocationOptions.length > 1
                              ? 's are'
                              : ' is'
                      } hidden due to other filters`
                    : undefined,
            pluralUnitLabel: 'locations',
        }),
        [visibleLocationOptions, locationOptions],
    );

    const selectedProductCountProps = useMemo(
        () => ({
            tooltipMessage:
                visibleProductOptions.length < productOptions.length
                    ? `${productOptions.length - visibleProductOptions.length} product${
                          productOptions.length - visibleProductOptions.length > 1 ? 's are' : ' is'
                      } hidden due to other filters`
                    : undefined,
            pluralUnitLabel: 'products',
        }),
        [visibleProductOptions, productOptions],
    );

    const resetFilters = useCallback(() => {
        setFilters({
            sparkBrands: [],
            categories: [],
            products: [],
            locations: [],
            outOfStock: false,
        });
    }, []);

    const tableFilters = useMemo(() => {
        if (isRetailerDashboard) {
            return [
                applyCategoryFilter,
                applySparkBrandsFilter,
                applyLocationFilter,
                applyProductFilter,
                applyOutOfStockFilter,
            ];
        } else {
            return [applyLocationFilter, applyOutOfStockFilter];
        }
    }, [
        applyCategoryFilter,
        applySparkBrandsFilter,
        applyLocationFilter,
        applyProductFilter,
        applyOutOfStockFilter,
        isRetailerDashboard,
    ]);

    return {
        searchFilter,
        filters,
        applySearch,
        updateFilters,
        onChangeSearchFilter,
        resetFilters,
        tableFilters,
        visibleBrandOptions,
        visibleCategoryOptions,
        visibleLocationOptions,
        visibleProductOptions,
        selectedBrandCountProps,
        selectedCategoryCountProps,
        selectedProductCountProps,
        selectedLocationCountProps,
    };
};
