import { useMemo, useState } from 'react';

import { ListControlCenterSparksQueryParams } from '@sparkplug/lib';

import { SparkProvider } from '@contexts/SparkContext';
import { TableProvider } from '@contexts/TableContext';

import Modal from '@components/overlays/Modal';
import { useQueryParams } from '@components/router';
import Table from '@components/table/Table';

import FilterToolbar from './components/FilterToolbar';
import SparkApprovalModal from './components/SparkApprovalModal';
import { useGetSparksQuery } from './hooks/useCCSparksQuery';
import {
    CCDNPSparksTableHeadCells,
    CCSparkTableRow,
    buildCCSparkTableRow,
} from './utils/tableUtils';

import './styles.scss';

const CCDNPSparksView = () => {
    const [activeSparkId, setActiveSparkId] = useState<string | undefined>(undefined);

    const { p, ...queryParams }: { p?: number } & ListControlCenterSparksQueryParams =
        useQueryParams();

    const {
        sparks,
        meta: queryMeta,
        isLoading,
        refetch,
    } = useGetSparksQuery({
        offset: Number(p),
        ...queryParams,
        internalStatus: 'do-not-payout',
    });

    const sparkTableData = useMemo(() => {
        if (isLoading) return [];

        return sparks.map((spark) => buildCCSparkTableRow(spark));
    }, [sparks, isLoading]);
    return (
        <div className="cc-recent-sparks-view" data-testid="cc-fulfilled-sparks-view">
            <FilterToolbar />
            <TableProvider
                rows={sparkTableData}
                headCells={CCDNPSparksTableHeadCells()}
                defaultOptions={{
                    rowsPerPage: queryParams.limit,
                    orderBy: queryParams.orderBy,
                    order: queryParams.order === 'desc' ? 'desc' : 'asc',
                }}
                isLoading={isLoading}
                showPagination
                showCheckboxes={false}
                rowCountOverride={queryMeta?.totalDocuments}
                enableQueryParams
                disableFrontendFiltering
            >
                <Table variant="raised" useExternalProvider>
                    <Table.RenderHead />
                    <Table.RenderBody
                        onRowClick={(sparkRow: CCSparkTableRow) => {
                            setActiveSparkId(sparkRow._id);
                        }}
                    />
                </Table>
            </TableProvider>

            {activeSparkId && (
                <Modal
                    isVisible={!!activeSparkId}
                    onClose={() => setActiveSparkId(undefined)}
                    className="direct-checkout-modal"
                    size="extra-wide"
                    maxWidth={1400}
                >
                    <SparkProvider sparkId={activeSparkId}>
                        <SparkApprovalModal
                            onClose={() => setActiveSparkId(undefined)}
                            refetchRecentCCSparks={refetch}
                        />
                    </SparkProvider>
                </Modal>
            )}
        </div>
    );
};
export default CCDNPSparksView;
