import axios from 'axios';
import { isNil, omitBy } from 'lodash';

import {
    FinalizeSparkRequestBody,
    GetSparkHistoryResponse,
    GetSparkPathParams,
    GetSparkResponseBody,
    GetSparkRewardsResponse,
    ListSparksQueryParams,
    ListSparksResponseBody,
    PublicSparkPosArchive,
    Spark,
    SparkParticipantGroup,
    UpsertManySparkRewardsRequestBody,
} from '@sparkplug/lib';

export default class SparksAPI {
    static async getSparks(params: ListSparksQueryParams): Promise<ListSparksResponseBody> {
        return (
            await axios.get(`/api/v1/pos/sparks`, {
                params: {
                    ...omitBy(params, isNil),
                    offset: params.offset ?? 0,
                    limit: params.limit ?? 1000,
                },
            })
        ).data;
    }

    static async getSpark({ sparkId }: GetSparkPathParams): Promise<GetSparkResponseBody> {
        try {
            return (await axios.get(`/api/v1/pos/sparks/${sparkId}`)).data;
        } catch (error: any) {
            return error;
        }
    }

    static async createSpark(
        properties: Spark & {
            participantGroups: SparkParticipantGroup[];
            requestForSparkId?: string;
        },
    ) {
        return (await axios.post<Spark>(`/api/v1/pos/sparks`, properties)).data;
    }

    static async updateSpark(
        sparkId: string,
        properties: Partial<Spark> & { participantGroups?: SparkParticipantGroup[] },
    ) {
        return (await axios.put(`/api/v1/pos/sparks/${sparkId}`, properties)).data;
    }

    static async deleteSpark(sparkId: string): Promise<any> {
        return (await axios.delete(`/api/v1/pos/sparks/${sparkId}`)).data;
    }

    static async endRecurringSparkSchedule(sparkId: string): Promise<any> {
        return (await axios.delete(`/api/v1/pos/sparks/end-recurrence/${sparkId}`)).data;
    }

    static getSparkPosArchive(sparkId: string) {
        try {
            return axios.get<PublicSparkPosArchive>(
                `/api/v1/sparkplug/sparks/pos-archives/${sparkId}`,
            );
        } catch (err) {
            if (err instanceof Error) {
                throw new Error(`getSparkPosArchive (${sparkId}) failed: ${err?.message}`);
            }

            throw new Error(`getSparkPosArchive (${sparkId}) failed: ${JSON.stringify(err)}`);
        }
    }

    static async getPendingSparksCount(accountId: string): Promise<number> {
        return (
            await axios.get(`/api/v1/sparks/pending`, {
                params: {
                    accountId,
                },
            })
        ).data;
    }

    static async getTotalSparksCount(accountId: string): Promise<number> {
        return (
            await axios.get(`/api/v1/sparks/total`, {
                params: {
                    accountId,
                },
            })
        ).data;
    }

    static getSparkRewards(sparkId: string) {
        return axios.get<GetSparkRewardsResponse>(`/api/v1/sparkplug/sparks/${sparkId}/rewards`);
    }

    static upsertManySparkRewards(sparkId: string, payload: UpsertManySparkRewardsRequestBody) {
        return axios.put<void>(`/api/v1/sparkplug/sparks/${sparkId}/rewards`, payload);
    }

    static finalizeSpark(sparkId: string, payload: FinalizeSparkRequestBody) {
        return axios.post<void>(`/api/v1/sparkplug/sparks/${sparkId}/finalize`, payload);
    }

    static getSparkInvoiceUrl(sparkId: string) {
        return axios.get<{ invoiceUrl: string }>(`/api/v1/sparkplug/sparks/${sparkId}/invoice-url`);
    }

    static getSparkHistory(sparkId: string) {
        return axios.get<GetSparkHistoryResponse>(`/api/v1/sparkplug/sparks/${sparkId}/history`);
    }

    static unfinalizeSpark(sparkId: string) {
        return axios.put<void>(`/api/v1/sparks/${sparkId}/unfinalize`);
    }
}
