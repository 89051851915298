import { FC, useMemo, useState } from 'react';

import { isEmpty, keyBy } from 'lodash';

import { Spark } from '@sparkplug/lib';

import { useSparkplugUsers } from '@core/users';

import { useGroupSparkCreditEntries, useSparkCreators } from '@features/spark-credits/queries';
import { isDebit } from '@features/spark-credits/utils';

import { SparksProvider, useSparks } from '@contexts/SparksContext';

import { Add as AddIcon } from '@components/icons';
import Toolbar from '@components/toolbar/Toolbar';

import { useQueryParamsState } from '@hooks/QueryParamsHooks';
import { useSparkplugAccount, useSparkplugAccounts } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import { IAccount } from '@app/types/AccountsTypes';

import { SparkCreditsModal } from './SparkCreditsModal';
import { SparkCreditsTable, SparkCreditsTableRow } from './SparkCreditsTable';

import './CCSparkCreditsView.scss';

const flattenGroupedSparks = (sparks: Array<Spark & { groupedSparks?: Spark[] }>) => {
    return sparks.flatMap((spark) => {
        if (spark.groupedSparks) {
            return [...spark.groupedSparks, spark];
        }

        return [spark];
    });
};

export const useSparkCreditsTableData = (account: IAccount) => {
    const { sparks: originalSparks = [], sparksAreReady } = useSparks();
    const sparks = useMemo(() => flattenGroupedSparks(originalSparks), [originalSparks]);

    const { sparkplugUsers: superAdminUsers = [], isSparkplugUsersReady: superAdminUsersAreReady } =
        useSparkplugUsers({ role: 'super-admin' });
    const { sparkCreators, sparkCreatorsAreReady } = useSparkCreators({ account, sparks });
    const { accounts = [], accountsAreReady } = useSparkplugAccounts();
    const { groupSparkCreditEntries = [], groupSparkCreditEntriesAreReady } =
        useGroupSparkCreditEntries({
            groupId: account._id,
        });

    const sparkCreditsTableData: SparkCreditsTableRow[] = useMemo(() => {
        const groupsById = keyBy(accounts, '_id');
        const sparksById = keyBy(flattenGroupedSparks(sparks), '_id');
        const superAdminUsersById = keyBy(superAdminUsers, '_id');
        const sparkCreatorsById = keyBy(sparkCreators, '_id');

        return groupSparkCreditEntries.map((sparkCredit) => {
            const spark = isDebit(sparkCredit) ? sparksById[sparkCredit.sparkId] : undefined;
            const sparkCreator = sparkCreatorsById[spark?.creatorUserId ?? ''];
            const superUser = superAdminUsersById[sparkCredit.authorizedBy];
            const user = isDebit(sparkCredit) ? sparkCreator : superUser;

            const externalValidRetailer =
                groupsById?.[sparkCredit.forGroupId!]?.name ?? 'Any Retailer';

            return {
                ...sparkCredit,
                key: sparkCredit._id,
                username: user ? `${user?.firstName} ${user?.lastName}` : undefined,
                spark,
                validRetailer: account.type === 'retailer' ? account.name : externalValidRetailer,
            };
        });
    }, [accounts, groupSparkCreditEntries, sparks, superAdminUsers, sparkCreators]);

    const sparkCreditsTableDataIsReady =
        accountsAreReady &&
        groupSparkCreditEntriesAreReady &&
        sparksAreReady &&
        superAdminUsersAreReady &&
        sparkCreatorsAreReady;

    return {
        sparkCreditsTableData,
        sparkCreditsTableDataIsReady,
    };
};

const CCSparkCreditsView: FC<{ account: IAccount }> = ({ account }) => {
    const [showSparkCreditsModal, setShowSparkCreditsModal] = useState<boolean>(false);

    const { sparkCreditsTableData, sparkCreditsTableDataIsReady } =
        useSparkCreditsTableData(account);

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'username',
        'creditType',
        'validRetailer',
        'amount',
        'spark.name',
    ]);

    const [query, setQuery] = useQueryParamsState({
        creditType: 'all',
    });

    const { creditType: creditTypeFilter } = query;

    const applyCreditTypeFilter = (sparkCreditsToFilter: any[] = []) => {
        if (creditTypeFilter === 'all') {
            return sparkCreditsToFilter;
        }
        return sparkCreditsToFilter.filter(
            (sparkCredit) => sparkCredit?.creditType === creditTypeFilter,
        );
    };

    return (
        <>
            <div className="cc-spark-credits-view_container">
                <Toolbar className="toolbar-content-start" scrollOnMobile>
                    {!isEmpty(sparkCreditsTableData) && (
                        <>
                            <Toolbar.Search
                                name="spark-credits_search"
                                defaultValue={searchFilter}
                                onChange={onChangeSearchFilter}
                            />

                            <Toolbar.Dropdown
                                label={null}
                                value={creditTypeFilter}
                                onSelect={(v) => setQuery({ creditType: v })}
                                options={[
                                    { value: 'all', label: 'All Credit Types' },
                                    { value: 'plan', label: 'Plan Credit Types' },
                                    { value: 'promotion', label: 'Promotional Credit Types' },
                                    { value: 'referral', label: 'Referral Credit Types' },
                                ]}
                                clear={{
                                    active: creditTypeFilter !== 'all',
                                    onClear: () => setQuery({ creditType: 'all' }),
                                }}
                            />
                        </>
                    )}

                    <Toolbar.Button
                        className="toolbar-group-end"
                        variant="filled"
                        startIcon={<AddIcon />}
                        onClick={() => setShowSparkCreditsModal(true)}
                    >
                        Create New Credit
                    </Toolbar.Button>
                </Toolbar>

                <SparkCreditsTable
                    isReady={sparkCreditsTableDataIsReady}
                    rows={sparkCreditsTableData}
                    filters={[applySearch, applyCreditTypeFilter]}
                />
            </div>

            {showSparkCreditsModal && (
                <SparkCreditsModal
                    isVisible
                    account={account}
                    onClose={() => {
                        setShowSparkCreditsModal(false);
                    }}
                />
            )}
        </>
    );
};

export default () => {
    const { account } = useSparkplugAccount();
    if (!account) {
        return <></>;
    }

    return (
        <SparksProvider>
            <CCSparkCreditsView account={account} />
        </SparksProvider>
    );
};
