import { LightspeedCredentials } from '@sparkplug/lib';

import Form from '@components/form/Form';

interface LightspeedPosSpecificFieldsProps {
    credentials?: LightspeedCredentials;
    onChange: (key: string, value: any) => void;
}

const LightspeedPosSpecificFields = ({
    credentials,
    onChange,
}: LightspeedPosSpecificFieldsProps) => {
    const { accountId = '', refreshToken = '' } = credentials || {};

    return (
        <>
            <Form.TextField
                label="Account ID"
                name="accountId"
                required
                defaultValue={accountId}
                onChange={(event) => onChange('accountId', event.target.value)}
            />
            <Form.TextField
                label="Refresh Token"
                name="refreshToken"
                required
                defaultValue={refreshToken}
                onChange={(event) => onChange('refreshToken', event.target.value)}
            />
        </>
    );
};

export default LightspeedPosSpecificFields;
