import { useMemo, useState } from 'react';

import { keyBy } from 'lodash';

import { ListControlCenterSparksQueryParams } from '@sparkplug/lib';

import { SparkProvider } from '@contexts/SparkContext';
import { TableProvider } from '@contexts/TableContext';

import Modal from '@components/overlays/Modal';
import { useQueryParams } from '@components/router';
import Table from '@components/table/Table';

import { useSparkPlugAccountsPaymentHealth } from '@hooks/SparkplugAccountsHooks';

import FilterButtons from './components/FilterButtons';
import FilterToolbar from './components/FilterToolbar';
import SparkApprovalModal from './components/SparkApprovalModal';
import SparkCheckoutViewModal from './components/SparkCheckoutModal';
import { useGetSparksQuery } from './hooks/useCCSparksQuery';
import { CCSparkTableRow, CCSparksTableHeadCells, buildCCSparkTableRow } from './utils/tableUtils';

import './styles.scss';

const CCUnfulfilledSparksView = () => {
    const [activeSparkId, setActiveSparkId] = useState<string | undefined>(undefined);
    const [quickCheckoutSparkId, setQuickCheckoutSparkId] = useState<string | undefined>(undefined);

    const { p, ...queryParams }: { p?: number } & ListControlCenterSparksQueryParams =
        useQueryParams();

    const {
        sparks,
        meta: queryMeta,
        isLoading,
        refetch,
    } = useGetSparksQuery({
        offset: Number(p),
        isUnfulfilled: true,
        ...queryParams,
    });

    const accountsFromSparks = useMemo(() => {
        if (isLoading) return [];

        const accountIds = sparks.map((spark) => spark.originatorGroupId || spark.groupId);
        const uniqueAccountIds = [...new Set(accountIds)];
        return uniqueAccountIds;
    }, [sparks, isLoading]);

    const { paymentHealth, paymentHealthIsReady } =
        useSparkPlugAccountsPaymentHealth(accountsFromSparks);

    const sparkTableData = useMemo(() => {
        if (isLoading) return [];

        const paymentHealthMap = keyBy(paymentHealth, 'accountId');
        return sparks.map((spark) => buildCCSparkTableRow(spark, paymentHealthMap));
    }, [sparks, isLoading, paymentHealthIsReady]);
    return (
        <div className="cc-recent-sparks-view" data-testid="cc-unfulfilled-sparks-view">
            <FilterButtons />
            <FilterToolbar />
            <TableProvider
                rows={sparkTableData}
                headCells={CCSparksTableHeadCells({ setQuickCheckoutSparkId })}
                defaultOptions={{
                    rowsPerPage: queryParams.limit,
                    orderBy: queryParams.orderBy,
                    order: queryParams.order === 'desc' ? 'desc' : 'asc',
                }}
                isLoading={isLoading}
                showPagination
                showCheckboxes={false}
                rowCountOverride={queryMeta?.totalDocuments}
                enableQueryParams
                disableFrontendFiltering
            >
                <Table variant="raised" useExternalProvider>
                    <Table.RenderHead />
                    <Table.RenderBody
                        onRowClick={(sparkRow: CCSparkTableRow, event) => {
                            if (!event.metaKey) {
                                setActiveSparkId(sparkRow._id);
                            }
                        }}
                    />
                </Table>
            </TableProvider>
            {quickCheckoutSparkId && (
                <SparkProvider sparkId={quickCheckoutSparkId}>
                    <SparkCheckoutViewModal
                        onClose={() => setQuickCheckoutSparkId(undefined)}
                        refetchRecentCCSparks={refetch}
                    />
                </SparkProvider>
            )}
            {activeSparkId && (
                <Modal
                    isVisible={!!activeSparkId}
                    onClose={() => setActiveSparkId(undefined)}
                    className="direct-checkout-modal"
                    size="extra-wide"
                    maxWidth={1400}
                >
                    <SparkProvider sparkId={activeSparkId}>
                        <SparkApprovalModal
                            onClose={() => setActiveSparkId(undefined)}
                            refetchRecentCCSparks={refetch}
                        />
                    </SparkProvider>
                </Modal>
            )}
        </div>
    );
};
export default CCUnfulfilledSparksView;
