import SparkCheckoutModal from '@views/sparks/SparkCheckoutView/SparkCheckoutModal';

import { SparkWizardAccountProvider } from '@features/spark-wizard/views/SparkWizardView/SparkWizardAccountProvider';

import { useAccountPaymentMethods } from '@hooks/SparkplugAccountsHooks';
import { useSpark, useSparkPayouts } from '@hooks/SparksHooks';

const SparkCheckoutViewModal = ({
    onClose,
    refetchRecentCCSparks,
}: {
    onClose: () => void;
    refetchRecentCCSparks: () => void;
}) => {
    const { spark, sparkSubGroups } = useSpark();
    const payerGroupId = spark?.originatorGroupId || spark?.groupId;

    const {
        isLoadingPayouts,
        payoutsAreFinalized,
        payoutsFinalizedAt,
        multiLeaderboardPayoutsFinalizedAt,
        payouts: sparkPayouts,
        payoutGroups: sparkPayoutGroups,
        updatePayouts: updateSparkPayouts,
        confirmPayouts: confirmSparkPayouts,
    } = useSparkPayouts(spark, sparkSubGroups);
    const { paymentMethods, paymentMethodsAreReady, defaultPaymentMethodId } =
        useAccountPaymentMethods(payerGroupId);
    return (
        <SparkWizardAccountProvider accountId={spark.originatorGroupId || spark.groupId || ''}>
            <div>
                <SparkCheckoutModal
                    sparkPayouts={sparkPayouts}
                    sparkPayoutGroups={sparkPayoutGroups}
                    sparkPayoutsAreReady={!isLoadingPayouts}
                    confirmSparkPayouts={confirmSparkPayouts}
                    paymentMethods={paymentMethods}
                    paymentMethodsAreReady={paymentMethodsAreReady}
                    isVisible={!!spark._id}
                    onClose={() => onClose()}
                    finalizeCallback={refetchRecentCCSparks}
                />
            </div>
        </SparkWizardAccountProvider>
    );
};

export default SparkCheckoutViewModal;
