import { useEffect, useState } from 'react';

import { EmployeeSpark, IPublicReward, Spark } from '@sparkplug/lib';

import { useEmployeeSparks } from '@core/employees/queries';

import FeaturedSnapsList from '@features/spark-snaps/components/FeaturedSnapsList';
import { useEmployeeFeaturedSnaps } from '@features/spark-snaps/queries/FeaturedSnapQueries';

import { NoSparksGraphic } from '@components/graphics';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Skeleton from '@components/layout/Skeleton';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useCurrentUserRewards } from '@hooks/RewardsHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import Intercom from '@helpers/Intercom';
import { getUrlParameter } from '@helpers/ui';

import { SPARK_SCHEDULE_OPTIONS, SPARK_TYPE_OPTIONS } from '../../SparksView.constants';
import SparkStatusButtons from '../../components/SparkStatusButtons';
import SparksList from '../../components/SparksList';
import { useSparksFilters } from '../../hooks/useSparkFilters';

import './SparksViewMobile.scss';

export const applySparkClaimFilters = (
    sparks: Spark[],
    rewards: IPublicReward[],
    value: 'claimed' | 'unclaimed' | 'all',
): Spark[] => {
    return sparks.filter((spark) => {
        if (value === 'all') {
            return true;
        }

        const userPayout = (rewards || []).find((reward) => {
            const isSameSpark = spark?._id === reward?.sparkId;
            return isSameSpark;
        });

        if (!userPayout) {
            return false;
        }

        return value === 'claimed'
            ? userPayout?.status === 'claimed'
            : userPayout?.status === 'confirmed';
    });
};

interface ISparksViewMobileProps {
    initialSparkStatus?: 'active' | 'upcoming' | 'complete';
}

const SparksViewMobile = ({ initialSparkStatus = 'active' }: ISparksViewMobileProps) => {
    const { history } = useApp();

    const { sparks, sparksAreReady, refetchSparks } = useEmployeeSparks();
    const { employeeFeaturedSnaps } = useEmployeeFeaturedSnaps();
    const { account } = useSparkplugAccount();

    const { sparkFilters, updateSparkFilters, applySparkFilters } = useSparksFilters<EmployeeSpark>(
        {
            accountType: account?.type,
            initialFilters: {
                location: account?.locations || [],
                type: [],
                status: initialSparkStatus,
                schedule: [],
            },
        },
    );

    useEffect(() => {
        if (sparkFilters.status === 'complete') {
            // we want to show sparks associated with disabled locations for complete sparks, so we need to use "allLocations" as they're filtered out of "locations"
            updateSparkFilters({ location: account?.allLocations || [] });
        }
    }, [sparkFilters.status]);

    const { isLoadingCurrentUserRewards, currentUserRewards, refetchCurrentUserRewards } =
        useCurrentUserRewards();

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch(['name', 'description']);

    const [filteredSparks, setFilteredSparks] = useState(sparks);

    useEffect(() => {
        refetchSparks();
        refetchCurrentUserRewards();
        if (getUrlParameter('openIntercom')) {
            Intercom.open();
        }
    }, []);

    useEffect(() => {
        let newFilteredSparks = applySparkFilters(sparks || []);
        newFilteredSparks = applySearch(newFilteredSparks);

        setFilteredSparks(newFilteredSparks);
    }, [sparks, sparkFilters, searchFilter, currentUserRewards]);

    const ModifiedSparkTypeOptions = SPARK_TYPE_OPTIONS;

    const sparkStatus = sparkFilters.status;

    const emptyLabel = `no ${sparkStatus} sparks found`;

    const onSparkCardSelected = (spark: Pick<Spark, '_id' | 'requestState'>) => {
        history.push(`/user/sparks/${spark._id}`, {
            prevUrl: document.location.pathname,
            prevTitle: `${sparkFilters.status} Sparks`,
        });
    };

    return (
        <>
            <AppHeaderMobile
                title="Your Sparks"
                subNav={
                    <SparkStatusButtons
                        value={sparkFilters.status || 'active'}
                        onSelect={({ value, to }) => {
                            window.history.pushState(null, '', `/user${to}`);
                            updateSparkFilters({ status: value });
                        }}
                        isBrand={false}
                        isAdmin={false}
                    />
                }
            />
            {employeeFeaturedSnaps && employeeFeaturedSnaps.length > 0 && (
                <FeaturedSnapsList snaps={employeeFeaturedSnaps} accountId={account?._id ?? ''} />
            )}

            <Toolbar className="spark-view-mobile__filter-toolbar">
                <div className="select-fields-wrapper">
                    <Toolbar.MultiSelectDropdown
                        label="Types"
                        options={ModifiedSparkTypeOptions}
                        selected={sparkFilters.type}
                        onApply={(value) => updateSparkFilters({ type: value })}
                    />

                    <Toolbar.MultiSelectDropdown
                        label="Schedule"
                        options={SPARK_SCHEDULE_OPTIONS}
                        selected={sparkFilters.schedule}
                        onApply={(value) => updateSparkFilters({ schedule: value })}
                    />
                </div>

                <Toolbar.Search
                    name="sparks-search"
                    defaultValue={searchFilter}
                    onChange={onChangeSearchFilter}
                />
            </Toolbar>
            {sparksAreReady && !isLoadingCurrentUserRewards ? (
                <SparksList
                    isUserSparksView
                    viewMode="grid"
                    showTrainingIcon
                    sparkStatus={sparkStatus}
                    isEmployeeView
                    sparks={filteredSparks}
                    onSparkCardSelected={onSparkCardSelected}
                    emptyStateComponent={
                        <EmptyStateDisplay graphic={<NoSparksGraphic />} label={emptyLabel} />
                    }
                />
            ) : (
                <>
                    <Grid className="app-sparks-list-grid">
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                    </Grid>
                </>
            )}
        </>
    );
};

export default SparksViewMobile;
